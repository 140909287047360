import router from './route'
// import NProgress from "nprogress";
// import "nprogress/nprogress.css";
import {getToken} from '@/utils/token' // get token from cookie
import {getGoogleType} from '@/utils/google'

// NProgress.configure({ showSpinner: false }); // 进度条
const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  // NProgress.start()

  if (whiteList.indexOf(to.path) !== -1) {
    next()
  }else if (getToken()) {
    let googleType = await getGoogleType();
    console.log('googleType',googleType)
    console.log('to.path',to.path)
    if (to.path === '/login') {
      next({path: '/'})
      // NProgress.done()
    } else if (googleType == null) {
      if(to.path==='/google-check'){
        next()
      }else{
        next({path: '/google-check'})
      }
    } else if([0,1,'0','1'].includes(googleType)){
      if(to.path==='/google-check'){
        next()
      }else{
        next({path: '/google-check'})
      }
    } else {
      next()
      // NProgress.done()
    }
  } else {
    next(`/login${to.path === '/dashboard' ? '' : `?redirect=${to.path}`}`)
    // NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
})
