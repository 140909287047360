<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="名称">
          <el-input clearable  placeholder="根据咨询页名称搜索" v-model="queryParams.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="addItemHandle">
            <el-icon>
              <Plus/>
            </el-icon>
            <span>新增</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column prop="title"  min-width="150"  label="标题" align="center"></el-table-column>
      <el-table-column prop="category" label="分类" align="center" min-width="150" ></el-table-column>
<!--      <el-table-column prop="switchCommon" align="center" label="是否常见问题"></el-table-column>-->

      <el-table-column prop="switchCommon" align="center" label="是否常见问题">
        <template v-slot="{row}">
          <template v-if="row.switchCommon=== '1'">有效</template>
          <template v-if="row.switchCommon=== '0' ">无效</template>
        </template>
      </el-table-column>
<!--      <el-table-column prop="switchServiceEntry" align="center"  label="联系服务入口"></el-table-column>-->

      <el-table-column prop="switchServiceEntry" align="center" label="联系服务入口">
        <template v-slot="{row}">
          <template v-if="row.switchServiceEntry === '1'">有效</template>
          <template v-if="row.switchServiceEntry === '0' ">无效</template>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容" align="center"></el-table-column>
      <el-table-column prop="priority" align="center" label="优先级"></el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status=== '1'">有效</template>
          <template v-if="row.status=== '0' ">无效</template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button  type="text"  size="small"  @click="itemEditHandle(scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
          <el-button v-if="scope.row.status === '1'" type="text" size="small" @click="forbidden(scope.$index)">关闭</el-button>
          <el-button v-else type="text" size="small" @click="open(scope.$index)">开启</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >

    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="标题" prop="title" required>
        <el-input v-model="item.title" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="分类" prop="category" required>
        <el-input v-model="item.category" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="分类" prop="category" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.category">
          <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否有效" prop="switchCommon" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.switchCommon">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否有效" prop="switchServiceEntry" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.switchServiceEntry">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="内容" prop="content" required>
        <el-input v-model="item.content" placeholder="请输入"></el-input>
      </el-form-item>

<!--      <el-form-item label="内容" prop="content">-->
<!--        <vue-tinymce  v-model="item.content"/>-->
<!--      </el-form-item>-->
      <el-form-item label="优先级：" >
        <el-slider v-model="item.priority" show-input />
      </el-form-item>
      <el-form-item label="是否有效" prop="status" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.status">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      tableData: [],
      item: {},
      dialogVisible: false,
      isEditDialog: false,
      queryParams:{},
      keeps: [
        {label: '有效', value: '1'},
        {label: '无效', value: '0'},
      ],
      type: [
        {label: '注册和登录', value: '注册和登录'},
        {label: '充值相关', value: '充值相关'},
        {label: '提款相关', value: '提款相关'},
        {label: '其它', value: '其它'},
      ],
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],
        category: [
          {required: true, message: '请输入分类', trigger: 'blur'},
        ],  switchCommon: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],  switchServiceEntry: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入内容', trigger: 'blur'},
        ],priority: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],status: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
      }
    };
  },

  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },

    /**
     * 添加数据
     */
    addData() {
      const data = {
        cmd: "faq",
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("faq", data)
          .then((response) => {
            console.log("response.data", response.data);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },

    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.queryData()
          this.dialogVisible = false;
        }
      })

    },
    /**
     * 修改数据
     */
    updateData() {
      const data = {
        cmd: "faq",
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post("faq", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 查询数据
     */
    queryData() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        action: "query",
        data: {
          paramVO: {params},
        },
      };
      this.$http
          .post("faq", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.tableData = response.data.list;
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 关闭数据
     */
    disableDate() {
      const data = {
        cmd: "faq",
        action: "disable",
        data: {
          dailyEventVO: {
            id: "1",
          },
        },
      };
      this.$http
          .post("faq", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    /**
     * 删除数据
     */
    deleteData(id) {
      const data = {
        cmd: "faq",
        action: "delete",
        data: {  paramVO: {id:id}
        },
      };
      this.$http
          .post("faq", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    forbidden(index) {
      this.$confirm("确定要禁用吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = this.tableData[index];
        this.item.status  = '0'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消禁用",
            });
          });
    },
    open(index) {
      this.$confirm("确定要开启吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item =JSON.parse(JSON.stringify(this.tableData[index]));
        this.item.status  = '1'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消开启",
            });
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
