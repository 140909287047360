<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <!--    <br />-->
    <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="3">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>

      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px" >
        <el-col v-for="info in background" :key="info.label" :span="3">
          <div>{{ info.label }} {{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <br/>
    <el-radio-group v-model="status" class="radio-group-left">
      <el-radio-button label="1">存款记录</el-radio-button>
      <el-radio-button label="2">提款记录</el-radio-button>
    </el-radio-group>
    <el-table :data="tableData" v-if="status == 1">
      <el-table-column prop="pay_time" label="发起时间"></el-table-column>
      <el-table-column prop="update_time" label="完成时间"></el-table-column>
      <el-table-column prop="type" label="类别">
        <template v-slot="{row}">
          <template v-if="row.type===1">用户充值</template>
          <template v-if="row.type===2">人工入款</template>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="金额"></el-table-column>
      <el-table-column prop="payStatusStr" label="订单状态"></el-table-column>
      <el-table-column prop="account_his" label="完成后金额"></el-table-column>
      <el-table-column prop="merchantorderid" label="支付流水号"></el-table-column>
      <el-table-column prop="payment_channel_code" label="支付/代付通道"></el-table-column>
      <el-table-column prop="fee" label="用户通道费"></el-table-column>
      <el-table-column prop="payment_channel_fee" label="平台通道费"></el-table-column>
      <el-table-column prop="create_user" label="相关操作人"></el-table-column>
<!--      <el-table-column prop="account" label="到账金额"></el-table-column>-->
    </el-table>
    <el-table :data="tableData" v-else>
      <el-table-column prop="create_time" label="发起时间"></el-table-column>
      <el-table-column prop="update_time" label="完成时间"></el-table-column>
      <el-table-column prop="type" label="控制类型">
        <template v-slot="{row}">
          <template v-if="row.type===1">用户发起提款</template>
          <template v-if="row.type===2">人工出款</template>
          <template v-if="row.type===3">代理出款</template>
        </template>
      </el-table-column>
      <el-table-column prop="disbursement_amount" label="金额"></el-table-column>
      <el-table-column prop="payOutStatusStr" label="订单状态"></el-table-column>
      <el-table-column prop="account_his" label="完成后金额"></el-table-column>
      <el-table-column prop="merchantorderid" label="支付流水号"></el-table-column>
      <el-table-column prop="payment_channel_code" label="支付/代付通道"></el-table-column>
      <el-table-column prop="fee" label="用户通道费"></el-table-column>
      <el-table-column prop="payment_channel_fee" label="平台通道费"></el-table-column>
      <el-table-column prop="create_user" label="相关操作人"></el-table-column>
<!--      <el-table-column prop="account" label="到账金额"></el-table-column>-->
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      tableDatas: [],
      item: {...defaultItem},
      queryParams: {},
      betInfos: [
        {label: '入账存款:', value: 0},
        {label: '入账笔数:', value: 0},
        {label: '发起存款:', value: 0},
        {label: '发起笔数:', value: 0},
        {label: '存款成功率:', value: 0},
      ],
      background: [
        {label: '入账提款:', value: 0},
        {label: '提款入账笔数:', value: 0},
        {label: '提款发起笔数:', value: 0},
        {label: '用户通道费:', value: 0},
        {label: '平台通道费:', value: 0},
      ],
      payStatusArr: [
        {label:'待支付',value:1},
        {label:'已支付',value:2},
        {label:'已补单',value:3},
        {label:'已取消',value:4},
        {label:'代收失败',value:5},
      ],
      payOutStatusArr: [
        {label:'待处理',value:1},
        {label:'代付失败',value:2},
        {label:'锁定中',value:3},
        {label:'代付中',value:4},
        {label:'已完成',value:5},
        {label:'已拒绝',value:6},
        {label:'已退单',value:7},
        {label:'已冻结',value:8},
        {label:'已关闭',value:9},
        {label:'拒绝并挂起',value:10},
        {label:'退单并挂起',value:11},
        {label:'待审核',value:12},
      ],
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()],
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params['time'][0], 2);
        params.end_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.where_value = this.userid

      if (this.status == 1) {
        let data = {
          cmd: "deposit",
          action: "query_deposit_list",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("deposit", data)
            .then((response) => {
              console.log("response.data", response.data);
              this.tableData = response.data.deposit_list;
              this.tableData.forEach((item) => {
                for (let i of this.payStatusArr) {
                  if (i.value == item.status) {
                    item.payStatusStr = i.label;
                    break;
                  }
                }
              })
              this.page = response.data.page;
              this.pages = response.data.pages;
              this.total = response.data.total;
            })
            .catch((error) => {
              console.log("error", error);
            });
      } else {
        let data = {
          cmd: "withdrawal",
          action: "query_withdrawal_list",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("deposit", data)
            .then((response) => {
              console.log("response.data", response.data);
              this.tableData = response.data.deposit_list;
              this.tableData.forEach((item) => {
                for (let i of this.payOutStatusArr) {
                  if (i.value == item.status) {
                    item.payOutStatusStr = i.label;
                    break;
                  }
                }
              })
              this.page = response.data.page;
              this.pages = response.data.pages;
              this.total = response.data.total;
            })
            .catch((error) => {
              console.log("error", error);
            });
      }

    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
      const data = {
        cmd: "user_behavior",
        action: "query_deposit_withdraw_behavior",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.betInfos[0].value = response.data.depositMoney;
            this.betInfos[1].value = response.data.depositNumber;
            this.betInfos[2].value = response.data.initiateDepositMoney;
            this.betInfos[3].value = response.data.initiateDepositNumber;
            this.betInfos[4].value = response.data.depositSuccessRate;
            this.background[0].value = response.data.withdrawalsMoney;
            this.background[1].value = response.data.withdrawalsNumber;
            this.background[2].value = response.data.initiatedWithdrawalsNumber;
            this.background[3].value = response.data.fee;
            this.background[4].value = response.data.paymentChannelFee;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dislogCancelHandle() {
      // this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
.radio-group-left {
  display: flex;
  justify-content: flex-start;
}
</style>
