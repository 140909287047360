<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-input placeholder="活动名称" v-model="queryParams.nameCn"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-select placeholder="请选择状态" clearable style="width: 100%" v-model="queryParams.state">
            <el-option
                v-for="(item, index) in status"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="addItemHandle">
            <el-icon>
              <Plus/>
            </el-icon>
            <span>新增</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column prop="nameCn" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="describeCn" label="活动简介" align="center"></el-table-column>
      <el-table-column label="活动类型" align="center">
        <template v-slot="{row}">
          <template v-if="row.dailyActivityType === 1">优惠充值</template>
          <template v-if="row.dailyActivityType === 2">今日挑战</template>
        </template>
      </el-table-column>

      <el-table-column prop="dailyActivityCondition" width="370" label="完成条件" align="center"></el-table-column>
      <el-table-column label="奖励人数" align="center">
        <template v-slot="{row}">
          <template v-if="row.dailyActivityRewardNumber">{{row.dailyActivityRewardNumber}}</template>
          <template v-else>--</template>
        </template>
      </el-table-column>

      <el-table-column prop="dailyActivityRewardCount" label="奖励次数" align="center"></el-table-column>

      <el-table-column prop="dailyActivityIndex" label="优先级" align="center"></el-table-column>

      <el-table-column prop="winnings" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.state === 0">关闭</template>
          <template v-else>开启</template>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template #default="scope">
          <el-button type="primary" size="small" @click="itemEditHandle(scope.$index)">编辑</el-button>
          <el-button v-if="scope.row.state === 1" type="primary" size="small" @click="closeStatus(scope.$index)">关闭
          </el-button>
          <el-button v-else type="primary" size="small" @click="closeStatus(scope.$index)">开启</el-button>
          <el-button type="primary" size="small" @click="editHandle(scope.row)">数据</el-button>
        </template>

      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogVisible"
      :title="'每日活动'"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">
      <el-form-item label="活动类型" prop="dailyActivityType">
        <el-select v-model="item.dailyActivityType" :disabled="may" style="width: 100%" @change="changeType(item.dailyActivityType)" placeholder="请选择">
          <el-option
              v-for="(item, index) in dailyActivityType"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>



      <el-form-item label="活动名称" prop="nameCn">
        <el-input v-model="item.nameCn" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="巴普活动名称" prop="name">
        <el-input v-model="item.name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="活动简介" prop="describeCn">
        <el-input v-model="item.describeCn" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="巴普活动简介" prop="describe">
        <el-input v-model="item.describe" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="图标" prop="URL">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="item.URL"></UploadImage>
      </el-form-item>

      <el-form-item label="完成条件" prop="dailyActivityCondition">
        <el-input v-model="item.dailyActivityCondition" placeholder="请输入完成条件"></el-input>
      </el-form-item>


      <el-form-item label="奖励次数" prop="dailyActivityRewardCount">
        <el-input type="number" v-model="item.dailyActivityRewardCount" placeholder="请输入奖励次数">
        </el-input>
      </el-form-item>

      <el-form-item label="优先级" prop="dailyActivityIndex">
        <el-input type="number" v-model="item.dailyActivityIndex"  placeholder="请输入优先级">
        </el-input>
      </el-form-item>

      <div v-if="item.dailyActivityType === 2">
        <el-form-item label="派奖方式">
          <el-input type="number" v-model="等差数列" placeholder="等差数列" disabled ></el-input>
        </el-form-item>
        <el-form-item label="获奖用户">
          <el-input type="number" v-model="等差数列" placeholder="限定人数" disabled ></el-input>
        </el-form-item>
      <el-form-item label="每日活动派奖人数" prop="dailyActivityRewardNumber">
        <el-input type="number" v-model="item.dailyActivityRewardNumber"  placeholder="请输入每日活动派奖人数"></el-input>
      </el-form-item>
        <el-form-item label="奖励类型">
          <el-input type="number" v-model="等差数列" placeholder="固定值" disabled ></el-input>
        </el-form-item>
        <el-form-item label="每日活动奖金池总额" prop="dailyActivityRewardFixAmount">
          <el-input type="number" v-model="item.dailyActivityRewardFixAmount"  placeholder="请输入每日活动派奖人数"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="奖金类型" prop="dailyActivityRewardType">
        <el-select v-model="item.dailyActivityRewardType" style="width: 100%" placeholder="请选择奖励类型">
          <el-option
              v-for="(item, index) in dailyActivityRewardType"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <div v-if="item.dailyActivityType === 2">
      <el-form-item label="指定游戏" prop="dailyActivityGame">
        <el-input v-model="item.dailyActivityGame"  placeholder="请输入指定游戏用逗号隔开"></el-input>
      </el-form-item>
      </div>
      <el-form-item v-if="item.dailyActivityType === 1" label="奖励比例" prop="dailyActivityIndex">
        <el-table :data="item.dailyActivityRewardRate"
                  element-loading-text="Loading"
                  border
                  fit
                  highlight-current-row>
          <el-table-column label="金额" align="center">
            <template v-slot="{row}">{{ row.amount * 100 + '% 奖金' }}</template>
          </el-table-column>
          <el-table-column label="比例" align="center">
            <template v-slot="{row}">{{ (row.rate * 100).toFixed(2) + '% 概率' }}</template>
          </el-table-column>
        </el-table>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import UploadImage from '@/components/UploadImage/index'

export default {
  components: {UploadImage},
  data() {
    return {
      tableData: [],
      total: 0,
      dialogVisible: false,
      may: false,
      queryParams: {},
      payTimeRange: {},
      dates: {},
      item: {},
      cmd: "cfg_daily_activity",
      isEdit: false,
      dailyActivityCondition: '100,200,300,500,1000,2000,3000,5000,10000,20000',
      dailyActivityRewardRate: [
        {amount: 0.01, rate: 0.9},
        {amount: 0.05, rate: 0.05},
        {amount: 0.1, rate: 0.03},
        {amount: 0.2, rate: 0.01},
        {amount: 0.3, rate: 0.009},
        {amount: 0.4, rate: 0.001},
      ],
      status: [
        {label: '开启', value: 1},
        {label: '关闭', value: 0},
      ],
      dailyActivityType: [
        {label: '优惠充值', value: 1},
        {label: '今日挑战', value: 2},
      ],
      dailyActivityRewardType: [
        {label: '赠金奖励', value: 1},
        {label: '真金奖励', value: 2},
      ],
      rules: {
        nameCn: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入巴普活动名称', trigger: 'blur'},
        ],
        describeCn: [
          {required: true, message: '请输入活动简介', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入巴普活动简介', trigger: 'blur'},
        ],

        URL: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],

        dailyActivityCondition: [
          {required: true, message: '请输入完成条件', trigger: 'blur'},
        ],

        dailyActivityRewardCount: [
          {required: true, message: '请输入奖励次数', trigger: 'blur'},
        ],
        dailyActivityIndex: [
          {required: true, message: '请输入优先级', trigger: 'blur'},
        ],
        dailyActivityRewardNumber: [
          {required: true, message: '请输入每日活动派奖人数', trigger: 'blur'},
        ],
        dailyActivityRewardFixAmount: [
          {required: true, message: '请输入每日活动奖金池总额', trigger: 'blur'},
        ],
        dailyActivityGame: [
          {required: true, message: '请输入指定游戏', trigger: 'blur'},
        ],
        dailyActivityType: [
          {required: true, message: '请选择活动类型', trigger: 'blur'},
        ],
        dailyActivityRewardType: [
          {required: true, message: '请选择奖励类型', trigger: 'blur'},
        ],
      }
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
  },

  methods: {

    changeType(val){
      if (val === 2) {
        this.item.dailyActivityCondition = undefined
        this.item.dailyActivityRewardRate = undefined
      }
      if (val === 1) {
        this.item.dailyActivityCondition = this.dailyActivityCondition
        this.item.dailyActivityRewardRate = this.dailyActivityRewardRate
      }
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.tableData.forEach((itme) => {
              itme.URL = itme.imageUrl ? this.$tools.cdn + itme.imageUrl : ''
              itme.dailyActivityRewardRate = JSON.parse(itme.dailyActivityRewardRate)
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addItemHandle() {
      this.isEdit = false;
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.item.state = 1
      this.item.dailyActivityType = 1
      this.item.dailyActivityCondition = this.dailyActivityCondition
      this.item.dailyActivityRewardRate = this.dailyActivityRewardRate
      this.may = false
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.may = true
      this.dialogVisible = true;
    },

    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogVisible = false;
        }
      })

    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: '修改成功',
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    /**
     * 关闭开启事件
     */
    closeStatus(index) {
      this.item = JSON.parse(JSON.stringify(this.tableData[index]))
      var status = '开启';
      if (this.item.state === 1) {
        status = '关闭'
        this.item.state = 0
      } else {
        this.item.state = 1
      }
      this.$confirm("确定要" + status + "活动吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.addDatas(status);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    addDatas(status) {
      const params = this.$tools.removeEmptyProperties(this.item);
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: params,
        }
      }
      this.$http
          .post('pub_club_allocat', data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: status + "成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    editHandle(row) {
      this.$router.push(`/daily-activitylog/${row.dailyActivityId}/${row.dailyActivityType}`)
    },
    /**
     * 图片上传成功回调
     * @param {*} res
     * @param {*} file
     */
    handleAvatarSuccess(data) {
      this.item.imageUrl = data.file_url;
      this.item.URL = data.url;
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
