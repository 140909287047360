<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="通道名称：">
          <el-input clearable  placeholder="请输入通道名称"></el-input>
        </el-form-item>
        <el-form-item label="商户号：">
          <el-input clearable  placeholder="请输入商户号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table :data="tableData">
      <el-table-column label="通道状态"></el-table-column>
      <el-table-column label="代付通道"></el-table-column>
      <el-table-column label="前端排序"></el-table-column>
      <el-table-column label="支付类型"></el-table-column>
      <el-table-column label="单笔存款限额"></el-table-column>
      <el-table-column label="通道单日存款限额"></el-table-column>
      <el-table-column label="商户号"></el-table-column>
      <el-table-column label="通道说明"></el-table-column>
      <el-table-column label="操作">
        <template>
          <el-button type="primary" size="small">编辑</el-button>
          <el-button type="primary" size="small">删除</el-button>
          <el-button type="primary" size="small">测试</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
