<template>
  <div>
    <div class="title">
      <el-text tag="b">订单状态</el-text>
    </div>
    <div class="title">
      <el-button type="info">{{statusStr}}</el-button>
    </div>
    <div class="title">
      <el-text tag="b">流转信息</el-text>
    </div>
    <el-table :data="trData">
      <el-table-column prop="step_describe" label="流程阶段"></el-table-column>
      <el-table-column prop="step_operator" label="流程处理方"></el-table-column>
      <el-table-column prop="create_time_str" label="时间"></el-table-column>
      <el-table-column prop="remark" label="备注信息"></el-table-column>
      <el-table-column prop="result_msg" label="回调信息"></el-table-column>
    </el-table>
    <br/>
    <div class="title">
      <el-text tag="b">用户信息</el-text>
    </div>
    <el-table :data="userData">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="firstname" label="真实姓名">
        <template #default="scope">
          <span>{{scope.row.firstname}} {{scope.row.lastname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cpf" label="用户CPF"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
      <el-table-column prop="recharge" label="总存款"></el-table-column>
    </el-table>
    <el-table :data="userData">
      <el-table-column prop="withdrawal" label="总提款"></el-table-column>
      <el-table-column prop="total_win_loss" label="总数赢"></el-table-column>
      <el-table-column prop="risk_level_exp" label="风险等级"></el-table-column>
      <el-table-column prop="" label="发起订单后余额"></el-table-column>
      <el-table-column prop="" label="归属渠道"></el-table-column>
      <el-table-column prop="source_user" label="归属代理"></el-table-column>
      <el-table-column prop="crerate_ip" label="发起订单IP"></el-table-column>
      <el-table-column label="IP归属地"></el-table-column>
    </el-table>
    <br/>
    <div class="title">
      <el-text tag="b">金流信息</el-text>
    </div>
    <el-table :data="orderData">
      <el-table-column prop="disbursement_amount" label="订单金额"></el-table-column>
      <el-table-column prop="payment_channel_code" label="代付通道"></el-table-column>
      <el-table-column prop="order_id" label="代付订单号"></el-table-column>
      <el-table-column prop="traceId" label="代付流水号"></el-table-column>
      <el-table-column prop="disbursement_type_str" label="提款方式"></el-table-column>
      <el-table-column prop="pix" label="PIX类型"></el-table-column>
      <el-table-column prop="pix_id" label="PIX ID"></el-table-column>
      <el-table-column prop="cpf" label="TAX ID"></el-table-column>
    </el-table>
    <el-table :data="orderData">
      <el-table-column prop="" label="提款银行"></el-table-column>
      <el-table-column prop="" label="提款银行账号"></el-table-column>
      <el-table-column prop="" label="用户提款手续费率"></el-table-column>
      <el-table-column prop="account" label="用户实际到账金额"></el-table-column>
      <el-table-column prop="" label="代付通道实际费率"></el-table-column>
      <el-table-column prop="fee" label="通道手续费（用户部分）"></el-table-column>
      <el-table-column prop="payment_channel_fee" label="通道手续费（平台部分）"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      orderData: [],
      statusStr: '',
      userData:[],
      trData:[],
      statusArr: [
        {label:'待处理',value:1},
        {label:'代付失败',value:2},
        {label:'锁定中',value:3},
        {label:'代付中',value:4},
        {label:'已完成',value:5},
        {label:'已拒绝',value:6},
        {label:'已退单',value:7},
        {label:'已冻结',value:8},
        {label:'已关闭',value:9},
        {label:'拒绝并挂起',value:10},
        {label:'退单并挂起',value:11},
        {label:'待审核',value:12},
      ],
      disbursement_type_arr: [
        {label:'真金(仅扣款)',value:1},
        {label:'真金(入账不提)',value:2},
        {label:'真金(入账提款)',value:3},
        {label:'赏金(仅扣款)',value:4},
        {label:'可提现金额(减少)',value:5},
        {label:'代理出款',value:6},
      ],
    };
  },
  methods: {
    queryData() {
      const data = {
        cmd: "withdrawal",
        action: "query_withdrawal_data",
        data: {
          paramVO: {},
          id: this.id,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            //订单
            this.orderData = [response.data.user_pay_order];
            this.orderData.forEach((item) => {
              for(let i of this.statusArr){
                if(i.value==item.status){
                  this.statusStr = i.label;
                  break;
                }
              }
              for(let i of this.disbursement_type_arr){
                if(i.value==item.disbursement_type){
                  item.disbursement_type_str = i.label;
                  break;
                }
              }
            });
            //
            //用户
            this.userData = [
              {
                userid:response.data.user_data.user.userid,
                nickname:response.data.user_data.user_customer_info.nickname,
                firstname:response.data.user_data.user_customer_info.firstname,
                lastname:response.data.user_data.user_customer_info.lastname,
                cpf:response.data.user_data.user_customer_info.customer_cert,
                email:response.data.user_data.user_customer_info.email,
                phone:response.data.user_data.user_customer_info.phone,
                vip_level:response.data.user_data.user_customer_info.vip_level_name,
                recharge:response.data.user_data.user_wallet.recharge,
                withdrawal:response.data.user_data.user_wallet.withdrawal,
                total_win_loss:response.data.user_pay_order.total_win_loss,
                risk_level:response.data.user_data.user.risk_level,
                crerate_ip:response.data.user_pay_order.create_ip,
                source_user:response.data.user_data.user_customer_info.source_user,
                risk_level_exp:this.$tools.getRiskLevelExp(response.data.user_data.user.risk_level)
              }
            ];
            //
            //流转
            this.trData = response.data.step_logs;
            this.trData.forEach((item) => {
              item.create_time_str = this.$tools.dateFormat(item.create_time)
            });
            //
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.title{
  text-align: left !important;
  margin-bottom: 20px;
}
</style>
