<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="奖品名称">
          <el-input clearable placeholder="奖品名称" v-model="queryParams.prizeNameCn"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="addItemHandle">
            <el-icon>
              <Plus/>
            </el-icon>
            <span>新增</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column prop="prizeNameCn" label="奖品名称" align="center"></el-table-column>
      <el-table-column prop="describeCn" label="奖品描述" align="center"></el-table-column>
      <el-table-column prop="icon" label="图标" align="center">
        <template #default="scope">
          <img :src="$tools.cdn+scope.row.prizeImageUrl" style="height: 50px;"/>
        </template>
      </el-table-column>

      <!--      <el-table-column prop="stateDurationParm" label="增益时长" align="center"></el-table-column>-->
      <!--      <el-table-column prop="depositExpirationParm" label="有效期" align="center"></el-table-column>-->
      <el-table-column label="增益时长" width="170" align="center">
        <template v-slot="{row}">
          {{ row.stateDurationParm + '分' }}
        </template>
      </el-table-column>
      <el-table-column label="有效期" width="170" align="center">
        <template v-slot="{row}">
          {{ row.depositExpirationParm +'分' }}
        </template>
      </el-table-column>

<!--      <el-table-column prop="depositExpirationParm" label="奖品" align="center"></el-table-column>-->
      <el-table-column label="奖品类型" width="170" align="center">
        <template v-slot="{row}">
          {{ this.findTypeNameById(row.prizeType) }}
        </template>
      </el-table-column>
      <!--      <el-table-column prop="status" align="center" label="状态">-->
      <!--        <template v-slot="{row}">-->
      <!--          <template v-if="row.state=== 1">启用</template>-->
      <!--          <template v-if="row.state=== 0 ">禁用</template>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button type="text" size="small" @click="itemEditHandle(scope.$index)">配置</el-button>
          <!--          <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>-->
          <!--          <el-button v-if="scope.row.state === 1" type="text" size="small" @click="forbidden(scope.$index)">禁用-->
          <!--          </el-button>-->
          <!--          <el-button v-else type="text" size="small" @click="open(scope.$index)">启用</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @open="handleDialogOpen"
  >

    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="奖品名称" prop="prizeNameCn">
        <el-input v-model="item.prizeNameCn" placeholder="请输入奖品名称"></el-input>
      </el-form-item>

      <el-form-item label="巴普文案" prop="prizeName">
        <el-input v-model="item.prizeName" placeholder="请输入巴普文案"></el-input>
      </el-form-item>

      <el-form-item label="奖品描述" prop="describeCn">
        <el-input v-model="item.describeCn" placeholder="请输入奖品描述"></el-input>
      </el-form-item>

      <el-form-item label="巴普描述文案" prop="describe">
        <el-input v-model="item.describe" placeholder="请输入巴普描述文案"></el-input>
      </el-form-item>


      <el-form-item label="图标" prop="URL">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="item.URL"></UploadImage>
      </el-form-item>




      <el-form-item label="有效时长" prop="depositExpirationParm">
        <el-input type="number" v-model="item.depositExpirationParm" :disabled="may" placeholder="请输入时长">
          <template #suffix>
            <span class="normal-text">分</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="奖品类型" prop="prizeType">
        <el-select placeholder="请选择奖品类型" style="width: 100%" v-model="item.prizeType" :disabled="may">
          <el-option
              v-for="(item, index) in types"
              :key="index"
              :label="item.typeName"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="增益时长" v-if="this.stateDurationParms.includes(this.item.prizeType)" prop="stateDurationParm">
        <el-input type="number" v-model="item.stateDurationParm" :disabled="may" placeholder="请输入增益时长">
          <template #suffix>
            <span class="normal-text">分</span>
          </template>
        </el-input>
      </el-form-item>



      <el-form-item label="抽奖券类型" v-if="this.item.prizeType === 8" prop="prizeLinkId">
        <el-select placeholder="请选择" style="width: 100%" v-model="item.prizeLinkId" :disabled="may">
          <el-option
              v-for="(item, index) in tickets"
              :key="index"
              :label="item.ticketNameCn"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="金额" v-if="this.item.prizeType === 1 || this.item.prizeType === 6 || this.item.prizeType === 10" prop="prizeAmount">
        <el-input type="number" v-model="item.prizeAmount" :disabled="may" placeholder="请输入金额">
          <template #suffix>
            <span class="normal-text">金额</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="倍率" v-if="this.item.prizeType === 2" prop="prizeAmount">
        <el-input type="number" v-model="item.prizeAmount" :disabled="may" placeholder="请输入倍率">
          <template #suffix>
            <span class="normal-text">倍</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="百分比" v-if="this.item.prizeType === 3 || this.item.prizeType === 4|| this.item.prizeType === 7" prop="prizeAmount">
        <el-input type="number" v-model="item.prizeAmount" :disabled="may" placeholder="请输入百分比">
          <template #suffix>
            <span class="normal-text">%</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="千分比" v-if="this.item.prizeType === 5" prop="prizeAmount">
        <el-input type="number" v-model="item.prizeAmount" :disabled="may" placeholder="请输入千分比">
          <template #suffix>
            <span class="normal-text">‰</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="抽奖券" v-if="this.item.prizeType === 8" prop="prizeAmount">
        <el-input type="number" v-model="item.prizeAmount" :disabled="may" placeholder="请输入抽奖券数">
          <template #suffix>
            <span class="normal-text">张</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="领奖所需数值" v-if="this.item.prizeType === 6 || this.item.prizeType === 10" prop="prizeRequireAmount">
        <el-input type="number" v-model="item.prizeRequireAmount" :disabled="may" placeholder="请输入领奖所需数值"></el-input>
      </el-form-item>

      <!--      <el-form-item label="奖品状态" prop="state" align="center">-->
      <!--        <el-radio-group v-model="item.state" :disabled="may">-->
      <!--          <el-radio :label="1">启用</el-radio>-->
      <!--          <el-radio :label="0">禁用</el-radio>-->
      <!--        </el-radio-group>-->
      <!--      </el-form-item>-->

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'

export default {
  components: {UploadImage},
  computed: {
    // 假设types数据结构已经存在于组件内部，且是响应式的
    typeNamesMap() {
      return this.types.reduce((map, item) => {
        map.set(item.id, item.typeName);
        return map;
      }, new Map());
    }
  },
  data() {
    const defaultItem = {
      userid: undefined,
      dates: undefined
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      viplevels: [],
      stateDurationParms: [2,3,4,5], //增益时长：奖品类型2、3、4、5显示，其他奖品类型不显示
      queryParams: {...defaultItem},
      dialogVisible: false,
      examine: false,
      dialogTitle: "",
      payTimeRange: undefined,
      isEditDialog: false,
      may: true,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      gameCategoryList: [],
      gameProviderList: [],
      cmd: "pub_setting_award",
      paramVO: {},
      item: {},
      dates: {},
      keeps: [
        {label: '启用(是)', value: 1},
        {label: '禁用(否)', value: 0},
      ],
      types: [],
      tickets: [],
      data: {cmd: this.cmd, action: "", data: {paramVO: {}},},
      rules: {
        prizeNameCn: [
          {required: true, message: '请输入奖品名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 255 个字符', trigger: 'blur'}
        ],
        prizeName: [
          {required: true, message: '请输入巴普文案奖品名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 255 个字符', trigger: 'blur'}
        ],
        describeCn: [
          {required: true, message: '请输入描述', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入巴普文案描述', trigger: 'blur'},
        ],
        prizeAmount: [
          {required: true, message: '请输入金额/百分比/千分比', trigger: 'blur'},
        ],
        URL: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],
        stateDurationParm: [
          {required: true, message: '请输入增益时长', trigger: 'blur'},
        ],
        depositExpirationParm: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        prizeType: [
          {required: true, message: '请选择类型', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        raffleVipLevelMin: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        prizeRequireAmount: [
          {required: true, message: '请输入领奖所需数值', trigger: 'blur'},
        ],
        prizeLinkId: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
      }
    }
  },
  mounted() {
    this.queryData();
    this.queryList()
  },
  methods: {

  findTypeNameById(id) {
    return this.typeNamesMap.get(id) || '__';
  },
  resetHandle() {
    Object.keys(this.queryParams).forEach(
        (key) => (this.queryParams[key] = "")
    );
    this.payTimeRange = []
    this.queryData();
  },

  pageChangeHandle(num) {
    this.queryData(num - 1);
  },
  queryData(index = 0) {
    const params = this.$tools.removeEmptyProperties(this.queryParams);
    const data = {
      cmd: this.cmd,
      action: "query_page",
      data: {
        paramVO: params,
        page: index,
      }
    }
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          console.log("response.data", response.data);
          this.tableData = response.data.list;
          this.tableData.forEach((itme) => {
            itme.URL = itme.prizeImageUrl ? this.$tools.cdn + itme.prizeImageUrl : ''
          });
          this.page = response.data.page;
          this.pages = response.data.pages;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
  },

  queryList() {
    const params = this.$tools.removeEmptyProperties(this.queryParams);
    const data = {
      cmd: this.cmd,
      action: "query_list",
      data: {paramVO: params}
    }
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          this.types = response.data.list;
        })
        .catch((error) => {
          console.log("error", error);
        });
  },

  querytickets() {
    const params = this.$tools.removeEmptyProperties(this.queryParams);
    const data = {
      cmd: this.cmd,
      action: "query_ticket",
      data: {paramVO: params}
    }
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          this.tickets = response.data.list;
        })
        .catch((error) => {
          console.log("error", error);
        });
  },

  handleDialogOpen() {

    this.querytickets()
    // // 用户等级
    // this.queryviplevels()
  },

  dialogCancelHandle() {
    this.$refs.rowForm.resetFields()
    this.dialogVisible = false;
  },

  dialogOkHandle() {
    this.$refs.rowForm.validate((valid) => {
      if (!valid) {
        this.$message({
          type: "info",
          message: "请填写数据",
        })
      } else {
        if (this.isEdit) {
          this.updateData('修改成功');
        } else {
          this.types.filter(item => item.id === this.item.prizeType).map(item => {
            this.item.followPage = item.followPage
          })
          this.addData();
        }
        this.queryData()
        this.dialogVisible = false;
      }
    })

  },

  addItemHandle() {
    this.isEdit = false;
    this.dialogTitle = "新增奖品";
    Object.keys(this.item).forEach((key) => (this.item[key] = ""));
    this.item.type = 1
    this.may = false
    this.dialogVisible = true;
  },
  itemEditHandle(index) {
    this.isEdit = true;
    this.dialogTitle = "编辑奖品";
    this.item = JSON.parse(JSON.stringify(this.tableData[index]));
    this.item.type = 1
    this.may = false
    this.dialogVisible = true;
  },
  updateData(dat) {
    const data = {
      cmd: this.cmd,
      action: "update",
      data: {
        paramVO: this.item,
      },
    };
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          console.log("response.data", response.data);
          this.$message({
            type: "success",
            message: dat,
          });
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
  },
  addData() {
    this.item.depositExpirationType = 3
    this.item.stateDurationType = 3
    const data = {
      cmd: this.cmd,
      action: "add",
      data: {
        paramVO: this.item,
      },
    };
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          console.log("response.data", response.data);
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
  },
  deleteHandle(index) {
    this.$confirm("确定要删除么?删除后无法回复", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      let item = JSON.parse(JSON.stringify(this.tableData[index]))
      this.deleteData(item.id);
    })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
  },

  forbidden(index) {
    this.$confirm("确定要禁用吗", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.item = JSON.parse(JSON.stringify(this.tableData[index]))
      this.item.state = 0
      this.updateData('关闭成功!');
    })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消关闭",
          });
        });
  },
  open(index) {
    this.$confirm("确定要开启吗", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.item = JSON.parse(JSON.stringify(this.tableData[index]))
      this.item.state = 1
      this.updateData('开启成功!');
    })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "已取消开启",
          });
        });
  },
  deleteData(id) {
    this.data.action = 'delete'
    this.data.data.paramVO.id = id
    this.$http
        .post(this.cmd, this.data)
        .then((response) => {
          console.log("response.data", response.data);
          this.queryData(this.page);
        })
        .catch((error) => {
          console.log("error", error);
        });
  },
  /**
   * 图片上传成功回调
   * @param {*} res
   * @param {*} file
   */
  handleAvatarSuccess(data) {
    this.item.prizeImageUrl = data.file_url;
    this.item.URL = data.url;
  },
  async queryviplevels() {
    const params = this.$tools.removeEmptyProperties(this.queryParams);
    const data = {
      cmd: "pob_vip_level",
      action: "query_page",
      data: {
        paramVO: params,
        page: 0,
        pageSize: 100,
      }
    }
    this.$http
        .post(this.cmd, data)
        .then((response) => {
          this.viplevels = response.data.list;
        })
        .catch((error) => {
          console.log("error", error);
        });
  },
}
}
;
</script>

<style lang="scss" scoped></style>
