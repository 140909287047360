<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="厂商名称">
          <el-input
              clearable
              placeholder="请输入"
            v-model="queryParams.providerName"
          ></el-input>
        </el-form-item>
        <el-form-item label="appid">
          <el-input  clearable placeholder="请输入" v-model="queryParams.appid"></el-input>
        </el-form-item>
        <el-form-item label="厂商标识">
          <el-input
              clearable
              placeholder="请输入"
            v-model="queryParams.providerTag"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select placeholder="请选择" clearable  v-model="queryParams.status">
            <el-option label="正常" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="addItemHandle">
          <el-icon><Plus /></el-icon>
          <span>新增</span>
        </el-button>
        <el-button @click="batchDeleteHandle">
          <el-icon><DeleteFilled /></el-icon>
          <span>删除</span>
        </el-button>
      </el-row>

      <el-table :data="tableData" @selection-change="handleSelected">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="" label="图片">
          <template #default="scope">
            <img :src="$tools.cdn+scope.row.provider_logo" style="width: 50px;" />
          </template>
        </el-table-column>
        <el-table-column prop="" label="icon">
          <template #default="scope">
            <img :src="$tools.cdn+scope.row.provider_icon" style="width: 50px;" />
          </template>
        </el-table-column>
        <el-table-column prop="providerName" label="厂商名称"></el-table-column>
        <el-table-column prop="providerTag" label="厂商标识"></el-table-column>
        <el-table-column prop="counts" label="游戏数量"></el-table-column>
        <el-table-column prop="status" align="center" label="是否展示">
          <template v-slot="{row}">
            <template v-if="row.status == 1">是</template>
            <template v-if="row.status == 0 ">否</template>
          </template>
        </el-table-column>
<!--        <el-table-column prop="apiUrl" label="接口地址"></el-table-column>-->
<!--        <el-table-column prop="appkey" label="应用key"></el-table-column>-->
<!--        <el-table-column prop="appid" label="appid"></el-table-column>-->
<!--        <el-table-column prop="clientUrl" label="前台地址"></el-table-column>-->
<!--        <el-table-column prop="appsecret" label="接口密钥"></el-table-column>-->
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="statusStr" label="状态"></el-table-column>
<!--        <el-table-column prop="" label="是否展示"></el-table-column>-->
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="itemEditHandle(scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="itemDeleteHandle(scope.$index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
      />
    </div>
  </div>

  <el-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    style="text-align: left"
    :close-on-click-modal="false"
    align-center
    @close="dialogCancelHandle"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="100px">
      <el-form-item label="厂商名称：" :label-width="auto" prop="providerName" required>
        <el-input v-model="item.providerName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="*厂商图片：">
        <UploadImage @onSuccess="handleProviderLogoSuccess" type="game_provider" :url="item.provider_logo_full"></UploadImage>
      </el-form-item>
      <el-form-item label="*厂商icon：">
        <UploadImage @onSuccess="handleProviderIconSuccess" type="game_provider" :url="item.provider_icon_full"></UploadImage>
      </el-form-item>

      <el-form-item label="厂商数量：">
        <el-input placeholder="0"></el-input>
        <span>默认为“0”，无需输入 </span>
      </el-form-item>

      <el-form-item label="厂商标识：">
        <el-input v-model="item.providerTag" placeholder="请输入"></el-input>
      </el-form-item>

<!--      <el-form-item label="接口地址：">-->
<!--        <el-input v-model="item.apiUrl" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="应用key：">-->
<!--        <el-input v-model="item.appkey" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="appid：">-->
<!--        <el-input v-model="item.appid" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="前台地址：">-->
<!--        <el-input v-model="item.clientUrl" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="接口秘钥：">-->
<!--        <el-input v-model="item.appsecret" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="状态：">
        <el-radio-group v-model="item.status">
          <el-radio label="1">正常</el-radio>
          <el-radio label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>

<!--      <el-form-item label="是否展示：">-->
<!--        <el-radio-group>-->
<!--          <el-radio label="1">展示</el-radio>-->
<!--          <el-radio label="0">隐藏</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from "@/components/UploadImage/index.vue";

export default {
  components: {UploadImage},
  data() {
    const defaultItem = {
      providerName: "厂商名称",
      providerTag: "厂商标签",
      createTime: "2023-01-01 00:00:00",
      updateTime: "2023-01-01 00:00:00",
      status: "1",
      pumpRate: "10",
      appid: "厂商的appid",
      appkey: "厂商的appkey",
      appsecret: "厂商的appsecret",
      apiUrl: "厂商的api地址",
      demoUrl: "厂商的试玩地址",
      clientUrl: "厂商的客户端地址",
      params: "厂商的参数",
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      dialogVisible: false,
      dialogTitle: "",
      isEdit: false,
      total: 0,
      pages: 0,
      page: 0,
      selectedRows: [],
      rules: {
        providerName: [
          { required: true, message: '请输入厂商名称', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
        (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增分类";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑厂商";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    itemDeleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteData(this.tableData[index].id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields();
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          return false;
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          // this.dialogVisible = false;
        }
      })
    },
    addData() {
      const data = {
        cmd: "game_provider",
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
        .post("game_provider", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.dialogCancelHandle();
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateData() {
      const data = {
        cmd: "game_provider",
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
        .post("game_provider", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.dialogCancelHandle();
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "game_provider",
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
        .post("game_provider", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.tableData = response.data.gameProviderVOList;
          this.tableData.forEach((provider) => {
            if (provider.status === 1) {
              provider.statusStr = "正常";
            } else {
              provider.statusStr = "禁用";
            }
            provider.status += "";
            provider.provider_logo_full = provider.provider_logo?this.$tools.cdn+provider.provider_logo:'';
            provider.provider_icon_full = provider.provider_icon?this.$tools.cdn+provider.provider_icon:'';
          });
          this.page = response.data.page;
          this.pages = response.data.pages;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteData(id) {
      const data = {
        cmd: "game_provider",
        action: "delete",
        data: {
          paramVO: {},
          ids: id,
        },
      };
      this.$http
        .post("game_provider", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    handleSelected(rows) {
      this.selectedRows = rows;
    },
    handleBatchDelete() {
      let finalRows = JSON.parse(JSON.stringify(this.selectedRows));
      finalRows = finalRows.map(row => row.id);
      let ids = finalRows.join(',');
      this.deleteData(ids);
    },
    batchDeleteHandle() {
      if (this.selectedRows.length<=0) {
        this.$message({
          type: "info",
          message: "请选择删除对象",
        })
        return;
      }
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.handleBatchDelete();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleProviderLogoSuccess(data){
      this.item.provider_logo = data.file_url;
      this.item.provider_logo_full = data.url;
    },
    handleProviderIconSuccess(data){
      this.item.provider_icon = data.file_url;
      this.item.provider_icon_full = data.url;
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
