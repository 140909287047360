// import loginHttp from "../utils/loginHttp"

const GoogleType = "google-type";

export async function getGoogleType() {
    return localStorage.getItem(GoogleType);
    // let type = localStorage.getItem(GoogleType);
    // if(type==null){
    //      await loginHttp.get('/google/getUserInfo')
    //         .then((response) => {
    //             setGoogleType(response.data.type);
    //             type = response.data.type;
    //         })
    // }
    // return type;
}

export function setGoogleType(type) {
    return localStorage.setItem(GoogleType, type);
}

export function removeGoogleType() {
    return localStorage.removeItem(GoogleType);
}
