<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="名称">
          <el-input clearable  placeholder="根据咨询页名称搜索"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select clearable  placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              class="select1"
            type="daterange"
            value-format="yyyy/MM/dd"
            range-separator="To"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetForm('form')">搜索</el-button>
          <el-button @click="dialogFormVisible = true">新增</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />
    <el-table :data="tableData">
      <el-table-column prop="name" label="咨询页名称"></el-table-column>
      <el-table-column prop="content" label="咨询页地址"></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="" label="操作">
        <template #default>
          <el-button type="warning" size="small" @click="closeHandle"
            >关闭</el-button
          >
          <el-button type="primary" size="small">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteHandle"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    const item = {
      buttonEnable: "1",
      buttonHyperLink: "https://www.baidu.com/",
      buttonTitle: "按钮文本",
      content: "资讯页面编辑",
      createBy: null,
      createTime: "2023-08-30",
      createTimeEnd: null,
      createTimeStart: null,
      id: 2,
      name: "资讯页名称",
      status: "1",
      updateBy: null,
      updateTime: null,
    };
    const arr = Array.from({ length: 10 }).fill(item);
    return {
      tableData: arr,
    };
  },
};
</script>

<style lang="scss" scoped></style>
