<template>
  <div class="btn">
    <el-button class="btn1" type="primary"   @click="skipLog()">用户行为数据</el-button>
  </div>
  <div>
    <el-descriptions direction="vertical" :column="5" title="账号状态" :size="size" border>
      <el-descriptions-item label="用户ID:">{{userInfo.user_status_info.userid}}</el-descriptions-item>
      <el-descriptions-item label="账号状态:">
          <template v-if="userInfo.user_status_info.status===1">正常</template>
          <template v-if="userInfo.user_status_info.status===2">封号</template>
          <template v-if="userInfo.user_status_info.status===3">禁提</template>
          <template v-if="userInfo.user_status_info.status===4">掉级</template>
          <template v-if="userInfo.user_status_info.status===5">低活</template>
          <template v-if="userInfo.user_status_info.status===6">冻结</template>
      </el-descriptions-item>
<!--      <el-descriptions-item label="账号标签:">&#45;&#45;</el-descriptions-item>-->
      <el-descriptions-item label="账号风险等级:">
        <span @click="showUserRisk(userid)">
           {{userInfo.user_status_info.risk_level_exp}}
        <template v-if="userInfo.user_status_info.risk_level>0">
          ({{userInfo.user_status_info.risk_level}})
        </template>
        </span>
      </el-descriptions-item>
      <el-descriptions-item label="活跃状态:">--</el-descriptions-item>
      <el-descriptions-item label="客服备注:">{{userInfo.user_status_info.remark}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="用户信息(可编辑)" class="margin-top" direction="vertical" :column="8" :size="size" border>
      <template v-slot:extra>
        <span style="margin-right: 5px;text-decoration:underline;cursor: pointer;" @click="editHandle()">编辑信息</span>
        <span style="text-decoration:underline;cursor: pointer;" @click="experienceHandle()">调整经验</span>
      </template>
      <el-descriptions-item label="真实姓名:">{{userInfo.user_customer_info.real_name}}</el-descriptions-item>
      <el-descriptions-item label="平台昵称:">{{userInfo.user_customer_info.nickname}}</el-descriptions-item>
      <el-descriptions-item label="绑定邮箱:">{{userInfo.user_customer_info.email}}</el-descriptions-item>
      <el-descriptions-item label="绑定手机号:">{{userInfo.user_customer_info.phone}}</el-descriptions-item>
      <el-descriptions-item label="税号CPF:">{{userInfo.user_customer_info.customer_cert}}</el-descriptions-item>
      <el-descriptions-item label="PIX:">{{userInfo.user_customer_info.pix_id}}</el-descriptions-item>
      <el-descriptions-item label="PIX类型:">{{userInfo.user_customer_info.pix}}</el-descriptions-item>
      <el-descriptions-item label="上级ID:">{{userInfo.user_customer_info.source_user==0?'--':userInfo.user_customer_info.source_user}}</el-descriptions-item>
      <el-descriptions-item label="登录设备类型:">
        {{userInfo.user_customer_info.last_os_type_desc?userInfo.user_customer_info.last_os_type_desc:'--'}}
      </el-descriptions-item>
      <el-descriptions-item label="VIP级别:">{{userInfo.user_customer_info.vip_level_name}}</el-descriptions-item>
      <el-descriptions-item label="VIP等级:">{{userInfo.user_customer_info.vip_small_level}}</el-descriptions-item>
      <el-descriptions-item label="VIP经验:">{{userInfo.user_customer_info.experience}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="用户信息(不可编辑)" class="margin-top" direction="vertical" :column="9" :size="size" border>
      <el-descriptions-item label="注册时间:">{{userInfo.user_account_info.register_time}}</el-descriptions-item>
      <el-descriptions-item label="注册IP地址:">{{userInfo.user_account_info.register_ip}}</el-descriptions-item>
      <el-descriptions-item label="最近登录时间:">{{userInfo.user_account_info.last_login_time}}</el-descriptions-item>
      <el-descriptions-item label="最近登录IP地址:">{{userInfo.user_account_info.last_login_ip}}</el-descriptions-item>
      <el-descriptions-item label="累计登录天数:">{{userInfo.user_account_info.login_days}}</el-descriptions-item>
      <el-descriptions-item label="累计登录次数:">{{userInfo.user_account_info.login_counts}}</el-descriptions-item>
      <el-descriptions-item label="累计登录时长:">{{userInfo.user_account_info.login_duration}}</el-descriptions-item>
      <el-descriptions-item label="账号现金:">{{walletRealCoins}}</el-descriptions-item>
      <el-descriptions-item label="账号奖金:">{{userInfo.user_wallet.coins_give}}</el-descriptions-item>
      <el-descriptions-item label="累计存款:">{{userInfo.user_wallet.recharge}}</el-descriptions-item>
      <el-descriptions-item label="累计提款:">{{userInfo.user_wallet.withdrawal}}</el-descriptions-item>
      <el-descriptions-item label="累计下注(次数):">{{userInfo.user_wallet.accumulated_bet}}</el-descriptions-item>
      <el-descriptions-item label="有效下注:">{{userInfo.user_wallet.effective_bet_amount}}</el-descriptions-item>
      <el-descriptions-item label="总输赢:">{{userInfo.user_wallet.win_lose_diff_sum}}</el-descriptions-item>
      <el-descriptions-item label="现金总输赢:">{{userInfo.user_wallet.coins_real_win_lose_diff}}</el-descriptions-item>
      <el-descriptions-item label="奖金总输赢:">{{userInfo.user_wallet.coins_give_win_lose_diff}}</el-descriptions-item>
      <el-descriptions-item label="总打码量:">{{userInfo.user_wallet.code_amount}}</el-descriptions-item>
      <el-descriptions-item label="现金打码量:">{{userInfo.user_wallet.coins_real_code_amount}}</el-descriptions-item>
      <el-descriptions-item label="奖金打码量:">{{userInfo.user_wallet.coins_give_code_amount}}</el-descriptions-item>
      <el-descriptions-item label="代理合作费总额:">
        <template v-if="userInfo.user_wallet.thaw_coins>=0 && userInfo.user_wallet.freeze_coins>=0">
          {{userInfo.user_wallet.thaw_coins+userInfo.user_wallet.freeze_coins}}
        </template>
      </el-descriptions-item>
      <el-descriptions-item label="已转出金额:">{{userInfo.user_wallet.thaw_coins}}</el-descriptions-item>
      <el-descriptions-item label="剩余金额:">{{userInfo.user_wallet.freeze_coins}}</el-descriptions-item>

        <el-descriptions-item label="总结算佣金:">{{userInfo.user_wallet.settlement_commission}}</el-descriptions-item>
      <el-descriptions-item label="佣金余额:">{{userInfo.user_wallet.withdrawal_commission}}</el-descriptions-item>
      <el-descriptions-item label="转出佣金总额:">{{userInfo.user_wallet.commission_out}}</el-descriptions-item>
      <el-descriptions-item label="提现佣金总额:">{{userInfo.user_wallet.pay_out_commission}}</el-descriptions-item>
    </el-descriptions>
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="信息编辑"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="editItem" ref="rowForm" :rules="rules" label-width="120px">
      <el-form-item label="Real Name：" prop="real_name">
        <el-input v-model="editItem.real_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="绑定邮箱：" prop="email">
        <el-input v-model="editItem.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="绑定手机号：" prop="phone">
        <el-input v-model="editItem.phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="税号CPF：" prop="customer_cert">
        <el-input v-model="editItem.customer_cert" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="平台昵称：" prop="nickname">
        <el-input v-model="editItem.nickname" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="上级ID：" prop="source_user">
        <el-input v-model="editItem.source_user" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="experienceDialogVisible"
      title="调整经验"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="experienceItem" ref="experienceForm" :rules="experienceRules" label-width="120px">
      <el-form-item label="调整方式：">
        <el-select placeholder="请选择" v-model="experienceItem.type">
          <el-option
              v-for="(item, index) in experience_type_arr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调整值：" prop="value">
        <el-input v-model="experienceItem.value" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="experienceDislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="experienceDialogOkHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="showRisk"
      title="用户风险系数"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-row style="padding-bottom: 20px">
      <el-col :span="4">用户id</el-col>
      <el-col :span="4">{{userid_s}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="12">风险类别</el-col>
      <el-col :span="6">风险系数</el-col>
      <el-col :span="6">风险判定</el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">提存比：{{ user_risk.withdrawals_recharge_rate }}</el-col>
      <el-col :span="6">{{ user_risk.withdrawals_recharge_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.withdrawals_recharge_coefficient==0">无风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>0 && user_risk.withdrawals_recharge_coefficient<=5">低风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>5 && user_risk.withdrawals_recharge_coefficient<=9">中高风险</span>
        <span
            v-else-if="user_risk.withdrawals_recharge_coefficient>9 && user_risk.withdrawals_recharge_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">关联账号数：{{ user_risk.ip_user_count }}</el-col>
      <el-col :span="6">{{ user_risk.ip_user_count_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.ip_user_count_coefficient==0">无风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>0 && user_risk.ip_user_count_coefficient<=5">低风险</span>
        <span
            v-else-if="user_risk.ip_user_count_coefficient>5 && user_risk.ip_user_count_coefficient<=9">中高风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>9 && user_risk.ip_user_count_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:20倍(*{{ user_risk.win_rate_20 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_20_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_20_coefficient==0">无风险</span>
        <span v-else>低风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:50倍(*{{ user_risk.win_rate_50 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_50_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_50_coefficient==0">无风险</span>
        <span v-else>中风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:100倍(*{{ user_risk.win_rate_100 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_100_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_100_coefficient==0">无风险</span>
        <span v-else>高风险</span>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showRisk=false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "UserDetail",
  data() {
    return {
      size: "",// medium small mini
      userid: this.$route.params.userid,
      userInfo: {
        user: {

        },
        user_info:{

        },
        user_wallet:{

        },
        user_status_info:{

        },
        user_customer_info:{

        },
        user_account_info:{

        }
      },
      editItem:{},
      dialogVisible: false,
      rules: {

      },
      experienceItem:{},
      experienceDialogVisible: false,
      experience_type_arr: [
        {label:'经验增加',value:'experience'},
        {label:'等级调整',value:'level'},
      ],
      experienceRules: {
        value: [
          { required: true, message: '请输入调整值', trigger: 'blur' }
        ],
      },
      showRisk: false,
      user_risk: null,
      userid_s: null,
    }
  },
  mounted() {
    this.queryData();
  },
  methods: {
    showUserRisk(userid) {
      this.userid_s =userid;
      const data = {
        cmd: "user",
        action: "user_risk_coefficient",
        data: {
          paramVO: {},
          userid: userid
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.showRisk = true;
            this.user_risk = response.data.user_risk;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryData() {
      const data = {
        cmd: "user",
        action: "query_user",
        data: {
          paramVO: {},
          userid: this.userid,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.userInfo = response.data.user_data;
            this.userInfo.user_status_info.risk_level_exp = this.$tools.getRiskLevelExp(this.userInfo.user_status_info.risk_level);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    editHandle() {
      let editItem = {
        nickname:this.userInfo.user_customer_info.nickname,
        email:this.userInfo.user_customer_info.email,
        phone:this.userInfo.user_customer_info.phone,
        firstname:this.userInfo.user_customer_info.firstname,
        lastname:this.userInfo.user_customer_info.lastname,
        customer_cert:this.userInfo.user_customer_info.customer_cert,
        source_user:this.userInfo.user_customer_info.source_user
      };
      this.editItem = JSON.parse(JSON.stringify(editItem));
      this.dialogVisible = true;
    },
    dislogCancelHandle() {
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.updateData();
    },
    updateData() {
      const data = {
        cmd: "user",
        action: "update_user",
        data: {
          paramVO: this.editItem,
          userid: this.userid
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "编辑成功",
            })
            this.dialogVisible = false;
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    experienceHandle() {
      let experienceItem = {
        type:"experience",
        value:"",
      };
      this.experienceItem = JSON.parse(JSON.stringify(experienceItem));
      this.experienceDialogVisible = true;
    },
    experienceDislogCancelHandle() {
      this.$refs.experienceForm.resetFields();
      this.experienceDialogVisible = false;
    },
    experienceDialogOkHandle() {
      this.$refs.experienceForm.validate((valid) => {
        if (!valid) {
          return false;
        }else {
          this.updateExperienceData();
        }
      })
    },
    updateExperienceData() {
      const data = {
        cmd: "user",
        action: "adjust_user",
        data: {
          type: this.experienceItem.type,
          value: this.experienceItem.value,
          userid: this.userid,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "调整成功",
            })
            this.experienceDialogVisible = false;
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    skipLog() {
      this.$router.push(`/agency-userDeed/${this.userid}`)
    }
  },
  computed: {
    walletRealCoins() {
      return (this.userInfo.user_wallet.coins - this.userInfo.user_wallet.coins_give).toFixed(2);
    }
  }

}
</script>

<style scoped>
.margin-top{
  margin-top: 40px;
}
.btn{
  min-width: 50%;
  height: 10px;
  position: relative;
  display: flex;
  justify-content:flex-end;
}
.btn1{
  position: relative;
  right: 10px;
  top: 10px;
}
.riskDiv {
  margin-top: 10px;
  //border: #0d1717 1px dashed;
}
</style>
<style>
@media screen and (max-width: 600px) {
  .el-dialog {
    width: 90% !important;
  }
}
@media screen and (min-width: 600px) {
  .el-dialog {
    width: 35% !important;
  }
}
</style>
