import http from "../utils/http"

export async function getUserShowViewData() {
    let showViewData = false;
    const data = {
        cmd: "pub_platform_user",
        action: "user_info",
        data: {},
    };
    await http.post("pub_platform_user", data)
        .then((response) => {
            if (response.data.userInfo.showViewData ===1){
                showViewData = true;
            }
        })
    return showViewData;
}
