import axios from "axios";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { getToken } from "@/utils/token";
const $toast = useToast();
// 创建一个 axios 实例
const instance = axios.create({
  baseURL: location.origin,
  //baseURL: "http://localhost:8080", // 设置接口的基础 URL
  timeout: 60000, // 设置请求超时时间
  headers: {
    "Content-Type": "application/json", // 设置请求头
  },
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        config.headers = Object.assign(config.headers,{'token': getToken()});

        return config;
    },
    (error) => {
        // 请求出错时的处理
        $toast.error("request:" + error);
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 请求成功后的处理
    return response.data;
  },
  (error) => {
      $toast.error(
          "账号密码错误"
      );
    return error.data;
  }
);

export default instance;
