import { createApp } from "vue";
import App from "./App.vue";

// 路由
import router from "@/route/index";

// element ui
import elementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

// 网络
import http from "@/utils/http";

// 自定义工具
import tools from "@/utils/tools";

import '@/permission' // permission control

const app = createApp(App);

// 全局注册el-icon
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(name, component);
}

app.config.globalProperties.$http = http;
app.config.globalProperties.$tools = tools;

app
  .use(router)
  .use(elementPlus, {
    locale: zhCn,
  })
  .mount("#app");
