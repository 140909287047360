<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="代理id">
          <el-input placeholder="请输入" clearable v-model="queryParams.userid"></el-input>
        </el-form-item>
<!--        value-format="YYYY-MM-DD HH:mm:ss"-->
<!--        type="datetimerange"-->
        <el-form-item label="交易类型">
          <el-select placeholder="请选择" style="width: 100%" clearable  v-model="queryParams.actionType">
            <el-option
                v-for="(item, index) in actionType"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资金类型">
          <el-select placeholder="请选择" style="width: 100%" clearable  v-model="queryParams.moneyType">
            <el-option
                v-for="(item, index) in moneyType"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据来源">
          <el-select placeholder="请选择" style="width: 100%" clearable  v-model="queryParams.dataFrom">
            <el-option
                v-for="(item, index) in dataFrom"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流水号">
          <el-input
              clearable
              placeholder="交易流水号"
              v-model="queryParams.linkOrder"
          ></el-input>
        </el-form-item>
        <el-form-item label="上线时间：">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="exportList">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="3">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column prop="createTime" label="时间" align="center"></el-table-column>
      <el-table-column prop="userid" label="代理ID" align="center"></el-table-column>
      <el-table-column prop="actionType" align="center" label="记录类型">
        <template v-slot="{row}">
          <template v-if="row.actionType=== 7">代理人头奖</template>
          <template v-if="row.actionType=== 8">代理直推人头数达标奖励</template>
          <template v-if="row.actionType=== 9">代理薪资</template>
        </template>
      </el-table-column>
      <el-table-column prop="linkOrder" label="流水号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="amount" label="对应金额" align="center"></el-table-column>
      <el-table-column prop="coinsBefore" label="真金之前" align="center"></el-table-column>
      <el-table-column prop="coinsAfter" label="真金之后" align="center"></el-table-column>
      <el-table-column prop="bonusBefore" label="赏金之前" align="center"></el-table-column>
      <el-table-column prop="bonusAfter" label="赏金之后" align="center"></el-table-column>
      <el-table-column prop="commissionBefore" label="佣金之前" align="center"></el-table-column>
      <el-table-column prop="commissionAfter" label="佣金之后" align="center"></el-table-column>
      <el-table-column prop="withdrawableBefore" label="提现之前" align="center"></el-table-column>
      <el-table-column prop="withdrawableAfter" label="提现之后" align="center"></el-table-column>
      <el-table-column prop="linkUser" label="操作人" align="center"></el-table-column>
      <el-table-column prop="logContent" label="备注说明" align="center" show-overflow-tooltip></el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>

</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {
      userid: undefined,
      dates: undefined
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      actionType: [
        {label: '代理人头奖', value: 7},
        {label: '代理直推人头数达标奖励', value: 8},
        {label: '代理薪资', value: 9},
      ],
      map: {
        7: '代理人头奖',
        8: '代理直推人头数达标奖励',
        9: '代理薪资'
      },
      moneyType: [
        {label: '真金', value: 1},
        {label: '赏金', value: 2},
        {label: '佣金', value: 3},
        {label: '可提现金额', value: 4},
        {label: '代理合作费', value: 5},
        {label: '真金打码', value: 11},
        {label: '赏金打码', value: 21},
      ],
      dataFrom: [
        {label: '客户发起（适用于充值、提现等）', value: 1},
        {label: '客户发起（适用于充值、提现等）', value: 2},
        {label: '后台人工操作（人工充值提现等）', value: 3},
      ],
      betInfos: [
        {label: '系统人头奖:', value: 0},
        {label: '系统薪金:', value: 0},
        {label: '人工(综合):', value: 0},
        {label: '人工(人头奖):', value: 0},
        {label: '人工(薪金):', value: 0},
        {label: '人工(其他):', value: 0},
        {label: '人工(邀请多人激励):', value: 0},
      ],
      tableData: [],
      queryParams: {type:1},
      dialogVisible: false,
      dialogTitle: "",
      payTimeRange: undefined,
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      gameCategoryList: [],
      gameProviderList: [],
      cmd: "user_wallt_change_log",
      paramVO: {},
      dates: {},
      // data: {cmd: this.cmd, action: "", data: {paramVO: {}, page: 0,},}
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryParams.type = 1
      this.payTimeRange = []
      this.queryData();
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.queryDataSum();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryDataSum() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      let data = {
        cmd: this.cmd,
        action: "query_page_sum",
        data: {
          paramVO: params
        },
      };
      this.$http
          .post("query_page_sum", data)
          .then((response) => {
            console.log("response.data", response.data);
            var datas = response.data.list
            this.betInfos[0].value = datas.systemHead
            this.betInfos[1].value = datas.systemSalary
            this.betInfos[2].value = datas.head
            this.betInfos[3].value = datas.theHead
            this.betInfos[4].value = datas.headSalary
            this.betInfos[5].value = datas.headElse
            this.betInfos[6].value = datas.inviteHead
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async exportList() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        const data = {
          cmd: this.cmd,
          action: "query_page",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          }
        }
        await this.$http
            .post(this.cmd, data)
            .then((response) => {
              if (response.data.list.length > 0) {
                listData = listData.concat(response.data.list);
                index++;
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      listData.forEach(item=>{
        item.actionType = this.map[item.actionType]
      })
      const data = [
        ['时间', '代理ID', '记录类型', '流水号', '对应金额', '真金之前', '真金之后', '赏金之前', '赏金之后', '佣金之前', '佣金之后', '提现之前','提现之后','代理合作费冻结总额-变化前','代理合作费冻结总额-变化后','代理合作费解冻总额-变化前','代理合作费解冻总额-变化后','操作人','备注说明'],
      ];
      listData.forEach(item=>{
        data.push([item.createTime, item.userid, item.actionType, item.linkOrder, item.amount, item.coinsBefore, item.coinsAfter, item.bonusBefore, item.bonusAfter, item.commissionBefore, item.commissionAfter, item.withdrawableBefore, item.withdrawableAfter, item.freezeCoinsBefore, item.freezeCoinsAfter, item.thawCoinsBefore, item.thawCoinsAfter, item.linkUser, item.logContent]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '薪金发放记录');
      XLSX.writeFile(wb, '薪金发放记录.xlsx');
    }
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
