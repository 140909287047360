<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="4">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <br/>
    <el-table :data="tableData">
      <el-table-column prop="reportDate" label="报告日期"></el-table-column>
      <el-table-column prop="loginCount" label="登录次数"></el-table-column>
      <el-table-column prop="lotteryCount" label="抽奖次数"></el-table-column>
      <el-table-column prop="operatingBehavior" label="操作行为"></el-table-column>
      <el-table-column prop="tryGame" label="游戏试玩"></el-table-column>
      <el-table-column prop="useProps" label="道具使用"></el-table-column>
      <el-table-column prop="betCount" label="下注次数"></el-table-column>
      <el-table-column prop="depositInitiateCount" label="发起存款(次数)"></el-table-column>
      <el-table-column prop="depositSuccessCount" label="成功存款(次数)"></el-table-column>
      <el-table-column prop="withdrawalInitiateCount" label="发起提款(次数)"></el-table-column>
      <el-table-column prop="withdrawalSuccessCount" label="成功提款(次数)"></el-table-column>
      <el-table-column prop="getCommissionCount" label="领取佣金款(次数)"></el-table-column>
      <el-table-column prop="userTags" label="活跃标签">
        <template v-slot="{row}">
          <template v-if="row.userTags===1">纯登录2</template>
          <template v-if="row.userTags===2">纯观光</template>
          <template v-if="row.userTags===3">薅羊毛</template>
          <template v-if="row.userTags===4">活跃</template>
          <template v-if="row.userTags===5">高活</template>
          <template v-if="row.userTags===6">狂热</template>
        </template>
      </el-table-column>
<!--      <el-table-column prop="wallet_coins" label="风险标签"></el-table-column>-->
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      item: {...defaultItem},
      queryParams: {},
      betInfos: [
        {label: '登录天数:', value: 0},
        {label: '活跃天数:', value: 0},
        {label: '薅羊毛天数:', value: 0},
        {label: '纯观光天数:', value: 0},
        {label: '纯登录天数:', value: 0},
      ],
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()],
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
        let data = {
          cmd: "user_behavior",
          action: "query_active_daily",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("user_behavior", data)
            .then((response) => {
              console.log("response.data", response.data);
              this.tableData = response.data.activeDailyLogs;
            })
            .catch((error) => {
              console.log("error", error);
            });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
      params.statistics = true;
      const data = {
        cmd: "user_behavior",
        action: "query_active_daily",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.betInfos[0].value = response.data.login_day;
            this.betInfos[1].value  = response.data.active_days;
            this.betInfos[2].value  = response.data.sheep_days;
            this.betInfos[3].value  = response.data.visit_days;
            this.betInfos[4].value  = response.data.login_days;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
