<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="datetimerange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下注金额" width="150">
          <el-input clearable placeholder="开始金额" v-model="queryParams.min_bet_amount"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input clearable placeholder="结束金额" v-model="queryParams.max_bet_amount"></el-input>
        </el-form-item>
        <el-form-item label="中奖金额" width="150">
          <el-input clearable placeholder="开始金额" v-model="queryParams.min_lottery_amount"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input clearable placeholder="结束金额" v-model="queryParams.max_lottery_amount"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="bet-info">

      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="3">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>

      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px" >
        <el-col v-for="info in background" :key="info.label" :span="3">
          <div>{{ info.label }} {{ info.value }}</div>
        </el-col>
      </el-row>

    </div>

    <br/>
  </div>

  <div class="table-container" style="width: 100%">
    <el-table :data="betAmountList.slice(0, 5)" style="width: 33%">
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="betAmount" label="下注额"></el-table-column>
    </el-table>
    <el-button class="btn1" type="primary" @click="ranking(1)">下载排行</el-button>

    <el-table :data="winAmountList.slice(0, 5)" style="width: 33%">
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="winAmount" label="结算额"></el-table-column>
    </el-table>
    <el-button class="btn1" type="primary"  @click="ranking(2)">中奖排行</el-button>

    <el-table :data="winLoseDiffList.slice(0, 5)" style="width: 33%">
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="winLoseDiff" label="总输赢"></el-table-column>
    </el-table>
    <el-button class="btn1" type="primary" @click="ranking(3)">输赢排行</el-button>
  </div>
  <div style="margin-top: 20px">
    <el-table :data="tableData">
      <el-table-column prop="createTime" label="下注时间"></el-table-column>
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="gameId" label="游戏ID"></el-table-column>
<!--      <el-table-column prop="" label="房间号(没有)"></el-table-column>-->
      <el-table-column prop="parentBetId" label="下注ID"></el-table-column>
<!--      <el-table-column prop="betAmount" label="下注额"></el-table-column>-->
      <el-table-column label="下注额">
        <template #default="scope">
          <span>现金：{{ (scope.row.betAmount - scope.row.giveBetAmount).toFixed(2)}}</span><br/>
          <span>奖金：{{ scope.row.giveBetAmount }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="winAmount" label="结算额"></el-table-column>-->
      <el-table-column label="结算额">
        <template #default="scope">
          <span>现金：{{ (scope.row.winAmount - scope.row.giveWinAmount).toFixed(2)}}</span><br/>
          <span>奖金：{{ scope.row.giveWinAmount }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="" label="抽水(没有)"></el-table-column>-->
<!--      <el-table-column prop="winLoseDiff" label="输赢"></el-table-column>-->
      <el-table-column label="输赢">
        <template #default="scope">
          <span>现金：{{ scope.row.coinsRealWinLoseDiff }}</span><br/>
          <span>奖金：{{ scope.row.coinsGiveWinLoseDiff }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="changes.before.coins" label="账号余额"></el-table-column>-->
      <el-table-column label="账号余额">
        <template #default="scope">
          <span>现金：{{ (scope.row.changes.after.coins - scope.row.changes.after.coins_give).toFixed(2) }}</span><br/>
          <span>奖金：{{ scope.row.changes.after.coins_give }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <span v-if="scope.row.winAmount>0">
            中奖<br/>
            {{scope.row.magnification}}倍
          </span>
          <span v-else>未中奖</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="supOrder(scope.$index)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="查看下注详情"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-descriptions direction="vertical" :column="5" title="下注游戏" :size="size" border>
      <el-descriptions-item label="游戏名称:">{{ gameRes.gameName }}</el-descriptions-item>
      <el-descriptions-item label="游戏供应商:">{{ gameRes.gameSuppliers }}</el-descriptions-item>
      <el-descriptions-item label="游戏id:">{{ gameRes.gameId }}</el-descriptions-item>
<!--      <el-descriptions-item label="游戏房间号:">{{ gameRes.gameRoom }}</el-descriptions-item>-->
    </el-descriptions>

    <el-descriptions direction="vertical" title="下注详情"/>
    <el-table :data="items">
      <el-table-column prop="parent_bet_id" label="下注ID"></el-table-column>
      <el-table-column prop="create_time" label="下注时间"></el-table-column>
      <el-table-column prop="bet_amount" label="下注额"></el-table-column>
      <el-table-column prop="win_amount" label="结算额"></el-table-column>
<!--      <el-table-column prop="" label="抽水(没有该字段)"></el-table-column>-->
      <el-table-column prop="transfer_amount" label="输赢"></el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
<!--        <el-button type="primary" @click="dialogOkHandle">确定</el-button>-->
      </span>
    </template>
  </el-dialog>

<!--  三个弹窗-->
  <el-dialog
      v-model="rankingVisible"
      title=""
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-radio-group v-model="status" size="small" >
      <el-radio-button label="1">下载排行</el-radio-button>
      <el-radio-button label="2">中奖排行</el-radio-button>
      <el-radio-button label="3">输赢排行</el-radio-button>
    </el-radio-group>

    <el-table :data="betAmountList" v-if="status == 1">
      <el-table-column prop="gameId" label="游戏ID"></el-table-column>
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="betAmount" label="下注额"></el-table-column>
      <el-table-column  label="占比">
        <template v-slot="{row}">
          {{ ((row.betAmount / betInfos[1].value) * 100).toFixed(2) }} %
        </template>
      </el-table-column>
    </el-table>
    <el-table :data="winAmountList" v-if="status == 2">
      <el-table-column prop="gameId" label="游戏ID"></el-table-column>
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="winAmount" label="结算额"></el-table-column>
      <el-table-column  label="占比">
        <template v-slot="{row}">
          {{ ((row.winAmount / betInfos[2].value) * 100).toFixed(2) }} %
        </template>
      </el-table-column>
    </el-table>
    <el-table :data="winLoseDiffList" v-if="status == 3">
      <el-table-column prop="gameId" label="游戏ID"></el-table-column>
      <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
      <el-table-column prop="winLoseDiff" label="总输赢"></el-table-column>
      <el-table-column  label="占比">
        <template v-slot="{row}">
          {{ ((row.winLoseDiff / betInfos[4].value) * 100).toFixed(2) }} %
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="rankingVisible = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    return {
      userid: this.$route.params.userid,
      tableData: [],
      betInfos: [
        {label: '下注次数：', value: 0},
        {label: '总下注额：', value: 0},
        {label: '总结算额：', value: 0},
        {label: '总抽水额：', value: 0},
        {label: '总输赢：', value: 0},
        {label: '下注(0~10)：', value: 0},
        {label: '下注(10~50)：', value: 0},
        {label: '下注(50~500)：', value: 0},
      ],
      background: [
        {label: '下注(500+)：', value: 0},
        {label: '中奖次数：', value: 0},
        {label: '<3倍中奖:', value: 0},
        {label: '3倍中奖：', value: 0},
        {label: '10倍中奖：', value: 0},
        {label: '30倍中奖：', value: 0},
        {label: '100倍中奖：', value: 0},
        {}
      ],
      betAmountList: [
      ],
      winAmountList: [],
      winLoseDiffList: [],
      item: {...defaultItem},
      // 详情弹窗
      // items: {gameName:'游戏名称',gameSuppliers:'供应商',gameId:'',gameRoom:'',data:[]},
      // 游戏下注详情表头
      gameRes: {...defaultItem},
      // 用户行为详情
      items: [],
      queryParams: {},
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      rankingVisible: false,
      dialogVisible: false
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      // this.queryParams.time = [startOfDay, new Date()],
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.queryDataSum();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 1);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 1);
      }
      params.userid = this.userid
      let data = {
        cmd: "user_behavior",
        action: "query_game_behavior",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.gameLogs;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryDataSum() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 1);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 1);
      }
      params.userid = this.userid
      params.statistics = true
      let data = {
        cmd: "user_behavior",
        action: "query_game_behavior",
        data: {
          paramVO: params
        },
      };
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            var datas = response.data
            this.betInfos[0].value = datas.betNumber
            this.betInfos[1].value = datas.betAmount
            this.betInfos[2].value = datas.winAmount
            this.betInfos[3].value = datas.pumpAmount
            this.betInfos[4].value = datas.winLoseDiff
            this.betInfos[5].value = datas.betTen
            this.betInfos[6].value = datas.betFifty
            this.betInfos[7].value = datas.betFiveHundred

            this.background[0].value = datas.betDayuFiveHundred
            this.background[1].value = datas.winsNumber
            this.background[2].value = datas.winsLTThreeNumber
            this.background[3].value = datas.winsGTThreeNumber
            this.background[4].value = datas.winsGTTenNumber
            this.background[5].value = datas.winsGTThirtyNumber
            this.background[6].value = datas.winsGTOneHundredNumber

            this.betAmountList = datas.betAmountList
            this.winAmountList = datas.winAmountList
            this.winLoseDiffList = datas.winLoseDiffList
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    details(gameId, parentBetId) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      params.gameId = gameId
      params.parentBetId = parentBetId
      let data = {
        cmd: "user_behavior",
        action: "query_history_by_bet_id",
        data: {
          paramVO: params
        },
      };

      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.gameRes = response.data.gameInfo;
            this.items = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    supOrder(index) {
      this.item = this.tableData[index];
      this.details(this.item.gameId, this.item.parentBetId)
      this.dialogVisible = true;
    },
    dislogCancelHandle() {
      // this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      alert('没有')
    },
    ranking(status){
      this.rankingVisible = true
      this.status = status
    }

  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
/* 在全局CSS文件中添加样式 */
.custom-header {
  background-color: #041715;
  color: white;
}

.table-container {
  display: flex;
}

.vertical-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(33.33% + 0.5px);
  width: 1px;
  background-color: #0d1717; /* 可以根据你的主题颜色进行修改 */
}

/* 防止内容被竖线遮挡 */
.table-container > div {
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.btn {
  min-width: 50%;
  height: 45px;
  position: relative;
  display: flex;
  //justify-content:flex-end;
  justify-content: flex-start;

}

.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}

.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}


</style>
