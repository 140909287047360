<template>
  <div class="d2">
    <el-button class="btn1" type="primary"   @click="skipLog()">公告发送记录</el-button>
  </div>

  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="发送对象" >
          <el-select placeholder="请选择" clearable  style="width: 100%" v-model="queryParams.type">
            <el-option
                v-for="(item, index) in status"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发送时间">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle"><span>重置</span>
          </el-button>
          <el-button @click="addHandle">新增</el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column prop="number" label="公告编号" align="center"></el-table-column>

      <el-table-column prop="details" label="内容" align="center"></el-table-column>

      <el-table-column prop="type" align="center" label="发送对象">
        <template v-slot="{row}">
          <template v-if="row.type===  0">立即发送</template>
          <template v-if="row.type=== 1">循环发送</template>
          <template v-if="row.type=== 2">定时发送</template>
        </template>
      </el-table-column>

      <el-table-column prop="sendTime" label="发送日期" align="center"></el-table-column>
      <el-table-column prop="time" label="发送时间" align="center"></el-table-column>
      <el-table-column prop="click" label="次数" align="center"></el-table-column>
      <el-table-column prop="userid" label="操作人" align="center"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status=== 1">有效</template>
          <template v-if="row.status=== 0 ">无效</template>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="250" align="center">
        <template #default="scope">
          <el-button type="primary" size="small" @click="editHandle(scope.$index)">查看</el-button>
          <el-button v-if="scope.row.status === 1" type="warning" size="small"  @click="forbidden(scope.$index)">关闭</el-button>
          <el-button v-else type="warning" size="small"  @click="open(scope.$index)">开启</el-button>
          <el-button type="danger" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">

      <el-form-item label="是否开启" prop="status" required>
        <el-select placeholder="请选择"  :disabled="disabled" style="width: 100%" v-model="item.status">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="公告编号" prop="number">
        <el-input v-model="item.number" :disabled="disabled" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="公告">
        <el-input
            :disabled="disabled"
            v-model="item.details"
            rows="5"
            type="textarea"
            maxlength="1000"
            placeholder="详情"
            autocomplete="off"
            show-word-limit
        />
      </el-form-item>

      <el-form-item label="选项" prop="type" align="center">
        <el-radio-group v-model="item.type" :disabled="disabled">
          <el-radio :label="0">立即发送</el-radio>
          <el-radio :label="1">循环发送</el-radio>
          <el-radio :label="2">定时发送</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item  v-if="item.type === 2" label="次数" prop="click">
        <el-input v-model="item.click" :disabled="disabled" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item v-if="item.type === 1" label="循环类型" prop="sendStatus" align="center">
        <el-radio-group v-model="item.sendStatus" :disabled="disabled">
          <el-radio :label="0">每天</el-radio>
          <el-radio :label="1">每月</el-radio>
          <el-radio :label="2">每周</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="item.sendStatus === 1|| item.type === 2" label="开始日期" prop="sendTime">
        <el-date-picker
            :disabled="disabled"
            v-model="item.sendTime"
            clearable
            style="width: 100%"
            type="date"
            placeholder="选择日期"
        />
      </el-form-item>

      <el-form-item v-if="item.sendStatus === 2" label="周数" prop="weekday">
        <el-select placeholder="请选择" :disabled="disabled" style="width: 100%" v-model="item.weekday">
          <el-option
              v-for="(item, index) in weekday"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="item.type !== 0" label="开始时间" prop="time">
        <el-time-picker :disabled="disabled" v-model="item.time"
                         placeholder="时间"
                        value-format="HH:mm:ss"
                        style="width: 100%"></el-time-picker>
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">关闭</el-button>
        <el-button v-if="!item.id" type="primary" @click="confirmHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      disabled: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pub_platform_affiche',
      queryParams: {},
      dates: {},
      item: {},
      payTimeRange: {},
      keeps: [
        {label: '开启', value: 1},
        {label: '关闭', value: 0},
      ],
      weekday: [
        {label: '周一', value: 1},
        {label: '周二', value: 2},
        {label: '周三', value: 3},
        {label: '周四', value: 4},
        {label: '周五', value: 5},
        {label: '周六', value: 6},
        {label: '周日', value: 7},
      ],
      status: [
        {label: '立即发送', value: 0},
        {label: '循环发送', value: 1},
        {label: '定时发送', value: 2},
      ],
      isEdit: false,
      rules: {
        number: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 6, max: 6, message: '长度在 6 到 6 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择时间', trigger: 'blur'},
        ],
        sendStatus: [
          {required: true, message: '请选择发送对象', trigger: 'blur'},
        ],

        sendTime: [
          {required: true, message: '选择日期', trigger: 'blur'},
        ],
        time: [
          {required: true, message: '选择时间', trigger: 'blur'},
        ],
        weekday: [
          {required: true, message: '请选择周数', trigger: 'blur'},
        ],

      }
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
  },
  methods: {
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.item.type = 0
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm('确定要删除么?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    forbidden(index) {
      this.$confirm("确定要禁用吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = this.tableData[index];
        this.item.status  = '0'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消禁用",
            });
          });
    },
    open(index) {
      this.$confirm("确定要开启吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item =JSON.parse(JSON.stringify(this.tableData[index]));
        this.item.status  = '1'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消开启",
            });
          });
    },
    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "查看";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.isEditDialog = true;
      this.disabled = true;
      this.dialogFormVisible = true;
    },
    dialogCancelHandles() {
      this.disabled = false
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: this.cmd,
        action: "delete",
        data: {
          paramVO: {
            id,
          },
        },
      };
      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    resetHandle() {
      this.payTimeRange = []
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    skipLog() {
      this.$router.push(`/agency-pubAfficheLog`)
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
 .d2 {
   min-width: 50%;
   height: 45px;
   position: relative;

   justify-content:flex-end;

 }
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
