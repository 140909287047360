<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-input placeholder="用户ID" v-model="queryParams.userid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item label="奖品类型">
          <el-select placeholder="请选择" clearable style="width: 100%" v-model="queryParams.prizeType">
            <el-option
                v-for="(item, index) in types"
                :key="index"
                :label="item.typeName"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <!--          <el-button @click="addItemHandle">-->
          <!--            <el-icon>-->
          <!--              <Plus/>-->
          <!--            </el-icon>-->
          <!--            <span>新增</span>-->
          <!--          </el-button>-->
        </el-form-item>
      </el-form>
    </el-row>

    <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column prop="userid" label="关联用户" align="center"></el-table-column>
      <el-table-column prop="prizeNameCn" label="奖品名称" align="center"></el-table-column>
      <el-table-column prop="describeCn" label="奖品描述" align="center"></el-table-column>
      <el-table-column prop="icon" label="奖品图标" align="center">
        <template #default="scope">
          <img :src="$tools.cdn+scope.row.prizeImageUrl" style="height: 50px;"/>
        </template>
      </el-table-column>
      <el-table-column label="有效期" width="200">
        <template v-slot="{row}">
          <span v-if="row.depositExpirationType===0">不限制</span>
          <span v-if="row.depositExpirationType!==0">{{ row.depositExpiration }}</span>
        </template>
      </el-table-column>
      <el-table-column label="增益时长" width="200">
        <template v-slot="{row}">
          <span v-if="row.stateDurationType===0">不限制</span>
          <span v-else>{{ row.stateDurationParm+'分'}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="isUsed" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.isUsed=== -1 ">未领取</template>
          <template v-if="row.isUsed=== 0 ">未使用</template>
          <template v-if="row.isUsed=== 1">使用中</template>
          <template v-if="row.isUsed=== 2">使用完成</template>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="派奖时间" align="center"></el-table-column>
      <el-table-column prop="useTime" label="使用时间" align="center"></el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    const defaultItem = {
      userid: undefined,
      dates: undefined
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      queryParams: {...defaultItem},
      dialogVisible: false,
      examine: false,
      dialogTitle: "",
      types: [],
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      cmd: "pub_setting_group",
      paramVO: {},
      item: {},
      payTimeRange: {},
      dates: {},
      data: {cmd: this.cmd, action: "", data: {paramVO: {}},},
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
    this.queryList();
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.tableData = response.data.list
            // this.tableData.forEach((it) => {
            //   it.stateDuration =  this.getDiffTime(it.stateDuration, it.useTime)
            // })
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    getDiffTime(new_date,old_date){
      let payTimeStamp = new Date(new_date).getTime();
      let TimeStamp = new Date(old_date).getTime();
      var subtime = (payTimeStamp - TimeStamp) / 1000;    //计算时间差,并将毫秒转化为秒
      var days = parseInt(subtime / 86400);  //天  24*60*60*1000
      var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
      var mins = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
      // var secs = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
      // return days + "天 " + hours + "小时 " + mins + "分钟 " + secs + "秒 ";
      if(days>0){
        return days + "天" + hours + "小时" + mins + "分钟";
      }else if(hours>0){
        return hours + "小时" + mins + "分钟";
      }else{
        return mins + "分钟";
      }
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    queryList() {
      const data = {
        cmd: 'pub_setting_award',
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.types = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },


  }
};
</script>

<style lang="scss" scoped></style>
