<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :model="form" :inline="true">
        <el-form-item label="用户ID：">
          <el-input placeholder="用户ID"  clearable v-model="queryParams.userid"></el-input>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
            clearable
            v-model="queryParams.time"
            class="select1"
            type="daterange"
            range-separator="To"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>

  <br />

  <el-table :data="tableData">
    <el-table-column prop="id"  label="ID"></el-table-column>
    <el-table-column prop="userid" label="用户ID"></el-table-column>
    <el-table-column prop="app_id" label="APPID"></el-table-column>
    <el-table-column prop="nickname" label="用户昵称"></el-table-column>
    <el-table-column prop="os_type_desc" label="设备类型"></el-table-column>
    <el-table-column prop="login_ip" label="IP"></el-table-column>
    <el-table-column prop="type_desc" label="上线类型"></el-table-column>
    <el-table-column prop="login_device" label="登录设备"></el-table-column>
    <el-table-column prop="begin_time" label="登录时间"></el-table-column>
    <el-table-column prop="end_time" label="离线时间"></el-table-column>
    <el-table-column prop="duration" label="在线时长"></el-table-column>
    <el-table-column prop="ip_home" label="地点"></el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
      v-if="tableData.length>0"
  />
</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      total: 0,
      pages: 0,
      page: 0,
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      let data = {
        cmd: "user",
        action: "user_login_logs",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.login_logs;

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        //时间
        if (params.time) {
          params.start_time = this.$tools.dateFormat(params['time'][0], 2);
          params.end_time = this.$tools.dateFormat(params['time'][1], 2);
        }
        //
        let data = {
          cmd: "user",
          action: "user_login_logs",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("user", data)
            .then((response) => {
              if (response.data.login_logs.length !== 0) {
                listData = listData.concat(response.data.login_logs);
                index++;
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['ID', '用户ID', 'APPID', '用户昵称', '设备类型', 'IP', '上线类型', '登录设备', '登录时间', '离线时间', '在线时长', '地点'],
      ];
      listData.forEach(item=>{
        data.push([item.id, item.userid, item.app_id, item.nickname, item.os_type_desc, item.login_ip, item.type_desc, item.login_device, item.begin_time, item.end_time, item.duration, item.ip_home]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '用户登录记录');
      XLSX.writeFile(wb, '用户登录记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
