<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="状态">
          <el-select placeholder="状态" style="width: 100%" v-model="queryParams.status" clearable>
            <el-option
                v-for="(item, index) in keeps"
                :key="index"
                :label="item.label"
                :value="item.value"

            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input placeholder="根据弹窗名" v-model="queryParams.remark" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="addHandle">新增</el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column
        prop="remark"
        label="弹窗说明（内部用）"
      ></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column prop="imageId" label="弹窗图" align="center">
        <template #default="scope">
          <img :src="$tools.cdn+scope.row.imageId" style="height: 50px;" />
        </template>
      </el-table-column>
      <el-table-column prop="hyperLink" label="超链接"></el-table-column>
      <el-table-column prop="priority" label="优先级"></el-table-column>
      <el-table-column prop="isLogin" align="center" label="是否限制登录">
        <template v-slot="{row}">
          <template v-if="row.isLogin=== 0">不限</template>
          <template v-if="row.isLogin=== 1">登录前</template>
          <template v-if="row.isLogin=== 2">登录后</template>
        </template>
      </el-table-column>
      <el-table-column prop="newUser" align="center" label="是否限制用户">
        <template v-slot="{row}">
          <template v-if="row.newUser=== 0">不限</template>
          <template v-if="row.newUser=== 1">新用户</template>
          <template v-if="row.newUser=== 2">老用户</template>
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status=== 1">有效</template>
          <template v-if="row.status=== 0">无效</template>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template #default="scope">
<!--          <el-button type="primary" size="small">查看</el-button>-->
          <el-button
            type="primary"
            size="small"
            @click="editHandle(scope.$index)"
            >编辑</el-button
          >
          <el-button v-if="scope.row.status === '1'" type="warning" size="small" @click="closeHandle(scope.$index)">关闭</el-button>
          <el-button v-else type="warning" size="small" @click="open(scope.$index)">开启</el-button>
          <el-button
            type="danger"
            size="small"
            @click="deleteHandle(scope.$index)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-dialog
    v-model="dialogFormVisible"
    :title="isEditDialog ? '编辑' : '新增'"
    style="text-align: left"
    :close-on-click-modal="false"
  >
    <el-form :model="itemData" ref="rowForm" :rules="rules" label-width="120px">
      <el-form-item label="弹窗说明：" :label-width="formLabelWidth" prop="remark">
        <el-input v-model="itemData.remark" placeholder="请输入弹窗说明，平台前端不显示，内部识别用"></el-input>
      </el-form-item>
      <el-form-item label="优先级：">
        <el-slider v-model="itemData.priority" show-input />
      </el-form-item>

      <el-form-item label="弹窗图" prop="imageId">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="itemData.URL"></UploadImage>
      </el-form-item>

      <el-form-item label="链接：" prop="hyperLink">
        <el-input
          v-model="itemData.hyperLink"
          placeholder="请输入链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否有效" prop="status" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="itemData.status">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否限制登录：" prop="isLogin">
        <el-radio-group v-model="itemData.isLoginIndex">
          <el-radio
            v-for="(item, index) in isLoginList"
            :key="index"
            :label="index"
            >{{ item }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    <el-form-item label="是否限制用户：" prop="newUser">
      <el-radio-group v-model="itemData.newUserIndex">
        <el-radio
            v-for="(item, index) in newUserList"
            :key="index"
            :label="index"
        >{{ item }}</el-radio
        >
      </el-radio-group>
    </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'
export default {
  components: {UploadImage},
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      queryParams: {},
      itemData: {
        priority: 0,
        URL: "",
        hyperLink: "",
        isLogin: "",
        isLoginIndex: 0,
        newUser: "",
        newUserIndex: 0,
        targetPersons: "",
        remark: "",
        createBy: "",
        status:0
      },
      keeps: [
        {label: '有效', value: 1},
        {label: '无效', value: 0},
      ],
      isLoginList: ["不限", "登录前", "登录后"],
      newUserList: ["不限", "新用户", "老用户"],
      isEditDialog: false,
      rules: {
        remark: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],priority: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        hyperLink: [
          {required: true, message: '请输入标题', trigger: 'blur'},
        ],
        isLoginIndex: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        newUserIndex: [
          {required: true, message: '请选择', trigger: 'blur'},
        ], URL: [
          {required: true, message: '弹窗图不能为空', trigger: 'blur'},
        ],
      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.itemData = {
        priority: 0,
        imageId: "",
        hyperLink: "",
        isLogin: "",
        isLoginIndex: 0,
        newUser: "",
        newUserIndex: 0,
        targetPersons: "",
        remark: "",
        createBy: "",
      };
      this.isEditDialog = false;
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm("确定要删除么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let item = JSON.parse(JSON.stringify(this.tableData[index]))
          this.deleteData(item.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      console.log("index", index);
      let item =  JSON.parse(JSON.stringify(this.tableData[index]));
      this.itemData = item;
      this.itemData.priority = Number(this.itemData.priority);
      if (this.itemData.isLogin === '不限') {
        this.itemData.isLoginIndex = 0;
      } else if (this.itemData.isLogin === '登录前') {
        this.itemData.isLoginIndex = 1;
      } else {
        this.itemData.isLoginIndex = 2;
      }
      if (this.itemData.newUser === '不限') {
        this.itemData.newUserIndex = 0;
      } else if (this.itemData.newUser === '新用户') {
        this.itemData.newUserIndex = 1;
      } else {
        this.itemData.newUserIndex = 2;
      }
      this.isEditDialog = true;
      this.dialogFormVisible = true;
    },
    isLoginChangeHandel(title, index, state) {
      console.log(title, index, state);
    },

    /**
     * 关闭按钮事件
     */
    closeHandle(index) {
      this.$confirm("确定要关闭弹窗么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let item = JSON.parse(JSON.stringify(this.tableData[index]))
          this.disableData(item.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已关闭",
          });
        });
    },

    /**
     * 开启按钮事件
     */
    open(index) {
      this.$confirm("确定要开启弹窗么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.itemData = JSON.parse(JSON.stringify(this.tableData[index]))
          this.itemData.status = 1
          this.updateData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已开启",
          });
        });
    },

    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          this.itemData.isLogin = this.itemData.isLoginIndex;
          this.itemData.newUser = this.itemData.newUserIndex;
          if (this.isEditDialog) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })

    },

    /**
     * 查询数据接口
     */
    queryData() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        action: "query",
        data: {
          popupVO: params,
        },
      };

      this.$http
        .post("popup", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.tableData = response.data.popupVOList
              this.tableData.forEach((itme) => {
                itme.URL = itme.imageId?this.$tools.cdn+itme.imageId:''
              })
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: "popup",
        action: "add",
        data: {
          popupVO: this.itemData,
        },
      };

      this.$http
        .post("popup", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.queryData();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: "popup",
        action: "update",
        data: {
          popupVO: this.itemData,
        },
      };

      this.$http
        .post("popup", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.queryData();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },

    /**
     * 关闭数据接口
     */
    disableData(id) {
      const data = {
        cmd: "popup",
        action: "disable",
        data: {
          popupVO: {
            id,
          },
        },
      };

      this.$http
        .post("popup", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.$message({
            type: "success",
            message: "关闭成功!",
          });
          this.queryData();
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: "popup",
        action: "delete",
        data: {
          popupVO: {
            id,
          },
        },
      };
      this.$http
        .post("popup", data)
        .then((response) => {
          // 请求成功后的处理
          console.log(response.data);
          this.queryData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch((error) => {
          // 请求失败后的处理
          console.error(error);
        });
    },

    /**
     * 图片上传成功回调
     * @param {*} res
     * @param {*} file
     */
    handleAvatarSuccess(data) {
      this.itemData.imageId = data.file_url;
      this.itemData.URL = data.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
