<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :model="form" :inline="true">
        <el-form-item label="">
          <el-input clearable  placeholder="请输入用户ID" v-model="queryParams.userid"></el-input>
        </el-form-item>
<!--        <el-form-item label="入款类型">-->
<!--          <el-select placeholder="请选择">-->
<!--            <el-option label="label" value="value"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="操作人">-->
<!--          <el-input placeholder="操作人代码"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
            class="select1"
            type="daterange"
            range-separator="To"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="createTimeSortChange">
            <span>入款时间</span>
            <el-icon>
<!--              <Bottom />-->
              <SortUp v-if="queryParams.createTimeDesc===0" />
              <SortDown v-else />
            </el-icon>
          </el-button>
          <el-button @click="paymentAmountSortChange">
            <span>入款金额</span>
            <el-icon>
<!--              <Bottom />-->
              <SortUp v-if="queryParams.paymentAmountDesc===0" />
              <SortDown v-else />
            </el-icon>
          </el-button>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button type="primary" @click="addItemHandle">
            <el-icon><Plus /></el-icon>
            <span>人工入款</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-row style="background-color: white; text-align: left; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">入款真金：</el-col>
          <el-col span="">{{realGoldDeposit}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">入款赠真金：</el-col>
          <el-col span="">{{depositDeposit}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">入款赏金：</el-col>
          <el-col span="">{{paymentBounty}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">可提现金额：</el-col>
          <el-col span="">{{withdrawableAmount}}</el-col>
        </el-row>
        <el-button type="" @click="doRefresh">刷新</el-button>
      </el-space>
    </el-row>
  </div>

  <br />

  <el-table :data="tableData">
    <el-table-column prop="pay_time" label="入款时间"></el-table-column>
    <el-table-column prop="userid" label="用户"></el-table-column>
    <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
    <el-table-column prop="phone" label="绑定手机号"></el-table-column>
    <el-table-column prop="real_name" label="真实姓名"></el-table-column>
    <el-table-column prop="payment_type_str" label="入款类型"></el-table-column>
    <el-table-column prop="amount" label="入款金额"></el-table-column>
    <el-table-column prop="remark" label="入款理由"></el-table-column>
    <el-table-column prop="create_user" label="操作人"></el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
      v-if="tableData.length>0"
  />

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @close="dislogCancelHandle"
  >
    <el-form-item class="searchAdd">
      <el-input placeholder="请输入用户ID" v-model="addUserId" style="width: 70%;"></el-input>
      <el-button type="primary" @click="getUserInfo()" style="position:absolute;right:0;">
        <span>查询并添加</span>
      </el-button>
    </el-form-item>
    <el-table :data="addUserInfoBox" style="margin-bottom:50px;" empty-text="请先在输入用户ID查询并添加用户数据" v-if="dialogVisible">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
      <el-table-column prop="phone" label="绑定手机号"></el-table-column>
      <el-table-column prop="firstname" label="真实姓名">
        <template #default="scope">
          <span>{{scope.row.firstname}} {{scope.row.lastname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coins" label="账户真金"></el-table-column>
      <el-table-column prop="bonus" label="账户赏金"></el-table-column>
    </el-table>
    <el-form :model="item" ref="rowForm" :rules="rules">
      <el-form-item label="入款类型：" prop="payment_type">
        <el-select placeholder="请选择" v-model="item.payment_type">
          <el-option
              v-for="(item, index) in payment_type_arr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入款金额：" prop="amount">
        <el-input-number v-model="item.amount" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="入款理由：" prop="remark">
        <el-input type="textarea" v-model="item.remark" placeholder="请输入入款理由"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">确定入款</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {SortDown, SortUp} from "@element-plus/icons-vue";
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";

export default {
  components: {SortUp, SortDown},
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      realGoldDeposit: 0,
      depositDeposit: 0,
      paymentBounty: 0,
      withdrawableAmount: 0,
      total: 0,
      pages: 0,
      page: 0,
      payment_type_arr: [
        {label:'真金(存款)',value:1},
        {label:'真金(赠送)',value:2},
        // {label:'赏金(赠送)',value:3},
        {label:'可提现金额(增加)',value:4},
        {label:'代理佣金(增加)',value:5},
        {label:'代理合作费(冻结)',value:6},
      ],
      dialogVisible: false,
      dialogTitle: "",
      addUserId:"",
      addUserInfoBox:[],
      rules: {
        payment_type: [
          { required: true, message: '请选择入款类型', trigger: 'change' }
        ],
        amount: [
          { required: true, message: '请输入入款金额', trigger: 'change' }
        ],
        remark: [
          { required: true, message: '请输入入款理由', trigger: 'blur' }
        ],
      },
      showViewData: false
    };
  },
  methods: {
    createTimeSortChange(){
      if(this.queryParams.createTimeDesc!==0){
        this.queryParams.createTimeDesc = 0;
      }else{
        this.queryParams.createTimeDesc = 1;
      }
      this.queryData();
    },
    paymentAmountSortChange(){
      if(this.queryParams.paymentAmountDesc!==0){
        this.queryParams.paymentAmountDesc = 0;
      }else{
        this.queryParams.paymentAmountDesc = 1;
      }
      this.queryData();
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance()
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      //排序
      if(![0,1].includes(params.createTimeDesc)){
        params.createTimeDesc = 1;
      }
      if(![0,1].includes(params.paymentAmountDesc)){
        params.paymentAmountDesc = 1;
      }
      //
      let data = {
        cmd: "manual_operate",
        action: "query_manual_income",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.manual_payment_list;
            this.tableData.forEach((item) => {
              //入款类型1-真金(存款)2-真金(赠送)3-赏金(赠送)4-可提现金额(增加)
              for(let i of this.payment_type_arr){
                if(i.value==item.payment_type){
                  item.payment_type_str = i.label;
                  break;
                }
              }
              item.create_time_str = this.$tools.dateFormat(item.create_time)
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      //排序
      if(![0,1].includes(params.createTimeDesc)){
        params.createTimeDesc = 1;
      }
      if(![0,1].includes(params.paymentAmountDesc)){
        params.paymentAmountDesc = 1;
      }
      const data = {
        cmd: "manual_operate",
        action: "query_manual_income_statistics",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.realGoldDeposit = response.data.realGoldDeposit;
            this.depositDeposit = response.data.depositDeposit;
            this.paymentBounty = response.data.paymentBounty;
            this.withdrawableAmount = response.data.withdrawableAmount;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addItemHandle() {
      this.dialogTitle = "人工入款";
      // Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.addUserId = "";
      this.addUserInfoBox = [];
      this.dialogVisible = true;
    },
    dislogCancelHandle() {
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.$refs.rowForm.resetFields();
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          return false;
        }else {
          this.addData();
        }
      })
    },
    addData() {
      if(this.addUserInfoBox.length<=0){
        this.$message({
          type: "info",
          message: "请先添加用户",
        });
        return;
      }
      //用户信息
      this.item.userid = this.addUserInfoBox[0]['userid'];
      this.item.vip_level = this.addUserInfoBox[0]['vip_level'];
      this.item.phone = this.addUserInfoBox[0]['phone'];
      this.item.real_name = (this.addUserInfoBox[0]['firstname']?this.addUserInfoBox[0]['firstname']+' ':'')+(this.addUserInfoBox[0]['lastname']?this.addUserInfoBox[0]['lastname']:'');
      //
      const data = {
        cmd: "manual_operate",
        action: "add_manual_income",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dislogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    getUserInfo() {
      if(!this.addUserId || this.addUserId==""){
        this.$message({
          type: "info",
          message: "请输入用户ID",
        });
        return;
      }
      const data = {
        cmd: "user",
        action: "query_user",
        data: {
          paramVO: {},
          userid: this.addUserId,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            if(!response.data.user_data?.user_status_info || response.data.user_data?.user_status_info==null){
              this.$message({
                type: "info",
                message: "用户不存在",
              });
              this.addUserInfoBox = [];
              return;
            }
            //用户
            this.addUserInfoBox = [
              {
                userid:response.data.user_data.user_status_info.userid,
                vip_level:response.data.user_data.user_customer_info.vip_level_name,
                firstname:response.data.user_data.user_customer_info.firstname,
                lastname:response.data.user_data.user_customer_info.lastname,
                phone:response.data.user_data.user_customer_info.phone,
                coins:response.data.user_data.user_wallet.coins,
                bonus:response.data.user_data.user_wallet.bonus,
              }
            ];
            //
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        //时间
        if(params.time){
          params.start_time = this.$tools.dateFormat(params['time'][0],2);
          params.end_time = this.$tools.dateFormat(params['time'][1],2);
        }
        //
        //排序
        if(![0,1].includes(params.createTimeDesc)){
          params.createTimeDesc = 1;
        }
        if(![0,1].includes(params.paymentAmountDesc)){
          params.paymentAmountDesc = 1;
        }
        //
        let data = {
          cmd: "manual_operate",
          action: "query_manual_income",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("manual_operate", data)
            .then((response) => {
              if (response.data.manual_payment_list.length !== 0) {
                listData = listData.concat(response.data.manual_payment_list);
                index++;
                listData.forEach((item) => {
                  //入款类型1-真金(存款)2-真金(赠送)3-赏金(赠送)4-可提现金额(增加)
                  for(let i of this.payment_type_arr){
                    if(i.value==item.payment_type){
                      item.payment_type_str = i.label;
                      break;
                    }
                  }
                  item.create_time_str = this.$tools.dateFormat(item.create_time)
                });

              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['入款时间', '用户', 'VIP级别', '绑定手机号', '真实姓名', '入款类型', '入款金额', '入款理由', '操作人'],
      ];
      listData.forEach(item=>{
        data.push([item.pay_time, item.userid, item.vip_level, item.phone, item.real_name, item.payment_type_str, item.amount, item.remark, item.create_user]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '人工入款记录');
      XLSX.writeFile(wb, '人工入款记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
    this.initShowViewData();
  },
};
</script>

<style lang="scss" scoped></style>
