<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="代理id">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.agentId"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br/>

    <div style="background-color: white">


      <el-table
          :data="tableData"
          :header-cell-style="{ 'text-align': 'center' }"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row>
        <el-table-column prop="agentId" label="代理ID" align="center"></el-table-column>
<!--        <el-table-column prop="phone" label="绑定手机号" align="center"></el-table-column>-->
        <el-table-column prop="first_pay_time" label="首充时间" align="center"></el-table-column>
        <el-table-column prop="pay_amount" label="首充金额" align="center"></el-table-column>
        <el-table-column prop="agentLevel" label="代理级别" align="center"></el-table-column>
        <el-table-column prop="totalNumberInviteLevel1" label="直推人数" align="center"></el-table-column>
<!--        <el-table-column prop="" label="结算方式" align="center"></el-table-column>-->
        <el-table-column prop="totalBonusSalaySettlement" align="center" label="已结算佣金"></el-table-column>
        <el-table-column prop="totalBonusSalayDray" align="center" label="已提取佣金"></el-table-column>
        <el-table-column prop="fatherAgentId" align="center" label="上级代理ID"></el-table-column>

        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="text" size="small" @click="editHandlesUser(scope.$index)">代理配置</el-button>
            <el-button type="text" size="small" @click="collect(scope.$index)">统计与薪金发放</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :page-size="20"
          layout="total,prev, pager, next, jumper"
          :total="total"
          @current-change="pageChangeHandle"
      />
    </div>
  </div>

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="结算方式" v-if="status === 1">
        <el-radio-group v-model="item.clientViewLevel">
          <el-radio :label="1">默认（只能查看到直推数据)</el-radio>
          <el-radio :label="2">二级（可查看直推和二级团队数据)</el-radio>
          <el-radio :label="3">三级级（可查看一、二、三级团队数据)</el-radio>
        </el-radio-group>
      </el-form-item>


      <el-form-item label="代理级别" v-if="status === 2">
        <el-input type="number" min='0' max='3' v-model="item.agentLevel" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="设置专属客服" v-if="status === 2">
        <el-input v-model="item.customerServiceUrl" placeholder="请输入"></el-input>
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="dialogVisibleas"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="row" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="结算方式" align="center">
        <el-radio-group v-model="row.inviteBonusSettlementMethod">
          <el-radio :label="1">人工</el-radio>
          <el-radio :label="2">系统每日自动</el-radio>
          <el-radio :label="3">系统实时自动</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleas = false">取消</el-button>
        <el-button type="primary" @click="allocation">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: {...defaultItem},
      queryParams: {...defaultItem},
      isEdit: false,
      dialogVisible: false,
      dialogVisibleas: false,
      payTimeRange: undefined,
      dialogTitle: "",
      isEditDialog: false,
      deleteGameDialogVisible: false,
      row: {inviteBonusSettlementMethod: 0},
      total: 0,
      pages: 0,
      page: 0,
      status: 0,
      gameCategoryList: [],
      gameProviderList: [],
      cmd: "pub_agent_info",
      dates: {},
      paramVO: {},
      data: {cmd: this.cmd, action: "", data: {paramVO: {}, page: 0,},}
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );

      this.queryData();
    },
    dialogOkHandle() {
      if (this.isEditDialog) {
        this.updateData();
      } else {
        this.addData();
      }
      this.dialogVisible = false;
    },
    queryData(index = 0) {
      this.queryParams.agentCode = this.$route.params.code
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page_push",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addNewHandle() {
      this.isEditDialog = false;
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogTitle = "团队配置";
      this.dialogVisible = true;
    },
    editHandle(index) {
      this.isEditDialog = true;
      this.status = 1
      this.dialogTitle = "团队配置";
      this.item = this.tableData[index];
      this.dialogVisible = true;
    },
    editHandles(index) {
      this.isEditDialog = true;
      this.status = 2
      this.dialogTitle = "等级与客户调整";
      this.item = this.tableData[index];
      this.dialogVisible = true;
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    allocation() {
      this.handleSave()
      this.dialogVisibleas = false;
    },

    editHandlesUser(index) {
      this.dialogTitle = "代理配置";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.row.inviteBonusSettlementMethod = 0
      this.inquire()
      this.dialogVisibleas = true;
    },
    collect(index) {
      let row = this.tableData[index];
      this.$router.push(`/agency-pubAgentInfoDetail/${row.id}`)
    },
    inquire() {
      const data = {
        cmd: 'user_agent_bonus',
        action: "list",
        data: {
          paramVO: {userid: this.item.userid},
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            if (response.data.list === null) {
              this.isEdit = true
            } else {
              this.row = response.data.list;
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleSave() {
      this.row.userid = this.item.agentId
      const data = {
        cmd: 'user_agent_bonus',
        action: "add",
        data: {
          paramVO: this.row,
        },
      };
      if (this.isEdit) {
        this.$http
            .post('user_agent_bonus', data)
            .then((response) => {
              console.log("response.data", response.data);
              this.$message({
                type: "success",
                message: "添加成功!",
              });
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      } else {
        data.action = "update"
        this.$http
            .post('user_agent_bonus', data)
            .then((response) => {
              console.log("response.data", response.data);
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      }

    }
  },
  mounted() {

    this.queryData();
  },
};
</script>


<style lang="scss" scoped>
.btn {
  width: 55px;
  height: 15px;
}


</style>
