<template>
  <div>
    <el-descriptions direction="vertical" :column="9" title="基本信息" :size="size" border>
      <el-descriptions-item label="代理ID:">{{ userInfo.agentId || '--'}}</el-descriptions-item>
      <el-descriptions-item label="代理等级:">{{ userInfo.agentLevel || '--' }}</el-descriptions-item>
      <el-descriptions-item label="绑定手机号:">{{ userInfo.phone || '--'}}</el-descriptions-item>
      <el-descriptions-item label="佣金钱包余额:">{{ userInfo.totalBonusSalayDray || '--'}}</el-descriptions-item>
      <el-descriptions-item label="团队总人数:">{{ userInfo.totalNumberInvite|| '--' }}</el-descriptions-item>
      <el-descriptions-item label="直推总人数:">{{ userInfo.totalNumberInviteLevel1 || '--' }}</el-descriptions-item>
      <el-descriptions-item label="直推充值人数:">{{ userInfo.totalNumberFirstPayLevel1 || '--'}}</el-descriptions-item>
      <el-descriptions-item label="直推流失人数:">--</el-descriptions-item>
      <el-descriptions-item label="结算方式:">--</el-descriptions-item>
    </el-descriptions>

    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
          <div>
        <el-form-item  label="统计层次">

          <el-checkbox-group v-model="checkList">
            <el-checkbox label='直推'/>
            <el-checkbox label='二级代理'/>
            <el-checkbox label='三级代理'/>
          </el-checkbox-group>
        </el-form-item>
          </div>
        <el-form-item label="查询时间：">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryCollect()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button type="primary" @click="resetHandle()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>


    <el-descriptions title="统计信息" class="margin-top" direction="vertical" :column="8" :size="size" border>
      <el-descriptions-item label="新增人数">{{ queryParams.totalNumberInviteToday || '0'}}</el-descriptions-item>
      <el-descriptions-item label="活跃人数">{{ queryParams.totalNumberActiveUser || '0'}}</el-descriptions-item>
      <el-descriptions-item label="充值人数:">{{ queryParams.totalNumberFirstPay || '0'}}</el-descriptions-item>
      <el-descriptions-item label="付费率:">--</el-descriptions-item>
      <el-descriptions-item label="首充人数:">{{ queryParams.totalNumberFirstPay || '0'}}</el-descriptions-item>
      <el-descriptions-item label="付费转化率:">--</el-descriptions-item>
      <el-descriptions-item label="打码量汇总:">{{ queryParams.totalSalaryCode || '0'}}</el-descriptions-item>
      <el-descriptions-item label="输赢差汇总:">{{ queryParams.totalSalaryWinLostDif || '0'}}</el-descriptions-item>
      <el-descriptions-item label="充值汇总:">--</el-descriptions-item>
      <el-descriptions-item label="充值比数:">--</el-descriptions-item>
      <el-descriptions-item label="提款汇总">{{ queryParams.totalBonusSalayDray || '0'}}</el-descriptions-item>
      <el-descriptions-item label="提款笔数:">{{ queryParams.totalNumberDrawTimes || '0'}}</el-descriptions-item>
      <el-descriptions-item label="充提差汇总:">{{ queryParams.totalSalaryPayDrawDif || '0'}}</el-descriptions-item>
      <el-descriptions-item label="赠金汇总:">真金:{{ queryParams.totalGiveCoins || '0'}}，赏金：{{
          queryParams.totalGiveBonus || '0'
        }}
      </el-descriptions-item>
      <el-descriptions-item label="周期已结算佣金:">{{ queryParams.totalBonusSalayDray }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>

export default {
  name: "UserDetail",
  data() {
    return {
      checkList: [],
      queryParams: {},
      size: "",
      id: this.$route.params.id,
      userInfo: {},
      payTimeRange: undefined,
      dates: {},
      agentLevels: {}
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      const data = {
        cmd: "pub_agent_info",
        action: "queryId",
        data: {
          paramVO: {id: this.id},
        },
      };
      this.$http
          .post("pub_agent_info", data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.userInfo = response.data.list
            this.queryParams = response.data.list
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryCollect() {
      this.agentLevels = {
        first:0,
        second:0,
        thirdly:0,
      }
      if (this.checkList.length >0) {
        this.checkList.forEach((key) => {
          if (key === '直推') {
            this.agentLevels.first = 1
          } else if (key === '二级代理') {
            this.agentLevels.second = 1
          } else if (key === '三级代理') {
            this.agentLevels.thirdly = 1
          }
        })
      }
      this.queryParams.agentLevels = this.agentLevels
      this.queryParams.agentId = this.userInfo.agentId
      const params = this.$tools.removeEmptyProperties(this.queryParams);

      const data = {
        cmd: "pub_agent_info",
        action: "query_collect",
        data: {
          paramVO:params,
        },
      };
      this.$http
          .post("pub_agent_info", data)
          .then((response) => {
            console.log("response.data", response.data.list);
            if (response.data.list){
              this.queryParams = response.data.list
            }else {
              this.queryParams = {}
            }

          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    resetHandle() {
      this.payTimeRange =[]
      this.agentLevels = {}
      this.checkList = []
      this.dates = {}
      this.queryData();
    }
  },

}
</script>

<style scoped>

.margin-top {
  margin-top: 40px;
}



</style>
