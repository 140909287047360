<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form ref="form" :inline="true" :rules="rules" :model="queryParams">
        <el-form-item label="汇总类型：">
          <el-select clearable  placeholder="请选择汇总类型" v-model="queryParams.log_type">
            <el-option
                v-for="(item, index) in statusArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <br/>
    <el-table class="el-table" :data="tableData" borader>
      <el-table-column prop="date" label="日期"></el-table-column>
      <el-table-column prop="hour" label="小时"></el-table-column>
      <el-table-column prop="min" label="分钟"></el-table-column>
      <el-table-column label="汇总类型">
        <template v-slot="{row}">
          <span v-if="row.log_type=='a'">all全部汇总</span>
          <span v-else-if="row.log_type==='d'">按天汇总</span>
          <span v-else-if="row.log_type==='h'">按小时汇总</span>
          <span v-else-if="row.log_type==='m5'">按5分钟汇总</span>
          <span v-else-if="row.log_type==='m10'">按10分钟汇总</span>
          <span v-else-if="row.log_type==='m15'">按15分钟汇总</span>
          <span v-else-if="row.log_type==='m30'">按30分钟汇总</span>
          <span v-else>{{row.log_type}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="log_time" label="记录开始时间"></el-table-column>
      <el-table-column prop="user_online_sum" label="当前(当日总)在线用户数量"></el-table-column>
      <el-table-column prop="user_register_page_view_sum" label="时间段注册页浏览数"></el-table-column>
      <el-table-column prop="user_new_register_sum" label="时间段注册用户数"></el-table-column>
      <el-table-column prop="user_login_sum" label="当日登录用户数(好像与在线用户重了)"></el-table-column>
      <el-table-column prop="user_today_new_user_login_sum" label="当日新增用户时间段内登录数"></el-table-column>
      <el-table-column prop="order_coins_pay_in_person_dis_count" label="真金充值订单总人次"></el-table-column>
      <el-table-column prop="order_coins_pay_in_person_sum_count" label="真金充值订单总数量"></el-table-column>
      <el-table-column prop="order_coins_pay_in_amount" label="真金充值订单总额"></el-table-column>
      <el-table-column prop="order_coins_pay_in_cancel_person_dis_count" label="真金充值订单取消(超时)总人次"></el-table-column>
      <el-table-column prop="order_coins_pay_in_cancel_person_sum_count" label="真金充值订单取消(超时)总数量"></el-table-column>
      <el-table-column prop="order_coins_pay_in_cancel_amount" label="真金充值订单取消(超时)总额"></el-table-column>
      <el-table-column prop="coins_pay_in_person_dis_count" label="真金充值到账人数"></el-table-column>
      <el-table-column prop="coins_pay_in_person_sum_count" label="真金充值到账人次"></el-table-column>
      <el-table-column prop="coins_pay_in_amount" label="真金充值到账金额"></el-table-column>
      <el-table-column prop="coins_pay_in_commission_amount" label="真金充值到账三方手续费记账总额"></el-table-column>
      <el-table-column prop="coins_pay_in_give_person_dis_count" label="真金充值到账赠金人数"></el-table-column>
      <el-table-column prop="coins_pay_in_give_person_sum_count" label="真金充值到账赠金人次"></el-table-column>
      <el-table-column prop="coins_pay_in_give_amount" label="真金充值到账赠金总金额"></el-table-column>
      <el-table-column prop="coins_activity_give_person_dis_count" label="活动到账赠金人数"></el-table-column>
      <el-table-column prop="coins_activity_give_person_sum_count" label="活动到账赠金人次"></el-table-column>
      <el-table-column prop="coins_activity_give_amount" label="活动到账赠金总金额"></el-table-column>
      <el-table-column prop="coins_task_give_person_dis_count" label="任务到账赠金人数"></el-table-column>
      <el-table-column prop="coins_task_give_person_sum_count" label="任务到账赠金人次"></el-table-column>
      <el-table-column prop="coins_task_give_amount" label="任务到账赠金总金额"></el-table-column>
      <el-table-column prop="coins_operate_in_person_dis_count" label="真金人工入款人数"></el-table-column>
      <el-table-column prop="coins_operate_in_person_sum_count" label="真金人工入款人次"></el-table-column>
      <el-table-column prop="coins_operate_in_amount" label="真金人工入款总额"></el-table-column>
      <el-table-column prop="order_coins_pay_out_person_dis_count" label="真金提款订单总人数"></el-table-column>
      <el-table-column prop="order_coins_pay_out_person_sum_count" label="真金提款订单总单量"></el-table-column>
      <el-table-column prop="order_coins_pay_out_amount" label="真金提款订单总额"></el-table-column>
      <el-table-column prop="order_coins_pay_wait_person_dis_count" label="真金提款订单待处理总人数"></el-table-column>
      <el-table-column prop="order_coins_pay_wait_person_sum_count" label="真金提款订单待处理总单量"></el-table-column>
      <el-table-column prop="order_coins_pay_wait_amount" label="真金提款订单待处理总额"></el-table-column>
      <el-table-column prop="order_coins_pay_out_fail_person_dis_count" label="真金提款失败订单总人数"></el-table-column>
      <el-table-column prop="order_coins_pay_out_fail_person_sum_count" label="真金提现失败订单总单量"></el-table-column>
      <el-table-column prop="order_coins_pay_out_fail_amount" label="真金提款失败订单总额"></el-table-column>
      <el-table-column prop="order_coins_pay_out_back_person_dis_count" label="真金提款退款订单总人数"></el-table-column>
      <el-table-column prop="order_coins_pay_out_back_person_sum_count" label="真金提现退款订单总单量"></el-table-column>
      <el-table-column prop="order_coins_pay_out_back_amount" label="真金提款退款订单总额"></el-table-column>
      <el-table-column prop="order_coins_pay_out_success_person_dis_count" label="真金提款成功订单总人数"></el-table-column>
      <el-table-column prop="order_coins_pay_out_success_person_sum_count" label="真金提款成功订单总单量"></el-table-column>
      <el-table-column prop="order_coins_pay_out_success_amount" label="真金提款成功订单总额"></el-table-column>
      <el-table-column prop="order_coins_pay_out_success_commission_amount" label="真金提款成功订单平台扣除手续费总额"></el-table-column>
      <el-table-column prop="coins_pay_out_person_dis_count" label="钱包真金记账提款人数"></el-table-column>
      <el-table-column prop="coins_pay_out_person_sum_count" label="钱包真金记账提款人次"></el-table-column>
      <el-table-column prop="coins_pay_out_amount" label="钱包真金记账提款总额"></el-table-column>
      <el-table-column prop="coins_pay_out_return_person_dis_count" label="钱包真金记账提款退款人数"></el-table-column>
      <el-table-column prop="coins_pay_out_return_person_sum_count" label="钱包真金记账提款退款人次"></el-table-column>
      <el-table-column prop="coins_pay_out_return_amount" label="钱包真金记账提款退款总额"></el-table-column>
      <el-table-column prop="coins_operate_out_person_dis_count" label="真金人工扣款人数"></el-table-column>
      <el-table-column prop="coins_operate_out_person_sum_count" label="真金人工扣款人次"></el-table-column>
      <el-table-column prop="coins_operate_out_amount" label="真金人工扣款总金额"></el-table-column>
      <el-table-column prop="coins_bet_person_dis_count" label="真金下注人数"></el-table-column>
      <el-table-column prop="coins_bet_person_sum_count" label="真金下注人次"></el-table-column>
      <el-table-column prop="coins_bet_amount" label="真金下注金额"></el-table-column>
      <el-table-column prop="coins_win_person_dis_count" label="真金奖金人数"></el-table-column>
      <el-table-column prop="coins_win_person_sum_count" label="真金奖金人次"></el-table-column>
      <el-table-column prop="coins_win_amount" label="真金奖金金额"></el-table-column>
      <el-table-column prop="coins_jackpot_person_dis_count" label="真金旋彩人数"></el-table-column>
      <el-table-column prop="coins_jackpot_person_sum_count" label="真金旋彩人次"></el-table-column>
      <el-table-column prop="coins_jackpot_amount" label="真金旋彩金额"></el-table-column>
      <el-table-column prop="order_coins_bet_code_amount" label="真金游戏打码量"></el-table-column>
      <el-table-column prop="order_coins_bet_win_lose_diff" label="真金游戏输赢差"></el-table-column>
      <el-table-column prop="order_coins_bet_round_times" label="真金游戏回合数"></el-table-column>
      <el-table-column prop="coins_adjustment_add_person_dis_count" label="真金游戏正调人数"></el-table-column>
      <el-table-column prop="coins_adjustment_add_person_sum_count" label="真金游戏正调人次"></el-table-column>
      <el-table-column prop="coins_adjustment_add_amount" label="真金游戏正调金额"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_person_dis_count" label="真金游戏负调人数"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_person_sum_count" label="真金游戏负调人次"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_amount" label="真金游戏负调金额"></el-table-column>
      <el-table-column prop="freeze_coins_operate_in_person_dis_count" label="代理合作费人工入款人数"></el-table-column>
      <el-table-column prop="freeze_coins_operate_in_person_sum_count" label="代理合作费人工入款人次"></el-table-column>
      <el-table-column prop="freeze_coins_operate_in_amount" label="代理合作费人工入款总额"></el-table-column>
      <el-table-column prop="freeze_coins_operate_out_person_dis_count" label="代理合作费人工扣款人数"></el-table-column>
      <el-table-column prop="freeze_coins_operate_out_person_sum_count" label="代理合作费人工扣款人次"></el-table-column>
      <el-table-column prop="freeze_coins_operate_out_amount" label="代理合作费人工扣款总额"></el-table-column>
      <el-table-column prop="freeze_coins_to_commission_person_dis_count" label="代理合作费转佣金人数"></el-table-column>
      <el-table-column prop="freeze_coins_to_commission_person_sum_count" label="代理合作费转佣金人次"></el-table-column>
      <el-table-column prop="freeze_coins_to_commission_amount" label="代理合作费(解冻)转佣金总额"></el-table-column>
      <el-table-column prop="agent_invite_bonus_person_dis_count" label="代理人头奖人数"></el-table-column>
      <el-table-column prop="agent_invite_bonus_person_sum_count" label="代理人头奖人次"></el-table-column>
      <el-table-column prop="agent_invite_bonus_amount" label="代理人头奖金额"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_person_dis_count" label="代理直推人头达标奖人数"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_person_sum_count" label="代理直推人头达标奖人次"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_amount" label="代理直推人头达标奖金额"></el-table-column>
      <el-table-column prop="agent_commission_to_coins_person_dis_count" label="代理佣金转真金人数"></el-table-column>
      <el-table-column prop="agent_commission_to_coins_person_sum_count" label="代理佣金转真金人次"></el-table-column>
      <el-table-column prop="agent_commission_to_coins_amount" label="代理佣金转真金总额"></el-table-column>
      <el-table-column prop="commission_operate_in_person_dis_count" label="佣金人工入款人数"></el-table-column>
      <el-table-column prop="commission_operate_in_person_sum_count" label="佣金人工入款人次"></el-table-column>
      <el-table-column prop="commission_operate_in_amount" label="佣金人工入款总额"></el-table-column>
      <el-table-column prop="order_commission_pay_out_person_dis_count" label="佣金提款订单总人数"></el-table-column>
      <el-table-column prop="order_commission_pay_out_person_sum_count" label="佣金提款订单总单量"></el-table-column>
      <el-table-column prop="order_commission_pay_out_amount" label="佣金提款订单总额"></el-table-column>
      <el-table-column prop="order_commission_pay_wait_person_dis_count" label="佣金提款订单待处理总人数"></el-table-column>
      <el-table-column prop="order_commission_pay_wait_person_sum_count" label="佣金提款订单待处理总单量"></el-table-column>
      <el-table-column prop="order_commission_pay_wait_amount" label="佣金提款订单待处理总额"></el-table-column>
      <el-table-column prop="order_commission_pay_out_fail_person_dis_count" label="佣金提款失败订单总人数"></el-table-column>
      <el-table-column prop="order_commission_pay_out_fail_person_sum_count" label="佣金提现失败订单总单量"></el-table-column>
      <el-table-column prop="order_commission_pay_out_fail_amount" label="佣金提款失败订单总额"></el-table-column>
      <el-table-column prop="order_commission_pay_out_back_person_dis_count" label="佣金提款退款订单总人数"></el-table-column>
      <el-table-column prop="order_commission_pay_out_back_person_sum_count" label="佣金提现退款订单总单量"></el-table-column>
      <el-table-column prop="order_commission_pay_out_back_amount" label="佣金提款退款订单总额"></el-table-column>
      <el-table-column prop="order_commission_pay_out_success_person_dis_count" label="佣金提款成功订单总人数"></el-table-column>
      <el-table-column prop="order_commission_pay_out_success_person_sum_count" label="佣金提款成功订单总单量"></el-table-column>
      <el-table-column prop="order_commission_pay_out_success_amount" label="佣金提款成功订单总额"></el-table-column>
      <el-table-column prop="order_commission_pay_out_success_commission_amount" label="佣金提款成功订单平台扣除手续费总额"></el-table-column>
      <el-table-column prop="commission_pay_out_person_dis_count" label="钱包佣金记账提款人数"></el-table-column>
      <el-table-column prop="commission_pay_out_person_sum_count" label="钱包佣金记账提款人次"></el-table-column>
      <el-table-column prop="commission_pay_out_amount" label="钱包佣金记账提款总额"></el-table-column>
      <el-table-column prop="commission_pay_out_return_person_dis_count" label="钱包佣金记账提款退款人数"></el-table-column>
      <el-table-column prop="commission_pay_out_return_person_sum_count" label="钱包佣金记账提款退款人次"></el-table-column>
      <el-table-column prop="commission_pay_out_return_amount" label="钱包佣金记账提款退款总额"></el-table-column>
      <el-table-column prop="commission_operate_out_person_dis_count" label="佣金人工扣款人数"></el-table-column>
      <el-table-column prop="commission_operate_out_person_sum_count" label="佣金人工扣款人次"></el-table-column>
      <el-table-column prop="commission_operate_out_amount" label="佣金人工扣款总金额"></el-table-column>
<!--      <el-table-column prop="coins_pay_in_person_dis_count" label="真金充值人数"></el-table-column>
      <el-table-column prop="coins_pay_in_person_sum_count" label="真金充值人次"></el-table-column>
      <el-table-column prop="coins_pay_in_amount" label="真金充值金额"></el-table-column>
      <el-table-column prop="coins_pay_out_person_dis_count" label="真金提现人数"></el-table-column>
      <el-table-column prop="coins_pay_out_person_sum_count" label="真金提现人次"></el-table-column>
      <el-table-column prop="coins_pay_out_amount" label="真金提现金额"></el-table-column>
      <el-table-column prop="coins_pay_out_return_person_dis_count" label="真金提现退款人数"></el-table-column>
      <el-table-column prop="coins_pay_out_return_person_sum_count" label="真金提现退款人次"></el-table-column>
      <el-table-column prop="coins_pay_out_return_amount" label="真金提现退款金额"></el-table-column>
      <el-table-column prop="coins_bet_person_dis_count" label="真金下注人数"></el-table-column>
      <el-table-column prop="coins_bet_person_sum_count" label="真金下注人次"></el-table-column>
      <el-table-column prop="coins_bet_amount" label="真金下注金额"></el-table-column>
      <el-table-column prop="coins_win_person_dis_count" label="真金奖金人数"></el-table-column>
      <el-table-column prop="coins_win_person_sum_count" label="真金奖金人次"></el-table-column>
      <el-table-column prop="coins_win_amount" label="真金奖金金额"></el-table-column>
      <el-table-column prop="coins_jackpot_person_dis_count" label="真金旋彩人数"></el-table-column>
      <el-table-column prop="coins_jackpot_person_sum_count" label="真金旋彩人次"></el-table-column>
      <el-table-column prop="coins_jackpot_amount" label="真金旋彩金额"></el-table-column>
      <el-table-column prop="coins_adjustment_add_person_dis_count" label="真金游戏正调人数"></el-table-column>
      <el-table-column prop="coins_adjustment_add_person_sum_count" label="真金游戏正调人次"></el-table-column>
      <el-table-column prop="coins_adjustment_add_amount" label="真金游戏正调金额"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_person_dis_count" label="真金游戏负调人数"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_person_sum_count" label="真金游戏负调人次"></el-table-column>
      <el-table-column prop="coins_adjustment_sub_amount" label="真金游戏负调金额"></el-table-column>
      <el-table-column prop="agent_invite_bonus_person_dis_count" label="代理人头奖人数"></el-table-column>
      <el-table-column prop="agent_invite_bonus_sub_person_sum_count" label="代理人头奖人次"></el-table-column>
      <el-table-column prop="agent_invite_bonus_sub_amount" label="代理人头奖金额"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_person_dis_count" label="代理直推人头达标奖人数"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_sub_person_sum_count" label="代理直推人头达标奖人次"></el-table-column>
      <el-table-column prop="agent_invite_level_bonus_sub_amount" label="代理直推人头达标奖金额"></el-table-column>
      <el-table-column prop="total_user" label="截止此报告记录总用户数"></el-table-column>
      <el-table-column prop="new_user" label="此报告新增注册用户数"></el-table-column>-->
    </el-table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        start_time: '',
        end_time: ''
      },
      statusArr: [
        {label:'all全部汇总',value:'a'},
        {label:'按天汇总',value:'d'},
        {label:'按小时汇总',value:'h'},
        {label:'按5分钟汇总',value:'m5'},
        {label:'按10分钟汇总',value:'m10'},
        {label:'按15分钟汇总',value:'m15'},
        {label:'按30分钟汇总',value:'m30'},
      ],
      dialogVisible: false,
      dialogTitle: "",
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      rules: {
      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData(index = 0) {
      // 表单验证成功
      // this.$refs.form.validate(valid => {
      //   if (valid) {
      //   }
      // })
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params['time'][0], 2);
        params.end_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.log_rb = 0;
      const data = {
        cmd: "sys_analysis",
        action: "query_page",
        data: {
          paramVO: {...params},
          page: index,
        },
      };
      this.$http
          .post("sys_analysis", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
