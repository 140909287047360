<template>
  <el-container class="layout-container-demo" style="height: 100%">
    <el-drawer v-model="drawer" :direction="direction" size="240px">
          <el-menu
              :default-openeds="['3']"
              @select="handleMenuSelect"
              unique-opened
          >
            <div v-for="(item, i) in menu" :key="i">
              <div v-if="item.children?.length === 0">
                <el-menu-item :index="i + ''">{{ item.title }}</el-menu-item>
              </div>
              <div v-else>
                <el-sub-menu :index="i + ''">
                  <template #title>
                    <el-icon><message /></el-icon>{{ item.title }}
                  </template>
                  <div v-for="(child, j) in item.children" :key="j">
                    <el-menu-item :index="i + '-' + j">{{
                        child.title
                      }}</el-menu-item>
                  </div>
                </el-sub-menu>
              </div>
            </div>
          </el-menu>
    </el-drawer>

    <el-container>
      <el-header
        style="display: flex; justify-content: space-between; text-align: right;s font-size: 12px"
      >
        <div style="line-height: 60px">
          <el-icon v-if="drawer==false" @click="drawer=true"><Menu /></el-icon>
        </div>
        <div style="line-height: 60px">{{ title }}</div>
        <div class="toolbar">
          <div style="line-height: 60px; margin-right: 20px;">当地时间： {{ nowTime }}</div>
          <el-dropdown>
            <el-icon style="margin-right: 8px; margin-top: 1px"
              ><setting
            /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="changePassword = true">修改密码</el-dropdown-item>
<!--                <el-dropdown-item>Add</el-dropdown-item>-->
                <el-dropdown-item @click="exitLogin">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <span>{{userInfo.name}}</span>
        </div>
      </el-header>

      <el-tabs
        v-model="editableTabsValue"
        type="card"
        closable
        @tab-remove="removeTab"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="item in editableTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>

      <el-dialog
          v-model="changePassword"
          :title="'修改密码'"
          style="text-align: left"
          :close-on-click-modal="false"
      >
        <el-form :model="userInfo" ref="rowForm" :rules="rules" label-width="180px">

          <el-form-item label="原密码" prop="newPassword">
            <el-input v-model="userInfo.newPassword" type="password" autocomplete="off" show-password/>
          </el-form-item>
          <el-form-item label="新密码" prop="passwordNew">
            <el-input v-model="userInfo.passwordNew" type="password" autocomplete="off" show-password/>
          </el-form-item>
          <el-form-item label="确认新密码" prop="passwordConfirm">
            <el-input v-model="userInfo.passwordConfirm" type="password" autocomplete="off" show-password/>
          </el-form-item>

        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="cipher()">保存</el-button>
      </span>
        </template>
      </el-dialog>

      <el-main>
        <el-scrollbar>
          <router-view></router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import { Menu as IconMenu, Message, Setting } from "@element-plus/icons-vue";
import { Menu as Message, Setting } from "@element-plus/icons-vue";
import { removeToken } from "@/utils/token"
import {getUserShowViewData} from "@/utils/user";

export default {
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      changePassword : false,
      item : {},
      userInfo:{
        id: null,
        name: null,
        newPassword: null,
        passwordNew: null,
        passwordConfirm: null
      },
      rules: Object.freeze({
        newPassword: [
          {required: true, message: '请输入原密码', trigger: 'blur'},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
        ],
        passwordNew: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
        ],
        passwordConfirm: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur'}
        ]
      }),
      menu: [
      ],
      editableTabsValue: "1",
      editableTabs: [
        // {
        //   title: "平台数据",
        //   name: "1",
        //   path: "dashboard",
        // },
      ],
      tabIndex: 1,
      title: "",
      nowTime:'',
    };
  },
  mounted() {
    this.initMenu();
    this.updateTime(); // 第一次调用，避免等待一个间隔时间才更新时间

    setInterval(() => {
      this.updateTime();
    }, 1000);
    if (getUserShowViewData()) {
      this.title = "平台数据";
    } else {
      this.title = "用户管理/用户列表";
    }
  },
  methods: {
    handleCancel() {
      this.$refs.rowForm.resetFields()
      this.changePassword = false
    },
    cipher() {
      if (this.userInfo.passwordNew !== this.userInfo.passwordConfirm ) {
        this.$message({
          type: "info",
          message: "密码不一致",
        })
      }else {
        this.$refs.rowForm.validate((valid) => {
          if (!valid) {
            this.$message({
              type: "info",
              message: "请填写数据",
            })
          }else {
            this.updateData()
            this.changePassword = false
          }
        })
      }
    },
    updateData() {
      const data = {
        cmd: "pub_platform_user",
        action: "update_password",
        data: {
          paramVO: this.userInfo
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    initMenu(){
      const data = {
        cmd: "pub_platform_user",
        action: "user_info",
        data: {},
      };
      this.$http
      .post("pub_platform_user", data)
      .then((response) => {
        this.menu = this.menu.concat(response.data.userMenuTree)
        this.userInfo = response.data.userInfo
      })
    },
    exitLogin(){
      removeToken()
      window.location.reload()
    },
    /**
     * tab点击事件
     * @param {*} tab
     * @param {*} event
     */
    handleClick(tab, event) {
      console.log(tab, event);
      const item = this.editableTabs[tab.index];
      this.$router.push({ name: item.path });
      this.title = item.title;
    },
    /**
     * 添加tab
     * @param {*} targetName
     */
    addTab(tabTitle, path) {
      let newTabName = ++this.tabIndex + "";
      this.editableTabs.push({
        title: tabTitle,
        name: newTabName,
        path: path,
      });
      this.editableTabsValue = newTabName;
    },
    /**
     * 移除tab
     * @param {*} targetName
     */
    removeTab(targetName) {
      console.log("targetName", targetName);
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
              this.title = nextTab.title;
              this.$router.push({ name: nextTab.path });
            } else {
              if (getUserShowViewData()) {
                this.addTab("平台数据", "dashboard");
              } else {
                this.addTab("用户管理/用户列表", "user-list");
              }
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    /**
     * 菜单选择回调
     * @param {number | string} index
     */
    handleMenuSelect(index) {
      console.log("index", index);
      let string = index + "";
      let title = "";
      let path = "";
      if (string.length == 1) {
        const item = this.menu[index];
        title = item.title;
      } else {
        let arr = string.split("-");
        if (arr.length > 1) {
          const item = this.menu[arr[0]];
          title = item.title;
          let child = item.children[arr[1]];
          title += " / " + child.title;
          path = child.path;
        }
      }
      this.title = title;
      if (path === "") {
        // 测试代码，path不存在内容，跳转到test页面
        path = "test";
      }
      this.$router.push({ name: path });
      this.addTab(title, path);
    },
    updateTime() {
      let currentDate = new Date();
      let timeZone = "America/Sao_Paulo";
      let dateFormat = new Intl.DateTimeFormat([], {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
      this.nowTime = dateFormat.format(currentDate);
    },
  },
  components: { Message, Setting },
};
</script>

<style lang="scss" scoped>
.layout-container-demo .el-header {
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary);
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: var(--el-color-primary-light-8);
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 10px;
}
.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>
