<template>
  <div>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>

      <el-table-column prop="userid" label="用户ID" align="center"></el-table-column>

      <el-table-column prop="userVipLevel" align="center" label="VIP级别（结算时)">
        <template v-slot="{row}">
          <span>{{ getVipLevelName(row.userVipLevel) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="specificUser" align="center" label="指定中奖">
        <template v-slot="{row}">
          <template v-if="row.specificUser== 1">是</template>
          <template v-if="row.specificUser== 0 ">否</template>
        </template>
      </el-table-column>

      <el-table-column prop="prizeLevel" label="中奖等级" align="center"></el-table-column>
      <el-table-column prop="prize" label="中奖金额" align="center"></el-table-column>

      <el-table-column prop="userCode" label="真金打码量" align="center"></el-table-column>

      <el-table-column prop="userContributionRank" label="贡献度排名" align="center"></el-table-column>

<!--      <el-table-column prop="userContributionRank" label="总贡献度" align="center"></el-table-column>-->

      <el-table-column prop="userContributionRank" align="center" label="总贡献度">
        <template v-slot="{row}">
          <span>{{row.userBetContribution+ row.userLossContribution   }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userBetContribution" label="下注贡献度" align="center"></el-table-column>

      <el-table-column prop="userLossContribution" label="亏损贡献度" align="center"></el-table-column>

      <el-table-column prop="userWinLossDiff" label="输赢差" align="center"></el-table-column>

    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      id: this.$route.params.id,
      cmd: 'pub_winning_details',
      queryParams: {},
      item: {},
      viplevels: [],
      keeps: [
        {label: '否', value: 0},
          {label: '是', value: 1},
      ],
      isEdit: false,

    };
  },
  mounted() {
    this.queryData();
    this.queryviplevels();
  },
  methods: {
    getVipLevelName(level) {
      const matchedLevel = this.viplevels.find(vip => vip.vipLevel == level);
      return matchedLevel ? matchedLevel.vipLevelName : '--';
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    async queryData(index = 0) {
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: {goldenClubId: this.id},
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async queryviplevels() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "pob_vip_level",
        action: "query_page",
        data: {
          paramVO: params,
          page: 0,
          pageSize: 100,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.viplevels = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
