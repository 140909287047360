<template>
  <div>
    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="dialogVisible = true">
          <el-icon><Plus /></el-icon>
          <span>新增</span>
        </el-button>
        <el-button @click="deleteGameDialogVisible = true">
          <el-icon><DeleteFilled /></el-icon>
          <span>删除</span>
        </el-button>
      </el-row>

      <el-table :data="tableData">
        <el-table-column type="selection" width="55" />
        <el-table-column label="日期"></el-table-column>
        <el-table-column label="奖励类型"></el-table-column>
        <el-table-column label="打码任务倍数"></el-table-column>
        <el-table-column label="赠送奖金任务有效时间"></el-table-column>
        <el-table-column label="是否开启奖金任务"></el-table-column>
        <el-table-column label="参与货币类型"></el-table-column>
        <el-table-column label="展示百分比"></el-table-column>
        <el-table-column label="结束时间"></el-table-column>
        <el-table-column label="活动项配置"></el-table-column>
        <el-table-column label="图片地址"></el-table-column>
        <el-table-column label="活动参与间隔时间"></el-table-column>
        <el-table-column label="活动名称"></el-table-column>
        <el-table-column label="活动类型"></el-table-column>
        <el-table-column label="是否任意金额"></el-table-column>
        <el-table-column label="最大参与价格"></el-table-column>
        <el-table-column label="最小参与价格"></el-table-column>
        <el-table-column label="开启时间"></el-table-column>
        <el-table-column label="状态"></el-table-column>
        <el-table-column label="权重"></el-table-column>
        <el-table-column label="操作">
          <template>
            <el-button type="primary" size="small">变更</el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteGameDialogVisible = true"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
