import axios from "axios";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import { getToken, removeToken } from "@/utils/token";
// import { userCode } from "../store/index";
import {ElMessage} from "element-plus/es"

const $toast = useToast();
// 创建一个 axios 实例
const instance = axios.create({
  baseURL: location.origin + "/gateway?cmd=",
  //baseURL: "http://localhost:8080/gateway?cmd=", // 设置接口的基础 URL
  timeout: 60000, // 设置请求超时时间
  headers: {
    "Content-Type": "application/json", // 设置请求头
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    console.log("config:", config);

    // config.headers["sign"] = "390D983B200B-3869E90BB117";
    // config.headers["sign"] = "67990B22-A381-4724-AFD0-D3B02CCA0D58";

    // 添加cmd参数
    if (!config.data.cmd) {
      config.data.cmd = config.url;
    }

	if(config.data.cmd!="upload"){//兼容上传
		config.url = config.url + "&sign=03EA6D98B0D1";
	}
    config.headers = Object.assign(config.headers,{'token': getToken()});
	//兼容上传
	if(config.data.cmd=="upload"){
		config.baseURL = config.baseURL.replace("?cmd=","");
		config.headers["Content-Type"] = "multipart/form-data; boundary=---011000010111000001101001";
	}
	//
    return config;
  },
  (error) => {
    // 请求出错时的处理
    $toast.error("request:" + error);
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    console.log("response:", response);

    const { rcode, errmsg} = response.data;
      console.log("rcode")
    console.log(rcode);
    //异常错误
      if (
          rcode === 400 ||
          rcode === 110008 ||
          rcode === 10086 ||
          rcode === 401
      ){
          removeToken();
          window.location.href = window.location.protocol + '//' + window.location.host + '/' + 'login';
          return;
      }
    // 业务错误
    if (rcode != 100) {
      const businessError =
        "request data:" +
        response.config.data +
        " err msg->" +
          errmsg;
      console.log("businessError:", businessError);
      // $toast.warning(businessError, { duration: 5000 });
      ElMessage({
        type: "error",
        message: response.data.errmsg,
      });
      return Promise.reject(response.data.errmsg);
    }

    // 请求成功后的处理
    return response.data;
  },
  (error) => {
    // 请求失败后的处理
    console.log("error", error);
    // $toast.error(
    //   "response url->" + error.config.url + " error->" + error.message
    // );
    // return Promise.reject(error);
    if(error.response.status=='500'){
      $toast.warning(error.response.data.errmsg, { duration: 5000 });
      return;
    }
    removeToken();
    window.location.href = window.location.protocol + '//' + window.location.host + '/' + 'login';
  }
);

// // 封装 POST 方法
// instance.postData = function (url, data) {
//   return this.post(url, data);
// };

export default instance;
