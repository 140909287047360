import { createRouter, createWebHashHistory } from "vue-router";

import HomePageVue from "@/pages/HomePage.vue";
import LoginPageVue from "@/pages/LoginPage.vue";
import GoogleCheckVue from "@/pages/GoogleCheck.vue";
import UserListVue from "@/pages/userManager/UserList.vue";
import UserDetailVue from "@/pages/userManager/UserDetail.vue";
import UserGameListlVue from "@/pages/userManager/UserGameList.vue";
import UserLoginListVue from "@/pages/userManager/UserLoginList.vue";
import PopupListVue from "@/pages/operationManager/PopupList.vue";
import OperationActivitiesListVue from "@/pages/operationManager/OperationActivitiesList.vue";
// import DailyActivitiesListVue from "@/pages/operationManager/DailyActivitiesList.vue";
import ConsultingListVue from "@/pages/operationManager/ConsultingList.vue";
import DashboardPageVue from "@/pages/DashboardPage.vue";
import AnalysisPageVue from "@/pages/AnalysisPage.vue"
import FAQListVue from "@/pages/operationManager/FAQList.vue";
import PaymentChannelListVue from "@/pages/operationManager/PaymentChannelList.vue";
import ReplacePayChannelListVue from "@/pages/operationManager/ReplacePayChannelList.vue";
import UserLevelListVue from "@/pages/operationManager/UserLevelList.vue";
import UserVipListVue from "@/pages/operationManager/UserVipList.vue";
import ManualPaymentListVue from "@/pages/operationManager/ManualPaymentList.vue";
import ManualDisbursementListVue from "@/pages/operationManager/ManualDisbursementList.vue";
import PlatformEmail from "@/pages/operationManager/PlatformEmail.vue";
import PlatformAnnouncement from "@/pages/operationManager/PlatformAnnouncement.vue";
import GameList from "@/pages/gameManager/GameList.vue";
import GameCate from "@/pages/gameManager/GameCate.vue";
import GameLabel from "@/pages/gameManager/GameLabel.vue";
import GameProvider from "@/pages/gameManager/GameProvider.vue";
import GameUpDown from "@/pages/gameManager/GameUpDown.vue";
import NoteManager from "@/pages/gameManager/NoteManager.vue";
import RevenueStatistics from "@/pages/gameManager/RevenueStatistics.vue";
import UserWallet from "@/pages/walletManager/UserWallet.vue";
import CurrencyList from "@/pages/walletManager/CurrencyList.vue";
import AgencyPayment from "@/pages/financialManager/AgencyPayment.vue";
import AgencyPaymentDetail from "@/pages/financialManager/AgencyPaymentDetail.vue";
import DepositList from "@/pages/financialManager/DepositList.vue";
import DepositDetail from "@/pages/financialManager/DepositDetail.vue";
import DrawMoneyList from "@/pages/financialManager/DrawMoneyList.vue";
import DrawMoneyDetail from "@/pages/financialManager/DrawMoneyDetail.vue";
import GiftMoneyLog from "@/pages/financialManager/GiftMoneyLog.vue";
import ManualDisbursement from "@/pages/financialManager/ManualDisbursement.vue";
import ManualPaymentVue from "@/pages/financialManager/ManualPayment.vue";
import ManualPaymentDetail from "@/pages/financialManager/ManualPaymentDetail.vue";
import RechargeActivity from "@/pages/financialManager/RechargeActivity.vue";
import PaymentChannel from "@/pages/financialManager/PaymentChannel.vue";
import AgencyChannel from "@/pages/financialManager/AgencyChannel.vue";
import CfgAgentBonus from "@/pages/tipOfFlash/CfgAgentBonus.vue";
import cfgAgentLevelAndService from "@/pages/tipOfFlash/cfgAgentLevelAndService.vue";
import PubAgentInfo from "@/pages/tipOfFlash/PubAgentInfo.vue";
import PubAgentInfoDirect from "@/pages/tipOfFlash/PubAgentInfoDirect.vue";
import PubAgentInfoDetail from "@/pages/tipOfFlash/PubAgentInfoDetail.vue";
import CfgAgentInvitePayBonus from "@/pages/tipOfFlash/CfgAgentInvitePayBonus.vue";
import PubUserWalletChangeLog from "@/pages/tipOfFlash/PubUserWalletChangeLog.vue";
import SystemMenus from "@/pages/system/SystemMenus.vue";
import SystemRole from "@/pages/system/SystemRole.vue";
import SystemUser from "@/pages/system/SystemUser.vue";
import PubUserLog from "@/pages/system/PubUserLog.vue";
import SystemMenusSubset from "@/pages/system/Subset/SystemMenusSubset.vue";
import PopNews from "@/pages/operationManager/PopNews.vue";
import PubUserLevel from "@/pages/operation/PubUserLevel.vue";
import PubVipLevel from "@/pages/operation/PubVipLevel.vue";
import PubEventl from "@/pages/operation/PubEventl.vue";
import PubMail from "@/pages/operation/PubMail.vue";
import PubAffiche from "@/pages/operation/PubAffiche.vue";
import PubAfficheLog from "@/pages/operation/PubAfficheLog.vue";
import PubBanner from "@/pages/operation/PubBanner.vue";
import PubCapitalDealLog from "@/pages/operation/PubUserWalletChangeLog.vue";
import PubClub from "@/pages/operation/PubClub.vue";
import PubWinningDetails from "@/pages/operation/log/PubWinningDetails.vue";
import TableType from "@/pages/operation/TableType.vue";
import PubInformationUser from "@/pages/operation/PubInformationUser.vue";
import UserGameDeed from "@/pages/userManager/log/TableType.vue";
import CfgdailyActivity from "@/pages/operation/CfgdailyActivity.vue";
import CfgdailyActivityLog from "@/pages/operation/log/CfgdailyActivityLog.vue";
import PubRebateOrder from "@/pages/operation/PubRebateOrder.vue";
import PromoteAdLogTable from "@/pages/operation/PromoteAdLogTable.vue";
import PromoteAd from '@/pages/operation/pubPromote/PromoteAd.vue'
import PromotePlatform from '@/pages/operation/pubPromote/PromotePlatform.vue'
import PromoteAcount from '@/pages/operation/pubPromote/PromoteAcount.vue'

import TestPageVue from "@/pages/TestPage.vue";
import PlatformDailyVue from "@/pages/PlatformDailyPage.vue";
import AnalysisUserPayData from "@/pages/analysis/AnalysisUserPayData.vue";
import AnalysisUserRigsterLoginData from "@/pages/analysis/AnalysisUserRigsterLoginData.vue";
import AnalysisUserBetData from "@/pages/analysis/AnalysisUserBetData.vue";
import AnalysisUserSearchList from "@/pages/analysis/AnalysisUserSearchList.vue";
import AnalysisUserOutData from "@/pages/analysis/AnalysisUserOutData.vue";
import UserRetentionAnalysisVue from "@/pages/analysisManager/UserRetentionAnalysis.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/login",
      name: "login",
      component: LoginPageVue,
    },
    {
      path: "/google-check",
      name: "google-check",
      component: GoogleCheckVue,
    },
    {
      path: "/",
      component: HomePageVue,
      children: [
        {
          path: "/test",
          name: "test",
          component: TestPageVue,
        },
        {
          path: "/platform-daily",
          name: "platform-daily",
          component: PlatformDailyVue,
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: DashboardPageVue,
        },
        {
          path: "/analysis",
          name: "analysis",
          component: AnalysisPageVue,
        },
        {
          path: "/user-list",
          name: "user-list",
          component: UserListVue,
        },
        {
          path: "/user-detail/:userid",
          name: "user-detail",
          component: UserDetailVue,
        },
        {
          path: "/user-game-list",
          name: "user-game-list",
          component: UserGameListlVue,
        },
        {
          path: "/user-login-list",
          name: "user-login-list",
          component: UserLoginListVue,
        },
        {
          path: "/popup-list",
          name: "popup-list",
          component: PopupListVue,
        },
        {
          path: "/consulting-list",
          name: "consulting-list",
          component: ConsultingListVue,
        },

        {
          path: "/operation-activities-list",
          name: "operation-activities-list",
          component: OperationActivitiesListVue,
        },
        {
          path: "/faq-list",
          name: "faq-list",
          component: FAQListVue,
        },
        {
          path: "/payment-channel-list",
          name: "payment-channel-list",
          component: PaymentChannelListVue,
        },
        {
          path: "/replace-pay-channel-list",
          name: "replace-pay-channel-list",
          component: ReplacePayChannelListVue,
        },
        {
          path: "/user-level-list",
          name: "user-level-list",
          component: UserLevelListVue,
        },
        {
          path: "/user-vip-list",
          name: "user-vip-list",
          component: UserVipListVue,
        },
        {
          path: "/manual-payment-list",
          name: "manual-payment-list",
          component: ManualPaymentListVue,
        },
        {
          path: "/manual-disbursement-list",
          name: "manual-disbursement-list",
          component: ManualDisbursementListVue,
        },
        {
          path: "/platform-email",
          name: "platform-email",
          component: PlatformEmail,
        },
        {
          path: "/platform-announcement",
          name: "platform-announcement",
          component: PlatformAnnouncement,
        },
        {
          path: "/game-list",
          name: "game-list",
          component: GameList,
        },
        {
          path: "/game-cate",
          name: "game-cate",
          component: GameCate,
        },
        {
          path: "/game-label",
          name: "game-label",
          component: GameLabel,
        },
        {
          path: "/game-provider",
          name: "game-provider",
          component: GameProvider,
        },
        {
          path: "/game-up-down",
          name: "game-up-down",
          component: GameUpDown,
        },
        {
          path: "/note-manager",
          name: "note-manager",
          component: NoteManager,
        },
        {
          path: "/revenue-statistics",
          name: "revenue-statistics",
          component: RevenueStatistics,
        },
        {
          path: "/user-wallet",
          name: "user-wallet",
          component: UserWallet,
        },
        {
          path: "/currecy-list",
          name: "currency-list",
          component: CurrencyList,
        },
        {
          path: "/agency-payment",
          name: "agency-payment",
          component: AgencyPayment,
        },
        {
          path: "/agency-payment-detail/:id",
          name: "agency-payment-detail",
          component: AgencyPaymentDetail,
        },
        {
          path: "/deposit-list",
          name: "deposit-list",
          component: DepositList,
        },
        {
          path: "/deposit-detail/:id",
          name: "deposit-detail",
          component: DepositDetail,
        },
        {
          path: "/draw-money-list",
          name: "draw-money-list",
          component: DrawMoneyList,
        },
        {
          path: "/draw-money-detail/:id",
          name: "draw-money-detail",
          component: DrawMoneyDetail,
        },
        {
          path: "/gift-money-log",
          name: "gift-money-log",
          component: GiftMoneyLog,
        },
        {
          path: "/manual-disbursement",
          name: "manual-disbursement",
          component: ManualDisbursement,
        },
        {
          path: "/manual-payment",
          name: "manual-payment",
          component: ManualPaymentVue,
        },
        {
          path: "/manual-payment-detail/:id",
          name: "manual-payment-detail",
          component: ManualPaymentDetail,
        },
        {
          path: "/recharge-activity",
          name: "recharge-activity",
          component: RechargeActivity,
        },
        {
          path: "/payment-channel",
          name: "payment-channel",
          component: PaymentChannel,
        },
        {
          path: "/agency-channel",
          name: "agency-channel",
          component: AgencyChannel,
        },
          {
          path: "/agency-CfgAgentBonus",
          name: "agency-CfgAgentBonus",
          component: CfgAgentBonus,
        },
          {
          path: "/agency-evelAndService",
          name: "agency-evelAndService",
          component: cfgAgentLevelAndService,
        },   {
          path: "/agency-pubagentinfo",
          name: "agency-pubagentinfo",
          component: PubAgentInfo,
        },
          {
          path: "/agency-pubagentinfos/:code",
          name: "agency-pubagentinfos",
          component: PubAgentInfoDirect,
        },    {
          path: "/agency-pubAgentInfoDetail/:id",
          name: "agency-pubAgentInfoDetail",
          component: PubAgentInfoDetail,
        },  {
          path: "/agency-cfgAgentInvite",
          name: "agency-cfgAgentInvite",
          component: CfgAgentInvitePayBonus,
        }, {
          path: "/agency-pubUserWalletChangeLog",
          name: "agency-pubUserWalletChangeLog",
          component: PubUserWalletChangeLog,
        },{
          path: "/agency-systemMenus",
          name: "agency-systemMenus",
          component: SystemMenus,
        },{
          path: "/agency-systemRole",
          name: "agency-systemRole",
          component: SystemRole,
        },{
          path: "/agency-systemUser",
          name: "agency-systemUser",
          component: SystemUser,
        },{
          path: "/agency-pubUserLog",
          name: "agency-pubUserLog",
          component: PubUserLog,
        },
          {
          path: "/agency-systemMenus/:id",
          name: "agency-systemMenusSubset",
          component: SystemMenusSubset,
        },  {
          path: "/agency-popNews",
          name: "agency-popNews",
          component: PopNews,
        },
          {
          path: "/agency-pubUserLevel",
          name: "agency-pubUserLevel",
          component: PubUserLevel,
        },
          {
          path: "/agency-pubVipLevel",
          name: "agency-pubVipLevel",
          component: PubVipLevel,
        }, {
          path: "/agency-pubEventl",
          name: "agency-pubEventl",
          component: PubEventl,
        },{
          path: "/agency-pubMail",
          name: "agency-pubMail",
          component: PubMail,
        }
        ,{
          path: "/agency-pubAffiche",
          name: "agency-pubAffiche",
          component: PubAffiche,
        },{
          path: "/agency-pubAfficheLog",
          name: "agency-pubAfficheLog",
          component: PubAfficheLog,
        },{
          path: "/agency-pubBanner",
          name: "agency-pubBanner",
          component: PubBanner,
        },{
          path: "/agency-pubCapitalDealLog",
          name: "agency-pubCapitalDealLog",
          component: PubCapitalDealLog,
        },{
          path: "/agency-pubClub",
          name: "agency-pubClub",
          component: PubClub,
        },{
          path: "/agency-pubWinningDetails/:id",
          name: "agency-pubWinningDetails",
          component: PubWinningDetails,
        },{
          path: "/agency-tabletype",
          name: "agency-tabletype",
          component: TableType,
        },{
          path: "/agency-pubInformationUser",
          name: "agency-pubInformationUser",
          component: PubInformationUser,
        },{
          path: "/agency-userDeed/:userid",
          name: "agency-userDeed",
          component: UserGameDeed,
        },
          // {
          //     path: "/daily-activites-list",
          //     name: "daily-activites-list",
          //     component: DailyActivitiesListVue,
          // },
        {   path: "/daily-activites-list",
            name: "daily-activites-list",
            component: CfgdailyActivity,
        },{
          path: "/daily-activitylog/:id/:type",
          name: "daily-activitylog",
          component: CfgdailyActivityLog,
        },
          {
          path: "/agency-rebateorder",
          name: "agency-rebateorder",
          component: PubRebateOrder,
        },
          {
          path: "/agency-promote",
          name: "agency-promote",
          component: PromoteAd,
        },
          {
          path: "/agency-platform",
          name: "agency-platform",
          component: PromotePlatform,
        },
          {
          path: "/agency-acount",
          name: "agency-acount",
          component: PromoteAcount,
        },
          {
          path: "/agency-placement",
          name: "agency-placement",
          component: PromoteAdLogTable,
        },
        {
          path: "/analysis-user-retention",
          name: "analysis-user-retention",
          component: UserRetentionAnalysisVue,
        },
        {
          path: "/analysis-user-pay",
          name: "analysis-user-pay",
          component: AnalysisUserPayData,
        },
        {
          path: "/analysis-register-login",
          name: "analysis-register-login",
          component: AnalysisUserRigsterLoginData,
        },
        {
          path: "/analysis-user-bet",
          name: "analysis-user-bet",
          component: AnalysisUserBetData,
        },
        {
          path: "/analysis-user-search/:day?",
          name: "analysis-user-search",
          component: AnalysisUserSearchList,
        },
        {
          path: "/analysis-user-out",
          name: "analysis-user-out",
          component: AnalysisUserOutData,
        }
      ],
    },
  ],
});

export default router;
