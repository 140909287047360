<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true" :model="queryParams">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.reg_date"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>

          <el-button type="primary" @click="RefreshHisData()">
            <el-icon><RefreshLeft /></el-icon>
            <span>刷新30内日数据</span>
          </el-button>
        </el-form-item>
      </el-form>

    </el-row>

    <br/>

    <table class="el-table tablestyle" >



      <thead>
<!--      style="border: solid #2c3e50 3px"-->
      <tr style="background-color: #DFDFDF">
        <th class="table-column-cell" :rowspan="2"><el-tooltip content="用户注册日期，后续数据皆以当日注册用户30日内数据为准" placement="top">日期</el-tooltip><el-icon element-loading-text="222"><QuestionFilled /></el-icon></th>
        <th class="table-column-cell" :rowspan="2">注册用户</th>
        <th class="table-column-cell" :rowspan="2">留存类型</th>
        <th class="table-column-cell" :colspan="9"><el-tooltip content="第N日登录有过登录、投注、付费行为的用户" placement="top">留存变化</el-tooltip><el-icon><QuestionFilled /></el-icon></th>
        <th class="table-column-cell" :colspan="5" ><el-tooltip content="以有过登录、投注、付费行为用户的总共行为天数分析用户数量" placement="top">累计天数</el-tooltip><el-icon ><QuestionFilled /></el-icon></th>


      </tr>
      <tr style="background-color: #DFDFDF">
        <th class="table-column-cell" >首日</th>
        <th class="table-column-cell" >次日</th>
        <th class="table-column-cell" >三日</th>
        <th class="table-column-cell">四日</th>
        <th class="table-column-cell" >五日</th>
        <th class="table-column-cell" >六日</th>
        <th class="table-column-cell">七日</th>

        <th class="table-column-cell" >第8-15日</th>
        <th class="table-column-cell" >第16-30日</th>
        <th class="table-column-cell">2天</th>
        <th class="table-column-cell">3天</th>
        <th class="table-column-cell">7天</th>


        <th class="table-column-cell" :rowspan="2">8-15天</th>
        <th class="table-column-cell" :rowspan="2">16-30天</th>
      </tr>

      </thead>




      <tbody>
      <template v-for="(item, index) in tableData" :key="index"  >
<!--        style="border: solid #2c3e50 3px"-->
        <tr >
          <td class="table-column-cell" :rowspan="3"><span  style="font-weight: bold">{{ this.$tools.dateFormat(item.reg_date,2) }} </span></td>
          <td class="table-column-cell" :rowspan="3"><span  style="font-weight: bold">{{item.reg_count }}</span></td>
          <td class="table-column-cell">登录</td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_1_login_user_count) }}  </span><span style="font-size: smaller"> - {{ retentionRate(item.day_1_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_2_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_2_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_3_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_3_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_4_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_4_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_5_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_5_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_6_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_6_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_7_login_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_7_login_user_count, item.reg_count) }}</span></td>


          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_8to15_login_user_count)  }}  </span><span style="font-size: smaller" > - {{ retentionRate(item.day_8to15_login_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_16to30_login_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.day_16to30_login_user_count, item.reg_count) }}</span></td>



<!--          <td class="table-column-cell " :rowspan="3" style="background-color: #EFEFEF"><span  style="font-weight: bold"> {{zeroIf(item.login_days_2_user_count)  }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_2_user_count, item.reg_count) }}</span></td>-->
<!--          <td class="table-column-cell " :rowspan="3" style="background-color: #EFEFEF"><span  style="font-weight: bold">{{zeroIf(item.login_days_7_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_7_user_count, item.reg_count) }}</span></td>-->
<!--          <td class="table-column-cell " :rowspan="3" style="background-color: #EFEFEF"><span  style="font-weight: bold">{{zeroIf(item.login_days_20_user_count) }}</span> <span style="font-size: smaller" > - {{ retentionRate(item.login_days_20_user_count, item.reg_count) }}</span></td>-->

          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.login_days_2_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_2_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.login_days_3_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_3_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.login_days_7_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_7_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.login_days_8to15_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_8to15_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.login_days_16to30_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.login_days_16to30_user_count, item.reg_count) }}</span></td>


        </tr>
        <tr style="background-color: #EFEFEF">
          <td class="table-column-cell">投注</td>




          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_1_bet_user_count) }}  </span><span style="font-size: smaller"> - {{ retentionRate(item.day_1_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_2_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_2_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_3_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_3_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_4_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_4_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_5_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_5_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_6_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_6_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_7_bet_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_7_bet_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_8to15_bet_user_count)  }}  </span><span style="font-size: smaller" > - {{ retentionRate(item.day_8to15_bet_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_16to30_bet_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.day_16to30_bet_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.bet_days_2_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.bet_days_2_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.bet_days_3_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.bet_days_3_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.bet_days_7_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.bet_days_7_user_count, item.reg_count) }}</span></td>




          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.bet_days_8to15_user_count)  }}  </span><span style="font-size: smaller" > - {{ retentionRate(item.bet_days_8to15_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.bet_days_16to30_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.bet_days_16to30_user_count, item.reg_count) }}</span></td>




        </tr>
        <tr style="background-color: #DFDFDF">
          <td class="table-column-cell">付费</td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_1_pay_user_count) }}  </span><span style="font-size: smaller"> - {{ retentionRate(item.day_1_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_2_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_2_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_3_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_3_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_4_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_4_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_5_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_5_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_6_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_6_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_7_pay_user_count)  }} </span><span style="font-size: smaller"> - {{ retentionRate(item.day_7_pay_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_8to15_pay_user_count)  }}  </span><span style="font-size: smaller" > - {{ retentionRate(item.day_8to15_pay_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.day_16to30_pay_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.day_16to30_pay_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.pay_days_2_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.pay_days_2_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.pay_days_3_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.pay_days_3_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{zeroIf(item.pay_days_7_user_count) }}</span><span style="font-size: smaller" > - {{ retentionRate(item.pay_days_7_user_count, item.reg_count) }}</span></td>

          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.pay_days_8to15_user_count)  }}  </span><span style="font-size: smaller" > - {{ retentionRate(item.pay_days_8to15_user_count, item.reg_count) }}</span></td>
          <td class="table-column-cell"><span  style="font-weight: bold"> {{ zeroIf(item.pay_days_16to30_user_count)  }} </span><span style="font-size: smaller" > - {{ retentionRate(item.pay_days_16to30_user_count, item.reg_count) }}</span></td>



        </tr>
      </template>
      </tbody>
    </table>




<!--    <el-table class="el-table " :data="tableData" height="800" >-->
<!--      <el-table-column prop="reg_date" label="注册日期" />-->
<!--      <el-table-column prop="reg_count" label="注册人数" />-->
<!--      <el-table-column  label="留存类型" >-->

<!--          <template  >-->

<!--            <div >aaa</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      <el-table-column  label="七日数据" >-->

<!--        <template  #default="scope">-->

<!--          <tr>         {{scope.row.reg_count}}</tr>-->
<!--          <tr>         {{scope.row.reg_count}}</tr>-->
<!--          <tr>         {{scope.row.reg_count}}</tr>-->

<!--        </template>-->
<!--      </el-table-column>-->
<!--        <el-table-column prop="day_2_login_user_count" label="次日" />-->
<!--        <el-table-column prop="day_3_login_user_count" label="三日" />-->
<!--        <el-table-column prop="day_4_login_user_count" label="四日" />-->
<!--        <el-table-column prop="day_5_login_user_count" label="五日" />-->
<!--        <el-table-column prop="day_6_login_user_count" label="六日" />-->
<!--        <el-table-column prop="day_7_login_user_count" label="七日" />-->

<!--        <el-table-column prop="day_1_bet_user_count" label="首日" />-->
<!--        <el-table-column prop="day_2_bet_user_count" label="次日" />-->
<!--        <el-table-column prop="day_3_bet_user_count" label="三日" />-->
<!--        <el-table-column prop="day_4_bet_user_count" label="四日" />-->
<!--        <el-table-column prop="day_5_bet_user_count" label="五日" />-->
<!--        <el-table-column prop="day_6_bet_user_count" label="六日" />-->
<!--        <el-table-column prop="day_7_bet_user_count" label="七日" />-->



<!--    </el-table>-->



    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
 import {QuestionFilled} from "@element-plus/icons-vue";



 import { ElLoading } from 'element-plus'

export default {
  components: {QuestionFilled},
  data() {
    return {
      tableData: [],
      queryParams: {
        reg_date: ""
      },
      total: 0,
      pages: 0,
      page: 0,

    };
  },
  mounted() {
    this.resetHandle();
    //this.queryData();
  },
  methods: {
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      console.log(params.reg_date);
      if (params.reg_date) {

        params.start_time = this.$tools.dateFormat(params.reg_date[0], 2);
        params.end_time = this.$tools.dateFormat(params.reg_date[1], 2);
        delete params.reg_date
      }
      const data = {
        cmd: "user_retention",
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        },
      };
      const loadingInstance =ElLoading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.5)' ,text:"正在获取数据",customClass:'loading-custom'});
      this.$http
          .post("user_retention", data)
          .then((response) => {
            console.log("response.data", response.data);
            // var dataList = [];
            response.data.list.forEach(item => {
             item.reg_date = item.reg_date.substring(0, 10);
            });
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
            loadingInstance.close();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    RefreshHisData() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      console.log(params.reg_date);
      // if (params.reg_date) {
      //   params.start_time = this.$tools.dateFormat(params.reg_date[0], 2);
      //   params.end_time = this.$tools.dateFormat(params.reg_date[1], 2);
      //   delete params.reg_date
      // }
      const data = {
        cmd: "user_retention",
        action: "refresh_his_data",
      };
      const loadingInstance =ElLoading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.5)' ,text:"正在请求服务器刷新数据",customClass:'loading-custom'});
      this.$http
          .post("user_retention", data)
          .then((response) => {
            console.log("response.data11", response.data);

            loadingInstance.close();
            this.$message({
              type: "info",
              message: response.errmsg,
            });
          })
          .catch((error) => {
            loadingInstance.close();
            console.log("error", error);
            this.$message({
              type: "info",
              message: error,
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 30);
      //默认本月
      this.queryParams.reg_date=[currentDate,new Date()];
      this.queryData();
    },
    retentionRate(number, sum){
      if(number == null || number==0){
        return null;
      }
      if (sum == 0){
        return null;
      }
      const result = (number / sum) * 100;
      // this.generatedElement="<span style='font-size: smaller'>"+result.toFixed(2)+"%<span>"
       return  `${result.toFixed(2)}%`;
    },
    zeroIf(number){
      if(number == 0){
        return null;
      }else
        return number ;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-column-cell{
  //border: solid #0d1717 1px;
}
::v-deep .el-pagination {
  justify-content: flex-end;

}
.tablestyle {
  margin-bottom: 20px;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  border: solid #0d1717 1px;
  th {
    padding: 8px 13px;
  }
  th,tr {
    border-bottom: solid #0d1717 1px;
  }

  td {
    margin: 0;
    padding: 0;
    padding: 8px 13px;
    //border-left: solid #0d1717 1px;
    //border-right: solid #0d1717 1px;
  }
  th:not(.rowsapndate),
  td:not(.rowsapndate) {
    border-left: solid #0d1717 1px;
  }


}



</style>

<style>

.loading-custom {
  color:red;
  font-size: 26px;
  background-color: #85b90b;
}
#loading-custom {
  color:forestgreen;
  font-size: 26px;
  background-color: #85b90b;

}


</style>
