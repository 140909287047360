<template >
<!--  <h1>推广数据</h1>-->
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="媒体">
        <PromoteA/>
      </el-tab-pane>
      <el-tab-pane label="账户">
        <PromotePlatform/>
      </el-tab-pane>
      <el-tab-pane label="广告">
        <PromoteAcount/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import PromoteA from '@/pages/operation/PubPromoteLog/PromoteAd.vue'
import PromotePlatform from '@/pages/operation/PubPromoteLog/PromoteAcount.vue'
import PromoteAcount from '@/pages/operation/PubPromoteLog/PromotePlatform.vue'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {PromotePlatform, PromoteAcount, PromoteA}

}
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

</script>

