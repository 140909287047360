<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table :data="tableData">
      <el-table-column prop="createTime" label="控制时间"></el-table-column>
      <el-table-column prop="controlType" label="控制类型">
        <template v-slot="{row}">
          <template v-if="row.controlType===1">禁止提款</template>
          <template v-if="row.controlType===2">解除禁止提款</template>
          <template v-if="row.controlType===3">封号</template>
          <template v-if="row.controlType===4">解除封号</template>
          <template v-if="row.controlType===5">VIP掉级</template>
          <template v-if="row.controlType===6">VIP等级恢复</template>
          <template v-if="row.controlType===7">控输</template>
          <template v-if="row.controlType===8">控输取消</template>
          <template v-if="row.controlType===9">控赢</template>
          <template v-if="row.controlType===10">控赢取消</template>
          <template v-if="row.controlType===11">其他</template>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="理由"></el-table-column>
      <el-table-column prop="updateUser" label="操作人"></el-table-column>
      <el-table-column prop="controlDetails" label="控制细节"></el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      item: {...defaultItem},
      queryParams: {},
      betInfos: [
        {label: '获赠真金:', value: 0},
        {label: '获赠赏金:', value: 0},
      ],
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()],
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
        let data = {
          cmd: "user_behavior",
          action: "query_control_record",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("user_behavior", data)
            .then((response) => {
              console.log("response.data", response.data);
              if (response.data.gameLogs){
                this.tableData = response.data.gameLogs;
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
    },

  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
