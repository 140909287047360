<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :model="form" :inline="true">
        <el-form-item label="用户">
          <el-input clearable  placeholder="用户ID" v-model="queryParams.userid"></el-input>
        </el-form-item>
        <el-form-item label="赠金来源">
          <el-select clearable  placeholder="请选择赠金来源" v-model="queryParams.type">
            <el-option
                v-for="(item, index) in typeArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资金类型">
          <el-select clearable  placeholder="请选择资金类型" v-model="queryParams.money_type">
            <el-option
                v-for="(item, index) in moneyTypeArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
            class="select1"
            type="daterange"
            range-separator="To"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-row style="background-color: white; text-align: left; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">送出现金：</el-col>
          <el-col span="">{{sendOutRealGold}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">送出奖金：</el-col>
          <el-col span="">{{sendOutBounty}}</el-col>
        </el-row>
      </el-space>
    </el-row>
  </div>

  <br />

  <el-table :data="tableData">
    <el-table-column prop="create_time_str"  label="时间"></el-table-column>
    <el-table-column prop="userid" label="用户ID"></el-table-column>
    <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
    <el-table-column prop="type_str" label="获取来源"></el-table-column>
    <el-table-column prop="money_type" label="赠金类型">
      <template v-slot="{row}">
        <template v-if="row.money_type===1">现金</template>
        <template v-if="row.money_type===2">奖金</template>
      </template>
    </el-table-column>
    <el-table-column prop="coins" label="赠送金额"></el-table-column>
    <el-table-column prop="prop_id" label="道具"></el-table-column>
    <el-table-column prop="bonus_task_id" label="奖金任务"></el-table-column>
    <el-table-column label="订单号">
      <template v-slot="{row}">
        <template v-if="row.type===1 || row.type===10 || row.type===13 || row.type===14">
          <router-link :to="'/deposit-detail/'+row.order_id">
            <span style="text-decoration:underline;cursor: pointer;">{{ row.order_id }}</span>
          </router-link>
        </template>
        <template v-else>{{ row.order_id }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="operational_id" label="运营活动"></el-table-column>
    <el-table-column prop="other_id" label="其他记录"></el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
      v-if="tableData.length>0"
  />
</template>

<script>
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";
export default {
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      sendOutRealGold: 0,
      sendOutBounty: 0,
      total: 0,
      pages: 0,
      page: 0,
      //获取来源1-首充活动2-赏金任务3-道具现金券4-道具免费游戏5-道具增益道具6-道具存款券7-VIP下注返利8-VIP黄金俱乐部9-运营活动10-每日活动
      typeArr: [
        {label:'首充活动',value:1},
        {label:'每日存款任务',value:24},
        {label:'每日累计有效投注',value:25},
        {label:'每日单笔有效投注',value:26},
        {label:'首次存款任务',value:27},
        {label:'兑换码',value:23},
        {label:'存钱罐活动',value:16},
        {label:'每日签到活动',value:18},
        {label:'会员周返奖励',value:1321},
        {label:'会员月返奖励',value:1322},
        {label:'会员升级奖励',value:1323},
        {label:'红包雨',value:67},
        {label:'新手任务活动',value:17},
        {label:'道具现金券',value:3},
        {label:'道具存款券',value:6},
        {label:'运营活动',value:9},
        {label:'每日活动',value:10},
        {label:'注册赠送',value:11},
        {label:'充值奖励',value:13},
        {label:'人工入款',value:14},
        {label:'奖金转现任务',value:2},
        {label:'奖金提现任务',value:22},
        {label:'每日挑战活动',value:15},
        {label:'VIP下注返利',value:7},
        {label:'VIP黄金俱乐部',value:8},
        {label:'道具免费游戏',value:4},
        {label:'道具增益道具',value:5},
      ],
      moneyTypeArr: [
        {label:'现金',value:1},
        {label:'奖金',value:2},
      ],
      showViewData: false
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance()
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      let data = {
        cmd: "gift_record",
        action: "query_gift_list",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("gift_record", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.gift_logs;
            this.tableData.forEach((item) => {
              for(let i of this.typeArr){
                if(i.value==item.type){
                  item.type_str = i.label;
                  break;
                }
              }
              item.create_time_str = this.$tools.dateFormat(item.create_time)
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      const data = {
        cmd: "gift_record",
        action: "query_statistics",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("gift_record", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.sendOutRealGold = response.data.coins;
            this.sendOutBounty = response.data.give_coins;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        //时间
        if(params.time){
          params.start_time = this.$tools.dateFormat(params['time'][0],2);
          params.end_time = this.$tools.dateFormat(params['time'][1],2);
        }

        let data = {
          cmd: "gift_record",
          action: "query_gift_list",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await  this.$http
            .post("gift_record", data)
            .then((response) => {
              if (response.data.gift_logs.length !== 0) {
                listData = listData.concat(response.data.gift_logs);
                index++;
                listData.forEach((item) => {
                  for (let i of this.typeArr) {
                    if (i.value == item.type) {
                      item.type_str = i.label;
                      break;
                    }
                  }
                  item.create_time_str = this.$tools.dateFormat(item.create_time)
                })
                index++;
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
      }
      const data = [
        ['时间', '用户ID', 'VIP级别', '获取来源', '获取真金', '真金余额', '获取赏金', '赏金余额', '道具', '赏金任务', '订单号', '运营活动', '其他纪录'],
      ];
      listData.forEach(item=>{
        data.push([item.create_time_str, item.userid, item.vip_level, item.type_str, item.coins, item.wallet_coins, item.bonus, item.wallet_bonus, item.prop_id, item.bonus_task_id, item.order_id, item.operational_id,item.other_id]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '增金记录');
      XLSX.writeFile(wb, '增金记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
    this.initShowViewData();
  },
};
</script>

<style lang="scss" scoped></style>
