<template>
  <div>
    <div class="container" v-if="googleType==1">
      <div class="context">
        <p>谷歌验证</p>
        <div class="password">
          <input type="text" v-model="checkForm.code" placeholder="验证码"/>
        </div>
      </div>
      <button class="login" @click="handleCheck">确 认</button>
    </div>
    <div class="container" v-if="googleType==0">
      <div class="context">
        <p>谷歌验证</p>
        <div class="account" style="width: auto;height: auto;">
<!--          <qrcode :value="qrcode"></qrcode>-->
          <iframe class="iframe-box" ref="myIframe" :src="qrcode" frameborder="0"></iframe>
        </div>
      </div>
      <button class="login" @click="googleType=1">已 绑 定</button>
    </div>
    <div class="container">
      <button class="login" @click="googleOut">退 出 登 录</button>
    </div>
  </div>
</template>

<script>
import loginHttp from "../utils/loginHttp"
import "vue-toast-notification/dist/theme-sugar.css";
import {getGoogleType, setGoogleType, removeGoogleType} from '@/utils/google'
import {useToast} from "vue-toast-notification";
import {removeToken} from "@/utils/token";
const $toast = useToast();

export default {
  data() {
    const checkForm = {code: ''}
    return {
      checkForm,
      qrcode: "",
      googleType: 0,
    }
  },
  async mounted() {
    let googleType = await getGoogleType();
    if (googleType == null) {
      await loginHttp.get('/google/getUserInfo')
      .then((response) => {
        let google_type = response.data.type == 0 ? 0 : 1;
        setGoogleType(google_type);
        this.googleType = google_type;
        if(this.googleType==0){
          this.getQrcode();
        }

      })
    }
  },
  methods: {
    async getQrcode() {
      loginHttp.get('/google/getGrcodeUrl')
          .then((response) => {
            this.qrcode = response.data.qrcodeUrl;
            console.log('this.qrcode',this.qrcode)
          })
    },
    async handleCheck() {
      loginHttp.get('/google/check?code='+this.checkForm.code)
          .then((response) => {
            if(response.rcode!==100){
              $toast.warning(response.errmsg, {duration: 3000});
            }else{
              setGoogleType(2);
              window.location.href = this.getRootPath_dc()
            }
          })
    },
    getRootPath_dc() {
      var pathName = window.location.pathname.substring(1);
      var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
      if (webName == "") {
        return window.location.protocol + '//' + window.location.host;
      } else {
        return window.location.protocol + '//' + window.location.host + '/' + webName;
      }
    },
    googleOut() {
      removeGoogleType();
      removeToken()
      this.$router.push('/login')
    },
  }
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  flex-direction: column;

  .context {
    margin: auto;

    p {
      text-align: left;
    }

    .account {
      margin: 40px 0;
      width: 368px;
      height: 40px;
      line-height: 40px;

      input {
        width: 100%;
        height: 100%;
      }
    }

    .password {
      margin: 40px 0;
      width: 368px;
      height: 40px;
      line-height: 40px;

      input {
        width: 100%;
        height: 100%;
      }
    }

    .options {
      display: flex;
      justify-content: space-between;
    }
  }

  .login {
    margin: 20px auto;
    width: 368px;
    height: 40px;
    background: #1890ff;
    border: 0;
    border-radius: 2px;
    color: white;
    font-size: 16px;
  }
}
</style>
