<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="用户ID">
          <el-input clearable  placeholder="搜索条件"></el-input>
        </el-form-item>
        <el-form-item label="钱包类型">
          <el-input clearable  placeholder="搜索条件"></el-input>
        </el-form-item>
        <el-form-item label="是否为法币">
          <el-select clearable  placeholder="请选择">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交易所名称">
          <el-input  clearable placeholder="搜索条件"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button>
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="isShowDialog = true">
          <el-icon><Plus /></el-icon>
          <span>新增</span>
        </el-button>
        <el-button>
          <el-icon><DeleteFilled /></el-icon>
          <span>删除</span>
        </el-button>
      </el-row>

      <el-table :data="tableData">
        <el-table-column label="用户ID"></el-table-column>
        <el-table-column label="钱包类型"></el-table-column>
        <el-table-column label="钱包余额"></el-table-column>
        <el-table-column label="冻结金额"></el-table-column>
        <el-table-column label="累计获取金额"></el-table-column>
        <el-table-column label="累计消费金额"></el-table-column>
        <el-table-column label="奖金余额"></el-table-column>
        <el-table-column label="累计获得奖金"></el-table-column>
        <el-table-column label="累计消耗奖金"></el-table-column>
        <el-table-column label="是否法币"></el-table-column>
        <el-table-column label="钱包key"></el-table-column>
        <el-table-column label="钱包密钥"></el-table-column>
        <el-table-column label="子账号的充值地址"></el-table-column>
        <el-table-column label="充值币种"></el-table-column>
        <el-table-column label="交易所名"></el-table-column>
        <el-table-column label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template #default>
            <el-button type="primary" size="small">编辑</el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteGameDialogVisible = true"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <el-dialog
    v-model="isShowDialog"
    title="新增钱包"
    style="text-align: left"
    :close-on-click-modal="false"
    align-center
  >
    <el-form :model="form">
      <el-form-item label="用户ID：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="钱包类型：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="钱包余额：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="冻结金额：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="累计获取金额：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="累计消耗金额：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="奖金余额：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="累计获得奖金：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="累计消耗奖金：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否法币：">
        <el-radio-group>
          <el-radio label="是"></el-radio>
          <el-radio label="否"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="钱包KEY：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="钱包秘钥：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="子账户的充值地址：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="充值币种：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="交易所名称：">
        <el-input v-model="id" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isShowDialog = false">取消</el-button>
        <el-button type="primary" @click="isShowDialog = false">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShowDialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
