<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="活跃日报">
        <ActiveDailyDeed/>
      </el-tab-pane>
      <el-tab-pane label="游戏行为">
        <UserGameDeed/>
      </el-tab-pane>
      <el-tab-pane label="存款行为">
        <UserdrawMoneyDeed/>
      </el-tab-pane>
      <el-tab-pane label="登录行为">
        <UserLoginDeed/>
      </el-tab-pane>
      <el-tab-pane label="赠金记录">
        <EndowmentDeed/>
      </el-tab-pane>
      <el-tab-pane label="中奖记录">
        <UserWinningDeed/>
      </el-tab-pane>
      <el-tab-pane label="管控记录">
        <ControlDeed/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
//活跃日报
import ActiveDailyDeed from '@/pages/userManager/log/ActiveDailyDeed.vue'
//游戏行为
import UserGameDeed from '@/pages/userManager/log/UserGameDeed.vue'
//存提款行为
import UserdrawMoneyDeed from '@/pages/userManager/log/UserdrawMoneyDeed.vue'
//中奖记录
import UserWinningDeed from '@/pages/userManager/log/UserWinningDeed.vue'
// 登录行为
import UserLoginDeed from '@/pages/userManager/log/UserLoginDeed.vue'
//赠金记录
import EndowmentDeed from '@/pages/userManager/log/EndowmentDeed.vue'
// 管控记录
import ControlDeed from '@/pages/userManager/log/ControlDeed.vue'



export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {UserGameDeed,UserdrawMoneyDeed,UserWinningDeed,UserLoginDeed,EndowmentDeed,ControlDeed,ActiveDailyDeed}

}
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

</script>

