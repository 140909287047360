<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="标签名称">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.tag_name"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br/>

    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="addItemHandle">
          <el-icon>
            <Plus/>
          </el-icon>
          <span>新增</span>
        </el-button>
        <el-button @click="batchDeleteHandle">
          <el-icon>
            <DeleteFilled/>
          </el-icon>
          <span>删除</span>
        </el-button>
      </el-row>

      <el-table :data="tableData" @selection-change="handleSelected">
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="tag_icon" label="图标" align="center">
          <template #default="scope">
            <img :src="$tools.cdn+scope.row.tag_icon" style="height: 50px;"/>
          </template>
        </el-table-column>
        <el-table-column prop="tag_name" label="标签名称"></el-table-column>
        <el-table-column prop="alias_name" label="中文名称"></el-table-column>
        <el-table-column prop="sort" label="展示排序"></el-table-column>
        <el-table-column prop="statusStr" label="是否展示"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                type="primary"
                size="small"
                @click="itemEditHandle(scope.$index)"
            >编辑
            </el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="itemDeleteHandle(scope.$index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          background
          :page-size="20"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="pageChangeHandle"
      />
    </div>
  </div>

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @close="dislogCancelHandle"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">
      <el-form-item label="标签名称：" prop="tag_name" required>
        <el-input v-model="item.tag_name" placeholder="请输入巴普文案"></el-input>
      </el-form-item>

      <el-form-item label="中文名称：" :label-width="auto" prop="alias_name">
        <el-input v-model="item.alias_name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="展示排序：" prop="sort" required>
        <el-input-number v-model="item.sort" placeholder="请输入"></el-input-number>
      </el-form-item>

      <el-form-item label="是否展示：">
        <el-radio-group v-model="item.status">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="图标：" prop="URL">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="item.URL"></UploadImage>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'

export default {
  components: {UploadImage},
  data() {
    const defaultItem = {
      tag_name: "标签名称",
      sort: "0",
      status: "1",
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: {...defaultItem},
      queryParams: {...defaultItem},
      isEdit: false,
      dialogVisible: false,
      dialogTitle: "",
      total: 0,
      pages: 0,
      page: 0,
      rules: {
        tag_name: [
          {required: true, message: '请输入标签名称', trigger: 'blur'}
        ],
        sort: [
          {required: true, message: '请输入展示排序', trigger: 'change'}
        ],
        URL: [
          {required: true, message: '请选择图标', trigger: 'change'}
        ],
      },
      selectedRows: [],
    };
  },
  methods: {
    /**
     * 图片上传成功回调
     * @param {*} res
     * @param {*} file
     */
    handleAvatarSuccess(data) {
      this.item.tag_icon = data.file_url;
      this.item.URL = data.url;
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增标签";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.item.status = '1';
      this.dialogVisible = true;
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑标签";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.item.URL = this.$tools.cdn+this.item.tag_icon;
      this.dialogVisible = true;
    },
    itemDeleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.deleteData(this.tableData[index].id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          // this.dialogVisible = false;
        }
      })
    },
    dislogCancelHandle() {
      this.$refs.rowForm.resetFields();
      this.dialogVisible = false;
    },
    addData() {
      const data = {
        cmd: "game_tags",
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post("game_tags", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "新增成功",
            })
            this.dislogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    updateData() {
      const data = {
        cmd: "game_tags",
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post("game_tags", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "编辑成功",
            })
            this.dislogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "game_tags",
        action: "query",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("game_tags", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.gameTagsVOList;
            this.tableData.forEach((cate) => {
              if (cate.status === 1) {
                cate.statusStr = "是";
              } else {
                cate.statusStr = "否";
              }
              cate.status += "";
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteData(id) {
      const data = {
        cmd: "game_tags",
        action: "delete",
        data: {
          paramVO: {},
          ids: id,
        },
      };
      this.$http
          .post("game_tags", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleSelected(rows) {
      this.selectedRows = rows;
    },
    handleBatchDelete() {
      let finalRows = JSON.parse(JSON.stringify(this.selectedRows));
      finalRows = finalRows.map(row => row.id);
      let ids = finalRows.join(',');
      this.deleteData(ids);
    },
    batchDeleteHandle() {
      if (this.selectedRows.length <= 0) {
        this.$message({
          type: "info",
          message: "请选择删除对象",
        })
        return;
      }
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.handleBatchDelete();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
