<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :model="form" :inline="true">
        <el-form-item label="">
          <el-input clearable placeholder="请输入用户ID" v-model="queryParams.where_value"></el-input>
        </el-form-item>
<!--        <el-form-item label="出款类型">-->
<!--          <el-select placeholder="请选择">-->
<!--            <el-option label="label" value="value"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="操作人">-->
<!--          <el-input placeholder="操作人代码"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="出款类型：">
          <el-select  clearable placeholder="请选择出款类型" v-model="queryParams.disbursement_type">
            <el-option
                v-for="(item, index) in disbursement_type_arr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="createTimeSortChange">
            <span>出款时间</span>
            <el-icon>
              <SortUp v-if="queryParams.createTimeDesc===0" />
              <SortDown v-else />
            </el-icon>
          </el-button>
          <el-button @click="disbursementAmountSortChange">
            <span>出款金额</span>
            <el-icon>
              <SortUp v-if="queryParams.DisbursementAmountDesc===0" />
              <SortDown v-else />
            </el-icon>
          </el-button>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button type="primary" @click="addItemHandle">
            <el-icon><Plus /></el-icon>
            <span>人工出款</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-row style="background-color: white; text-align: left; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">入款提款：</el-col>
          <el-col span="">{{incomeWithdrawal}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">入款不提：</el-col>
          <el-col span="">{{incomeNotWithdrawal}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">真金扣款：</el-col>
          <el-col span="">{{realGoldDeduction}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">赏金扣款：</el-col>
          <el-col span="">{{bountyDeduction}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">可提现金额：</el-col>
          <el-col span="">{{withdrawableAmount}}</el-col>
        </el-row>
        <el-button type="" @click="doRefresh">刷新</el-button>
      </el-space>
    </el-row>
  </div>

  <br />

  <el-table :data="tableData">
    <el-table-column prop="create_time_str" label="出款时间"></el-table-column>
    <el-table-column prop="userid" label="用户ID"></el-table-column>
    <el-table-column prop="disbursement_type_str" label="出款类型"></el-table-column>
    <el-table-column prop="disbursement_amount" label="出款金额"></el-table-column>
    <el-table-column prop="disbursement_remark" label="出款理由"></el-table-column>
    <el-table-column prop="create_user" label="操作人"></el-table-column>
    <el-table-column prop="merchantorderid" label="订单号"></el-table-column>
    <el-table-column prop="statusStr" label="订单状态"></el-table-column>
    <el-table-column prop="result_msg" label="返回信息"></el-table-column>
    <el-table-column prop="payment_channel_code" label="代付通道"></el-table-column>
    <el-table-column prop="update_time" label="完成时间"></el-table-column>
    <el-table-column prop="" label="操作">
      <template #default="scope">
        <el-button type="primary" size="small" @click="goDetail(scope.$index)">详情</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
      v-if="tableData.length>0"
  />

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @close="dislogCancelHandle"
  >
    <el-form-item class="searchAdd">
      <el-input placeholder="请输入用户ID" v-model="addUserId" style="width: 70%;"></el-input>
      <el-button type="primary" @click="getUserInfo()" style="position:absolute;right:0;">
        <span>查询并添加</span>
      </el-button>
    </el-form-item>
    <el-table :data="addUserInfoBox" style="margin-bottom:50px;" empty-text="请先在输入用户ID查询并添加用户数据" v-if="dialogVisible">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="cpf" label="税号"></el-table-column>
      <el-table-column prop="phone" label="绑定手机号"></el-table-column>
      <el-table-column prop="firstname" label="真实姓名">
        <template #default="scope">
          <span>{{scope.row.firstname}} {{scope.row.lastname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coins" label="账户真金"></el-table-column>
      <el-table-column prop="bonus" label="账户赏金"></el-table-column>
    </el-table>
    <el-form :model="item" ref="rowForm" :rules="rules">
      <el-form-item label="出款类型：" prop="disbursement_type">
        <el-select placeholder="请选择" v-model="item.disbursement_type">
          <el-option
              v-for="(item, index) in disbursement_type_arr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出款金额：" prop="disbursement_amount">
        <el-input-number v-model="item.disbursement_amount" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="PIX类型：" prop="pix" v-if="item.disbursement_type==3">
        <el-select placeholder="请选择" v-model="item.pix">
          <el-option
              v-for="(item, index) in pix_arr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="PIX ID：" prop="pix_id" v-if="item.disbursement_type==3">
        <el-input v-model="item.pix_id" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="代付通道：" prop="payment_channel_id" v-if="item.disbursement_type==3">
        <el-select placeholder="请选择" v-model="item.payment_channel_id">
          <el-option
              v-for="(item, index) in payment_channel_arr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出款理由：" prop="disbursement_remark">
        <el-input type="textarea" v-model="item.disbursement_remark" placeholder="请输入出款理由"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">确定出款</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      incomeWithdrawal: 0,
      incomeNotWithdrawal: 0,
      realGoldDeduction: 0,
      bountyDeduction: 0,
      withdrawableAmount: 0,
      total: 0,
      pages: 0,
      page: 0,
      statusArr: [
        {label:'待处理',value:1},
        {label:'代付失败',value:2},
        {label:'锁定中',value:3},
        {label:'代付中',value:4},
        {label:'已完成',value:5},
        {label:'已拒绝',value:6},
        {label:'已退单',value:7},
        {label:'已冻结',value:8},
        {label:'已关闭',value:9},
        {label:'拒绝并挂起',value:10},
        {label:'退单并挂起',value:11},
        {label:'待审核',value:12},
      ],
      //人工出款，优化：真金入账不提改为佣金入账不提，真金入账提款改为佣金入账提款；
      disbursement_type_arr: [
        {label:'真金(仅扣款)',value:1},
        {label:'真金(入账不提)',value:2},
        {label:'真金(入账提款)',value:3},
        // {label:'赏金(仅扣款)',value:4},
        {label:'可提现金额(减少)',value:5},
        {label:'佣金提款',value:6},
        {label:'佣金转出',value:7},
        {label:'代理合作费(解冻)',value:8},
      ],
      dialogVisible: false,
      dialogTitle: "",
      addUserId:"",
      addUserInfoBox:[],
      payment_channel_arr: [
        {label:'PIX',value:'1'},
      ],
      pix_arr: [
        {label:'CPF',value:'CPF'},
        {label:'CNPJ',value:'CNPJ'},
        {label:'PHONE',value:'PHONE'},
        {label:'EMAIL',value:'EMAIL'},
      ],
      rules: {
        disbursement_type: [
          { required: true, message: '请选择出款类型', trigger: 'change' }
        ],
        disbursement_amount: [
          { required: true, message: '请输入出款金额', trigger: 'change' }
        ],
        pix: [
          { required: true, message: '请选择PIX类型', trigger: 'change' }
        ],
        pix_id: [
          { required: true, message: '请输入PIX ID', trigger: 'blur' }
        ],
        payment_channel_id: [
          { required: true, message: '请选择代付通道', trigger: 'change' }
        ],
        disbursement_remark: [
          { required: true, message: '请输入出款理由', trigger: 'blur' }
        ],
      },
      showViewData: false
    };
  },
  methods: {
    createTimeSortChange(){
      if(this.queryParams.createTimeDesc!==0){
        this.queryParams.createTimeDesc = 0;
      }else{
        this.queryParams.createTimeDesc = 1;
      }
      this.queryData();
    },
    disbursementAmountSortChange(){
      if(this.queryParams.DisbursementAmountDesc!==0){
        this.queryParams.DisbursementAmountDesc = 0;
      }else{
        this.queryParams.DisbursementAmountDesc = 1;
      }
      this.queryData();
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance()
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      //排序
      if(![0,1].includes(params.createTimeDesc)){
        params.createTimeDesc = 1;
      }
      if(![0,1].includes(params.DisbursementAmountDesc)){
        params.DisbursementAmountDesc = 1;
      }
      //
      let data = {
        cmd: "manual_operate",
        action: "query_manual_disbursement",
        data: {
          paramVO: params,
          page: index,
        },
      };
      // //搜索项
      // for(let i in params){
      //   if(i=='time'){
      //     data.data.start_time = this.$tools.dateFormat(params[i][0],2);
      //     data.data.end_time = this.$tools.dateFormat(params[i][1],2);
      //   }else{
      //     data.data[i] = params[i]
      //   }
      // }
      // //
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.manual_disbursement_list;
            this.tableData.forEach((item) => {
              //出款类型1-真金(仅扣款)2-真金(入账不提)3-真金(入账提款)4-赏金(仅扣款)5-可提现金额(减少)6-代理出款
              for(let i of this.disbursement_type_arr){
                if(i.value==item.disbursement_type){
                  item.disbursement_type_str = i.label;
                  break;
                }
              }
              //订单状态1-待处理2-代付失败3-锁定中4-代付中5-已完成6-已拒绝7-已退单8-已冻结9-已关闭10-拒绝并挂起11-退单并挂起12-待审核
              for(let i of this.statusArr){
                if(i.value==item.status){
                  item.statusStr = i.label;
                  break;
                }
              }
              item.create_time_str = this.$tools.dateFormat(item.create_time)
              item.update_time_str = this.$tools.dateFormat(item.update_time)
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      //排序
      if(![0,1].includes(params.createTimeDesc)){
        params.createTimeDesc = 1;
      }
      if(![0,1].includes(params.paymentAmountDesc)){
        params.paymentAmountDesc = 1;
      }
      const data = {
        cmd: "manual_operate",
        action: "query_manual_disbursement_statistics",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.incomeWithdrawal = response.data.incomeWithdrawal;
            this.incomeNotWithdrawal = response.data.incomeNotWithdrawal;
            this.realGoldDeduction = response.data.realGoldDeduction;
            this.bountyDeduction = response.data.bountyDeduction;
            this.withdrawableAmount = response.data.withdrawableAmount;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    goDetail(index) {
      let row = this.tableData[index];
      this.$router.push(`/manual-payment-detail/${row.id}`)
    },
    addItemHandle() {
      this.dialogTitle = "人工出款";
      // Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.addUserId = "";
      this.addUserInfoBox = [];
      this.dialogVisible = true;
    },
    dislogCancelHandle() {
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.$refs.rowForm.resetFields();
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          return false;
        }else {
          if(this.item.disbursement_type===6){
            if (this.item.disbursement_amount<=0){
              this.$message({
                type: "info",
                message: "提款金额不能小于等于0",
              });
              return;
            }
          }
          this.addData();
        }
      })
    },
    addData() {
      if(this.addUserInfoBox.length<=0){
        this.$message({
          type: "info",
          message: "请先添加用户",
        });
        return;
      }
      //用户信息
      this.item.userid = this.addUserInfoBox[0]['userid'];
      this.item.vip_level = this.addUserInfoBox[0]['vip_level'];
      this.item.phone = this.addUserInfoBox[0]['phone'];
      this.item.real_name = this.addUserInfoBox[0]['firstname']+' '+this.addUserInfoBox[0]['lastname'];
      //
      const data = {
        cmd: "manual_operate",
        action: "add_manual_disbursement",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post("manual_operate", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dislogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    getUserInfo() {
      if(!this.addUserId || this.addUserId==""){
        this.$message({
          type: "info",
          message: "请输入用户ID",
        });
        return;
      }
      const data = {
        cmd: "user",
        action: "query_user",
        data: {
          paramVO: {},
          userid: this.addUserId,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            if(!response.data.user_data?.user_status_info || response.data.user_data?.user_status_info==null){
              this.$message({
                type: "info",
                message: "用户不存在",
              });
              this.addUserInfoBox = [];
              return;
            }
            //用户
            this.addUserInfoBox = [
              {
                userid:response.data.user_data.user_status_info.userid,
                vip_level:response.data.user_data.user_customer_info.vip_level_name,
                firstname:response.data.user_data.user_customer_info.firstname,
                lastname:response.data.user_data.user_customer_info.lastname,
                phone:response.data.user_data.user_customer_info.phone,
                coins:response.data.user_data.user_wallet.coins,
                bonus:response.data.user_data.user_wallet.bonus,
                cpf:response.data.user_data.user_customer_info.customer_cert,
              }
            ];
            //
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        //时间
        if(params.time){
          params.start_time = this.$tools.dateFormat(params['time'][0],2);
          params.end_time = this.$tools.dateFormat(params['time'][1],2);
        }
        //排序
        if(![0,1].includes(params.createTimeDesc)){
          params.createTimeDesc = 1;
        }
        if(![0,1].includes(params.paymentAmountDesc)){
          params.paymentAmountDesc = 1;
        }
        const data = {
          cmd: "manual_operate",
          action: "query_manual_disbursement",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("manual_operate", data)
            .then((response) => {
              if (response.data.manual_disbursement_list.length !== 0) {
                listData = listData.concat(response.data.manual_disbursement_list);
                index++;
                listData.forEach((item) => {
                  //出款类型1-真金(仅扣款)2-真金(入账不提)3-真金(入账提款)4-赏金(仅扣款)5-可提现金额(减少)6-代理出款
                  for(let i of this.disbursement_type_arr){
                    if(i.value==item.disbursement_type){
                      item.disbursement_type_str = i.label;
                      break;
                    }
                  }
                  //订单状态1-待处理2-代付失败3-锁定中4-代付中5-已完成6-已拒绝7-已退单8-已冻结9-已关闭10-拒绝并挂起11-退单并挂起12-待审核
                  for(let i of this.statusArr){
                    if(i.value==item.status){
                      item.statusStr = i.label;
                      break;
                    }
                  }
                  item.create_time_str = this.$tools.dateFormat(item.create_time)
                  item.update_time_str = this.$tools.dateFormat(item.update_time)
                });

              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['出款时间', '用户ID', '出款类型', '出款金额', '出款理由', '操作人', '订单号', '订单状态','返回信息' ,'代付通道','完成时间'],
      ];
      listData.forEach(item=>{
        data.push([item.create_time_str, item.userid, item.disbursement_type_str, item.disbursement_amount, item.disbursement_remark, item.create_user, item.merchantorderid, item.statusStr, item.result_msg, item.payment_channel_code, item.update_time]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '人工出款记录');
      XLSX.writeFile(wb, '人工出款记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
    this.initShowViewData();
  },
};
</script>

<style lang="scss" scoped></style>
