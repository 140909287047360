<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="标题">
          <el-input clearable  placeholder="标题" v-model="queryParams.title"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle"><span>重置</span>
          </el-button>
          <el-button @click="addHandle">新增</el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column prop="title" label="标题" align="center"></el-table-column>
      <el-table-column prop="details" label="描述" align="center"></el-table-column>

      <el-table-column prop="picture" label="图片url" align="center"></el-table-column>
      <el-table-column prop="phonePicture" label="手机图片url" align="center"></el-table-column>

<!--      <el-table-column prop="picture" label="图片url" align="center">-->
<!--        <template #default="scope">-->
<!--          <img :src="$tools.cdn+scope.row.picture" style="height: 50px;" />-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column prop="phonePicture" label="手机图片url" align="center">-->
<!--      <template #default="scope">-->
<!--        <img :src="$tools.cdn+scope.row.phonePicture" style="height: 50px;" />-->
<!--      </template>-->
<!--    </el-table-column>-->

      <el-table-column prop="url" label="跳转路径" align="center"></el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template #default="scope">
          <el-button type="primary" size="small" @click="editHandle(scope.$index)">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">

      <el-form-item label="标题" prop="title">
        <el-input v-model="item.title" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="描述" prop="details">
        <el-input v-model="item.details" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="web端" prop="URL">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="item.URL"></UploadImage>
      </el-form-item>

      <el-form-item label="移动端" prop="URL1">
        <UploadImage @onSuccess="handleAvatarSuccess1" type="game" :url="item.URL1"></UploadImage>
      </el-form-item>



      <el-form-item label="跳转路径" prop="url">
        <el-input v-model="item.url" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="confirmHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'
export default {
  components: {UploadImage},
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pub_platform_banner',
      queryParams: {},
      item: {},
      status: [
        {label: '指定用户', value: 0},
        {label: '所有用户', value: 1},
        {label: '限定VIP级别', value: 2},
      ],
      isEdit: false,
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        details: [
          {required: true, message: '请选择时间', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 100 个字符', trigger: 'blur'}
        ],
        URL: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],
        URL1: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],

      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm('确定要删除么?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.isEditDialog = true;
      this.dialogFormVisible = true;
    },
    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.tableData = response.data.list;
            this.tableData.forEach((itme) => {
              itme.URL = itme.picture?this.$tools.cdn+itme.picture:''
              itme.URL1 = itme.phonePicture?this.$tools.cdn+itme.phonePicture:''
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: this.cmd,
        action: "delete",
        data: {
          paramVO: {
            id,
          },
        },
      };
      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    /**
     * 图片上传成功回调
     * @param {*} res
     * @param {*} file
     */
    handleAvatarSuccess(data) {
      this.item.picture = data.file_url;
      this.item.URL = data.url;
    },

    /**
     * 图片上传成功回调
     * @param {*} res
     * @param {*} file
     */
    handleAvatarSuccess1(data) {
      this.item.phonePicture = data.file_url;
      this.item.URL1 = data.url;
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
