<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true" :model="queryParams">
        <el-form-item label="用户ID" clearable prop="userid">
          <el-input
              v-model="queryParams.userid"
              class="select1"
              placeholder="请输入用户ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" clearable prop="phone">
          <el-input
              v-model="queryParams.phone"
              class="select1"
              placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" clearable prop="phone">
          <el-input
              v-model="queryParams.email"
              class="select1"
              placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="CPF" clearable prop="customer_cert">
          <el-input
              v-model="queryParams.customer_cert"
              class="select1"
              placeholder="请输入CPF"
          ></el-input>
        </el-form-item>
        <el-form-item label="邀请人" clearable prop="source_user">
          <el-input
              v-model="queryParams.source_user"
              class="select1"
              placeholder="请输入邀请人"
          ></el-input>
        </el-form-item>
        <el-form-item label="输赢：">
          <el-select placeholder="请选择" clearable v-model="queryParams.win_lose">
            <el-option
                v-for="(item, index) in win_lose"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="媒体：">
          <el-select placeholder="请选择媒体" clearable v-model="queryParams.promote_platform_id">
            <el-option
                v-for="(item, index) in platforms"
                :key="index"
                :label="item.nameCn"
                :value="item.promotePlatformId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告：">
          <el-select placeholder="请选择广告" clearable v-model="queryParams.promote_ad_id">
            <el-option
                v-for="(item, index) in adList"
                :key="index"
                :label="item.nameCn"
                :value="item.promoteAdId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属渠道：">
          <el-select placeholder="请选择渠道" clearable v-model="queryParams.source_channe_id">
            <el-option
                v-for="(item, index) in source_channe"
                :key="index"
                :label="item.source"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="风险等级：">
          <el-select placeholder="请选择风险等级" clearable v-model="queryParams.risk_level">
            <el-option
                v-for="(item, index) in risk_level_arr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="vip级别：">
          <el-select placeholder="请选择vip级别" clearable v-model="queryParams.vip_level">
            <el-option
                v-for="(item, index) in vip_level_arr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select placeholder="请选择状态" clearable v-model="queryParams.status">
            <el-option
                v-for="(item, index) in status"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钱包余额">
          <el-col :span="11">
            <el-input v-model="queryParams.min_coins" placeholder="最小金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_coins" placeholder="最大金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="现金余额">
          <el-col :span="11">
            <el-input v-model="queryParams.min_real_coins" placeholder="最小金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_real_coins" placeholder="最大金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="奖金余额">
          <el-col :span="11">
            <el-input v-model="queryParams.min_coins_give" placeholder="最小金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_coins_give" placeholder="最大金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="历史存款">
          <el-col :span="11">
            <el-input v-model="queryParams.min_recharge" placeholder="最小金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_recharge" placeholder="最大金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="历史提款">
          <el-col :span="11">
            <el-input v-model="queryParams.min_withdrawal" placeholder="最小金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_withdrawal" placeholder="最大金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
              clearable
              v-model="queryParams.register_time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="最后登录时间">
          <el-date-picker
              clearable
              v-model="queryParams.last_login_time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>

    </el-row>

    <br/>

    <el-table class="el-table" :data="tableData" borader>
      <el-table-column label="ID">
        <template v-slot="{row}">
          <router-link :to="'/user-detail/'+row.userid">
            <span style="text-decoration:underline;cursor: pointer;">{{ row.userid }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="用户名"></el-table-column>
      <el-table-column label="VIP级别">
        <template v-slot="{row}">
          {{ row.vip_level_name }}
        </template>
      </el-table-column>
      <el-table-column label="现金余额">
        <template v-slot="{row}">
          {{ (row.coins - row.coins_give).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column label="奖金余额">
        <template v-slot="{row}">
          {{ row.coins_give }}
        </template>
      </el-table-column>
<!--      <el-table-column label="可提现金额">-->
<!--        <template v-slot="{row}">-->
<!--          {{ row.withdrawable }}-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column>
        <template v-slot:header>
          <div>存钱罐</div>
          <div>可提金额</div>
        </template>
        <template v-slot:default="{row}">
          {{ row.cofrinho_amount }}
        </template>
      </el-table-column>
      <el-table-column label="历史存款">
        <template v-slot="{row}">
          {{ row.recharge }}
        </template>
      </el-table-column>
      <el-table-column label="历史提款">
        <template v-slot="{row}">
          {{ row.withdrawal }}
        </template>
      </el-table-column>
      <el-table-column prop="win_lose_diff_sum" label="总输赢"></el-table-column>
      <el-table-column prop="source_user" label="邀请人" @click="userdetails(scope.row)">
        <template v-slot="{row}">

          <div class="blue-font-color" v-if="row.source_user===0">--</div>
          <div class="blue-font-color" v-else @click="userdetails(row.source_user)"
               style="text-decoration:underline;cursor: pointer;">{{ row.source_user }}
          </div>

        </template>
      </el-table-column>
      <el-table-column prop="promote_platform_name" label="媒体">
        <template v-slot="{row}">
          {{ row.promote_platform_name ? row.promote_platform_name : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="promote_acount_name" label="账号">
        <template v-slot="{row}">
          {{ row.promote_acount_name ? row.promote_acount_name : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="promote_ad_name" label="广告">
        <template v-slot="{row}">
          {{ row.promote_ad_name ? row.promote_ad_name : '--' }}
        </template>
      </el-table-column>
      <!--      <el-table-column prop="agent" label="归属代理">&#45;&#45;</el-table-column>-->
      <el-table-column prop="source_channel" label="归属渠道"></el-table-column>

      <el-table-column prop="risk_level_exp" label="风险等级">
        <template v-slot="{row}">
          <span @click="showUserRisk(row.userid)" v-if="row.risk_level>=6" style="color:red;text-decoration:underline;cursor: pointer;">{{ row.risk_level_exp }}</span>
          <span @click="showUserRisk(row.userid)" v-else style="text-decoration:underline;cursor: pointer;">{{ row.risk_level_exp }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status===1">正常</template>
          <template v-if="row.status===2">封号</template>
          <template v-if="row.status===3">禁提</template>
          <template v-if="row.status===4">掉级</template>
          <template v-if="row.status===5">低活</template>
          <template v-if="row.status===6">冻结</template>
        </template>
      </el-table-column>
      <el-table-column label="最后登录时间">
        <template v-slot="{row}">
          {{ row.last_login_time ? row.last_login_time : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="register_time" label="注册时间">
        <template v-slot="{row}">
          {{ row.register_time ? row.register_time : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="optins" fixed="right" align="center" label="操作" min-width="140">
        <template v-slot="{row}">
          <template v-if="row.status===1">
            <el-button type="primary" size="small" @click="changeUserStatusWithReason(row.userid,2, '封号')">封号
            </el-button>
            <el-button type="primary" size="small" @click="changeUserStatusWithReason(row.userid,3, '禁出')">禁出
            </el-button>
            <el-button type="primary" size="small" @click="changeUserStatusWithReason(row.userid,6, '冻结')">冻结
            </el-button>
          </template>
          <template v-if="row.status===2">
            <el-button type="primary" size="small" @click="changeUserStatus(row.userid,1, '解封')">解封</el-button>
            <el-button type="info" size="small" disabled>禁出</el-button>
            <el-button type="info" size="small" disabled>解冻</el-button>
          </template>
          <template v-if="row.status===3">
            <el-button type="info" size="small" disabled>封号</el-button>
            <el-button type="primary" size="small" @click="changeUserStatus(row.userid,1, '解禁')">解禁</el-button>
            <el-button type="info" size="small" disabled>解冻</el-button>
          </template>
          <template v-if="row.status===6">
            <el-button type="info" size="small" disabled>封号</el-button>
            <el-button type="info" size="small" disabled>解禁</el-button>
            <el-button type="primary" size="small" @click="changeUserStatus(row.userid,1, '解冻')">解冻</el-button>
          </template>
          <template v-if="![1,2,3,6].includes(row.status)">
            <el-button type="info" size="small" disabled>封号</el-button>
            <el-button type="info" size="small" disabled>禁出</el-button>
            <el-button type="info" size="small" disabled>解冻</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="item" ref="rowForm">
      <el-form-item label="输入操作理由：">
        <el-input v-model="remark" style="width:80%;"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="showRisk"
      title="用户风险系数"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-row style="padding-bottom: 20px">
      <el-col :span="4">用户id</el-col>
      <el-col :span="4">{{ userid_s }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="12">风险类别</el-col>
      <el-col :span="6">风险系数</el-col>
      <el-col :span="6">风险判定</el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">提存比：{{ user_risk.withdrawals_recharge_rate }}</el-col>
      <el-col :span="6">{{ user_risk.withdrawals_recharge_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.withdrawals_recharge_coefficient==0">无风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>0 && user_risk.withdrawals_recharge_coefficient<=5">低风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>5 && user_risk.withdrawals_recharge_coefficient<=9">中高风险</span>
        <span
            v-else-if="user_risk.withdrawals_recharge_coefficient>9 && user_risk.withdrawals_recharge_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">关联账号数：{{ user_risk.ip_user_count }}</el-col>
      <el-col :span="6">{{ user_risk.ip_user_count_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.ip_user_count_coefficient==0">无风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>0 && user_risk.ip_user_count_coefficient<=5">低风险</span>
        <span
            v-else-if="user_risk.ip_user_count_coefficient>5 && user_risk.ip_user_count_coefficient<=9">中高风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>9 && user_risk.ip_user_count_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:20倍(*{{ user_risk.win_rate_20 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_20_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_20_coefficient==0">无风险</span>
        <span v-else>低风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:50倍(*{{ user_risk.win_rate_50 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_50_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_50_coefficient==0">无风险</span>
        <span v-else>中风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:100倍(*{{ user_risk.win_rate_100 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_100_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_100_coefficient==0">无风险</span>
        <span v-else>高风险</span>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showRisk=false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        min_coins: "",
        max_coins: "",
        min_real_coins: "",
        max_real_coins: "",
        min_coins_give: "",
        max_coins_give: "",
        min_bonus: "",
        max_bonus: "",
        min_recharge: "",
        max_recharge: "",
        min_withdrawal: "",
        max_withdrawal: "",
        customer_cert: "",
        promote_platform_id: null,
        promote_ad_id: null,
      },
      dialogVisible: false,
      dialogTitle: "",
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      actionUserId: null,
      actionStatus: null,
      remark: '',
      source_channe: [],
      win_lose: [
        {label: '正', value: 1},
        {label: '负', value: 0},
      ],
      risk_level_arr: [
        {label: '无风险', value: 0},
        {label: '低风险', value: 1},
        {label: '中高风险', value: 6},
        {label: '高风险', value: 10},
        {label: '极高风险', value: 15},
      ],
      status: [
        {label: '正常', value: 1},
        {label: '封号', value: 2},
        {label: '禁提', value: 3},
        {label: '掉级', value: 4},
        {label: '低活', value: 5},
        {label: '冻结', value: 6}
      ],
      //Unranked 、Bronze、Silver、Gold、Platinum 、Diamond、Crown
      vip_level_arr: [
        {label: 'Comum', value: 0},
        {label: 'Bronze', value: 1},
        {label: 'Prata', value: 2},
        {label: 'Ouro', value: 3},
        {label: 'Platina', value: 4},
        {label: 'Diamante', value: 5},
        {label: 'Coroa', value: 6},
      ],
      status_arr: [
        {label: '是', value: 3},
        {label: '否', value: ''},
      ],
      showRisk: false,
      user_risk: null,
      userid_s: null,
      platforms: [],
      adList: [],
    };
  },
  mounted() {
    this.queryPlatformsList();
    this.queryadList();
    this.queryData();
    this.sourceChanne();
  },
  methods: {
    queryadList() {
      const data = {
        cmd: "pub_promote_ad",
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post("pub_promote_ad", data)
          .then((response) => {
            this.adList = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryPlatformsList() {
      const data = {
        cmd: "pub_promote_platform",
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post("pub_promote_platform", data)
          .then((response) => {
            this.platforms = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    showUserRisk(userid) {
      this.userid_s = userid;
      const data = {
        cmd: "user",
        action: "user_risk_coefficient",
        data: {
          paramVO: {},
          userid: userid
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.showRisk = true;
            this.user_risk = response.data.user_risk;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.register_time) {
        params.min_register_time = this.$tools.dateFormat(params.register_time[0], 2);
        params.max_register_time = this.$tools.dateFormat(params.register_time[1], 2);
        delete params.register_time
      }
      if (params.last_login_time) {
        params.min_last_login_time = this.$tools.dateFormat(params.last_login_time[0], 2);
        params.max_last_login_time = this.$tools.dateFormat(params.last_login_time[1], 2);
        delete params.last_login_time
      }
      const data = {
        cmd: "user",
        action: "query_user_list",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.user_list;
            this.tableData.forEach((item) => {
              item.risk_level_exp = this.$tools.getRiskLevelExp(item.risk_level)
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    sourceChanne() {
      const data = {
        cmd: "source_channel",
        action: "list",
        data: {
          paramVO: {},
        },
      };
      this.$http
          .post("source_channel", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.source_channe = response.data.source_channel_list
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    changeUserStatusWithReason(id, status, title) {
      this.$confirm("确定要" + title + "么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.actionUserId = id;
            this.actionStatus = status;
            //弹框信息
            if (title == '封号') {
              this.dialogTitle = '是否确定封号，该用户账号将无法登录平台';
            } else if (title == '禁出') {
              this.dialogTitle = '提示：设置禁出后，用户将无法发起提款申请。';
            }
            //
            this.dialogVisible = true;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    resetDenyData() {
      this.actionUserId = null;
      this.actionStatus = null;
      this.remark = '';
    },
    dislogCancelHandle() {
      this.resetDenyData();
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      if (this.remark == '') {
        this.$message({
          type: "info",
          message: "请填写理由",
        });
        return;
      }
      this.userStatus(this.actionUserId, this.actionStatus, this.remark);
    },
    changeUserStatus(id, status, title) {
      this.$confirm("确定要" + title + "么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.userStatus(id, status, title);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    userStatus(id, status, remark) {
      console.log([id, status])
      const data = {
        cmd: "user",
        action: "user_status",
        data: {
          paramVO: {},
          "status": status,
          "remark": remark,
          "userid": id
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dislogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    userdetails(row) {
      this.$router.push(`/user-detail/${row}`)
    },
  }
};
</script>

<style lang="scss" scoped>
.riskDiv {
  margin-top: 10px;
  //border: #0d1717 1px dashed;
}
</style>
<style>
@media screen and (max-width: 600px) {
  .el-dialog {
    width: 90% !important;
  }
}
@media screen and (min-width: 600px) {
  .el-dialog {
    width: 35% !important;
  }
}
</style>
