<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-input placeholder="用户ID" v-model="queryParams.userid"></el-input>
        </el-form-item>
        <el-form-item label="会员级别">
          <el-select placeholder="请选择" clearable style="width: 100%" v-model="queryParams.vipLevel">
            <el-option
                v-for="(item, index) in viplevels"
                :key="index"
                :label="item.vipLevelName"
                :value="item.vipLevel"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="bet-info" v-if="showViewData">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="4">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <br />
    <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column prop="userid" label="用户id" align="center"></el-table-column>
      <el-table-column prop="vipLevel" align="center" label="会员级别">
        <template v-slot="{row}">
          <span>{{ getVipLevelName(row.vipLevel) }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="orderDate" label="返利日期" align="center"></el-table-column>-->
      <el-table-column prop="orderDate" align="center" label="返利日期">
        <template v-slot="{row}">
          <span>{{ row.orderDate.substr(0,10)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="effectiveBetAmount" label="有效下注" align="center"></el-table-column>
      <el-table-column prop="rebateAmount" label="返利金额" align="center"></el-table-column>
<!--      <el-table-column prop="rebateRate" label="返水率" align="center"></el-table-column>-->
      <el-table-column label="返水率" align="center">
        <template v-slot="{row}">
          <span >{{this.$tools.formatRebate(row.rebateAmount,row.effectiveBetAmount,0,4,1000)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="receiveAmount" label="领取金额" align="center"></el-table-column>
      <el-table-column label="领取状态" width="200">
        <template v-slot="{row}">
          <span v-if="row.state===1">进行中</span>
          <span v-if="row.state===2">待领取</span>
          <span v-if="row.state===3">已领取</span>
          <span v-if="row.state===-1">已失效</span>
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>
</template>

<script>

import {getUserShowViewData} from "@/utils/user";

export default {
  data() {
    return {
      tableData: [],
      queryParams: {},
      types: [],
      showViewData: false,
      betInfos: [
        {label: '参与人数:', value: 0},
        {label: '领取人数:', value: 0},
        {label: '有效下注总量:', value: 0},
        {label: '总返利:', value: 0},
        {label: '总领取:', value: 0},
      ],
      viplevels: [],
      total: 0,
      pages: 0,
      page: 0,
      cmd: "pub_rebate_order",
      paramVO: {},
      item: {},
      payTimeRange: {},
      dates: {},
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
    this.queryviplevels();
    this.initShowViewData();
  },
  methods: {
    getVipLevelName(level) {
      const matchedLevel = this.viplevels.find(vip => vip.vipLevel === level);
      return matchedLevel ? matchedLevel.vipLevelName : '--';
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.tableData = response.data.list

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
            this.betInfos[0].value = response.data.sum.userid;
            this.betInfos[1].value =  response.data.sumstate.userid;
            this.betInfos[2].value = response.data.sum.effectiveBetAmount;
            this.betInfos[3].value =   response.data.sum.rebateAmount;
            this.betInfos[4].value = response.data.sumstate.receiveAmount;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async queryviplevels() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "pob_vip_level",
        action: "query_page",
        data: {
          paramVO: params,
          page: 0,
          pageSize: 100,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.viplevels = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },

  }
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}

</style>
