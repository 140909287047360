<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="4.5">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
      <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px" >
        <el-col v-for="info in background" :key="info.label" :span="12">
          <div>{{ info.label }} {{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <br/>
    <el-table :data="tableData">
      <el-table-column prop="begin_time" label="登录时间"></el-table-column>
      <el-table-column prop="type_desc" label="登录类型"></el-table-column>
      <el-table-column prop="device" label="登录平台"></el-table-column>
      <el-table-column prop="login_device" label="设备型号"></el-table-column>
      <el-table-column prop="client_version" label="系统版本"></el-table-column>
      <el-table-column prop="install_package" label="安装包"></el-table-column>
      <el-table-column prop="" label="版本号"></el-table-column>
      <el-table-column prop="ip_home" label="登录IP"></el-table-column>
      <el-table-column prop="location" label="IP归属地"></el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="supOrder(scope.row.id)">关联IP查询</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>

  <el-dialog
      v-model="dialogVisible"
      title="关联ip查询"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="IP地址">
          {{ipUserInfo.ip}}
        </el-form-item>
        <el-form-item label="IP关联用户数">
          {{ipUserInfo.ip_counts}}
        </el-form-item>
      </el-form>
    </el-row>
    <el-table :data="ipUserInfo.associated_ip_user_list">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column label="注册IP">
        <template v-slot="{row}">
          <template v-for="(item,index) in row.register_ips" :key="index">{{item}}<br/></template>
        </template>
      </el-table-column>
      <el-table-column label="登录IP">
        <template v-slot="{row}">
          <template v-for="(item,index) in row.login_ips" :key="index">{{item}}<br/></template>
        </template>
      </el-table-column>
      <el-table-column label="存款IP">
        <template v-slot="{row}">
          <template v-for="(item,index) in row.pay_ips" :key="index">{{item}}<br/></template>
        </template>
      </el-table-column>
      <el-table-column label="提款IP">
        <template v-slot="{row}">
          <template v-for="(item,index) in row.payout_ips" :key="index">{{item}}<br/></template>
        </template>
      </el-table-column>
      <el-table-column label="下注IP"></el-table-column>
      <el-table-column label="注销IP">
        <template v-slot="{row}">
          <template v-for="(item,index) in row.out_ips" :key="index">{{item}}<br/></template>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogOkHandle">关闭</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      item: {...defaultItem},
      queryParams: {},
      status: 1,
      betInfos: [
        {label: '登录天数:', value: 10},
        {label: '登录次数:', value: 10},
        {label: '登录时长:', value: 10},
        {label: '用户IP数:', value: 10},
        {label: '常用平台:', value: 'Android:80%｜IOS:19%|PC:1%'},
      ],
      background: [
        {label: '上线是时间段：', value: ''},
        {label: '下线是时间段：', value: ''}
      ],
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
      ipUserInfo:{
        ip: '',
        ip_counts: '',
        associated_ip_user_list:[]
      }
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()]
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance()
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.login_logs_start_time = this.$tools.dateFormat(params['time'][0], 2);
        params.login_logs_end_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
        let data = {
          cmd: "user",
          action: "user_login_logs",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("user", data)
            .then((response) => {
              console.log("response.data", response.data);
              this.tableData = response.data.login_logs;
              this.page = response.data.page;
              this.pages = response.data.pages;
              this.total = response.data.total;
            })
            .catch((error) => {
              console.log("error", error);
            });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
      const data = {
        cmd: "user_behavior",
        action: "query_login_behavior",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.betInfos[0].value = response.data.login_day;
            this.betInfos[1].value = response.data.login_count;
            this.betInfos[2].value = response.data.login_duration;
            this.betInfos[3].value = response.data.login_ip_count;
            let sepDot = '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
            let sepDot1 = '\u00A0\u00A0\u00A0';
            this.betInfos[4].value = 'Android:'+response.data.Android+sepDot1+'|'+sepDot1+'IOS:'+response.data.IOS+sepDot1+'|'+sepDot1+'PC:'+response.data.PC;

            this.background[0].value = ' 0点~6点：'+response.data.begin_time_0_6+sepDot +'6点~9点：'+response.data.begin_time_6_9+sepDot+' 9点~16点：'+response.data.begin_time_9_16+sepDot+' 16点~19点：'+response.data.begin_time_16_19+sepDot+' 19点~24点：'+response.data.begin_time_19_24

            this.background[1].value = ' 0点~6点：'+response.data.end_time_0_6+sepDot +'6点~9点：'+response.data.end_time_6_9+sepDot+' 9点~16点：'+response.data.end_time_9_16+sepDot+' 16点~19点：'+response.data.end_time_16_19+sepDot+' 19点~24点：'+response.data.end_time_19_24

          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    supOrder(logs_id) {
      let self = this;
      let data = {
        cmd: "user",
        action: "assoctiated_ip_user",
        data: {
          paramVO: {},
          logs_id: logs_id,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            self.ipUserInfo = response.data;
            self.dialogVisible = true;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogOkHandle(){
      this.dialogVisible = false;
    }
  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
