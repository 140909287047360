<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-input placeholder="用户ID" v-model="queryParams.userid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <div class="bet-info" v-if="showViewData">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in type == 1? betInfos:betInfos1" :key="info.label" :span="4">
          <div>{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>

  </div>

  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <el-table-column fixed prop="userid" label="用户" align="center"></el-table-column>
    <el-table-column fixed prop="counts" label="存款金额" align="center"></el-table-column>
<!--    <el-table-column fixed prop="rewardTotal" label="总金额" align="center"></el-table-column>-->
    <el-table-column fixed prop="rewardAmount" label="赠送金额" align="center"></el-table-column>
    <el-table-column fixed prop="rewardAmounts" label="赠送比例" align="center"></el-table-column>
    <el-table-column fixed v-if="type == 2" prop="bet_dama_amount" label="打码量" align="center"></el-table-column>
    <el-table-column fixed v-if="type == 2" prop="win_lose_diff" label="输赢差" align="center"></el-table-column>
    <el-table-column fixed v-if="type == 2" prop="receiveState" label="状态" align="center">
      <template #default="scope">
        <span v-if="scope.row.receiveState == 0">待领取</span>
        <span v-if="scope.row.receiveState == 1" >已领取</span>
        <span v-if="scope.row.receiveState == 2" >已失效</span>
      </template>
    </el-table-column>
    <el-table-column fixed v-if="type == 2" prop="rewardRank" label="排名" align="center"></el-table-column>
    <el-table-column fixed prop="createTime" label="赠送时间" align="center"></el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"/>

  <div style="text-align: left; padding: 5px" v-if="type == 2">
    <p>说明：完成投注任务，归类为 参与人数</p>
    <p>赠送比例：奖励金额(reward_amount)/ 奖金池总额(reward_total)</p>
  </div>
</template>


<script>


import {getUserShowViewData} from "@/utils/user";

export default {
  data() {
    return {
      tableData: [],
      queryParams: {},
      id: this.$route.params.id,
      type: this.$route.params.type,
      showViewData: false,
      payTimeRange: {},
      dates: {},
      proportion: "--",
      total: 0,
      pages: 0,
      page: 0,
      betInfos: [
        {label: '获赠人数:', value: 0},
        {label: '获赠总存款:', value: 0},
        {label: '总赠送:', value: 0},
        {label: '赠送比例:', value: 0},
      ],
      betInfos1: [
        {label: '参与人数:', value: 0},
        {label: '获赠人数:', value: 0},
        {label: '获赠总存款:', value: 0},
        {label: '总赠送金额:', value: 0},
        {label: '已领取金额:', value: 0},
      ],
      cmd: "cfg_daily_activity",
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  created() {
    this.queryData()
    this.initShowViewData();
  },
  methods: {
    async queryData(index = 0) {
      this.queryParams.status = 1
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      params.dailyActivityId = this.id
      let data = {}
      if (this.type == 1) {
        params.payState = 1
        data = {
          cmd: this.cmd,
          action: "query_list",
          data: {
            paramVO: params,
            page: index,
          }
        }
      } else {
        data = {
          cmd: this.cmd,
          action: "query_list",
          data: {
            paramVO: params,
            page: index,
          }
        }
      }

      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.tableData.forEach((itme) => {
              itme.rewardAmounts = this.$tools.formatRebate(itme.rewardAmount, itme.rewardTotal, 1, 4, 100)
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
            if (this.type == 1) {
              this.betInfos[0].value = response.data.sum.userid;
              this.betInfos[1].value = response.data.deposit;
              this.betInfos[2].value = response.data.sum.rewardAmount;
              this.betInfos[3].value = this.$tools.formatRebate(response.data.sum.rewardAmount, response.data.sum.rewardTotal, 1, 4, 100)
            } else {
              this.betInfos1[0].value = response.data.tional? response.data.tional : 0;
              this.betInfos1[1].value = response.data.draw.userid;
              this.betInfos1[2].value = response.data.deposit;
              this.betInfos1[3].value = response.data.sum.rewardAmount;
              this.betInfos1[4].value = response.data.draw.rewardAmount;
            }

          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
  }
}
</script>
<style>
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content: flex-end;

}

.btn1 {
  position: relative;
  left: 10px;
  top: 10px;
}

.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}

</style>
