<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="发送对象" prop="status">
          <el-select  clearable placeholder="请选择" style="width: 100%" v-model="queryParams.status">
            <el-option
                v-for="(item, index) in status"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle"><span>重置</span>
          </el-button>
            <el-button @click="addHandle">新增</el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column prop="sendTime" label="发送时间" align="center"></el-table-column>

      <el-table-column prop="status" align="center" label="发送对象">
        <template v-slot="{row}">
          <template v-if="row.status===  0">指定用户</template>
          <template v-if="row.status=== 1">所有用户</template>
          <template v-if="row.status=== 2">限定VIP级别</template>
        </template>
      </el-table-column>
      <el-table-column prop="userIdList" label="发送对象详情" align="center"></el-table-column>
      <el-table-column prop="" label="icon">
        <template #default="scope">
          <img :src="$tools.cdn+scope.row.cover_images" style="width: 50px;" />
        </template>
      </el-table-column>
      <el-table-column prop="title" label="邮件主体" align="center"></el-table-column>
      <el-table-column prop="mailDetails" label="邮件详情" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="userid" label="操作人" align="center"></el-table-column>
     <el-table-column prop="" label="操作" align="center">
        <template #default="scope">
          <el-button type="primary" size="small" @click="editHandle(scope.$index)">编辑</el-button>
          <el-button type="danger" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">


      <el-form-item label="开始时间" prop="sendTime">
        <el-date-picker
            v-model="item.sendTime"
            clearable
            style="width: 100%"
            type="datetime"
            placeholder="选择日期时间"
        />
      </el-form-item>

      <el-form-item label="发送对象" prop="status" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.status">
          <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="searchAdd" v-if="item.status === 0">
        <el-input placeholder="请输入用户ID" v-model="addUserId" style="width: 70%;"></el-input>
        <el-button type="primary" @click="getUserInfo()" style="position:absolute;right:0;">
          <span>查询并添加</span>
        </el-button>
        <br/>
      </el-form-item>

      <el-form-item  v-if="item.status === 0" label="对象详情" prop="details">
        <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
          {{tag}}
        </el-tag>
      </el-form-item>
<!--      <el-form-item  v-if="item.status === 0" label="对象详情" prop="details">-->
<!--        <el-input type="number"  v-model="item.details" placeholder="请输入 用逗号隔开id"></el-input>-->
<!--      </el-form-item>-->

      <el-form-item  v-if="item.status === 2" label="最小VIP等级" prop="min">
        <el-input type="number"  v-model="item.min" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item  v-if="item.status === 2" label="最大VIP等级" prop="max">
        <el-input  type="number" v-model="item.max" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="邮件主体" prop="title">
        <el-input v-model="item.title" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="封面图：">
        <UploadImage @onSuccess="handleCoverImagesSuccess" type="game_provider" :url="item.cover_images_full"></UploadImage>
      </el-form-item>

      <el-form-item label="邮件详情" >
        <el-input
            v-model="item.mailDetails"
            rows="5"
            type="textarea"
            maxlength="1000"
            placeholder="详情"
            autocomplete="off"
            show-word-limit
        />
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="confirmHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from "@/components/UploadImage/index.vue";

export default {
  components: {UploadImage},
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      dynamicTags: [],
      cmd: 'pub_platform_mail',
      queryParams: {},
      addUserInfoBox: [],
      inputVisible: false,
      addUserId: "",
      item: {},
      status: [
        {label: '指定用户', value: 0},
        {label: '所有用户', value: 1},
        {label: '限定VIP级别', value: 2},
      ],
      isEdit: false,
      rules: {
        sendTime1: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        sendTime: [
          {required: true, message: '请选择时间', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择发送对象', trigger: 'blur'},
        ],
        details: [
          {required: true, message: '请输入对象详情', trigger: 'blur'},
        ],

        min: [
          {required: true, message: '请输入等级', trigger: 'blur'},
        ],

        max: [
          {required: true, message: '请输入等级', trigger: 'blur'},
        ],

        title: [
          {required: true, message: '请输入邮件主体', trigger: 'blur'},
        ],
        mailDetails: [
          {required: true, message: '请输入邮件详情', trigger: 'blur'},
        ],
      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.dynamicTags = [];
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm('确定要删除么?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.isEditDialog = true;
      this.dialogFormVisible = true;
    },

    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      if (this.item.status === 0){
        this.item.details = this.dynamicTags
      }
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.tableData.forEach((item) => {
              this.dynamicTags = item.userIdList;
              item.provider_logo_full = item.provider_logo?this.$tools.cdn+item.provider_logo:'';
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: this.cmd,
        action: "delete",
        data: {
          paramVO: {
            id,
          },
        },
      };
      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    handleCoverImagesSuccess(data){
      this.item.cover_images = data.file_url;
      this.item.cover_images_full = data.url;
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    getUserInfo() {
      if (!this.addUserId || this.addUserId == "") {
        this.$message({
          type: "info",
          message: "请输入用户ID",
        });
        return;
      }
      const data = {
        cmd: "user",
        action: "query_user",
        data: {
          paramVO: {},
          userid: this.addUserId,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            if (!response.data.user_data?.user_status_info || response.data.user_data?.user_status_info == null) {
              this.$message({
                type: "info",
                message: "用户不存在",
              });
              this.addUserInfoBox = [];
              return;
            }
            //用户id
            this.handleInputConfirm(response.data.user_data.user_status_info.userid)
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm(value) {
      if (value) {
        this.dynamicTags.push(value);
      }
      this.dynamicTags= this.dynamicTags.filter((item, index,self)=>{
        return self.indexOf(item)===index
      })
      this.inputVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
