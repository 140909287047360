<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="游戏ID">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.gameId"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名称">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.gameNameEn"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏厂商">
          <el-select placeholder="请选择" clearable v-model="queryParams.gameProvider">
            <el-option
                v-for="(provider, index) in gameProviderList"
                :key="index"
                :label="provider.providerName"
                :value="provider.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏分类">
          <el-select placeholder="请选择" clearable v-model="queryParams.gameCategory">
            <el-option
                v-for="(category, index) in gameCategoryList"
                :key="index"
                :label="category.categoryName"
                :value="category.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏标签">
          <el-select placeholder="请选择游戏标签" clearable v-model="queryParams.gameTag">
            <el-option
                v-for="(item, index) in gameTagsList"
                :key="index"
                :label="item.alias_name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="产商游戏标识">-->
<!--          <el-input-->
<!--              clearable-->
<!--              placeholder="请输入"-->
<!--              v-model="queryParams.providerTag"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="产商标识">-->
<!--          <el-input clearable placeholder="请输入"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="游戏状态">
          <el-select placeholder="请选择" clearable v-model="queryParams.status">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="下架" :value="0"></el-option>
            <el-option label="维护" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上线时间：">
          <el-date-picker
              v-model="queryParams.publishTime"
              type="datetime"
              placeholder="请选择"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br/>

    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="addNewHandle">
          <el-icon>
            <Plus/>
          </el-icon>
          <span>新增</span>
        </el-button>
        <el-button @click="batchDeleteHandle">
          <el-icon>
            <DeleteFilled/>
          </el-icon>
          <span>删除</span>
        </el-button>
      </el-row>

      <el-table :data="tableData" @selection-change="handleSelected">
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="gameId" label="游戏ID"></el-table-column>
        <el-table-column prop="gameNameEn" label="游戏名称"></el-table-column>
        <el-table-column prop="imageUrl" label="游戏封面图">
          <template #default="scope">
            <img :src="$tools.cdn+scope.row.imageUrl" style="width: 50px;"/>
          </template>
        </el-table-column>
        <el-table-column prop="gameCategory" label="游戏类别"></el-table-column>
        <el-table-column prop="gameTagname" label="游戏标签"></el-table-column>
        <el-table-column prop="gameProvider" label="游戏厂商"></el-table-column>
        <el-table-column prop="rtp" label="rtp"></el-table-column>
        <el-table-column prop="hotBet" label="下注热度"></el-table-column>
        <el-table-column prop="hotInit" label="热度值"></el-table-column>
        <el-table-column prop="isNewStr" label="新游标记"></el-table-column>
        <el-table-column prop="isHotStr" label="推荐标记"></el-table-column>
        <el-table-column prop="publishTime" label="上线时间"></el-table-column>
        <!--        <el-table-column prop="none" label="游戏标识"></el-table-column>-->
        <!--        <el-table-column-->
        <!--          prop="gameProviderTag"-->
        <!--          label="厂商游戏标识"-->
        <!--        ></el-table-column>-->
        <!--        <el-table-column prop="providerTag" label="厂商标识"></el-table-column>-->
        <el-table-column prop="statusStr" label="游戏状态"></el-table-column>
        <el-table-column
            prop="demoDisabledStr"
            label="是否禁用demo"
        ></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                type="primary"
                size="small"
                @click="editHandle(scope.$index)"
            >编辑
            </el-button
            >
            <el-button
                type="primary"
                size="small"
                @click="deleteHandle(scope.$index)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :page-size="20"
          layout="total, prev, pager, next"
          :total="total"
          @current-change="pageChangeHandle"
      />

    </div>
  </div>

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @close="dialogCancelHandle"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="150px" style="width: 100%">
      <el-form-item label="是否关闭DEMO：">
        <el-radio-group v-model="item.demoDisabled">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="游戏ID：" :label-width="auto" prop="gameId" required>
        <el-input v-model="item.gameId" placeholder="请输入">
        </el-input>
      </el-form-item>
      <el-form-item label="游戏名称：" :label-width="auto" prop="gameNameEn" required>
        <el-input v-model="item.gameNameEn" placeholder="请输入">
          <!--          <template #append>填入游戏名称时，并判断是否重名（1）不重名时提示：可用（绿色字体）；2）重名时提示：标识重复，请重命名（红色字体）。可更改游戏标识</template>-->
        </el-input>
      </el-form-item>
      <div class="upload-row">
        <el-form-item label="* 上传封面：" label-width="150px">
          <UploadImage @onSuccess="handleImageUrlSuccess" type="game" :url="item.imageUrlFull"></UploadImage>
        </el-form-item>
        <el-form-item label="游戏启动图：" label-width="200px">
          <UploadImage @onSuccess="handlePosterUrlSuccess" type="game" :url="item.poster_url_full"></UploadImage>
        </el-form-item>
      </div>

      <el-form-item label="游戏厂商：" prop="gameProvider" required>
        <el-select placeholder="下拉选择，供应商名称走配置" v-model="item.gameProvider" style="width: 100%">
          <el-option
              v-for="(provider, index) in gameProviderList"
              :key="index"
              :label="provider.providerName"
              :value="provider.id"
          >
          </el-option>
        </el-select>
        <template #append>
          <el-button :icon="Search"/>
        </template>
      </el-form-item>

      <el-form-item label="游戏类别：" prop="gameCategory" required>
        <el-select placeholder="仅支持单选" v-model="item.gameCategory" style="width: 100%">
          <el-option
              v-for="(category, index) in gameCategoryList"
              :key="index"
              :label="category.categoryName"
              :value="category.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="游戏标签：" prop="gameTag">
        <el-select placeholder="支持多选" v-model="item.gameTag" style="width: 100%" multiple>
          <el-option
              v-for="(tag, index) in gameTagsList"
              :key="index"
              :label="tag.tag_name"
              :value="tag.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="游戏标识：">
        <el-input placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="厂商游戏标识：">
        <el-input
            v-model="item.gameProviderTag"
            placeholder="有些厂商必须输入，如JDB"
        ></el-input>
      </el-form-item>
      <el-form-item label="rtp：">
        <el-input v-model="item.rtp" placeholder="游戏rtp"></el-input>
      </el-form-item>
      <el-form-item label="下注热度：">
        <el-input v-model="item.hotBet" placeholder="默认。仅显示真实数据"></el-input>
      </el-form-item>
      <el-form-item label="热度值：">
        <el-input v-model="item.hotInit" placeholder="默认0。数值越高、排序越靠前"></el-input>
      </el-form-item>
      <el-form-item label="新游标记">
        <el-radio-group v-model="item.isNew">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
        <!--        <el-col :span="16">标记后会在游戏封面增加“NEW”icon</el-col>-->
        <el-col :span="16" class="radio-col">
          <span class="radio-text">提示：标记后会在游戏封面增加“NEW”icon</span>
        </el-col>
      </el-form-item>
      <el-form-item label="推荐标记">
        <el-radio-group v-model="item.isHot">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
        <el-col :span="16" class="radio-col">
          <span class="radio-text">提示：标记后会在游戏封面增加“HOT”icon</span>
        </el-col>
      </el-form-item>

      <el-form-item label="默认时间可修改：" prop="publishTime">
        <el-date-picker
            style="width: 100%"
            v-model="item.publishTime"
            type="datetime"
            :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <!--      <el-form-item label="厂商标识：">-->
      <!--        <el-input v-model="item.providerTag" placeholder="请输入"></el-input>-->
      <!--      </el-form-item>-->

      <el-form-item label="游戏状态：">
        <el-radio-group v-model="item.status">
          <el-radio label="1">正常</el-radio>
          <el-radio label="0">下架</el-radio>
          <el-radio label="2">维护</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
      title="提示"
      v-model="deleteGameDialogVisible"
      style="text-align: left"
      align-center
  >
    <el-row>
      <el-icon style="width: 20px; height: 20px">
        <WarningFilled/>
      </el-icon>
      <span>你确定要删除游戏吗？删除后无法恢复</span>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteGameDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="deleteGameDialogVisible = false">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'

export default {
  components: {UploadImage},
  data() {
    const defaultItem = {
      demoDisabled: "0",
      gameNameEn: "游戏名称",
      imageUrl: "/source/game/126/bg.png",
      gameProvider: "1001",
      gameCategory: "500",
      gameTag: "游戏标签",
      gameProviderTag: "厂商游戏标签",
      rtp: "0.00",
      hotBet: "100",
      hotInit: "100",
      isNew: "1",
      isHot: "1",
      publishTime: "2023-01-01 12:30:59",
      providerTag: "厂商标识",
      status: "0",
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      merchantTypes: [
        {value: 0, description: '最初的数据'},
        {value: 1, description: '出游旅游'},
      ],
      tableData: [],
      item: {...defaultItem},
      queryParams: {...defaultItem},
      dialogVisible: false,
      dialogTitle: "",
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      gameCategoryList: [],
      gameProviderList: [],
      gameTagsList: [],
      selectedRows: [],
      rules: {
        gameId: [
          {required: true, message: '请输入游戏ID', trigger: 'blur'}
        ],
        gameNameEn: [
          {required: true, message: '请输入游戏名称', trigger: 'blur'}
        ],
        gameProvider: [
          {required: true, message: '请选择游戏厂商', trigger: 'change'}
        ],
        gameCategory: [
          {required: true, message: '请选择游戏类别', trigger: 'change'}
        ],
        // gameTag: [
        //   { required: true, message: '请选择游戏标签', trigger: 'change' }
        // ],
        publishTime: [
          {required: true, message: '请选择上线时间', trigger: 'change'}
        ],
      },
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields();
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.isEditDialog) {
            this.updateData();
          } else {
            this.addData();
          }
          // this.dialogVisible = false;
        }
      })
    },
    addNewHandle() {
      this.isEditDialog = false;
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.item.demoDisabled = '1';
      this.item.gameNameLang = null;
      this.item.gameTag = [];
      this.dialogTitle = "新增游戏";
      this.dialogVisible = true;
      //当前时间
      this.item.publishTime = this.$tools.dateFormat(new Date().getTime());
      //
    },
    editHandle(index) {
      this.isEditDialog = true;
      this.dialogTitle = "编辑游戏";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      //标签回显,注意id必须要和列表id数据类型一致
      let gameTagBox = [];
      if (this.item.gameTag) {
        let gameTagArr = this.item.gameTag.split(',');
        for (let i of gameTagArr) {
          gameTagBox.push(Number(i));
        }
        this.item.gameTag = gameTagBox;

      }
      //
      this.dialogVisible = true;
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    addData() {
      let finalItem = JSON.parse(JSON.stringify(this.item));
      //标签数组转字符串
      finalItem.gameTag = finalItem.gameTag.join(',');
      //
      //默认时间
      if (finalItem.publishTime == "") {
        finalItem.publishTime = this.$tools.dateFormat(new Date());
      } else {
        finalItem.publishTime = this.$tools.dateFormat(finalItem.publishTime);
      }
      //
      const data = {
        cmd: "game_list",
        action: "add",
        data: {
          paramVO: finalItem,
        },
      };
      this.$http
          .post("game_list", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dialogCancelHandle();
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    updateData() {
      let finalItem = JSON.parse(JSON.stringify(this.item));
      //标签数组转字符串
      finalItem.gameTag = finalItem.gameTag.join(',');
      //
      const data = {
        cmd: "game_list",
        action: "update",
        data: {
          paramVO: finalItem,
        },
      };
      this.$http
          .post("game_list", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dialogCancelHandle();
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryData(index = 0) {
      // this.queryGameTagsData();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "game_list",
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("game_list", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.gameListVOList;
            this.tableData.forEach((game) => {
              if (game.gameTag) {
                var arr = game.gameTag.split(',').map(Number);
                const filter = this.gameTagsList.filter(itme => arr.includes(itme.id));
                if (filter.length > 0) {
                  let map = filter.map((obj) => {
                    return obj.tag_name;
                  }).join(",").split(',')
                  game.gameTagname = map
                }
              }
              if (game.isNew === 1) {
                game.isNewStr = "是";
              } else {
                game.isNewStr = "否";
              }
              game.isNew = game.isNew + "";
              if (game.isHot === 1) {
                game.isHotStr = "是";
              } else {
                game.isHotStr = "否";
              }
              game.isHot = game.isHot + "";

              if (game.status === 1) {
                game.statusStr = "正常";
              } else if (game.status === 0) {
                game.statusStr = "下架";
              } else if (game.status === 2) {
                game.statusStr = "维护";
              }
              game.status = game.status + "";

              if (game.demoDisabled === 1) {
                game.demoDisabledStr = "禁用";
              } else {
                game.demoDisabledStr = "不禁用";
              }
              game.demoDisabled = game.demoDisabled + "";
              game.imageUrlFull = game.imageUrl ? this.$tools.cdn + game.imageUrl : '';
              game.poster_url_full = game.poster_url ? this.$tools.cdn + game.poster_url : '';
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteData(id) {
      const data = {
        cmd: "game_list",
        action: "delete",
        data: {
          paramVO: {},
          ids: id,
        },
      };
      this.$http
          .post("game_list", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryGameCategoryData() {
      const data = {
        action: "query",
        data: {
          paramVO: {
            status: "1",
          },
        },
      };

      this.$http
          .post("game_category", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.gameCategoryList = response.data.gameCategoryVOList;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryGameProviderData() {
      const data = {
        action: "query",
        data: {
          paramVO: {
            status: "1",
          },
        },
      };

      this.$http
          .post("game_provider", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.gameProviderList = response.data.gameProviderVOList;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryGameTagsData() {
      const data = {
        action: "list",
        data: {
          paramVO: {
            status: "1",
          },
        },
      };

      this.$http
          .post("game_tags", data)
          .then((response) => {
            this.queryData();
            this.gameTagsList = response.data.gameTagsVOList;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleSelected(rows) {
      this.selectedRows = rows;
    },
    handleBatchDelete() {
      let finalRows = JSON.parse(JSON.stringify(this.selectedRows));
      finalRows = finalRows.map(row => row.id);
      let ids = finalRows.join(',');
      this.deleteData(ids);
    },
    batchDeleteHandle() {
      if (this.selectedRows.length <= 0) {
        this.$message({
          type: "info",
          message: "请选择删除对象",
        })
        return;
      }
      this.$confirm("你确定要删除游戏吗？删除后无法恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.handleBatchDelete();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    handleImageUrlSuccess(data) {
      this.item.imageUrl = data.file_url;
      this.item.imageUrlFull = data.url;
    },
    handlePosterUrlSuccess(data) {
      this.item.poster_url = data.file_url;
      this.item.poster_url_full = data.url;
    },
  },
  mounted() {
    this.queryGameTagsData();
    this.queryGameCategoryData();
    this.queryGameProviderData();
  },
};
</script>

<style lang="scss" scoped>
.radio-col {
  display: flex;
  align-items: center;
}

.radio-text {
  margin-left: 200px;
  color: #999;
}

.upload-row {
  display: flex;
  align-items: center;
}

.upload-row > el-form-item {
  flex: 1;
}

</style>
