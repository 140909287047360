<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="">
          <el-select placeholder="请选择媒体" clearable style="width: 100%" v-model="queryParams.promotePlatformId">
            <el-option
                v-for="(item, index) in platforms"
                :key="index"
                :label="item.nameCn"
                :value="item.promotePlatformId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryDatas()">搜索</el-button>
          <el-button @click="resetHandle"><span>重置</span>
          </el-button>
          <el-button @click="exportData">30日数据导出</el-button>

        </el-form-item>

      </el-form>
    </el-row>

    <br/>

      <el-button class="btn" >整体数据</el-button>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              highlight-current-row>
      <!--      <el-table-column prop="promoteDay" label="日期" align="center"></el-table-column>-->
      <el-table-column prop="promoteDay" align="center" label="日期">
        <template #default="scope">
          <span>{{ scope.row.promote + '~' + scope.row.promoteDay }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="media" label="媒体" align="center"></el-table-column>
<!--      <el-table-column prop="bank" label="账户" align="center"></el-table-column>-->
<!--      <el-table-column prop="name" label="广告名" align="center"></el-table-column>-->
      <el-table-column prop="promoteAmount" label="投流消耗" align="center"></el-table-column>
      <el-table-column prop="promoteShow" label="展示次数" align="center"></el-table-column>
      <el-table-column prop="promoteClick" label="点击广告" align="center"></el-table-column>
      <el-table-column prop="promoteOpen" label="点击平台" align="center"></el-table-column>
      <el-table-column prop="promoteRegister" label="注册人数" align="center"></el-table-column>
      <el-table-column prop="promoteFirstPay" label="首充人数" align="center"></el-table-column>

      <el-table-column prop="promoteRegister" align="center" label="充值率">
        <template v-slot="{row}">
          <span>{{ this.$tools.formatRebate(row.promoteFirstPay, row.promoteRegister, 1, 2, 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="promoteRegister" align="center" label="注册成本">
        <template v-slot="{row}">
          <span>{{ this.divisor(row.promoteAmount, row.promoteRegister) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="promoteRegister" align="center" label="首充成本">
        <template v-slot="{row}">
          <span>{{ this.divisor(row.promoteAmount, row.promoteFirstPay) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="promotePay" label="充值总额" align="center"></el-table-column>
      <el-table-column prop="promotePayOut" label="提款总额" align="center"></el-table-column>
      <el-table-column prop="promoteWinLoseDiff" label="输赢差" align="center"></el-table-column>
      <el-table-column prop="ARPU" align="center" label="ARPU">
        <template v-slot="{row}">
          <span>{{ this.divisor(row.promotePay, row.promoteRegister) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ARRPU" align="center" label="ARRPU">
        <template v-slot="{row}">
          <span>{{ this.divisor(row.promotePay, row.promoteFirstPay) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="promoteAgent" label="代理人数" align="center"></el-table-column>
      <el-table-column prop="promoteAgentCount" label="邀请人数" align="center"></el-table-column>
      <el-table-column prop="promoteAgentPayOut" label="代理提现" align="center"></el-table-column>

      <el-table-column label="day1盈利" align="center">
        <template v-slot="{ row }">
          <span>{{ row.daySumList[0] }}</span>
        </template>
      </el-table-column>

      <el-table-column label="day2盈利" align="center">
        <template v-slot="{ row }">
          <span>{{ this.sum(row.daySumList.slice(0, 2)) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="day3盈利" align="center">
        <template v-slot="{ row }">
          <span>{{ this.sum(row.daySumList.slice(0, 3))}}</span>
        </template>
      </el-table-column>

      <el-table-column label="day4盈利" align="center">
        <template v-slot="{ row }">
          <span>{{this.sum(row.daySumList.slice(0, 4))}}</span>
        </template>
      </el-table-column>

      <el-table-column label="day5盈利" align="center">
        <template v-slot="{ row }">
          <span>{{ this.sum(row.daySumList.slice(0, 5)) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="day6盈利" align="center">
        <template v-slot="{ row }">
          <span>{{  this.sum(row.daySumList.slice(0, 6)) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="day7盈利" align="center">
        <template v-slot="{ row }">
          <span>{{  this.sum(row.daySumList.slice(0, 7)) }}</span>
        </template>
      </el-table-column>


      <el-table-column label="ROI1" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(row.daySumList[0], row.promoteAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ROI2" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 2)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ROI3" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 3)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ROI4" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 4)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ROI5" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 5)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="ROI6" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 6)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="ROI7" align="center">
        <template v-slot="{row}">
          <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 7)), row.promoteAmount) }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
  <el-pagination
      background
      :page-size="5"
      :current-page="page"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />

  <br/>
  <el-button class="btn" >分日数据</el-button>
  <el-button  @click="exportData(1)"  align="right">30天数据导出</el-button>
  <div class="app-container" style="box-sizing: border-box"/>
  <el-table :data="tableDataList" :header-cell-style="{ 'text-align': 'center' }"
            element-loading-text="Loading"
            highlight-current-row>
    <el-table-column prop="promoteDay" label="日期" align="center"></el-table-column>
    <el-table-column prop="media" label="媒体" align="center"></el-table-column>
<!--    <el-table-column prop="bank" label="账户" align="center"></el-table-column>-->
<!--    <el-table-column prop="name" label="广告名" align="center"></el-table-column>-->
    <el-table-column prop="promoteAmount" label="投流消耗" align="center"></el-table-column>
    <el-table-column prop="promoteShow" label="展示次数" align="center"></el-table-column>
    <el-table-column prop="promoteClick" label="点击广告" align="center"></el-table-column>

<!--    <el-table-column prop="promoteAmount" label="投流消耗" align="center">-->
<!--      <template #default="{ row }">-->
<!--        <el-input-->
<!--            v-model="row.promoteAmount"-->
<!--            autocomplete="off"-->
<!--            size="small"-->
<!--            placeholder="请输入"-->
<!--        />-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column prop="promoteShow" label="展示次数" align="center">-->
<!--      <template #default="{ row }">-->
<!--        <el-input-->
<!--            v-model="row.promoteShow"-->
<!--            autocomplete="off"-->
<!--            size="small"-->
<!--            placeholder="请输入"-->
<!--        />-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column prop="promoteClick" label="点击广告" align="center">-->
<!--      <template #default="{ row }">-->
<!--        <el-input-->
<!--            v-model="row.promoteClick"-->
<!--            autocomplete="off"-->
<!--            size="small"-->
<!--            placeholder="请输入"-->
<!--        />-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column prop="promoteOpen" label="点击平台" align="center"></el-table-column>
    <el-table-column prop="promoteRegister" label="注册人数" align="center"></el-table-column>
    <el-table-column prop="promoteFirstPay" label="首充人数" align="center"></el-table-column>

    <el-table-column prop="promoteRegister" align="center" label="充值率">
      <template v-slot="{row}">
        <span>{{ this.$tools.formatRebate(row.promoteFirstPay, row.promoteRegister, 1, 2, 100) }}</span>
      </template>
    </el-table-column>

    <el-table-column prop="promoteRegister" align="center" label="注册成本">
      <template v-slot="{row}">
        <span>{{ this.divisor(row.promoteAmount, row.promoteRegister) }}</span>
      </template>
    </el-table-column>

    <el-table-column prop="promoteRegister" align="center" label="首充成本">
      <template v-slot="{row}">
        <span>{{ this.divisor(row.promoteAmount, row.promoteFirstPay) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="promotePay" label="充值总额" align="center"></el-table-column>
    <el-table-column prop="promotePayOut" label="提款总额" align="center"></el-table-column>
    <el-table-column prop="promoteWinLoseDiff" label="输赢差" align="center"></el-table-column>

    <el-table-column prop="ARPU" align="center" label="ARPU">
      <template v-slot="{row}">
        <span>{{ this.divisor(row.promotePay, row.promoteRegister) }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="ARRPU" align="center" label="ARRPU">
      <template v-slot="{row}">
        <span>{{ this.divisor(row.promotePay, row.promoteFirstPay) }}</span>
      </template>
    </el-table-column>

    <el-table-column prop="promoteAgent" label="代理人数" align="center"></el-table-column>
    <el-table-column prop="promoteAgentCount" label="邀请人数" align="center"></el-table-column>
    <el-table-column prop="promoteAgentPayOut" label="代理提现" align="center"></el-table-column>

    <el-table-column label="day1盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ row.daySumList[0] }}</span>
      </template>
    </el-table-column>

    <el-table-column label="day2盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ this.sum(row.daySumList.slice(0, 2)) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="day3盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ this.sum(row.daySumList.slice(0, 3))}}</span>
      </template>
    </el-table-column>

    <el-table-column label="day4盈利" align="center">
      <template v-slot="{ row }">
        <span>{{this.sum(row.daySumList.slice(0, 4))}}</span>
      </template>
    </el-table-column>

    <el-table-column label="day5盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ this.sum(row.daySumList.slice(0, 5)) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="day6盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ this.sum(row.daySumList.slice(0, 6)) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="day7盈利" align="center">
      <template v-slot="{ row }">
        <span>{{ this.sum(row.daySumList.slice(0, 7)) }}</span>
      </template>
    </el-table-column>


    <el-table-column label="ROI1" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(row.daySumList[0], row.promoteAmount) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="ROI2" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 2)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="ROI3" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 3)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="ROI4" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 4)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="ROI5" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 5)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="ROI6" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 6)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>

    <el-table-column label="ROI7" align="center">
      <template v-slot="{row}">
        <span>{{ this.calculate(this.sum(row.daySumList.slice(0, 7)), row.promoteAmount) }}</span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="5"
      :current-page="page1"
      layout="total,prev, pager, next, jumper"
      :total="total1"
      @current-change="pageChangeHandle1"
  />

</template>

<script>
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      tableData: [],
      tableDataList: [],
      platforms: [],
      ascount: [],
      ascountAd: [],
      page: 0,
      num:1,
      pages: 0,
      total1: 0,
      page1: 1,
      pages1: 0,
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pub_promote_placement',
      queryParams: {},
      payTimeRange: [],
      dates: {},
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
    this.queryData1();
    this.queryList();
  },
  methods: {

    queryDatas() {
      this.queryData();
      this.queryData1();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page_p",
        data: {
          paramVO: params,
          page: index,
          pageSize: 5,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page+1;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    pageChangeHandle1(num) {
      this.queryData1(num - 1);
    },
    async queryData1(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_day_p",
        data: {
          paramVO: params,
          page: index,
          pageSize: 5,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableDataList = response.data.list;
            this.page1 = response.data.page+1;
            this.pages1 = response.data.pages;
            this.total1 = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryDatas();
    },
    queryList() {
      const data = {
        cmd: "pub_promote_platform",
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post("pub_promote_platform", data)
          .then((response) => {
            this.platforms = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryLists() {
      const data = {
        cmd: "pub_promote_acount",
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post("pub_promote_acount", data)
          .then((response) => {
            this.ascount = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    queryListAd() {
      const data = {
        cmd: "pub_promote_ad",
        action: "query_list",
        data: {paramVO: {}}
      }
      this.$http
          .post("pub_promote_acount", data)
          .then((response) => {
            this.ascountAd = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    /**
     * 更新数据接口
     */
    updateData(index) {
      let item = JSON.parse(JSON.stringify(this.tableDataList[index]));
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: item,
        },
      };
      this.$http
          .post("pub_promote_placement", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData1();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    async exportData(type) {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      let action = 'query_page_p'
      if (type == 1){
         action = 'query_day_p'
      }
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        const data = {
          cmd: this.cmd,
          action: action,
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          }
        }
        await this.$http
            .post(this.cmd, data)
            .then((response) => {
              if (response.data.list.length !== 0) {
                listData = listData.concat(response.data.list);
                index++;
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['日期', '媒体', '账户', '广告名', '投流消耗', '展示次数', '点击广告', '点击平台', '注册人数', '首充人数','充值率','注册成本','首充成本','充值总额','提款总额','输赢差','ARPU', 'ARRPU', '代理人数', '邀请人数', '代理提现',
          'day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7', 'day8', 'day9', 'day10', 'day11', 'day12', 'day13', 'day14', 'day15', 'day16', 'day17', 'day18', 'day19', 'day20', 'day21', 'day22', 'day23', 'day24', 'day25', 'day26', 'day27', 'day28', 'day29', 'day30',
          'ROI1', 'ROI2', 'ROI3', 'ROI4', 'ROI5', 'ROI6', 'ROI7', 'ROI8', 'ROI9', 'ROI10', 'ROI11', 'ROI12', 'ROI13', 'ROI14', 'ROI15', 'ROI16', 'ROI17', 'ROI18', 'ROI19', 'ROI20', 'ROI21', 'ROI22', 'ROI23', 'ROI24', 'ROI25', 'ROI26', 'ROI27', 'ROI28', 'ROI29', 'ROI30'
        ],
      ];

      listData.forEach(row => {
        const day = []; // 创建一个新的临时数组来存储一行数据
        const newRow = []; // 创建一个新的临时数组来存储一行数据

        // 遍历row.daySumList的30个元素
        for (let i = 1; i < row.daySumList.length && i <= 30; i++) {
          day.push(this.sum(row.daySumList.slice(0, i)));
        }
        // 遍历row.daySumList的30个元素
        for (let i = 1; i <= row.daySumList.length && i <= 30; i++) {
          newRow.push(this.calculate(this.sum(row.daySumList.slice(0, i)), row.promoteAmount));
        }
        let promoteDay = row.promote + '~'+ row.promoteDay
         if (type == 1){
           promoteDay = row.promoteDay

         }
        data.push([
          promoteDay,
          row.media,
          row.bank,
          row.name,
          row.promoteAmount,
          row.promoteShow,
          row.promoteClick,
          row.promoteOpen,
          row.promoteRegister,
          row.promoteFirstPay,
          this.$tools.formatRebate(row.promoteFirstPay, row.promoteRegister, 1, 2, 100),
          this.divisor(row.promoteAmount, row.promoteRegister),
          this.divisor(row.promoteAmount, row.promoteFirstPay),
          row.promotePay,
          row.promotePayOut,
          row.promoteWinLoseDiff,
          this.divisor(row.promotePay, row.promoteRegister),
          this.divisor(row.promotePay, row.promoteFirstPay),
          row.promoteAgent,
          row.promoteAgentCount,
          row.promoteAgentPayOut,
            ...day,
            ...newRow,
        ]);

      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '推广30天数据');
      XLSX.writeFile(wb, '推广数据记录.xlsx');
    },


    calculate(a, b) {
      if (a === '' || b === '' || a === 0 || b === 0 || a === null || b === null) {
        return 0;
      }
      return ((a / b)* 100).toFixed(2)  + '%';
    },
    sum(arr) {
      var s = 0;
      arr.forEach(val => {
        s += val;
      })
      return s;
    },
    divisor(a, b) {
      if (a === '' || b === '' || a === 0 || b === 0 || a === null || b === null) {
        return 0;
      }
      return (a / b).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.btn{
  display: flex;
  justify-content:flex-end;
  text-align: left;
  position: relative;
  right: 10px;
  top: 1px;
  font-weight: bold;
  border: none; /* 添加此行以去除边框 */
  transition: none;
  &:hover {
    background-color: inherit !important;
    color: inherit !important;
  }
}
</style>
