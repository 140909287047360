<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="状态">
          <el-select placeholder="状态" style="width: 100%" v-model="queryParams.status" clearable>
            <el-option
                v-for="(item, index) in keeps"
                :key="index"
                :label="item.label"
                :value="item.value"

            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input clearable  placeholder="根据咨询页名称" v-model="queryParams.name"></el-input>
        </el-form-item>
        <el-form-item label="上线时间：">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="addItemHandle">
            <el-icon>
              <Plus/>
            </el-icon>
            <span>新增</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table
        :data="tableData"
        :header-cell-style="{ 'text-align': 'center' }"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row>
      <el-table-column prop="name" label="咨询页名称" align="center"></el-table-column>
      <el-table-column prop="content" label="咨询页地址" align="center"></el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status=== '1'">有效</template>
          <template v-if="row.status=== '0' ">无效</template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button  type="text"  size="small"  @click="itemEditHandle(scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
          <el-button v-if="scope.row.status === '1'" type="text" size="small" @click="forbidden(scope.$index)">关闭</el-button>
          <el-button v-else type="text" size="small" @click="open(scope.$index)">开启</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        :pager-count="pages"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >

    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="名称" prop="name" >
        <el-input v-model="item.name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="咨询页地址" prop="content" required>
        <el-input v-model="item.content" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="是否有效" prop="status" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.status">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

export default {
  data() {
    const defaultItem = {
      userid: undefined,
      dates: undefined
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      queryParams: {...defaultItem},
      dialogVisible: false,
      examine: false,
      dialogTitle: "",
      payTimeRange: undefined,
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      gameCategoryList: [],
      gameProviderList: [],
      cmd: "news",
      paramVO: {},
      item: {},
      dates: {},
      keeps: [
        {label: '有效', value: '1'},
        {label: '无效', value: '0'},
      ],
      data: {cmd: this.cmd, action: "", data: {paramVO: {}},},
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入咨询页地址', trigger: 'blur'},
        ],  status: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
      }
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },

     dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.queryData()
          this.dialogVisible = false;
        }
      })

    },

    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = JSON.parse(JSON.stringify(this.tableData[index]))
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    forbidden(index) {
      this.$confirm("确定要禁用吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
      this.item = JSON.parse(JSON.stringify(this.tableData[index]))
        this.item.status  = '0'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消禁用",
            });
          });
    },
    open(index) {
      this.$confirm("确定要开启吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = JSON.parse(JSON.stringify(this.tableData[index]))
        this.item.status  = '1'
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消开启",
            });
          });
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
