<template>
  <div>
    <el-upload
        style="
            border: 1px dashed red;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 178px;
            height: 178px;
          "
        action=""
        :show-file-list="false"
        :on-success="onSuccess"
        :before-upload="beforeUpload"
        :http-request="uploadFiles"
        :accept="accept"
    >
      <img v-if="fileData.url" :src="fileData.url" class="avatar" />
      <el-icon v-else style="width: 178px; height: 178px"><Plus /></el-icon>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'UploadImage',
  props: {
    accept: {
      type: String,
      default() {
        return '.png,.jpg,.jpeg,.webp'
      }
    },
    type: {
      type: String,
      default() {
        return ''
      }
    },
    url: {
      type: String,
      default() {
        return ''
      }
    },
  },
  data() {
    return {
      fileData:{},
    }
  },
  watch: {
    url: {
      immediate: true,
      deep: true,
      handler(value) {
        this.fileData.url = value;
      }
    }
  },
  methods: {
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png" || file.type === "image/webp";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG,JPEG,PNG,WEBP 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onSuccess(res, file) {
      console.log('u-res',res)
      console.log('u-file',file)
    },
    uploadFiles(param){
      const { file } = param;
      let data = {
        cmd: "upload",
        action: this.type,
        file: file,
      };
      this.$http
          .post("", data)
          .then((response) => {
            this.fileData = response.data;
            this.$emit('onSuccess', response.data)
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
