<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary">
            <el-icon><Plus /></el-icon>
            <span>新增</span>
          </el-button>
          <el-button type="primary">
            <el-icon><DeleteFilled /></el-icon>
            <span>删除</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column label="ID"></el-table-column>
      <el-table-column label="等级名称"></el-table-column>
      <el-table-column label="所需充值量"></el-table-column>
      <el-table-column label="升级礼盒ID"></el-table-column>
      <el-table-column label="等级"></el-table-column>
      <el-table-column label="下一等级"></el-table-column>
      <el-table-column label="当前等级升级条件"></el-table-column>
      <el-table-column label="下一等级升级条件"></el-table-column>
      <el-table-column label="升级到下一等级所需经验"></el-table-column>
      <el-table-column label="操作"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
