<template>
      <div class="d2">
    <el-button class="btn1" type="primary"   @click="addItemHandle">新增</el-button>
        </div>

  <el-row style="background-color: white; text-align: left; padding: 10px">
    <el-form :inline="true">
      <el-form-item label="路由名称">
        <el-input
            clearable
            placeholder="请输入"
            v-model="queryParams.name"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button @click="resetHandle">
          <el-icon>
            <Refresh/>
          </el-icon>
          <span>重置</span>
        </el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <br/>
  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <el-table-column fixed prop="id" label="id" align="center"></el-table-column>
    <el-table-column fixed prop="name" label="路由名称" align="center"></el-table-column>
    <el-table-column fixed prop="path" label="路径" align="center"></el-table-column>

    <el-table-column fixed prop="disabled" align="center" label="是否隐藏">
      <template v-slot="{row}">
        <template v-if="row.disabled=== 0">否</template>
        <template v-if="row.disabled=== 1 ">是</template>
      </template>
    </el-table-column>

<!--    <el-table-column fixed prop="disabled" label="是否隐藏" align="center"></el-table-column>-->

    <el-table-column fixed prop="node" label="父节点" align="center"></el-table-column>
    <el-table-column fixed prop="sort" label="排序" align="center"></el-table-column>
    <el-table-column fixed prop="filePath" label="文件路径" align="center"></el-table-column>
    <el-table-column prop="show" label="展示名称" align="center"></el-table-column>
    <el-table-column prop="icon" label="图标" align="center"></el-table-column>
    <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
      <template #default="scope">
        <el-button style="margin-top: 1px" type="text" size="small" @click="itemEditHandle(scope.$index)">编辑</el-button>
        <el-button  style="margin-top: 1px" type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
        <el-button  style="margin-top: 5px" type="text" size="small" @click="handlePhone(scope.row.id)">子菜单</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >

    <el-form :model="item" ref="rowForm" :rules="rules"  label-width="120px">

      <el-form-item label="路由名称" prop="name" >
        <el-input v-model="item.name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="路径" prop="path" >
        <el-input v-model="item.path" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="是否隐藏" prop="disabled" >
        <el-select placeholder="请选择" style="width: 100%" v-model="item.disabled">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

    <el-form-item label="排序" prop="sort" >
        <el-input type="number" v-model="item.sort" placeholder="请输入"></el-input>
      </el-form-item>


      <el-form-item label="文件路径">
        <el-input v-model="item.filePath" placeholder="请输入"></el-input>
      </el-form-item>
    <el-form-item label="展示名称">
        <el-input v-model="item.show" placeholder="请输入"></el-input>
      </el-form-item>
<!--      <el-form-item label="图标">-->
<!--        <el-input v-model="item.icon" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="图标" >
        <el-select placeholder="请选择" style="width: 100%" v-model="item.icon">
          <el-option
              v-for="(item, index) in iocns"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="KeepAlive" prop="keepAlive" >
        <el-select placeholder="请选择" style="width: 100%" v-model="item.keepAlive">
          <el-option
              v-for="(item, index) in KeepAlive"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="closeTab" prop="closeTab" >
        <el-select placeholder="请选择" style="width: 100%" v-model="item.closeTab">
          <el-option
              v-for="(item, index) in KeepAlive"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>



<!--    <el-form-item label="新增菜单参数" >-->
<!--        <el-input v-model="item.parameter" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="新增可控按钮">-->
<!--        <el-input v-model="item.button" placeholder="请输入"></el-input>-->
<!--      </el-form-item>-->

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  data() {
    return {
      keeps: [
        {label:'是',value:1},
        {label:'否',value:0},
      ],
      KeepAlive: [
        {label:'否',value:0},
        {label:'是',value:1},
      ],
      iocns: [
        {label:'加号',value:'Plus'},
        {label:'菱形',value:'House'},
        {label:'设置',value:'Setting'}
      ],
      tableData: [],
      total: 0,
      pages: 0,
      page: 0,
      item: {},
      selectedRow: {},
      queryParams: {},
      dialogVisible: false,
      dialogTitle: "",
      cmd: "pub_menus",
      data: {cmd: this.cmd, action: "query_page", data: {paramVO: {}},}
    }
  },
  created() {
    this.queryData()
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    async queryData(index = 0) {
      this.queryParams.node =0
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogVisible = false;
        }
      })
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updateData() {
      this.data.action = "update"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      this.item.node = 0
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "info",
              message: "删除成功!",
            });
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handlePhone(id) {
      this.$router.push(`/agency-systemMenus/${id}`)
    },
  }
}
</script>
<style>
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
