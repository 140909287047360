<template>
  <div class="d2">
    <el-button class="btn1" type="primary"   @click="addItemHandle">新增</el-button>
  </div>
  <el-row style="background-color: white; text-align: left; padding: 10px">
    <el-form :inline="true">
      <el-form-item label="名称">
        <el-input
            clearable
            placeholder="请输入"
            v-model="queryParams.name"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button @click="resetHandle">
          <el-icon>
            <Refresh/>
          </el-icon>
          <span>重置</span>
        </el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <el-table-column fixed prop="id" label="id" align="center"></el-table-column>
    <el-table-column fixed prop="name" label="角色名称" align="center"></el-table-column>
    <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
      <template #default="scope">
        <el-button style="margin-top: 5px" type="text" @click="itemEditHandle(scope.$index)">编辑</el-button>
        <el-button type="primary" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-drawer
      v-model="dialogVisible"
      :title="dialogTitle"
      :show-close="false"
      :before-close="handleClose"
      direction="rtl"
      size="400px"
      @opened="handleOpen"
  >
    <div style="padding: 5px">
      <el-form ref="rowForm" :model="item" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="item.name" autocomplete="off" :autofocus="true"  placeholder="请输入角色名"/>
        </el-form-item>
      </el-form>
      <el-tree
          ref="tree"
          :data="tree"
          show-checkbox
          default-expand-all
          node-key="id"
          highlight-current
          :props="props"
          @check-change="handleChange"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-drawer>


</template>

<script>


export default {
  name: 'SystemRole',
  data() {
    return {
      tableData: [],
      tree: [],
      props: Object.freeze({
        label: 'name',
        children: 'children'
      }),
      total: 0,
      pages: 0,
      page: 0,
      item: {},
      selectedCodes: [],
      // selectedCodes: new Set(),
      selectedRow: {},
      authorityTree: {},
      queryParams: {},
      dialogVisible: false,
      dialogTitle: "",
      cmd: "pub_role",
      data: {cmd: this.cmd, action: "query_page", data: {paramVO: {}},}
    }
  },
  created() {
    this.queryData()
    this.treeLisy()
  },
  methods: {
    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.selectedCodes = [];
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.selectedCodes.size === 0) {
            this.$message.warning('请选择权限')
            return
          }
          this.item.authorities =  Array.from(new Set(this.selectedCodes))
          this.item.authorityTree = this.tree
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          // // this.treeLisy()
          // this.queryData()
          this.dialogVisible = false;
        }
      })
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      // this.treeLisy(false)
      this.item.authorities = []
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      // this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.item = this.tableData[index];
      this.dialogVisible = true;
    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          }).finally( () => {
        this.queryData()
      }).catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          }).finally( () => {
        this.queryData()
      }).catch((error) => {
            console.log("error", error);
          });
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "info",
              message: "删除成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    treeLisy() {
      const data = {
        cmd: 'pub_menus',
        action: "list",
        data: {
          paramVO: {}
        }
      }
      this.$http
          .post('pub_menus', data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tree = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleOpen() {
      if (this.item.authorities) { // 编辑时填充权限树
        const refTree = this.$refs.tree
        // 过滤:只选择叶子节点, 父节点会自动选中
        const keys =  JSON.parse(this.item.authorities).filter(code => {
          return refTree.getNode(code)?.isLeaf
        })
        refTree.setCheckedKeys(keys)
      }
    },
    handleClose() {
      this.$refs.rowForm.resetFields()
      this.$refs.tree.setCheckedKeys([])
      this.selectedCodes = [];
      this.dialogVisible = false;
    },
    /**
     *
     * @param row {Authority}
     * @param currentChecked {boolean}
     * @param childChecked {boolean}
     */
    handleChange(row, currentChecked, childChecked) {
      row.checked = currentChecked || childChecked
      if (!row.children) {
        if (currentChecked) {
          this.selectedCodes.push(row.id)
        } else {
          this.selectedCodes = this.selectedCodes.filter(itme =>  itme !== row.id)
        }
      }
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
  }
}
</script>
<style>
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
