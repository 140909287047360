<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form ref="form" :inline="true" :rules="rules" :model="queryParams">
        <el-form-item label="用户ID" prop="userid">
          <el-input v-model="queryParams.userid" placeholder="请输入用户ID" clearable></el-input>
        </el-form-item>
        <el-form-item label="交易号" prop="transaction_id">
          <el-input v-model="queryParams.transaction_id" placeholder="请输入交易号" clearable></el-input>
        </el-form-item>
        <el-form-item label="下注金额">
          <el-col :span="11">
            <el-input v-model="queryParams.min_bet_amount" placeholder="开始金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_bet_amount" placeholder="结束金额" clearable></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="中奖金额">
          <el-col :span="11">
            <el-input v-model="queryParams.min_win_amount" placeholder="开始金额" clearable></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="11">
            <el-input v-model="queryParams.max_win_amount" placeholder="结束金额" clearable></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <br/>
    <el-table class="el-table" :data="tableData" borader>
      <el-table-column prop="create_time" label="创建时间" ></el-table-column>
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="game_id" label="游戏ID"></el-table-column>

      <el-table-column prop="parent_bet_id" label="回合ID"></el-table-column>
      <el-table-column prop="currency_code" label="货币ID">--</el-table-column>
      <el-table-column prop="bet_id" label="交易号"></el-table-column>
      <el-table-column prop="transaction_id" label="3方交易号"></el-table-column>
      <el-table-column prop="nickname" label="免费回合描述">--</el-table-column>
      <el-table-column prop="nickname" label="回合串号">--</el-table-column>
      <el-table-column label="余额">
        <template v-slot="{row}">
          <span v-if="row.bet_type===1">{{ row.changes.before.coins }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="下注金额">
        <template v-slot="{row}">
          <span v-if="row.bet_type===1">{{ row.bet_amount }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="赏金">--</el-table-column>
      <el-table-column prop="nickname" label="累计奖金">
      <!--累计奖金： 中奖真金+中奖赏金-->
        <template v-slot="{row}">
          <span v-if="row.bet_type===1">{{ row.win_amount }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="结算金额">
        <template v-slot="{row}">
          <span v-if="row.bet_type===1">{{ row.win_amount }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="中奖赏金">--</el-table-column>
      <el-table-column label="收益">
        <template v-slot="{row}">
          <span v-if="row.bet_type===1">{{ row.transfer_amount }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="下注单数量">--</el-table-column>
      <el-table-column prop="nickname" label="活动交易号">--</el-table-column>
      <el-table-column prop="nickname" label="推广活动类型">--</el-table-column>
    </el-table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        userid: '',
        transaction_id: '',
        min_bet_amount: '',
        max_bet_amount: '',
        min_win_amount: '',
        max_win_amount: '',
        start_time: '',
        end_time: ''
      },
      dialogVisible: false,
      dialogTitle: "",
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      rules: {
        userid: [
          { required: true, message: '请输入用户ID', trigger: 'blur' }
        ],
        //create_time: [
        //  { required: true, message: '请选择查询时间', trigger: 'blur' }
        //],
      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData(index = 0) {
      // 表单验证成功
      // this.$refs.form.validate(valid => {
      //   if (valid) {
      //   }
      // })
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params['time'][0], 1);
        params.end_time = this.$tools.dateFormat(params['time'][1], 1);
      }
      const data = {
        cmd: "user",
        action: "game_bet_history",
        data: {
          paramVO: {},
          page: index,
          ...params
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.bet_history;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
