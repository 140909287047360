<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true" :model="queryParams">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>

    </el-row>

    <br/>
    <el-table class="el-table" :data="tableData" borader>
      <el-table-column prop="date" label="日期" ></el-table-column>
      <el-table-column prop="total_profit" label="总盈利"></el-table-column>
      <el-table-column prop="total_deposit" label="入账存款"></el-table-column>
      <el-table-column prop="total_withdrawa" label="入账提款"></el-table-column>
      <el-table-column prop="channel_fee" label="通道费"></el-table-column>
      <el-table-column prop="total_agent_cooperation_fee" label="合作费用"></el-table-column>
      <el-table-column prop="user_login_sum" label="登录用户"></el-table-column>
      <el-table-column prop="user_active_sum" label="活跃用户"></el-table-column>
<!--      <el-table-column prop="new_user_pay_in_amount_sum" label="付费用户"></el-table-column>-->
<!--      <el-table-column prop="paid_conversion_rate" label="付费率"></el-table-column>-->
      <el-table-column prop="user_pay_in_sum" label="付费用户"></el-table-column>
      <el-table-column prop="user_payment_rate" label="付费率"></el-table-column>
      <el-table-column prop="order_coins_bet_win_lose_diff" label="真金输赢差"></el-table-column>
      <el-table-column prop="order_coins_bet_code_amount" label="真金打码量"></el-table-column>
      <el-table-column prop="order_give_bet_win_lose_diff" label="赠金输赢差"></el-table-column>
      <el-table-column prop="order_give_bet_code_amount" label="赠金打码量"></el-table-column>
      <el-table-column prop="new_user_register_active_sum" label="新增活跃"></el-table-column>
      <el-table-column prop="new_user_pay_in_amount_sum" label="新增付费"></el-table-column>
      <el-table-column prop="paid_conversion_rate" label="付费转化率"></el-table-column>
    </el-table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        start_time: '',
        end_time: '',
        time: "",
        log_type: "d",
      },
      total: 0,
      pages: 0,
      page: 0,
    };
  },
  mounted() {
    this.resetHandle();
  },
  methods: {
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params.time[0], 2);
        params.end_time = this.$tools.dateFormat(params.time[1], 2);
        delete params.time
      }
      params.log_type = "d";
      params.log_rb = 1;
      const data = {
        cmd: "sys_analysis",
        action: "query_page",
        data: {
          paramVO: {...params},
          page: index,
        },
      };
      this.$http
          .post("sys_analysis", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      //var currentDate = new Date();
      //currentDate.setDate(currentDate.getDate() - 30);
      ////默认本月
      //this.queryParams.time=[currentDate,new Date()];
      this.queryData();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-column-cell{
  border: solid #0d1717 1px;
}
</style>

