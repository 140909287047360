<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="代理id">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.agentCodeId"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理类型">
          <el-select
              clearable
              v-model="queryParams.agentType"
              class="m-2"
              placeholder="代理类型"
              size="large"
          >
            <el-option label="全民代理" value="1"/>
            <el-option label="签约代理" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
              clearable
              placeholder="请输入"
              v-model="queryParams.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="查询时间：">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item label="代理等级">
          <el-col :span="8">
            <el-input v-model="agentLevels.min" clearable placeholder="最小等级"></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="8">
            <el-input v-model="agentLevels.max" clearable placeholder="最大等级"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="团队人数">
          <el-col :span="8">
            <el-input v-model="agentLevels.sumMin" clearable placeholder="最小人数"></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="8">
            <el-input v-model="agentLevels.sumMax" clearable placeholder="最大人数"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="直推人数">
          <el-col :span="8">
            <el-input v-model="agentLevels.directMin" clearable placeholder="最小人数"></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="8">
            <el-input v-model="agentLevels.directMax" clearable placeholder="最大人数"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="已结算佣金">
          <el-col :span="8">
            <el-input v-model="agentLevels.sumupMin" clearable placeholder="最小金额"></el-input>
          </el-col>
          <el-col :span="1"></el-col>
          <el-col :span="8">
            <el-input v-model="agentLevels.sumupMax" clearable placeholder="最大金额"></el-input>
          </el-col>
        </el-form-item>



        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br/>

    <div style="background-color: white">
      <el-table
          :data="tableData"
          :header-cell-style="{ 'text-align': 'center' }"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row>
        <!--        <el-table-column prop="userid" label="代理ID" align="center"></el-table-column>-->
        <el-table-column label="代理ID" align="center">
          <template #default="scope">
            <div class="blue-font-color" @click="userdetails(scope.row)"
                 style="text-decoration:underline;cursor: pointer;">{{ scope.row.agentId }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="phone" label="绑定手机号" align="center">
          <template #default="scope">
            <span v-if="scope.row.phone!=null && scope.row.phone!=''">{{ scope.row.phone }}</span>
            <span v-else>{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column label="代理类型" align="center">
          <template #default="scope">
            <span v-if="scope.row.agentType==1">全民代理</span>
            <span v-if="scope.row.agentType==2">签约代理</span>
          </template>
        </el-table-column>
        <el-table-column prop="agentLevel" label="代理级别" align="center"></el-table-column>
        <el-table-column label="直推人数" align="center">
          <template #default="scope">
            <div class="blue-font-color" style="text-decoration:underline;cursor: pointer;"
                 @click="handlePhone(scope.row)">{{ scope.row.totalNumberInviteLevel1 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结算方式" align="center">
          <template #default="scope">
            <span v-if="scope.row.inviteBonusSettlementMethod === 1">人工</span>
            <span v-if="scope.row.inviteBonusSettlementMethod === 2">系统每日自动</span>
            <span v-if="scope.row.inviteBonusSettlementMethod === 3">系统实时自动</span>
          </template>
        </el-table-column>

        <!--        <el-table-column prop="totalBonusSalaySettlement" align="center" label="已结算佣金"></el-table-column>-->
        <!--        <el-table-column prop="totalBonusSalayDray" align="center" label="已提取佣金"></el-table-column>-->

        <el-table-column prop="settlementCommission" align="center" label="总结算佣金"></el-table-column>
        <el-table-column prop="withdrawalCommission" label="佣金余额"></el-table-column>
        <el-table-column prop="commissionOut" label="转出佣金总额"></el-table-column>
        <el-table-column prop="payOutCommission" label="提现佣金总额"></el-table-column>

        <el-table-column prop="fatherAgentId" align="center" label="上级代理ID"></el-table-column>
        <el-table-column label="团队数据" align="center">
          <template #default="scope">
            <el-popover effect="light" trigger="hover" placement="top" width="auto">
              <template #default>
                <div>总邀请人数: {{ scope.row.totalNumberInvite || 0 }}</div>
                <p>新增邀请人数: {{ scope.row.totalNumberInviteToday || 0  }}</p>
                <p>活跃人数： {{ scope.row.totalNumberActiveUser || 0 }}（真金：{{
                    scope.row.totalNumberActiveUserCoins || 0
                  }}，赏金：{{ scope.row.totalNumberActiveUserBonus || 0 }}）</p>
                <!--                <p>充值人数： {{ scope.row.totalNumberFirstPay }}（付费率：98.1%）</p>-->
                <p>充值人数： {{ scope.row.totalNumberFirstPay || 0  }}</p>
                <!--                <p>首次充值人数： {{ scope.row.totalNumberFirstPay }}</p>-->
                <p>充值汇总： {{ scope.row.totalNumberPayTimes|| 0  }}笔，充值总额：{{ scope.row.totalAmountPay || 0 }}</p>
                <p>提款汇总： {{ scope.row.totalNumberDrawTimes || 0  }}笔，提款金额：{{ scope.row.totalAmountPayOut || 0  }}</p>
                <!--                <p>充提差汇总： 123456789.00（25544笔）{{ scope.row.totalSalaryPayDrawDif }}</p>-->
                <p>充提差汇总： {{ scope.row.totalSalaryPayDrawDif || 0 }}</p>
                <p>打码量汇总： 真金：{{ scope.row.totalSalaryCode || 0 }}，赏金：0.00</p>
                <p>输赢差汇总： 真金：{{ scope.row.totalSalaryWinLostDif || 0 }}，赏金：0.00</p>
                <p>赠金获取汇总： 真金：{{ scope.row.totalGiveCoins || 0 }}，赏金：{{ scope.row.totalGiveBonus || 0 }}</p>
              </template>
              <template #reference>
                <el-tag>详情</el-tag>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="直推数据" align="center">
          <template #default="scope">
            <el-popover effect="light" trigger="hover" placement="top" width="auto">
              <template #default>
                <div>总邀请人数: {{ scope.row.totalNumberInviteLevel1 || 0 }}</div>
                <p>新增邀请人数: {{ scope.row.totalNumberInviteTodayLevel1 || 0 }}</p>
                <p>活跃人数： {{ scope.row.totalNumberActiveUserLevel1 || 0 }}（真金：{{
                    scope.row.totalNumberActiveUserCoinsLevel1
                    || 0
                  }}，赏金：{{ scope.row.totalNumberActiveUserBonusLevel1 || 0 }}）</p>
                <p>充值人数： {{ scope.row.totalNumberFirstPayLevel1 || 0 }}</p>
                <p>充值汇总： {{ scope.row.totalNumberPayTimesLevel1 || 0 }}笔，充值总额：{{ scope.row.totalAmountPayLevel1 || 0 }}</p>
                <p>提款汇总： {{ scope.row.totalAmountPayOutLevel1 || 0 }}笔，提款金额：{{ scope.row.totalAmountPayOutLevel1 }}</p>
                <!--                <p>提款汇总： {{ scope.row .totalBonusSalayDrayLevel1 || 0 }}笔</p>-->
                <p>充提差汇总： {{ scope.row.totalSalaryPayDrawDifLevel1 || 0 }}</p>
                <p>打码量汇总： 真金：{{ scope.row.totalSalaryCodeLevel1 || 0 }}，赏金：0.00</p>
                <p>输赢差汇总： 真金：{{ scope.row.totalSalaryWinLostDifLevel1 || 0 }}，赏金：0.00</p>
                <p>赠金获取汇总： 真金：{{
                    scope.row.totalGiveCoinsLevel1 || 0
                  }}，赏金：{{ scope.row.totalGiveBonusLevel1 || 0 }}</p>
              </template>
              <template #reference>
                <el-tag>详情</el-tag>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="二级数据" align="center">
          <template #default="scope">
            <el-popover effect="light" trigger="hover" placement="top" width="auto">
              <template #default>
                <div>总邀请人数: {{ scope.row.totalNumberInviteLevel2 || 0 }}</div>
                <p>新增邀请人数: {{ scope.row.totalNumberInviteTodayLevel2 || 0 }}</p>
                <p>活跃人数： {{ scope.row.totalNumberActiveUserLevel2 || 0 }}（真金：{{
                    scope.row.totalNumberActiveUserCoinsLevel2
                    || 0
                  }}，赏金：{{ scope.row.totalNumberActiveUserBonusLevel2 || 0 }}）</p>
                <p>充值人数： {{ scope.row.totalNumberFirstPayLevel2 || 0 }}</p>
                <p>充值汇总： {{ scope.row.totalNumberPayTimesLevel2 || 0 }}笔，充值总额：{{ scope.row.totalAmountPayLevel2 || 0 }}</p>
                <p>提款汇总： {{ scope.row.totalAmountPayOutLevel2 || 0 }}笔，提款金额：{{ scope.row.totalAmountPayOutLevel2 || 0 }}</p>
                <!--                <p>提款汇总： {{ scope.row.totalBonusSalaySettlementLevel2 || 0 }}笔</p>-->
                <p>充提差汇总： {{ scope.row.totalSalaryPayDrawDifLevel2 || 0 }}</p>
                <p>打码量汇总： 真金：{{ scope.row.totalSalaryCodeLevel2 || 0 }}，赏金：0.00</p>
                <p>输赢差汇总： 真金：{{ scope.row.totalSalaryWinLostDifLevel2 || 0 }}，赏金：0.00</p>
                <p>赠金获取汇总： 真金：{{
                    scope.row.totalGiveCoinsLevel2 || 0
                  }}，赏金：{{ scope.row.totalGiveCoinsLevel2 || 0 }}</p>
              </template>
              <template #reference>
                <el-tag>详情</el-tag>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="三级数据" align="center">
          <template #default="scope">
            <el-popover effect="light" trigger="hover" placement="top" width="auto">
              <template #default>
                <div>总邀请人数: {{ scope.row.totalNumberInviteLevel3 || 0 }}</div>
                <p>新增邀请人数: {{ scope.row.totalNumberInviteTodayLevel3 || 0 }}</p>
                <p>活跃人数： {{ scope.row.totalNumberActiveUserLevel3 || 0 }}（真金：{{
                    scope.row.totalNumberActiveUserCoinsLevel3
                    || 0
                  }}，赏金：{{ scope.row.totalNumberActiveUserBonusLevel3 || 0 }}）</p>
                <p>充值人数： {{ scope.row.totalNumberFirstPayLevel3 || 0 }}</p>
                <p>充值汇总： {{ scope.row.totalNumberPayTimesLevel3 || 0 }}笔，充值总额：{{ scope.row.totalAmountPayLevel3 || 0 }}</p>
                <p>提款汇总： {{ scope.row.totalAmountPayOutLevel3 || 0 }}笔，提款金额：{{ scope.row.totalAmountPayOutLevel3  || 0}}</p>
                <!--                <p>提款汇总： {{ scope.row.totalBonusSalaySettlementLevel3 || 0 }}笔</p>-->
                <p>充提差汇总： {{ scope.row.totalSalaryPayDrawDifLevel3 || 0 }}</p>
                <p>打码量汇总： 真金：{{ scope.row.totalSalaryCodeLevel3 || 0 }}，赏金：0.00</p>
                <p>输赢差汇总： 真金：{{ scope.row.totalSalaryWinLostDifLevel3 || 0 }}，赏金：0.00</p>
                <p>赠金获取汇总： 真金：{{
                    scope.row.totalGiveCoinsLevel3 || 0
                  }}，赏金：{{ scope.row.totalGiveCoinsLevel3 || 0 }}</p>
              </template>
              <template #reference>
                <el-tag>详情</el-tag>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="提款开关"  align="center" width="125">
          <template #default="scope">
            <el-switch
                v-model="scope.row.amountStatus"
                size="small"
                active-text="正常"
                inactive-text="冻结"
                :active-value=1
                :inactive-value=0
                @click="slidingblock(scope.row.amountStatus,scope.$index)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
<!--          <template #default="scope" v-if="payTime">-->
          <template #default="scope">
            <el-button type="text" size="small" @click="editHandle(scope.$index)">团队配置</el-button>
            <el-button type="text" size="small" @click="editHandles(scope.$index)">等级与客服调整</el-button>
            <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
            <el-button type="text" size="small" @click="editHandlesUser(scope.$index)">代理配置</el-button>
            <el-button type="text" size="small" @click="collect(scope.$index)">统计与薪金发放</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          :page-size="20"
          layout="total,prev, pager, next, jumper"
          :total="total"
          @current-change="pageChangeHandle"
      />
    </div>
  </div>

  <el-dialog v-model="dialogVisible" :title="dialogTitle" style="text-align: left" :close-on-click-modal="false"
             align-center>
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">

      <el-form-item label="结算方式" v-if="status === 1">
        <el-radio-group v-model="item.clientViewLevel">
          <el-radio :label="1">默认（只能查看到直推数据)</el-radio>
          <el-radio :label="2">二级（可查看直推和二级团队数据)</el-radio>
          <el-radio :label="3">三级（可查看一、二、三级团队数据)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代理级别" v-if="status === 2">
        <el-input type="number" min='0' max='3' v-model="item.agentLevel" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="设置专属客服" v-if="status === 2">
        <el-input v-model="item.customerServiceUrl" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogOk">保存</el-button>
      </span>
    </template>
  </el-dialog>


  <el-dialog
      v-model="dialogVisibleas"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="row" ref="rowForm" :rules="rules" label-width="120px">
      <el-form-item label="代理类型" align="center">
        <el-radio-group v-model="row.agentType">
          <el-radio :label="1">全民代理</el-radio>
          <el-radio :label="2">签约代理</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="row.agentType===2">
        <el-form-item label="结算方式" align="center" v-show="false">
          <el-radio-group v-model="row.inviteBonusSettlementMethod">
            <el-radio :label="1">人工</el-radio>
            <el-radio :label="2">系统每日自动</el-radio>
            <el-radio :label="3">系统实时自动</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="结算金额">
          <el-col class="line" :span="3">直推金额:</el-col>
          <el-col :span="5">
            <el-input placeholder="直推金额" v-model="row.inviteBonusLevel1" style="width: 100%;"></el-input>
          </el-col>
          <el-col class="line" :span="3">二级代理:</el-col>
          <el-col :span="5">
            <el-input placeholder="二级代理" v-model="row.inviteBonusLevel2" style="width: 100%;"></el-input>
          </el-col>
          <el-col class="line" :span="3">三级代理:</el-col>
          <el-col :span="5">
            <el-input placeholder="三级代理" v-model="row.inviteBonusLevel3" style="width: 100%;"></el-input>
          </el-col>
        </el-form-item>
        <span>代理提款手续费</span>
        <el-form-item label="手续费">
          <el-col :span="5">
            <el-input placeholder="手续费" v-model="row.withdrawalFee" style="width: 100%;">
              <template #append>%</template>
            </el-input>
          </el-col>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleas = false">取消</el-button>
        <el-button type="primary" @click="allocation">保存</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      first: {},
      item: {...defaultItem},
      queryParams: {...defaultItem},
      dialogVisible: false,
      payTimeRange: undefined,
      payTime: true,
      dialogTitle: "",
      isEdit: false,
      isEditDialog: false,
      dialogVisibleas: false,
      deleteGameDialogVisible: false,
      deleteGameDialogVisibleas: false,
      total: 0,
      pages: 0,
      page: 0,
      status: 0,
      gameCategoryList: [],
      gameProviderList: [],
      cmd: "pub_agent_info",
      row: {
        inviteBonusSettlementMethod: 0,
        inviteBonusLevel1: 0,
        inviteBonusLevel2: 0,
        inviteBonusLevel3: 0,
        withdrawalFee: 0,
      },
      dates: {},
      paramVO: {},
      data: {cmd: this.cmd, action: "", data: {paramVO: {}, page: 0,},},
      agentLevels: {}
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.agentLevels = {}
      this.payTime = true
      this.queryData();
    },
    dialogOk(){
      if (this.status === 1){
        this.updateData()
      }
      if ( this.status === 2){
       this.dialogOkHandle()
      }
    },
    dialogOkHandle() {
      const data = {
        cmd: this.cmd,
        action: "adjusting_levels",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then(() => {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    queryData(index = 0) {

      if (this.queryParams.dates && JSON.stringify(this.queryParams.dates) !== '{}') {
        this.payTime = false
      }
      this.queryParams.agentLevels = this.agentLevels
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;

          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addNewHandle() {
      this.isEditDialog = false;
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogTitle = "团队配置";
      this.dialogVisible = true;
    },
    editHandle(index) {
      this.isEditDialog = true;
      this.status = 1
      this.dialogTitle = "团队配置";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    editHandles(index) {
      this.isEditDialog = true;
      this.status = 2
      this.dialogTitle = "等级与客户调整";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },


    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then(() => {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handlePhone(row) {
      this.$router.push(`/agency-pubagentinfos/${row.agentId}`)
    },
    userdetails(row) {
      this.$router.push(`/user-detail/${row.agentId}`)
    },
    collect(index) {
      let row = this.tableData[index];
      this.$router.push(`/agency-pubAgentInfoDetail/${row.id}`)
    },
    allocation() {
      this.handleSave()
      this.dialogVisibleas = false;
    },

    editHandlesUser(index) {
      this.dialogTitle = "代理配置";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.row.inviteBonusSettlementMethod = 0
      this.row.inviteBonusLevel1 = 0
      this.row.inviteBonusLevel2 = 0
      this.row.inviteBonusLevel3 = 0
      this.row.withdrawalFee = 0
      this.row.agentType = this.item.agentType
      this.inquire()
    },

    inquire() {
      const data = {
        cmd: 'user_agent_bonus',
        action: "list",
        data: {
          paramVO: {userid: this.item.agentId},
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            if (response.data.list === null) {
              this.isEdit = true
            } else {
              this.isEdit = false
              this.row = response.data.list;
              this.row.agentType = this.item.agentType;
            }
            this.dialogVisibleas = true;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleSave() {
      this.row.userid = this.item.agentId
      const data = {
        cmd: 'user_agent_bonus',
        action: "update",
        data: {
          paramVO: this.row,
        },
      };
      if (this.isEdit) {
        data.action = "add"
        this.$http
            .post('user_agent_bonus', data)
            .then((response) => {
              this.$message({
                type: "success",
                message: "添加成功!",
              });
              console.log("response.data", response.data);
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      } else {
        this.$http
            .post('user_agent_bonus', data)
            .then((response) => {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              console.log("response.data", response.data);
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      }
    },
    slidingblock(da, index) {
      this.item = JSON.parse(JSON.stringify(this.tableData[index]))
      this.item.amountStatus = da
      var name = ''
      if (da === 1) {
        name = '开启'
      } else {
        name = '关闭'
      }
      this.$confirm("确定要" + name + "此账号提现功能?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.amountStatus(name);
      })
          .catch(() => {
            this.queryData();
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    amountStatus(name) {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: name + "成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>


<style lang="scss" scoped>


</style>
