<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="名称">
          <el-input clearable placeholder="根据名称搜索" v-model="queryParams.raffleNameCn"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table :data="tableData"
              :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row
    >
      <el-table-column prop="raffleNameCn" label="名称" align="center"></el-table-column>
      <el-table-column prop="describeCn" label="奖项描述" align="center"></el-table-column>
<!--      <el-table-column prop="raffleVipLevelMin" label="关联条件" align="center"></el-table-column>-->
      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.state=== 1">启用</template>
          <template v-if="row.state=== 0 ">禁用</template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="250" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button type="text" size="small" @click="itemEditHandle(scope.row.id)">配置</el-button>
          <!--          <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>-->
          <el-button v-if="scope.row.state === 1" type="text" size="small" @click="forbidden(scope.$index)">禁用
          </el-button>
          <el-button v-else type="text" size="small" @click="open(scope.$index)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="total,prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="抽奖配置"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @open="prizeNameCn"
  >
    <el-table :data="listData" style="width: 100%">
      <el-table-column prop="raffleAddress" label="位置" width="170" align="center"/>
      <el-table-column label="奖品名称"  align="center">
        <template v-slot="{row}">
          <el-select placeholder="奖品名称" v-model="row.prizeId">
            <el-option
                v-for="(item, index) in nameTypes"
                :key="index"
                :label="item.id +'::'+ item.prizeNameCn +'::'+ item.describeCn"
                :value="item.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="prizeCount" label="数量"  align="center">
        <template v-slot="{row}">
          <el-input type="number" v-model="row.prizeCount" autocomplete="off" size="small" placeholder="请输入"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="prizeRate" label="概率%"  align="center">
        <template v-slot="{row}" >
          <el-input type="number" v-model="row.prizeRate" autocomplete="off" size="small" max="100" @input="row.prizeRate = row.prizeRate <= 100 ? row.prizeRate : 10" placeholder="请输入">
            <template #suffix>
              <span class="normal-text">%</span>
            </template>
          </el-input>
        </template>
      </el-table-column>

<!--      <el-table-column fixed="right" label="操作" align="center">-->
<!--        <template #default="scope">-->
<!--          <el-button v-if="isLastRow(scope.$index)"  link type="primary" size="small" @click.prevent="deleteRow(scope.$index)">删除-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
<!--    <el-button class="mt-4" style="width: 100%" @click="onAddItem">添加</el-button>-->
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

export default {
  data() {
    const defaultItem = {
      userid: undefined,
      dates: undefined
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      listData: [{}],
      queryParams: {...defaultItem},
      dialogVisible: false,
      examine: false,
      dialogTitle: "",
      payTimeRange: undefined,
      isEditDialog: false,
      deleteGameDialogVisible: false,
      total: 0,
      pages: 0,
      page: 0,
      raffleId: 0,
      raffleAddress: 1,
      nameTypes: [],
      cmd: "pub_awards_setting",
      paramVO: {},
      item: {},
      dates: {},
      keeps: [
        {label: '启用', value: '1'},
        {label: '禁用', value: '0'},
      ],
      data: {cmd: this.cmd, action: "", data: {paramVO: {}},},
      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
        ],
        details: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        minLevel: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        maxLevel: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
      }
    }
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  methods: {
    isLastRow(index) {
      // 假设data中存储了表格数据
      return index === this.listData.length - 1;
    },
    deleteRow(index) {
      this.listData.splice(index, 1)
    },
    onAddItem() {
      let raffleAddress = 1;
      if (this.listData.length>0) {
        if (this.listData.length >= 12) {
          this.$message({
            type: "info",
            message: "数据已满",
          })
          return;
        }
        raffleAddress = this.listData[this.listData.length - 1].raffleAddress + 1
      }
      this.listData.push({
        raffleAddress: raffleAddress,
        state: "",
        prizeId: 2,
        raffleId: this.raffleId,
        prizeCount: 100,
        prizeRate: 10,
      })
    },
    prizeNameCn() {
      const data = {
        cmd: "pub_setting_award",
        action: "query_page",
        data: {
          paramVO: {},
          page: 0,
          pageSize: 1000,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.nameTypes = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },


    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    configurationData(id) {
      const data = {
        cmd: "cfg_raffle_prize",
        action: "query_page",
        data: {
          paramVO: {raffleId: id},
          page: 0,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.listData = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    dialogCancelHandle() {
      this.dialogVisible = false;
    },

    dialogOkHandle() {
      if (this.listData.length < 12) {
        this.$message({
          type: "info",
          message: "数据未满12条不能提交",
        })
        return;
      }
      for (const item of this.listData) {
        if (!item.prizeId && item.prizeId.length === 0) {
          this.$message({
            type: "warning",
            message: "位置为:" + item.raffleAddress + "列表中有数据不完整，请确保每一条数据的完整",
          });
          return;
        }
        if (!item.prizeCount && item.prizeCount.length === 0) {
          this.$message({
            type: "warning",
            message: "位置为:" + item.raffleAddress + "列表中有数据不完整，请确保每一条数据的完整",
          });
          return;
        }
        if (!item.prizeRate && item.prizeRate.length === 0) {
          this.$message({
            type: "warning",
            message: "位置为:" + item.raffleAddress + "列表中有数据不完整，请确保每一条数据的完整",
          });
          return;
        }
      }
      this.updateAddData();
      this.dialogVisible = false;
    },

    itemEditHandle(id) {
      // this.isEdit = true;
      // alert(index)
      this.raffleId = id
      this.listData = []
      this.configurationData(id)
      // this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updateAddData() {
      const data = {
        cmd: "cfg_raffle_prize",
        action: "add",
        data: {
          paramVO: this.listData,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then(() => {
            this.$message({
              type: "success",
              message: "配置成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    forbidden(index) {
      this.$confirm("确定要禁用吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = JSON.parse(JSON.stringify(this.tableData[index]))
        this.item.state = 0
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消禁用",
            });
          });
    },
    open(index) {
      this.$confirm("确定要开启吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = JSON.parse(JSON.stringify(this.tableData[index]))
        this.item.state = 1
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消开启",
            });
          });
    },
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    examineData() {

    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
