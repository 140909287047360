<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="名称">
          <el-input clearable  placeholder="等级名称" v-model="queryParams.vipLevelName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle">
            <span>重置</span>
          </el-button>
          <el-button @click="addHandle">新增</el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column prop="vipLevel" label="vip等级" align="center"></el-table-column>
      <el-table-column prop="vipLevelName" label="vip等级名称" align="center"></el-table-column>
      <el-table-column prop="vipSmallLevelStart" label="最小等级" align="center"></el-table-column>
      <el-table-column prop="vipSmallLevelEnd" label="最大等级" align="center"></el-table-column>
      <el-table-column label="提现手续费比例" align="center">
        <template #default="scope">
          {{ scope.row.payOutRate + '%' }}
        </template>
      </el-table-column>
<!--      <el-table-column prop="dayRaffleTicketId" label="每日抽奖ID" align="center"></el-table-column>-->
<!--      <el-table-column prop="levelRaffleTicketId" label="晋级抽奖ID" align="center"></el-table-column>-->
      <el-table-column label="每日抽奖ID" align="center">
        <template v-slot="{row}">
          {{ this.findTypeNameById(row.dayRaffleTicketId) }}
        </template>
      </el-table-column>

      <el-table-column label="晋级抽奖ID" align="center">
        <template v-slot="{row}">
          {{ this.findTypeNameById(row.levelRaffleTicketId) }}
        </template>
      </el-table-column>

      <el-table-column label="返水率" align="center">
        <template #default="scope">
          {{ scope.row.betReturnRatePerThousand + '‰' }}
        </template>
      </el-table-column>
      <el-table-column label="是否开启黄金俱乐部"  prop="levelRaffleTicketId" align="center">
        <template #default="scope">
          <span v-if="scope.row.goldClubOpen == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>

      <el-table-column label="黄金俱乐部有效下注量" width="170" prop="levelRaffleTicketId" align="center">
        <template #default="scope">
          <span v-if="scope.row.goldClubOpen == 0">--</span>
          <span v-else>{{ scope.row.goldClubCodeAmount }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="vipLevelKeepNeed" label="保级要求" align="center"></el-table-column>

      <el-table-column prop="" label="操作" align="center">
        <template #default="scope">
          <el-button
              type="primary"
              size="small"
              @click="editHandle(scope.$index)"
          >编辑
          </el-button
          >
          <el-button
              type="danger"
              size="small"
              @click="deleteHandle(scope.$index)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">

      <el-form-item label="vip等级" prop="vipLevel">
        <el-input v-model="item.vipLevel" type="number" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="vip等级名称" prop="vipLevelName">
        <el-input v-model="item.vipLevelName" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="最小等级" prop="vipSmallLevelStart">
        <el-input type="number" v-model="item.vipSmallLevelStart" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="最大等级" prop="vipSmallLevelEnd">
        <el-input type="number" v-model="item.vipSmallLevelEnd" placeholder="请输入"></el-input>
      </el-form-item>


      <el-form-item label="提现手续费比例" prop="payOutRate">
        <el-input v-model="item.payOutRate"  type="number" placeholder="请输入">
          <template #suffix>
            <span class="normal-text">%</span>
          </template>
        </el-input>
      </el-form-item>



      <el-form-item label="每日抽奖ID"  prop="dayRaffleTicketId">
        <el-select placeholder="请选择" clearable  style="width: 100%" v-model="item.dayRaffleTicketId" :disabled="may">
          <el-option
              v-for="(item, index) in tickets"
              :key="index"
              :label="item.ticketName"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="晋级抽奖ID"  prop="levelRaffleTicketId">
        <el-select placeholder="请选择" clearable  style="width: 100%" v-model="item.levelRaffleTicketId" :disabled="may">
          <el-option
              v-for="(item, index) in tickets"
              :key="index"
              :label="item.ticketName"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="白银返水率" prop="betReturnRatePerThousand">
        <el-input v-model="item.betReturnRatePerThousand" type="number" placeholder="请输入">
          <template #suffix>
            <span class="normal-text">‰</span>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item label="是否开启黄金俱乐部" prop="goldClubOpen" align="center" >
        <el-radio-group v-model="item.goldClubOpen">
          <el-radio :label=1>是</el-radio>
          <el-radio :label=0>否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="黄金俱乐部有效下注量" v-if="item.goldClubOpen===1" prop="goldClubCodeAmount">
        <el-input type="number"  v-model="item.goldClubCodeAmount" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="保级要求" prop="vipLevelKeepNeed">
        <el-input type="number" v-model="item.vipLevelKeepNeed" placeholder="请输入"></el-input>
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="confirmHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import { cloneDeep } from 'lodash';
export default {
  computed: {
    // 假设types数据结构已经存在于组件内部，且是响应式的
    typeNamesMap() {
      return this.tickets.reduce((map, item) => {
        map.set(item.id, item.ticketName);
        return map;
      }, new Map());
    }
  },
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pob_vip_level',
      queryParams: {},
      item: {},
      tickets: [
          {
            id: null,
            ticketName: '--'
          }

      ],
      keeps: [
        {label: '是', value: 1},
        {label: '否', value: 0},
      ],
      isEdit: false,
      rules: {
        vipLevelName: [
          {required: true, message: '请输入VIP名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        vipLevel: [
          {required: true, message: '请输入等级', trigger: 'blur'},
        ],
        vipSmallLevelStart: [
          {required: true, message: '请输入最小等级', trigger: 'blur'},
        ],
        vipSmallLevelEnd: [
          {required: true, message: '请输入小等级级别结束', trigger: 'blur'},
        ],

        goldClubOpen: [
          {required: true, message: '请选择是否开启', trigger: 'blur'},
        ],

        betReturnRatePerThousand: [
          {required: true, message: '请输入白银返水率', trigger: 'blur'},
        ],
        // dayRaffleTicketId: [
        //   {required: true, message: '请选择每日抽奖ID', trigger: 'blur'},
        // ],
        // levelRaffleTicketId: [
        //   {required: true, message: '请选择晋级抽奖ID', trigger: 'blur'},
        // ]  ,
        payOutRate: [
          {required: true, message: '请输入提现手续费比例', trigger: 'blur'},
        ],
        vipLevelKeepNeed: [
          {required: true, message: '请输入保级要求', trigger: 'blur'},
        ],
        goldClubCodeAmount: [
          {required: true, message: '请输入黄金俱乐部有效下注量', trigger: 'blur'},
        ]
      }
    };
  },
  mounted() {
    this.queryData();
    this.querytickets();
  },
  methods: {
    // 抽奖劵名称
  findTypeNameById(id) {
    return this.typeNamesMap.get(id) || '--';
  },
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm("确定要删除么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.isEditDialog = true;
      this.dialogFormVisible = true;
    },
    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: this.cmd,
        action: "delete",
        data: {
          paramVO: {
            id,
          },
        },
      };
      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    // 抽奖劵列表
    querytickets() {
      const data = {
        cmd: 'pub_setting_award',
        action: "query_ticket",
        data: {paramVO: {}}
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.tickets = [...this.tickets, ...response.data.list]
            // this.tickets = response.data.list;
            // this.tickets = cloneDeep(response.data.list);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
