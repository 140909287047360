<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="游戏名称">
          <el-input v-model="queryParams.game_name" clearable placeholder="请输入游戏名称"></el-input>
        </el-form-item>
        <el-form-item label="游戏厂商">
          <el-select placeholder="请选择" clearable v-model="queryParams.game_provider">
            <el-option
                v-for="(provider, index) in gameProviderList"
                :key="index"
                :label="provider.providerName"
                :value="provider.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏房间号">
          <el-input clearable placeholder="请输入游戏房间号"></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="queryParams.user_id" clearable placeholder="请输入用户ID"></el-input>
        </el-form-item>

        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="chaxun()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br/>

    <el-row style="background-color: white; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">总下注：</el-col>
          <el-col span="">{{ noteStatistics.bet_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">总真金下注：</el-col>
          <el-col span="">{{ noteStatistics.real_bet_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">总赠金下注：</el-col>
          <el-col span="">{{ noteStatistics.give_bet_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">总结算：</el-col>
          <el-col span="">{{ noteStatistics.win_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">总真金结算：</el-col>
          <el-col span="">{{ noteStatistics.real_win_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">总赠金结算：</el-col>
          <el-col span="">{{ noteStatistics.give_win_amount }}</el-col>
        </el-row>
        <el-row>
          <el-col span="">输赢差：</el-col>
          <el-col span="">{{ noteStatistics.win_lose_diff }}</el-col>
        </el-row>
<!--        <el-row>-->
<!--          <el-col span="">抽水盈利：</el-col>-->
<!--          <el-col span="">{{ noteStatistics.pump }}</el-col>-->
<!--        </el-row>-->
        <el-button type="" @click="doRefresh">刷新</el-button>
        <el-button @click="exportData">导出数据</el-button>
      </el-space>
    </el-row>

    <br/>

    <div style="background-color: white">
      <el-table :data="tableData">
        <el-table-column label="用户ID" prop="userid" min-width="70"></el-table-column>
        <el-table-column label="游戏ID" prop="game_id" min-width="70"></el-table-column>
        <el-table-column label="游戏名称" prop="game_name"></el-table-column>
        <el-table-column label="游戏供应商" prop="game_supplier"></el-table-column>
        <el-table-column label="游戏房间号">--</el-table-column>
        <el-table-column label="开局流水号" prop="parent_bet_id"></el-table-column>
        <el-table-column label="参与用户数">1</el-table-column>
        <el-table-column label="下注额">
          <template #default="scope">
            累计下注:{{ scope.row.bet_amount }}<br/>
            真金:{{ scope.row.bet_amount - scope.row.give_bet_amount }}<br/>
            赠金:{{ scope.row.give_bet_amount }}
          </template>
        </el-table-column>
        <el-table-column label="结算额">
          <template #default="scope">
            累计结算:{{ scope.row.win_amount }}<br/>
            真金:{{ scope.row.win_amount - scope.row.give_win_amount }}<br/>
            赠金:{{ scope.row.give_win_amount }}
          </template>
        </el-table-column>
<!--        <el-table-column label="抽水">-->
<!--          真金:0<br/>赠金:0-->
<!--        </el-table-column>-->
        <el-table-column label="输赢差">
          <template #default="scope">
            累计输赢差:{{ scope.row.win_lose_diff }}<br/>
            真金:{{ scope.row.coins_real_win_lose_diff }}<br/>
            赠金:{{ scope.row.coins_give_win_lose_diff }}
          </template>
        </el-table-column>
        <el-table-column label="结算时间" prop="settlement_time"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="small" @click="goDetail(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :page-size="20"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="pageChangeHandle"
          v-if="tableData.length>0"
      />
    </div>
  </div>

  <el-dialog
      v-model="dialogVisibleas"
      title="注单详情"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      width="80%"
  >
    <H3>基本信息</H3>
    <el-table
        :data="tableData2"
        style="width: 100%">
      <el-table-column label="游戏ID" prop="game_id"></el-table-column>
      <el-table-column label="游戏名称" prop="game_name"></el-table-column>
<!--      <el-table-column label="游戏供应商" prop="game_supplier"></el-table-column>-->
<!--      <el-table-column label="游戏房间号">&#45;&#45;</el-table-column>-->
      <el-table-column label="开局流水号" prop="parent_bet_id"></el-table-column>
<!--      <el-table-column label="参与用户数">1</el-table-column>-->
      <el-table-column label="下注额">
        <template #default="scope">
          累计下注:{{ scope.row.bet_amount }}<br/>
          真金:{{ scope.row.bet_amount - scope.row.give_bet_amount }}<br/>
          赠金:{{ scope.row.give_bet_amount }}
        </template>
      </el-table-column>
      <el-table-column label="结算额">
        <template #default="scope">
          累计结算:{{ scope.row.win_amount }}<br/>
          真金:{{ scope.row.win_amount - scope.row.give_win_amount }}<br/>
          赠金:{{ scope.row.give_win_amount }}
        </template>
      </el-table-column>
<!--      <el-table-column label="抽水">-->
<!--        真金:0<br/>赏金:0-->
<!--      </el-table-column>-->
      <el-table-column label="输赢差">
        <template #default="scope">
          累计输赢差:{{ scope.row.win_lose_diff }}<br/>
          真金:{{ scope.row.coins_real_win_lose_diff }}<br/>
          赠金:{{ scope.row.coins_give_win_lose_diff }}
        </template>
      </el-table-column>
      <el-table-column label="结算时间" prop="settlement_time"></el-table-column>
    </el-table>
    <H3>中奖细节</H3>
    <el-table
        :data="tableData3"
        style="width: 100%">
      <el-table-column label="用户ID" prop="userid"></el-table-column>
<!--      <el-table-column label="下注细节">&#45;&#45;</el-table-column>-->
      <el-table-column label="下注额" prop="bet_amount"></el-table-column>
      <el-table-column label="结算额" prop="win_amount"></el-table-column>
<!--      <el-table-column label="抽水">&#45;&#45;</el-table-column>-->
      <el-table-column label="输赢差" prop="transfer_amount"></el-table-column>
      <el-table-column label="账户余额" prop="changes.before.coins"></el-table-column>
<!--      <el-table-column label="下注ip">&#45;&#45;</el-table-column>-->
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleas = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {
      game_supplier: '',
      game_name: '',
      user_id: null
    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      tableData2: [],
      tableData3: [],
      item: {...defaultItem},
      queryParams: {...defaultItem},
      total: 0,
      pages: 0,
      page: 0,
      showViewData: false,
      gameProviderList: [],
      noteStatistics: {
        win_lose_diff: 0,
        pump: 0,
        win_amount: 0,
        give_bet_amount: 0,
        real_bet_amount: 0,
        give_win_amount: 0,
        real_win_amount: 0,
        coins_give_win_lose_diff: 0,
        coins_real_win_lose_diff: 0,
        coins_give_code_amount: 0,
        coins_real_code_amount: 0,
        code_amount: 0,
        bet_amount: 0
      },
      dialogVisibleas: false
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryParams.time = this.chose_date('month');
      this.chaxun();
    },
    statisticsData() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params.time[0], 2);
        params.end_time = this.$tools.dateFormat(params.time[1], 2);
        delete params.time
      }
      const data = {
        cmd: "bet_event_log",
        action: "note_statistics",
        data: {
          paramVO: {...params}
        },
      };
      this.$http
          .post("bet_event_log", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.noteStatistics = response.data.noteStatistics;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    doRefresh() {
      this.statisticsData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryGameProviderData() {
      const data = {
        action: "query",
        data: {
          paramVO: {
            status: "1",
          },
        },
      };

      this.$http
          .post("game_provider", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.gameProviderList = response.data.gameProviderVOList;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    chose_date(type) {
      let nowData = new Date();
      let end = new Date(new Date().toDateString())
      let start = new Date(new Date().toDateString())
      if (type == 'today') { //今天
        end = new Date()
        start.setTime(start.getTime())
      } else if (type == 'yesterday') { //昨天
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
      } else if (type == 'week') { //本周（本周一到当天）
        start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1))
      } else if (type == 'lastweek') { //上周
        start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 2))
        end.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay()))
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      } else if (type == 'month') { //本月
        start = new Date(nowData.getFullYear(), nowData.getMonth(), 1);
        end = new Date(nowData.getFullYear(), nowData.getMonth() + 1, 0);
      } else if (type == 'lastmonth') { //上月
        start = new Date(nowData.getFullYear(), nowData.getMonth() - 1, 1);
        end = new Date(nowData.getFullYear(), nowData.getMonth(), 0);
      }
      return this.search_time([start, end]) //转出时间戳
    },
    search_time(time) {
      let time_arr = []
      time.map(item => {
        let month = '' + (item.getMonth() + 1)
        if (month.length < 2) month = '0' + month;
        let day = '' + item.getDate()
        if (day.length < 2) day = '0' + day;
        let hours = '' + item.getHours()
        if (hours.length < 2) hours = '0' + hours;
        let minutes = '' + item.getMinutes()
        if (minutes.length < 2) minutes = '0' + minutes;
        let seconds = '' + item.getSeconds()
        if (seconds.length < 2) seconds = '0' + seconds;
        var newTime = item.getFullYear() + '-' + month + '-' + day
        time_arr.push(newTime)
      })
      console.log(time_arr)
      return time_arr;
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params.time[0], 2);
        params.end_time = this.$tools.dateFormat(params.time[1], 2);
        delete params.time
      }
      const data = {
        cmd: "bet_event_log",
        action: "note_page",
        data: {
          paramVO: {...params},
          page: index,
        },
      };
      this.$http
          .post("bet_event_log", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        if (params.time) {
          params.start_time = this.$tools.dateFormat(params['time'][0], 2);
          params.end_time = this.$tools.dateFormat(params['time'][1], 2);
        }
        let data = {
          cmd: "bet_event_log",
          action: "note_page",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("bet_event_log", data)
            .then((response) => {
              if (response.data.list.length !== 0) {
                listData = listData.concat(response.data.list);
                index++;
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['游戏ID', '游戏名称', '游戏供应商', '游戏房间号', '开局流水号', '参与用户数', '下注额', '结算额', '抽水', '输赢差', '结算时间'],
      ];
      listData.forEach(item => {
        let bet_amount = "真金:" + item.bet_amount + "<br/>赏金:0";//下注额
        let win_amount = "真金:" + item.win_amount + "<br/>赏金:0";//结算额
        let win_lose_diff = "真金:" + item.win_lose_diff + "<br/>赏金:0";//输赢差
        if (item.bet_type == 2) {
          bet_amount = "真金:0<br/>赏金:" + item.bet_amount;
          win_amount = "真金:0<br/>赏金:" + item.win_amount;
          win_lose_diff = "真金:0<br/>赏金:" + item.win_lose_diff;
        }
        data.push([item.game_id, item.game_name, item.game_supplier, "--", item.parent_bet_id, "1",
          bet_amount,
          win_amount,
          "真金:0<br/>赏金:0",
          win_lose_diff,
          item.settlement_time]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '注单管理');
      XLSX.writeFile(wb, '注单管理.xlsx');
    },
    chaxun() {
      this.queryData();
      this.doRefresh();
    },
    goDetail(info) {
      this.dialogVisibleas = true;
      this.tableData2 = [info];
      this.tableData3 = [];
      //加载明细数据
      const data = {
        cmd: "bet_event_log",
        action: "note_detail_list",
        data: {
          paramVO: {parent_bet_id: info.parent_bet_id},
        },
      };
      this.$http
          .post("bet_event_log", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData3 = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    }
  },
  mounted() {
    this.resetHandle();
    this.initShowViewData();
    this.queryGameProviderData();
  },
};
</script>

<style lang="scss" scoped></style>
