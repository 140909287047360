<template>
      <div class="d2">
    <el-button  v-if="tableData.length < 7" class="btn1" type="primary"  @click="addItemHandle">新增</el-button>
        </div>
  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <div class="app-container" style="box-sizing: border-box"/>

    <el-table-column fixed prop="agentLevel" label="代理级别" align="center"></el-table-column>
    <el-table-column prop="firstPayLevel1" label="升级条件:直推充值人数" align="center"></el-table-column>
    <el-table-column prop="addBonusFirstPayLevel1" label="升级奖励：直推人头奖增加" align="center"></el-table-column>
    <el-table-column prop="addBonusFirstPayLevel2" label="升级奖励：二级代理人头奖增加" align="center"></el-table-column>
    <el-table-column prop="addBonusFirstPayLevel3" label="升级奖励：三级代理人头奖增加" align="center"></el-table-column>
    <el-table-column prop="addBonusFirstPayLevel3" label="升级奖励：三级代理人头奖增加" align="center"></el-table-column>
    <el-table-column prop="withdrawalFee" label="手续费" align="center"></el-table-column>
    <el-table-column prop="customerServiceUrl" label="客服配置" align="center"></el-table-column>

    <el-table-column fixed="right" label="操作" width="170" align="center" >
      <template v-slot="{$index}">
        <el-button
            style="margin-top: 5px"
            type="text"
            @click="itemEditHandle($index)"
        >编辑
        </el-button>
        <el-button
            type="text"
            @click="deleteHandle($index)"
        >删除</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="220px">

      <el-form-item label="代理级别"  prop="agentLevel" required>
        <el-input v-model="item.agentLevel" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="升级条件:直推充值人数"  prop="firstPayLevel1" required>
        <el-input v-model="item.firstPayLevel1" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="升级奖励：直推人头奖增加"  prop="addBonusFirstPayLevel1" required>
        <el-input v-model="item.addBonusFirstPayLevel1" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="升级奖励：二级代理人头奖增加"  prop="addBonusFirstPayLevel2" required>
        <el-input v-model="item.addBonusFirstPayLevel2" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="升级奖励：三级代理人头奖增加"  prop="addBonusFirstPayLevel3" required>
        <el-input v-model="item.addBonusFirstPayLevel3" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="手续费"  prop="addBonusFirstPayLevel3" required>
        <el-input v-model="item.withdrawalFee" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="客服配置" >
        <el-input v-model="item.customerServiceUrl" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  data() {
    return {
      tableData: [],
      item: {},
      selectedRow: {},
      dialogVisible: false,
      listLoading: true,
      dialogTitle: "",
      cmd: "agent_level_service",
      data: {cmd: this.cmd, action: "list", data: {paramVO: {}},},
      rules: {
        agentLevel: [
          {required: true, message: '请输入代理级别', trigger: 'blur'},
        ],
        firstPayLevel1: [
          {required: true, message: '请输入直推充值人数', trigger: 'blur'},
        ] ,   addBonusFirstPayLevel1: [
          {required: true, message: '请输入人头奖增加', trigger: 'blur'},
        ],  addBonusFirstPayLevel2: [
          {required: true, message: '请输入二级代理人头奖', trigger: 'blur'},
        ],  addBonusFirstPayLevel3: [
          {required: true, message: '请输入三级代理人头奖增加', trigger: 'blur'},
        ],
      }
    }
  },
  created() {
    this.queryData()
  },
  methods: {
    async queryData() {
      this.data.action = 'list'
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogVisible = false;
        }
      })
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增配置";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑配置";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updateData() {
      this.data.action = "update"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data)
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData()
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      this.data.action = "add"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data)
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData()
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "info",
              message: "删除成功!",
            });
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  }
}
</script>
<style>
.d2 {
  min-width: 100%;
  height: 45px;
  position: relative;
  display:flex;
  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
