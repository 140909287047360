<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table :data="tableData">
      <el-table-column prop="" label="中奖时间"></el-table-column>
      <el-table-column prop="" label="抽奖类型"></el-table-column>
      <el-table-column prop="" label="中奖说明"></el-table-column>
      <el-table-column prop="" label="道具ID"></el-table-column>
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      item: {...defaultItem},
      queryParams: {},
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()]
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params['time'][0], 2);
        params.end_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
      index;
        // let data = {
        //   cmd: "",
        //   action: "",
        //   data: {
        //     paramVO: params,
        //     page: index,
        //   },
        // };
        // this.$http
        //     .post("deposit", data)
        //     .then((response) => {
        //       console.log("response.data", response.data);
        //       this.tableData = response.data.list;
        //       this.page = response.data.page;
        //       this.pages = response.data.pages;
        //       this.total = response.data.total;
        //     })
        //     .catch((error) => {
        //       console.log("error", error);
        //     });
    },

  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
