<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary">
            <el-icon><Plus /></el-icon>
            <span>新增</span>
          </el-button>
          <el-button type="primary">
            <el-icon><DeleteFilled /></el-icon>
            <span>删除</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column label="ID"></el-table-column>
      <el-table-column label="VIP分组类型"></el-table-column>
      <el-table-column label="最小等级"></el-table-column>
      <el-table-column label="最大等级"></el-table-column>
      <el-table-column label="是否开启返点"></el-table-column>
      <el-table-column label="返水万分比"></el-table-column>
      <el-table-column label="抽奖盒子分组id"></el-table-column>
      <el-table-column label="操作"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
