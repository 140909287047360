<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true" :model="queryParams">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>

    </el-row>

    <br/>
    <el-table class="el-table" :data="tableData" borader>
      <el-table-column prop="day" label="日期" ></el-table-column>
      <el-table-column prop="login_count" label="登录人数"></el-table-column>
      <el-table-column>
        <template v-slot:header>
          <div>投注人数</div>
          <div>(现金)</div>
        </template>
        <template v-slot:default="{row}">
          {{row.real_bet_user_count}}
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot:header>
          <div>投注次数</div>
          <div>(现金)</div>
        </template>
        <template v-slot:default="{row}">
          {{row.real_bet_count}}
        </template>
      </el-table-column>
      <el-table-column label="投注均值">
        <template v-slot="{row}">
          <template v-if="row.real_bet_user_count===0">0</template>
          <template v-else> {{ (row.real_bet_count / row.real_bet_user_count).toFixed(2) }} </template>
        </template>
      </el-table-column>
      <el-table-column prop="real_bet_win_lose_diff" label="输赢差"></el-table-column>
      <el-table-column prop="real_bet_code_amount" label="打码量"></el-table-column>

      <el-table-column>
        <template v-slot:header>
          <div>投注人数</div>
          <div>(奖金)</div>
        </template>
        <template v-slot:default="{row}">
          {{row.give_bet_user_count}}
        </template>
      </el-table-column>
      <el-table-column>
        <template v-slot:header>
          <div>投注次数</div>
          <div>(奖金)</div>
        </template>
        <template v-slot:default="{row}">
          {{row.give_bet_count}}
        </template>
      </el-table-column>
      <el-table-column label="投注均值">
        <template v-slot="{row}">
          <template v-if="row.give_bet_user_count===0">0</template>
          <template v-else> {{ (row.give_bet_count / row.give_bet_user_count).toFixed(2) }} </template>
        </template>
      </el-table-column>
      <el-table-column prop="give_bet_win_lose_diff" label="输赢差"></el-table-column>
      <el-table-column prop="give_bet_code_amount" label="打码量"></el-table-column>
      <el-table-column prop="bet_user_count_0_7" label="0~7"></el-table-column>
      <el-table-column prop="bet_user_count_8_12" label="8~12"></el-table-column>
      <el-table-column prop="bet_user_count_13_18" label="13~18"></el-table-column>
      <el-table-column prop="bet_user_count_19_23" label="19~23"></el-table-column>
      <el-table-column prop="search_user_count" label="搜索次数"></el-table-column>
      <el-table-column label="搜索内容">
        <template v-slot="{row}">
          <router-link :to="'/analysis-user-search/'+row.day">
            搜索记录
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        start_time: '',
        end_time: '',
        time: "",
      },
      total: 0,
      pageSize: 0,
      pageIndex: 0,
    };
  },
  mounted() {
    this.resetHandle();
  },
  methods: {
    queryData(index) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params.time[0], 1);
        params.end_time = this.$tools.dateFormat(params.time[1], 3);
        delete params.time
      }
      const data = {
        cmd: "analysis_data",
        action: "query_user_bet_data",
        data: Object.assign(
            {pageIndex: index},params
        )
      };
      this.$http
          .post("analysis_data", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.list;
            this.pageIndex = response.data.pageIndex;
            this.pageSize = response.data.pageSize;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData(0);
    },
  }
};
</script>

<style lang="scss" scoped>
.table-column-cell{
  border: solid #0d1717 1px;
}
</style>

