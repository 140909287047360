<template>
  <div class="container">
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true" :model="queryParams">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.last_login_time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>搜索</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>

    </el-row>

    <br/>

    <table class="el-table tablestyle" >
      <thead>
<!--      style="border: solid #2c3e50 3px"-->
      <tr style="background-color: #DFDFDF">
        <th class="table-column-cell rowsapndate">日期</th>
        <th class="table-column-cell">留存类型</th>
        <th class="table-column-cell">用户数</th>
        <th class="table-column-cell">占比</th>
        <th class="table-column-cell">次留</th>
        <th class="table-column-cell">三留</th>
        <th class="table-column-cell">四日</th>
        <th class="table-column-cell">五日</th>
        <th class="table-column-cell">六日</th>
        <th class="table-column-cell">七日</th>
        <th class="table-column-cell">8-15日</th>
        <th class="table-column-cell">16-30日</th>
        <th class="table-column-cell">登录2天</th>
        <th class="table-column-cell">登录7天</th>
        <th class="table-column-cell">登录20天</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item, index) in tableData" :key="index">
<!--        style="border: solid #2c3e50 3px"-->
        <tr >
          <td class="table-column-cell rowsapndate" :rowspan="3">{{ item.day }}</td>
          <td class="table-column-cell">新增注册</td>
          <td class="table-column-cell">{{ item.new_user_number }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_user_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d2_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d3_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d4_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d5_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d6_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d7_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d8_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_d16_login_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_login_2d_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_login_7d_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.new_login_20d_number, item.new_user_number) }}</td>
        </tr>
        <tr style="background-color: #EFEFEF">
          <td class="table-column-cell">新增活跃</td>
          <td class="table-column-cell">{{ item.active_user_number }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_user_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d2_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d3_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d4_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d5_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d6_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d7_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d8_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_d16_login_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_login_2d_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_login_7d_number, item.active_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.active_login_20d_number, item.active_user_number) }}</td>
        </tr>
        <tr style="background-color: #DFDFDF">
          <td class="table-column-cell">新增付费</td>
          <td class="table-column-cell">{{ item.pay_user_number }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_user_number, item.new_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d2_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d3_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d4_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d5_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d6_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d7_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d8_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_d16_login_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_login_2d_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_login_7d_number, item.pay_user_number) }}</td>
          <td class="table-column-cell">{{ zanbi(item.pay_login_20d_number, item.pay_user_number) }}</td>
        </tr>
      </template>
      </tbody>
    </table>
    <el-pagination
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        last_login_time: ""
      },
      total: 0,
      pages: 0,
      page: 0,
    };
  },
  mounted() {
    this.resetHandle();
    //this.queryData();
  },
  methods: {
    queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      console.log(params.last_login_time);
      if (params.last_login_time) {
        params.start_time = this.$tools.dateFormat(params.last_login_time[0], 2);
        params.end_time = this.$tools.dateFormat(params.last_login_time[1], 2);
        delete params.last_login_time
      }
      const data = {
        cmd: "user_retained",
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("user_retained", data)
          .then((response) => {
            console.log("response.data", response.data);
            // var dataList = [];
            response.data.list.forEach(item => {
             item.day = item.day.substring(0, 10);
            });
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 30);
      //默认本月
      this.queryParams.last_login_time=[currentDate,new Date()];
      this.queryData();
    },
    zanbi(number, sum){
      if(number == null){
        return null;
      }
      if (sum == 0){
        return '0.00%';
      }
      const result = (number / sum) * 100;
      return `${result.toFixed(2)}%`;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-column-cell{
  //border: solid #0d1717 1px;
}
::v-deep .el-pagination {
  justify-content: flex-end;

}
.tablestyle {
  margin-bottom: 20px;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  border: solid #0d1717 1px;
  th {
    padding: 8px 13px;
  }
  th,tr {
    border-bottom: solid #0d1717 1px;
  }

  td {
    margin: 0;
    padding: 0;
    padding: 8px 13px;
    //border-left: solid #0d1717 1px;
    //border-right: solid #0d1717 1px;
  }
  th:not(.rowsapndate),
  td:not(.rowsapndate) {
    border-left: solid #0d1717 1px;
  }
}
</style>
