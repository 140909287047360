<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form-item label="操作id">
        <el-input  clearable placeholder="操作用户id" v-model="queryParams.userId"></el-input>
      </el-form-item>
      <el-form :inline="true">
        <el-form-item label="请求时间">
          <el-date-picker
              clearable
              v-model="payTimeRange"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle"><span>重置</span>
          </el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData">

      <el-table-column prop="userId" label="操作人" align="center"></el-table-column>

      <el-table-column prop="ip" label="IP地址" align="center"></el-table-column>

      <el-table-column prop="url" label="请求路径" align="center"></el-table-column>
      <el-table-column prop="requestTime" label="请求时间" align="center"></el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      disabled: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pub_platform_user_log',
      queryParams: {},
      dates: {},
      item: {},
      payTimeRange: {},
      isEdit: false
    };
  },
  watch: {
    payTimeRange(val) {
      if (val) {
        this.dates.begin = val[0]
        this.dates.end = val[1]
        this.queryParams.dates = this.dates
      } else {
        this.queryParams.dates = undefined
      }
    }
  },
  mounted() {
    this.queryData();
  },
  methods: {
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.payTimeRange = []
      this.queryData();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
