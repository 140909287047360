<template>
  <div style="width: 50%;margin-top: 20px;margin-left:50px">
    <el-form ref="rowForm" :model="row" :rules="rules" label-width="100px">

      <span>默认人头奖结算配置</span>

      <el-form-item label="结算方式" align="center" >
        <el-radio-group v-model="row.inviteBonusSettlementMethod">
          <el-radio :label="1">人工</el-radio>
          <el-radio :label="2">系统每日自动(隔天)</el-radio>
          <el-radio :label="3">系统实时自动(实时)</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="结算金额">
        <el-col class="line" :span="3">直推金额:</el-col>
        <el-col :span="5">
          <el-input   placeholder="直推金额" v-model="row.inviteBonusLevel1" style="width: 100%;"></el-input>
        </el-col>
        <el-col class="line" :span="3">二级代理:</el-col>
        <el-col :span="5">
          <el-input placeholder="二级代理" v-model="row.inviteBonusLevel2" style="width: 100%;"></el-input>
        </el-col>
        <el-col class="line" :span="3">三级代理:</el-col>
        <el-col :span="5">
          <el-input   placeholder="三级代理" v-model="row.inviteBonusLevel3" style="width: 100%;"></el-input>
        </el-col>
      </el-form-item>

      <span>代理提款手续费</span>
      <el-form-item label="手续费">
        <el-col :span="5">
          <el-input  placeholder="手续费" v-model="row.withdrawalFee" style="width: 100%;">
            <template #append>%</template>
          </el-input>
        </el-col>
      </el-form-item>

<!--      <span>默认薪金结算配置</span>-->

<!--      <el-form-item label="结算方式">-->
<!--        <el-radio-group v-model="row.salarySettlementMethod">-->
<!--          <el-radio :label="1">人工</el-radio>-->
<!--          <el-radio :label="2">系统自动结算</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="结算周期">-->
<!--        <el-radio-group v-model="row.salarySettlementMethod">-->
<!--          <el-radio :label="1">日薪</el-radio>-->
<!--          <el-radio :label="2">周薪</el-radio>-->
<!--          <el-radio :label="3">月薪(30天)</el-radio>-->
<!--        </el-radio-group>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="打码量">-->
<!--        <el-radio-group v-model="row.salaryCodeState">-->
<!--          <el-radio :label="2">日薪</el-radio>-->
<!--          <el-form-item>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="直推" v-model="row.salaryCodeLevel1" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="二级代理" v-model="row.salaryCodeLevel2" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="三级代理" v-model="row.salaryCodeLevel3" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-radio-group>-->

<!--        <el-radio-group v-model="row.salaryWinLostDifState">-->
<!--          <el-radio :label="2">输赢差</el-radio>-->
<!--          <el-form-item>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="直推" v-model="row.salaryWinLostDifLevel1" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="二级代理" v-model="row.salaryWinLostDifLevel2" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="三级代理" v-model="row.salaryWinLostDifLevel3" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-radio-group>-->

<!--        <el-radio-group v-model="row.salaryPayState">-->
<!--          <el-radio :label="2">充值数</el-radio>-->
<!--          <el-form-item>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="直推" v-model="row.salaryPayLevel1" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="二级代理" v-model="row.salaryPayLevel2" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="三级代理" v-model="row.salaryPayLevel3" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-radio-group>-->

<!--        <el-radio-group v-model="row.salaryPayDrawDifState">-->
<!--          <el-radio :label="2">充提差</el-radio>-->
<!--          <el-form-item>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="直推" v-model="row.salaryPayDrawDifLevel1" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="二级代理" v-model="row.salaryPayDrawDifLevel2" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--            <el-col class="line" :span="1"></el-col>-->
<!--            <el-col :span="5">-->
<!--              <el-input placeholder="三级代理" v-model="row.salaryPayDrawDifLevel3" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-radio-group>-->

<!--        <el-radio-group v-model="row.salaryFixedState">-->
<!--          <el-radio :label="2">固定金额</el-radio>-->
<!--          <el-form-item>-->
<!--            <el-col :span="15">-->
<!--              <el-input placeholder="直推" v-model="row.salaryFixedNumber" style="width: 100%;"></el-input>-->
<!--            </el-col>-->
<!--          </el-form-item>-->
<!--        </el-radio-group>-->

<!--      </el-form-item>-->


    </el-form>
    <div class="dialog-footer" style="margin-left: 30px">
      <el-button type="primary" :loading="btnSaveLoading" @click="handleSave">保存</el-button>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      rules: Object.freeze({}),
      btnSaveLoading: false,
      cmd: "agent_bonus",
      row: {
        inviteBonusSettlementMethod: 3,
        inviteBonusLevel1: 0,
        inviteBonusLevel2: 0,
        inviteBonusLevel3: 0,
        salarySettlementMethod: 0,

        salaryCodeState: 0,
        salaryCodeLevel1: 0,
        salaryCodeLevel2: 0,
        salaryCodeLevel3: 0,
        salaryWinLostDifState: 0,
        salaryWinLostDifLevel1: 0,
        salaryWinLostDifLevel2: 0,
        salaryWinLostDifLevel3: 0,
        salaryPayState: 0,
        salaryPayLevel1: 0,
        salaryPayLevel2: 0,
        salaryPayLevel3: 0,
        salaryPayDrawDifState: 0,
        salaryPayDrawDifLevel1: 0,
        salaryPayDrawDifLevel2: 0,
        salaryPayDrawDifLevel3: 0,
        salaryFixedState: 0,
        salaryFixedNumber: 0,
        withdrawalFee: 0
      },
      data: { cmd: this.cmd, action: "list",data: {paramVO: {}},}
    }
  },
  created() {
    this.inquire()
  },
  methods: {
    async inquire(){
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data.cfgAgentBonus);
            this.row = response.data.cfgAgentBonus;
          })
          .catch((error) => {
            console.log("error", error);
          });
     },
    handleSave() {
      this.data.action = "add"
      this.data.data.paramVO = this.row
      if (this.row.inviteBonusSettlementMethod === 0) {
        this.$http
            .post(this.cmd, this.data)
            .then((response) => {
              console.log("response.data", response.data);
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      }else {
        this.data.action = "update"
        this.$http
            .post(this.cmd, this.data)
            .then((response) => {
              console.log("response.data", response.data);
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.queryData();
            })
            .catch((error) => {
              console.log("error", error);
            });
      }
    }
  }
}
</script>
<style scoped>
</style>
