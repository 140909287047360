<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="活动名称">
          <el-input clearable  placeholder="请输入关键字"></el-input>
        </el-form-item>
        <div>
          <el-form-item label="创建时间">
            <el-date-picker
                clearable
                class="select1"
              type="daterange"
              value-format="yyyy/MM/dd"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="resetForm('form')"
              >搜索</el-button
            >
            <el-button @click="dialogFormVisible = true">新增</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column label="管理"></el-table-column>
      <el-table-column label="状态"></el-table-column>
      <el-table-column label="活动名称"></el-table-column>
      <el-table-column label="活动周期"></el-table-column>
      <el-table-column label="活动概要"></el-table-column>
      <el-table-column label="活动图1"></el-table-column>
      <el-table-column label="活动图2"></el-table-column>
      <el-table-column label="活动详情"></el-table-column>
      <el-table-column label="操作"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    addData() {
      const data = {
        cmd: "news",
        action: "add",
        data: {
          paramVO: {
            name: "资讯页名称",
            content: "资讯页面编辑",
            button_enable: "1",
            button_title: "按钮文本",
            button_hyper_link: "https://www.baidu.com/",
            status: "1",
            create_by: null,
          },
        },
      };
      this.$http
        .post("add", data)
        .then((response) => {
          console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateData() {
      const data = {
        cmd: "news",
        action: "update",
        data: {
          paramVO: {
            id: 3,
            name: "资讯页名称222",
            content: "资讯页面编辑222",
            button_enable: "0",
            button_title: "按钮文本222",
            button_hyper_link: "https://www.baidu.com/222",
            status: "0",
            update_by: null,
          },
        },
      };
      this.$http
        .post("update", data)
        .then((response) => {
          console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    queryData() {
      const data = {
        cmd: "news",
        action: "query",
        data: {
          paramVO: {
            status: "1",
            create_time_start: "2023-08-20 00:00:00",
            create_time_end: "2023-09-01 00:00:00",
          },
        },
      };
      this.$http
        .post("queray", data)
        .then((response) => {
          console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteData() {
      const data = {
        cmd: "news",
        action: "delete",
        data: {
          paramVO: {
            id: "1",
          },
        },
      };
      this.$http
        .post("delete", data)
        .then((response) => {
          console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    disableData() {
      const data = {
        cmd: "news",
        action: "disable",
        data: {
          paramVO: {
            id: 3,
            update_by: null,
          },
        },
      };
      this.$http
        .post("disable", data)
        .then((response) => {
          console.log("response.data", response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
  mounted() {
    this.addData();
    this.updateData();
    this.queryData();
    this.disableData();
    this.deleteData();
  },
};
</script>

<style lang="scss" scoped></style>
