<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="" style="width: 245px;">
          <el-input clearable  placeholder="请输入订单号、商户订单号、用户ID" v-model="queryParams.where_value"></el-input>
        </el-form-item>
<!--        <el-form-item label="用户：">-->
<!--          <el-input placeholder="用户ID"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="支付通道：">-->
<!--          <el-select placeholder="请选择支付通道">-->
<!--            <el-option label="label" value="value"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="订单状态：">
          <el-select clearable  placeholder="请选择订单状态" v-model="queryParams.status">
            <el-option
                v-for="(item, index) in statusArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单金额：">
          <el-input  clearable placeholder="最小金额" v-model="queryParams.min_amount"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input clearable  placeholder="最大金额" v-model="queryParams.max_amount"></el-input>
        </el-form-item>
        <el-form-item label="首充：">
          <el-select clearable  placeholder="请选择" v-model="queryParams.is_first_pay">
            <el-option
                v-for="(item, index) in is_first_pay_arr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单创建时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单完成时间">
          <el-date-picker
              clearable
              v-model="queryParams.up_time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
<!--        <el-form-item label="存款活动：">-->
<!--          <el-select placeholder="请选择存款活动">-->
<!--            <el-option label="label" value="value"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-row style="background-color: white; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">入账存款：</el-col>
          <el-col span="" style="color:rgba(31, 165, 0, 0.84705882352)">{{depositReceived}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">发起存款：</el-col>
          <el-col span="">{{initiateDeposit}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">通道手续费：</el-col>
          <el-col span="">{{channelHandlingFee}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">用户成功率：</el-col>
          <el-col span="">{{userSuccessRate}}%</el-col>
        </el-row>
        <el-button @click="doRefresh">刷新</el-button>
      </el-space>
    </el-row>

    <br />

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="orderid" label="平台订单号"></el-table-column>
      <el-table-column prop="amount" label="订单金额"></el-table-column>
      <el-table-column label="存款活动">
        <template #default="scope">
          <span v-if="scope.row.in_activity">{{scope.row.activity_type}}</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_first_pay_str" label="是否首充"></el-table-column>
      <el-table-column prop="statusStr" label="订单状态"></el-table-column>
      <el-table-column prop="pay_time_str" label="发起时间"></el-table-column>
      <el-table-column prop="update_time_str" label="完成时间"></el-table-column>
      <el-table-column prop="payment_channel_code" label="支付通道"></el-table-column>
      <el-table-column prop="merchantorderid" label="商户订单号"></el-table-column>
      <el-table-column prop="traceid" label="支付流水号"></el-table-column>
<!--      <el-table-column prop="account" label="到账金额"></el-table-column>-->
      <el-table-column label="赠送金额">
        <template #default="scope">
          <span>真金：{{scope.row.give_coins}}</span><br/>
          <span>赏金：{{scope.row.give_bonus}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="account_his" label="历史存款"></el-table-column>
      <el-table-column prop="create_user" label="操作人"></el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope" >
          <el-button type="primary" size="small" @click="goDetail(scope.$index)">详情</el-button>
          <el-button
            type="primary"
            size="small"
            @click="deleteHandle(scope.$index)"
            >补单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>
  <el-dialog
      v-model="dialogVisible"
      title="补单"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-form :model="item">
      <el-form-item label="" :label-width="auto">
        <el-text>距离补单发起时间已过{{orderDiffTime}}</el-text>
      </el-form-item>

      <el-form-item label="补单理由：">
        <el-select placeholder="请选择或输入新理由" v-model="note" style="width:80%;">
          <el-option
              v-for="(item, index) in noteArr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="其它理由：" v-if="note=='其它理由（请填写）'">
        <el-input v-model="notes" placeholder="请输入" style="width:80%;"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      depositReceived: 0,
      initiateDeposit: 0,
      channelHandlingFee: 0,
      userSuccessRate: 0,
      total: 0,
      pages: 0,
      page: 0,
      statusArr: [
        {label:'待支付',value:1},
        {label:'已支付',value:2},
        {label:'已补单',value:3},
        {label:'已取消',value:4},
        {label:'代收失败',value:5},
      ],
      is_first_pay_arr: [
        {label:'是',value:1},
        {label:'否',value:0},
      ],
      orderDiffTime:'',
      dialogVisible: false,
      note:'',
      notes:'',
      noteOther:'',
      noteArr: [
        {label:'已和代付方确认收到订单存款',value:'已和代付方确认收到订单存款'},
        {label:'该用户属于高信任度用户',value:'该用户属于高信任度用户'},
        {label:'其它理由（请填写）',value:'其它理由（请填写）'},
      ],
      showViewData: false
    };
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      if(params.up_time){
        params.up_start_time = this.$tools.dateFormat(params['up_time'][0],2);
        params.up_end_time = this.$tools.dateFormat(params['up_time'][1],2);
      }
      let data = {
        cmd: "deposit",
        action: "query_deposit_list",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("deposit", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.deposit_list;
            this.tableData.forEach((item) => {
              //status:订单状态1-待支付2-已支付3-已补单4-已取消
              for(let i of this.statusArr){
                if(i.value==item.status){
                  item.statusStr = i.label;
                  break;
                }
              }
              //is_first_pay:是否首充 true/false
              if (item.is_first_pay === 1) {
                item.is_first_pay_str = "是";
              } else {
                item.is_first_pay_str = "否";
              }
              item.update_time_str = this.$tools.dateFormat(item.update_time)
              item.pay_time_str = this.$tools.dateFormat(item.pay_time)
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      if(params.up_time){
        params.up_start_time = this.$tools.dateFormat(params['up_time'][0],2);
        params.up_end_time = this.$tools.dateFormat(params['up_time'][1],2);
      }
      const data = {
        cmd: "deposit",
        action: "query_statistics",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("deposit", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.depositReceived = response.data.depositReceived;
            this.initiateDeposit = response.data.initiateDeposit;
            this.channelHandlingFee = response.data.channelHandlingFee;
            this.userSuccessRate = response.data.userSuccessRate;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    goDetail(index) {
      let row = this.tableData[index];
      this.$router.push(`/deposit-detail/${row.id}`)
    },

    deleteHandle(index) {
      this.$confirm("谨慎操作！请勿重复补单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.item = this.tableData[index];
            let payTimeStamp = new Date(this.item.pay_time).getTime();
            let currTimeStamp = new Date().getTime();
            let diffTime = this.getDiffTime(currTimeStamp,payTimeStamp);
            this.orderDiffTime = diffTime;
            this.dialogVisible = true;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消补单",
            });
          });
    },
    supOrder(index) {
      this.item = this.tableData[index];
      let payTimeStamp = new Date(this.item.pay_time).getTime();
      let currTimeStamp = new Date().getTime();
      let diffTime = this.getDiffTime(currTimeStamp,payTimeStamp);
      this.orderDiffTime = diffTime;
      this.dialogVisible = true;
    },
    dislogCancelHandle() {
      // this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.doManualOrder();
    },
    doManualOrder() {
      //其他
      if(!this.note){
        this.$message({
          type: "info",
          message: "请填写其它理由",
        });
        return;
      }
      if(this.note === '其它理由（请填写）' ){
        if (!this.notes){
          this.$message({
            type: "info",
            message: "请填写其它理由",
          });
          return;
        }
        this.note = this.notes;
      }
      //
      const data = {
        cmd: "deposit",
        action: "deposit_manual_order",
        data: {
          paramVO: {},
          id:this.item.id,
          note:this.note
        },
      };
      this.$http
          .post("deposit", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dislogCancelHandle();
            this.note = ""
            this.notes = ""
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    getDiffTime(new_date,old_date){
      var subtime = (new_date - old_date) / 1000;    //计算时间差,并将毫秒转化为秒
      var days = parseInt(subtime / 86400);  //天  24*60*60*1000
      var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
      var mins = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
      // var secs = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
      // return days + "天 " + hours + "小时 " + mins + "分钟 " + secs + "秒 ";
      if(days>0){
        return days + "天" + hours + "小时" + mins + "分钟";
      }else if(hours>0){
        return hours + "小时" + mins + "分钟";
      }else{
        return mins + "分钟";
      }
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        if(params.time){
          params.start_time = this.$tools.dateFormat(params['time'][0],2);
          params.end_time = this.$tools.dateFormat(params['time'][1],2);
        }
        let data = {
          cmd: "deposit",
          action: "query_deposit_list",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("user", data)
            .then((response) => {
              if (response.data.deposit_list.length !== 0) {
                listData = listData.concat(response.data.deposit_list);
                index++;
                listData.forEach((item) => {
                  //status:订单状态1-待支付2-已支付3-已补单4-已取消
                  for(let i of this.statusArr){
                    if(i.value==item.status){
                      item.statusStr = i.label;
                      break;
                    }
                  }
                  //is_first_pay:是否首充 true/false
                  if (item.is_first_pay === 1) {
                    item.is_first_pay_str = "是";
                  } else {
                    item.is_first_pay_str = "否";
                  }
                  if (item.in_activity){
                    item.in_activity = item.activity_code
                  }else {
                    item.in_activity = '无'
                  }
                  item.update_time_str = this.$tools.dateFormat(item.update_time)
                  item.pay_time_str = this.$tools.dateFormat(item.pay_time)
                });
              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['用户ID', '平台订单号', '订单金额', '存款活动', '是否首充', '订单状态', '发起时间', '完成时间', '支付通道', '平台订单号', '支付流水号', '赠送金额', '历史存款', '操作人'],
      ];
      listData.forEach(item=>{
        data.push([item.userid, item.orderid, item.amount, item.in_activity, item.is_first_pay_str, item.statusStr, item.pay_time_str, item.update_time_str, item.payment_channel_code, item.merchantorderid, item.traceid,'真金:'+item.give_coins+'赏金:'+item.give_bonus,item.account_his,item.create_user]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '存款记录');
      XLSX.writeFile(wb, '存款记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
    this.initShowViewData();
  },
};
</script>

<style lang="scss" scoped></style>
