<template>
      <div class="d2">
    <el-button class="btn1" type="primary"   @click="addItemHandle">新增</el-button>
        </div>
  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <div class="app-container" style="box-sizing: border-box"/>

    <el-table-column fixed prop="firstPayLevel1" label="直推充值人数" align="center"></el-table-column>
    <el-table-column prop="addBonus" label="奖励" align="center"></el-table-column>
    <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
      <template v-slot="{$index}">
        <el-button
            style="margin-top: 5px"
            type="text"
            @click="itemEditHandle($index)"
        >编辑
        </el-button>
        <el-button
            type="text"
            @click="deleteHandle($index)"
        >删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
<!--  size="small"-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >

    <el-form :model="item" ref="rowForm" :rules="rules" label-width="120px">
      <el-form-item label="直推充值人数" prop="firstPayLevel1" required>
        <el-input v-model="item.firstPayLevel1" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="奖励" prop="addBonus" required>
        <el-input v-model="item.addBonus" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  data() {
    return {
      tableData: [],
      item: {},
      total: 0,
      pages: 0,
      page: 0,
      selectedRow: {},
      dialogVisible: false,
      dialogTitle: "",
      cmd: "cfg_agent_invite_pay",
      data: {cmd: this.cmd, action: "list", data: {paramVO: {}},},
      rules: {
        firstPayLevel1: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        addBonus: [
          {required: true, message: '请输入奖励', trigger: 'blur'},
        ]
      }
    }
  },
  created() {
    this.queryData()
  },
  methods: {

    pageChangeHandle(num) {
      this.queryData(num - 1);
    },

    async queryData(index = 0) {
      const data = {
        cmd: this.cmd,
        action: "list",
        data: {
          paramVO:{},
          page: index
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogVisible = false;
        }
      })
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updateData() {
      this.data.action = "update"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      this.data.action = "add"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "info",
              message: "删除成功!",
            });
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

  }
}
</script>
<style>
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
