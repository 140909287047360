<template>
  <div class="d2">
    <el-button class="btn1" type="primary" @click="closeStatus()">{{item.state === 1 ? '关闭活动' : '开启活动'}}</el-button>
    <el-button class="btn1" type="primary" @click="addHandle()">活动奖金配置</el-button>
    <el-button class="btn1" type="primary" @click="addItemHandle()">指定本期中奖用户</el-button>
  </div>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 20px">
      <el-row>
        <el-space span="">运行状态</el-space>
        <el-tag v-if="item.state === 1" type="success" span="">开启</el-tag>
        <el-tag v-else type="success" span="">关闭</el-tag>
      </el-row>
    </el-row>

    <el-row style="background-color: white; text-align: left; padding: 20px">
      <el-space direction="horizontal" style="width: 100%">
        <el-row :span="6">
          <el-space span="">当前总奖金</el-space>
          <el-tag type="success" span="">{{ totalPrize }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">一等奖名额</el-space>
          <el-tag type="success" span="">{{ item.firstPrizeNum }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">奖金比例</el-space>
          <el-tag type="success" span="">{{ item.firstPrizeProportion  }}{{ '%' }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">奖金预计</el-space>
          <el-tag type="success" span="">{{ (totalPrize) * item.firstPrizeProportion/100 }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">二等奖名额</el-space>
          <el-tag type="success" span="">{{ item.secondPrizeNum }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">奖金比例</el-space>
          <el-tag type="success" span="">{{ item.secondPrizeProportion  }}{{ '%' }}</el-tag>
        </el-row>
        <el-row :span="6">
          <el-space span="">奖金预计</el-space>
          <el-tag type="success" span="">{{ (totalPrize) * item.secondPrizeProportion/100 }}</el-tag>
        </el-row>

      </el-space>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>
   <el-table-column prop="goldenClubId" label="期号" align="center"></el-table-column>
   <el-table-column prop="fixedPrize" label="保底奖金" align="center"></el-table-column>
   <el-table-column prop="variablePrize" label="动态奖金" align="center"></el-table-column>
   <el-table-column label="周期" align="center">
        <template #default="scope">
          <span >{{scope.row.startDate.substring(0, 10)}}~ {{scope.row.endDate.substring(0, 10)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="winnings" align="center" label="开奖状态">
        <template v-slot="{row}">
          <template v-if="row.haveDrawn === 0">未开奖</template>
          <template v-else>已开奖</template>
        </template>
      </el-table-column>

      <el-table-column prop="winnings" align="center" label="中奖人数">
        <template v-slot="{row}">
          <template v-if="row.haveDrawn === 0">未开奖</template>
          <template v-else>{{row.sum}}</template>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" align="center">
        <template #default="scope" >
          <el-button type="primary" size="small" :disabled="scope.row.haveDrawn === 0" @click="editHandle(scope.row)">查看详情</el-button>
<!--          <el-button type="primary" size="small" v-if="scope.row.haveDrawn === 0" @click="addItemHandle(scope.row.goldenClubId)">指定本期中奖用户</el-button>-->
        </template>

      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="'活动奖金配置'"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="items" ref="rowForm" :rules="rules" label-width="180px">

<!--      <el-form-item label="是否开启" prop="state" required>-->
<!--        <el-select placeholder="请选择" style="width: 100%" v-model="items.state">-->
<!--          <el-option-->
<!--              v-for="(item, index) in type"-->
<!--              :key="index"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

      <el-form-item label="保底奖金" prop="fixedPrize">
        <el-input v-model="items.fixedPrize" type="number" max="3" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="动态奖金系数" prop="variablePrizeRatio">
        <el-input v-model="items.variablePrizeRatio" type="number" max="3" @input="items.variablePrizeRatio = items.variablePrizeRatio <= 20 ? items.variablePrizeRatio: 20" placeholder="可调节范围为0%~20%">
          <template #suffix>
            <span class="normal-text">%</span>
          </template>
        </el-input>
      </el-form-item>


      <el-form-item label="一等奖名额" prop="firstPrizeNum">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="items.firstPrizeNum" @input="items.firstPrizeNum = items.firstPrizeNum <= 3 ? items.firstPrizeNum : 3" type="number" max="3" placeholder="名额"></el-input>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="一等奖奖金比例" prop="firstPrizeProportion">
              <el-input v-model="items.firstPrizeProportion" placeholder="请输入">
                <template #suffix>
                  <span class="normal-text">%</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="二等奖名额" prop="secondPrizeNum">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="items.secondPrizeNum" placeholder="请输入"></el-input>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="二等奖奖金比例" prop="secondPrizeProportion">
              <el-input v-model="items.secondPrizeProportion" width="100%" placeholder="请输入">
                <template #suffix>
                  <span class="normal-text">%</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="三等奖名额" prop="thirdPrizeNum">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="items.thirdPrizeNum" placeholder="请输入"></el-input>
          </el-col>
          <el-col :span="12" >
            <el-form-item label="三等奖奖金比例" prop="thirdPrizeProportion">
              <el-input v-model="items.thirdPrizeProportion"  placeholder="请输入">
                <template #suffix>
                  <span class="normal-text">%</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-col :span="12" >
          <div >
            <p>总奖金=保底奖金+动态奖金</p>
            <p>动态奖金=平台打码量（当周）*2%*动态奖金系数</p>
            <p>假设动态奖金系数为5%，相当于让出平台毛利的5%</p>
          </div>
        </el-col>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="confirmHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
      v-model="dialogVisible"
      :title="'指定本期中奖(最多3人)'"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @close="dislogCancelHandle"
  >
    <el-form-item class="searchAdd">
      <el-input placeholder="请输入用户ID" v-model="addUserId" style="width: 70%;"></el-input>
      <el-button type="primary" @click="getUserInfo()" style="position:absolute;right:0;">查询</el-button>
    </el-form-item>
    <el-table :data="addUserInfoBox" style="margin-bottom:50px;" empty-text="请先在输入用户ID查询" v-if="dialogVisible">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
      <el-table-column prop="vip_levels" label="账户真金">
        <template #default="scope">
          <span>{{ scope.row.coins -scope.row.coins_give }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coins_give" label="账户赠金"></el-table-column>
      <el-table-column prop="recharge" label="历史存款"></el-table-column>
      <el-table-column prop="withdrawal" label="历史提款"></el-table-column>
      <el-table-column prop="win_lose_diff_sum" label="总输赢"></el-table-column>
      <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button type="text" @click="adduser(scope.row)">指定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-descriptions direction="vertical" title="已指定一等奖"/>

    <el-table :data="userBox" style="margin-bottom:50px;">
      <el-table-column fixed prop="userid" label="用户" align="center"></el-table-column>
      <el-table-column prop="userVipLevel" align="center" label="用户vip级别">
        <template v-slot="{row}">
          <span>{{ getVipLevelName(row.userVipLevel) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="vip_levels" label="账户真金">
        <template #default="scope">
          <span>{{ scope.row.coins -scope.row.coins_give }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coins_give" label="账户赠金"></el-table-column>
      <el-table-column prop="recharge" label="历史存款"></el-table-column>
      <el-table-column prop="withdrawal" label="历史提款"></el-table-column>
      <el-table-column prop="win_lose_diff_sum" label="总输赢"></el-table-column>
      <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
        <template #default="scope">
          <el-button type="text" @click="deleteHandle(scope.row.goldenClubPrizeId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dislogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      addUserInfoBox: [],
      userBox: [],
      viplevels: [],
      goldenClubId: undefined,
      dialogFormVisible: false,
      totalPrize : 0,
      dialogVisible: false,
      imageUrl: "",
      addUserId: "",
      dialogTitle: "",
      queryParams: {},
      item: {},
      items: {},
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      isEdit: false,
      keeps: [
        {label: '不限制', value: 0},
        {label: '限制', value: 1},
      ],
      type: [
        {label: '开启', value: 1},
        {label: '关闭', value: 0},
      ],
      rules: {
        state: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        fixedPrize: [
          {required: true, message: '请输入保底奖金', trigger: 'blur'},
        ],

        variablePrizeRatio: [
          {required: true, message: '请输入动态奖金系数', trigger: 'blur'},
        ],

        firstPrizeNum: [
          {required: true, message: '请输入一等奖名额', trigger: 'blur'},
        ],
        firstPrizeProportion: [
          {required: true, message: '请输入一等奖比例', trigger: 'blur'},
          // {min: 1, max: 2, message: '只能是2位数', trigger: 'blur'}
        ],

        secondPrizeNum: [
          {required: true, message: '请输入二等奖名额', trigger: 'blur'},
        ],

        secondPrizeProportion: [
          {required: true, message: '请输入二等奖比例', trigger: 'blur'},
          // {min: 1, max: 2, message: '长度在 1 到 2 个字符', trigger: 'blur'}
        ],

        thirdPrizeNum: [
          {required: true, message: '请输入三等奖名额', trigger: 'blur'},
        ],

        thirdPrizeProportion: [
          {required: true, message: '请输入三等奖奖金比例', trigger: 'blur'},
        ],

      }
    };
  },
  mounted() {
    // 历史版本
    this.queryData();

    this.queryDatas();
  },
  methods: {
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: 'pub_winning',
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.totalPrize =  response.data.list[0].fixedPrize+ response.data.list[0].variablePrize
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    async queryDatas() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: 'pub_club_allocat',
        action: "query_page",
        data: {
          paramVO: params,
          page: 0,
        }
      }
      this.$http
          .post('pub_club_allocat', data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.item = response.data.list[0];
            this.item.variablePrizeRatio = this.item.variablePrizeRatio * 100;
            this.item.firstPrizeProportion = this.item.firstPrizeProportion * 100;
            this.item.secondPrizeProportion = this.item.secondPrizeProportion * 100;
            this.item.thirdPrizeProportion = this.item.thirdPrizeProportion * 100;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    /**
     * 关闭开启事件
     */
    closeStatus() {
      this.items = JSON.parse(JSON.stringify(this.item))
      var status = '开启';
      if (this.items.state === 1){
        status ='关闭'
        this.items.state = 0
      }else {
        this.items.state = 1
      }
      this.$confirm("确定要" + status+"活动吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.addDatas(status);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },

    addDatas(status) {
      const params = this.$tools.removeEmptyProperties(this.items);
      const data = {
        cmd: 'pub_club_allocat',
        action: "update",
        data: {
          paramVO: params,
          page: 0,
        }
      }
      this.$http
          .post('pub_club_allocat', data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: status+"成功!",
            });
            this.queryDatas();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * 弹窗事件
     */
    addHandle() {
      this.items = JSON.parse(JSON.stringify(this.item));
      this.dialogFormVisible = true;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          this.items.variablePrizeRatio = this.items.variablePrizeRatio / 100;
          this.items.firstPrizeProportion = this.items.firstPrizeProportion / 100;
          this.items.secondPrizeProportion = this.items.secondPrizeProportion / 100;
          this.items.thirdPrizeProportion = this.items.thirdPrizeProportion / 100;
          this.handleSave()
          this.dialogFormVisible = false;
        }
      })
    },
    handleSave() {
      const params = this.$tools.removeEmptyProperties(this.items);
      const data = {
        cmd: 'pub_club_allocat',
        action: "update",
        data: {
          paramVO: params,
          page: 0,
        }
      }
        this.$http
            .post('pub_club_allocat', data)
            .then((response) => {
              console.log("response.data", response.data);
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.queryDatas();
            })
            .catch((error) => {
              console.log("error", error);
            });

    },
    editHandle(row) {
      this.$router.push(`/agency-pubWinningDetails/${row.goldenClubId}`)
    },
    addItemHandle() {
      if(this.tableData.length===0){
        this.$message({
          type: "info",
          message: "没有本期开奖",
        })
        return;
      }
      this.goldenClubId = this.tableData[0].goldenClubId;
      // alert(this.goldenClubId)
      this.addUserId = "";
      this.addUserInfoBox = [];
      this.queryDataUsers(this.goldenClubId);
      this.queryviplevels()
      this.dialogVisible = true;
    },

    async queryDataUsers(id) {
      const data = {
        cmd: 'pub_winning_details',
        action: "query_page",
        data: {
          paramVO: {goldenClubId: id},
          page: 0,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.userBox = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    async adduser(row) {
     if (this.userBox.length>=3){
       this.$message({
         type: "info",
         message: "一等奖只能添加三个用户",
       })
       return;
     }
      const data = {
        cmd: 'pub_winning_details',
        action: "add",
        data: {
          paramVO: {goldenClubId: this.goldenClubId, userid: row.userid,userVipLevel: row.vip_levels,specific_user: 1 },
          page: 0,
        }
      }
      this.$http
          .post("pub_winning_details", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryDataUsers(this.goldenClubId);
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(id) {
      this.$confirm('确定要删除么?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {

            this.deleteData(id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: 'pub_winning_details',
        action: "delete",
        data: {
          paramVO: {goldenClubPrizeId:id},
        },
      };
      this.$http
          .post("pub_winning_details", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryDataUsers(this.goldenClubId);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },
    dislogCancelHandle() {

      this.dialogVisible = false;
    },
    dialogOkHandle() {
      this.dialogVisible = false;
      this.$message({
        type: "success",
        message: "配置成功!",
      });
    },

    getUserInfo() {
      if (!this.addUserId || this.addUserId == "") {
        this.$message({
          type: "info",
          message: "请输入用户ID",
        });
        return;
      }
      const data = {
        cmd: "user",
        action: "query_user",
        data: {
          paramVO: {},
          userid: this.addUserId,
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            if (!response.data.user_data?.user_status_info || response.data.user_data?.user_status_info == null) {
              this.$message({
                type: "info",
                message: "用户不存在",
              });
              this.addUserInfoBox = [];
              return;
            }
            //用户
            this.addUserInfoBox = [
              {
                userid: response.data.user_data.user_status_info.userid,
                vip_level: response.data.user_data.user_customer_info.vip_level_name,
                vip_levels: response.data.user_data.user_customer_info.vip_small_level,
                firstname: response.data.user_data.user_customer_info.firstname,
                lastname: response.data.user_data.user_customer_info.lastname,
                phone: response.data.user_data.user_customer_info.phone,
                coins: response.data.user_data.user_wallet.coins,
                coins_give: response.data.user_data.user_wallet.coins_give,
                recharge: response.data.user_data.user_wallet.recharge,
                withdrawal: response.data.user_data.user_wallet.withdrawal,
                win_lose_diff_sum: response.data.user_data.user_wallet.win_lose_diff_sum,
                bonus: response.data.user_data.user_wallet.bonus,
              }
            ];
           // this.handleInputConfirm(response.data.user_data.user_status_info.userid)
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    handleInputConfirm(value) {
      if (value) {
        this.dynamicTags.push(value);
      }
      this.dynamicTags= this.dynamicTags.filter((item, index,self)=>{
        return self.indexOf(item)===index
      })
      this.inputVisible = false;
    },
    getVipLevelName(level) {
      const matchedLevel = this.viplevels.find(vip => vip.vipLevel == level);
      return matchedLevel ? matchedLevel.vipLevelName : '--';
    },
    async queryviplevels() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: "pob_vip_level",
        action: "query_page",
        data: {
          paramVO: params,
          page: 0,
          pageSize: 100,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            this.viplevels = response.data.list;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed red;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
