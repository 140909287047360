<template>
    <div>
      <el-row style="background-color: white; text-align: left; padding: 10px">
        <el-form :inline="true">
          <el-form-item label="用户查询">
            <el-input clearable  placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary">
              <el-icon>
                <Search />
              </el-icon>
              <span>查询并添加</span>
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <br />

      <el-table :data="tableData">
        <el-table-column label="用户ID"></el-table-column>
        <el-table-column label="税号"></el-table-column>
        <el-table-column label="绑定手机号"></el-table-column>
        <el-table-column label="真实姓名"></el-table-column>
        <el-table-column label="账户真金"></el-table-column>
        <el-table-column label="账户赏金"></el-table-column>
        <el-table-column label="出款类型"></el-table-column>
        <el-table-column label="出款金额"></el-table-column>
        <el-table-column label="税号类型"></el-table-column>
        <el-table-column label="代付通道"></el-table-column>
        <el-table-column label="出款理由"></el-table-column>
        <el-table-column label="操作"></el-table-column>
      </el-table>
    </div>
  </template>

  <script>
  export default {};
  </script>

  <style lang="scss" scoped></style>
