<template>
  <div>
    <div class="title">
      <el-text tag="b">订单状态</el-text>
    </div>
    <div class="title">
      <el-button type="info">{{statusStr}}</el-button>
    </div>
    <div class="title">
      <el-text tag="b">用户信息</el-text>
    </div>
    <el-table :data="userData">
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="firstname" label="用户姓名">
        <template #default="scope">
          <span>{{scope.row.firstname}} {{scope.row.lastname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="cpf" label="用户CPF"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="phone" label="手机号"></el-table-column>
      <el-table-column prop="vip_level" label="VIP级别"></el-table-column>
      <el-table-column prop="recharge" label="历史存款"></el-table-column>
      <el-table-column prop="old_coins" label="发起订单前钱包余额"></el-table-column>
      <el-table-column prop="crerate_ip" label="发起订单IP"></el-table-column>
      <el-table-column label="IP归属地"></el-table-column>
    </el-table>
    <br/>
    <div class="title">
      <el-text tag="b">金流信息</el-text>
    </div>
    <el-table :data="orderData">
      <el-table-column prop="amount" label="订单金额"></el-table-column>
      <el-table-column prop="payment_channel_code" label="支付通道"></el-table-column>
      <el-table-column prop="merchantorderid" label="商户订单号"></el-table-column>
      <el-table-column prop="traceid" label="支付流水号"></el-table-column>
      <el-table-column prop="account" label="到账金额"></el-table-column>
      <el-table-column prop="payment_channel_fee" label="通道手续费(平台)"></el-table-column>
      <el-table-column label="存款活动">
        <template #default="scope">
          <span v-if="scope.row.in_activity">{{scope.row.activity_code}}</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column prop="give_coins" label="赠送真金"></el-table-column>
      <el-table-column prop="give_bonus" label="赠送赏金"></el-table-column>
      <el-table-column prop="give_copper_wheel" label="赠送青铜转盘次数"></el-table-column>
      <el-table-column prop="give_gold_wheel" label="赠送黄金转盘次数"></el-table-column>
    </el-table>
    <br/>
    <div class="title">
      <el-text tag="b">流转信息</el-text>
    </div>
    <el-table :data="trData">
      <el-table-column prop="pay_time_str" label="发起存款"></el-table-column>
      <el-table-column prop="update_time_str" label="付款成功"></el-table-column>
      <el-table-column prop="create_time_str" label="流转时间"></el-table-column>
      <el-table-column prop="step_operator" label="流转处理方"></el-table-column>
      <el-table-column prop="remark" label="备注信息"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      orderData: [],
      statusStr: '',
      userData:[],
      trData:[],
    };
  },
  methods: {
    queryData() {
      const data = {
        cmd: "deposit",
        action: "query_deposit_data",
        data: {
          paramVO: {},
          id: this.id,
        },
      };
      this.$http
          .post("deposit", data)
          .then((response) => {
            //订单
            this.orderData = [response.data.user_pay_order];
            this.orderData.forEach((item) => {
              //status:订单状态1-待支付2-已支付3-已补单4-已取消
              if (item.status === 1) {
                this.statusStr = "待支付";
              } else if (item.status === 2) {
                this.statusStr = "已支付";
              } else if (item.status === 3) {
                this.statusStr = "已补单";
              } else if (item.status === 4) {
                this.statusStr = "已取消";
              }
            });
            //
            //用户
            this.userData = [
              {
                userid:response.data.user_data.user.userid,
                firstname:response.data.user_data.user_customer_info.firstname,
                lastname:response.data.user_data.user_customer_info.lastname,
                cpf:response.data.user_data.user_customer_info.customer_cert,
                email:response.data.user_data.user_customer_info.email,
                phone:response.data.user_data.user_customer_info.phone,
                vip_level:response.data.user_data.user_customer_info.vip_level_name,
                recharge:response.data.user_data.user_wallet.recharge,
                old_coins:response.data.user_pay_order.old_coins,
                crerate_ip:response.data.user_pay_order.crerate_ip,
              }
            ];
            //
            //流转
            this.trData = response.data.step_logs;
            this.trData.forEach((item) => {
              item.create_time_str = this.$tools.dateFormat(item.create_time)
              item.update_time_str = this.$tools.dateFormat(item.update_time)
              item.pay_time_str = this.$tools.dateFormat(response.data.user_pay_order.pay_time)
            });
            //
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped>
.title{
  text-align: left !important;
  margin-bottom: 20px;
}
</style>
