<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="名称">
          <el-input clearable  placeholder="等级名称" v-model="queryParams.levelName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">搜索</el-button>
          <el-button @click="resetHandle">
            <span>重置</span>
          </el-button>
          <el-button @click="addHandle">新增</el-button>
        </el-form-item>

      </el-form>
    </el-row>

    <br/>

    <el-table :data="tableData" :header-cell-style="{ 'text-align': 'center' }"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row>

      <el-table-column prop="status" align="center" label="状态">
        <template v-slot="{row}">
          <template v-if="row.status===  0">未开始</template>
          <template v-if="row.status=== 1">进行中</template>
          <template v-if="row.status=== 2">已结束</template>
          <template v-if="row.status=== 3">已下架</template>
          <template v-if="row.status===  4">已关闭</template>
        </template>
      </el-table-column>


      <el-table-column prop="title" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="beginTime" label="开始时间" align="center"></el-table-column>
      <el-table-column prop="endTime" label="结束时间" align="center"></el-table-column>

      <el-table-column prop="synopsis" label="活动概要" align="center"></el-table-column>

      <el-table-column label="Banner图" align="center" height="10px">
        <template #default="scope">
            <img :src="$tools.cdn+scope.row.imageUrl" style="height: 50px;" />
        </template>
      </el-table-column>

      <el-table-column label="Banner图" align="center" height="10px">
        <template #default="scope">
            <img :src="$tools.cdn+scope.row.imageUrl1" style="height: 50px;" />
        </template>
      </el-table-column>

      <el-table-column prop="" label="操作" width="145" align="center" >
        <template #default="scope">
          <el-button type="text" size="small" @click="editHandle(scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
          <el-button v-if="scope.row.status !== 4" type="text" size="small" @click="open(scope.$index)">关闭</el-button>
          <el-button v-if="scope.row.status === 0"  type="text" size="small" @click="opens(scope.$index)">开启</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />
  <el-dialog
      v-model="dialogFormVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
  >
    <el-form :model="item" ref="rowForm" :rules="rules" label-width="180px">

      <el-form-item label="状态" prop="status">
        <el-select placeholder="请选择" style="width: 100%" v-model="item.status">
          <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动名称" prop="title">
        <el-input v-model="item.title" placeholder="请输入活动名称（最多24个字符）"></el-input>
      </el-form-item>

      <el-form-item label="活动概要" prop="synopsis">
        <el-input v-model="item.synopsis" placeholder="请输入简明扼要的描述文案，供内部查看"></el-input>
      </el-form-item>


      <el-form-item label="结算方式" prop="activityType" align="center">
        <el-radio-group v-model="item.activityType">
          <el-radio :label="0">常规</el-radio>
          <el-radio :label="1">周活动</el-radio>
          <el-radio :label="2">月活动</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="开始时间" prop="beginTime">
      <el-date-picker
          v-model="item.beginTime"
          clearable
          style="width: 100%"
          type="datetime"
          placeholder="选择日期时间"
      />
    </el-form-item>

      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
            v-model="item.endTime"
            clearable
            style="width: 100%"
            type="datetime"
            placeholder="选择日期时间"
        />
      </el-form-item>

      <el-form-item label="参与条件" prop="participation" align="center">
        <el-radio-group v-model="item.participation">
          <el-radio :label="0">全员参加</el-radio>
          <el-radio :label="1">限定VIP</el-radio>
          <el-radio :label="2">首冲用户</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="结算方式" prop="signup" align="center">
        <el-radio-group v-model="item.signup">
          <el-radio :label="0">不需要</el-radio>
          <el-radio :label="1">需要</el-radio>
        </el-radio-group>
      </el-form-item>


      <el-form-item label="活力推荐等级" prop="activityGrade" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.activityGrade">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="统计信息" prop="statsType" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.statsType">
          <el-option
              v-for="(item, index) in statsType"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="奖金计算" prop="calculateType" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.calculateType">
          <el-option
              v-for="(item, index) in calculateType"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="奖金" prop="bonus">
        <el-input v-model="item.bonus" placeholder="请输入"></el-input>
      </el-form-item>


      <el-form-item label="奖金类型" prop="bonusType" align="center">
        <el-radio-group v-model="item.bonusType">
          <el-radio :label="0">真金</el-radio>
          <el-radio :label="1">赏金</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="联系客服" prop="serviceShow" align="center">
        <el-radio-group v-model="item.serviceShow">
          <el-radio :label="0">不显示</el-radio>
          <el-radio :label="1">显示</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="排序" prop="sort" required>
        <el-input type="number" v-model="item.sort" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="活动简介" prop="details1">
        <el-input
            v-model="item.details"
            rows="5"
            type="textarea"
            maxlength="500"
            placeholder="活动简介"
            autocomplete="off"
            show-word-limit
        />
      </el-form-item>

      <el-form-item label="如何参与" prop="how1">
        <el-input
            v-model="item.how"
            rows="5"
            type="textarea"
            maxlength="500"
            placeholder="活动简介"
            autocomplete="off"
            show-word-limit
        />
      </el-form-item>

      <el-form-item label="条款条件" prop="clause1">
        <el-input
            v-model="item.clause"
            rows="5"
            type="textarea"
            maxlength="500"
            placeholder="条款条件"
            autocomplete="off"
            show-word-limit
        />
      </el-form-item>

      <el-form-item label="Banner图" prop="URL">
        <UploadImage @onSuccess="handleAvatarSuccess" type="game" :url="item.URL"></UploadImage>
      </el-form-item>

      <el-form-item label="列表封面" prop="URL1">
        <UploadImage @onSuccess="handleAvatarSuccess1" type="game" :url="item.URL1"></UploadImage>
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import UploadImage from '@/components/UploadImage/index'
export default {
  components: {UploadImage},
  data() {
    return {
      tableData: [],
      dialogFormVisible: false,
      imageUrl: "",
      dialogTitle: "",
      cmd: 'pub_event',
      queryParams: {},
      item: {},
      keeps: [
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
      ],
      statsType: [
        {label: '全平台', value: 0},
        {label: '指定游戏类型', value: 1},
        {label: '指定供应商', value: 2},
        {label: '指定游戏', value: 3},
      ],
      calculateType: [
        {label: '存款', value: 0},
        {label: '打码量', value: 1},
        {label: '胜负差', value: 2},
        {label: '固定奖金', value: 3},
      ],
      status: [
        {label: '未开始', value: 0},
        {label: '进行中', value: 1},
        {label: '已结束', value: 2},
        {label: '已下架', value: 3},
        {label: '已关闭', value: 4},
      ],
      isEdit: false,
      rules: {
        title: [
          {required: true, message: '请输入VIP名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 24个字符', trigger: 'blur'}
        ],
        status: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        sort: [
          {required: true, message: '请输入概要', trigger: 'blur'},
        ],
        synopsis: [
          {required: true, message: '请输入概要', trigger: 'blur'},
        ],
        activityType: [
          {required: true, message: '请选择结算方式', trigger: 'blur'},
        ],
        beginTime: [
          {required: true, message: '请选择时间', trigger: 'blur'},
        ],
        endTime: [
          {required: true, message: '请选择时间', trigger: 'blur'},
        ],

        participation: [
          {required: true, message: '请选择参与条件', trigger: 'blur'},
        ],

        signup: [
          {required: true, message: '请选择结算方式', trigger: 'blur'},
        ],
        activityGrade: [
          {required: true, message: '请选择活力推荐等级', trigger: 'blur'},
        ],
        statsType: [
          {required: true, message: '请选择统计信息', trigger: 'blur'},
        ],

        calculateType: [
          {required: true, message: '请选择奖金计算', trigger: 'blur'},
        ],

        bonus: [
          {required: true, message: '请输入奖金', trigger: 'blur'},
        ],

        bonusType: [
          {required: true, message: '请选择奖金类型', trigger: 'blur'},
        ],
        serviceShow: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        URL: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],
        URL1: [
          {required: true, message: '请选择图片', trigger: 'blur'},
        ],

      }
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    /**
     * 新增按钮事件
     */
    addHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogFormVisible = true;
    },

    /**
     * 删除按钮事件
     * @param {*} index
     */
    deleteHandle(index) {
      this.$confirm("确定要删除么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let item = this.tableData[index];
            this.deleteData(item.id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    /**
     * 编辑
     * @param {*} index
     */
    editHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.isEditDialog = true;
      this.dialogFormVisible = true;
    },

    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.dialogFormVisible = false;
    },
    /**
     * dialog确定按钮事件
     */
    confirmHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        } else {
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.dialogFormVisible = false;
        }
      })
    },

    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.tableData.forEach((itme) => {
              itme.URL = itme.imageUrl?this.$tools.cdn+itme.imageUrl:''
              itme.URL1 = itme.imageUrl1?this.$tools.cdn+itme.imageUrl1:''
            });
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    /**
     * 新增数据接口
     */
    addData() {
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 更新数据接口
     */
    updateData() {
      const data = {
        cmd: this.cmd,
        action: "update",
        data: {
          paramVO: this.item,
        },
      };

      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    /**
     * 删除数据接口
     */
    deleteData(id) {
      const data = {
        cmd: this.cmd,
        action: "delete",
        data: {paramVO: {id}},
      };
      this.$http
          .post("popup", data)
          .then((response) => {
            // 请求成功后的处理
            console.log(response.data);
            this.queryData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          })
          .catch((error) => {
            // 请求失败后的处理
            console.error(error);
          });
    },

    open(index) {
      this.$confirm('确定要关闭吗', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item = JSON.parse(JSON.stringify(this.tableData[index]));
        if (this.item.status === 1) {
          this.$confirm('活动还在进行中是否关闭', "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
              this.item.status = 4
              this.updateData();
          })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消关闭",
                });
              });
        } else {
          this.item.status = 4
          this.updateData();
        }
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消关闭",
            });
          });
    },
    opens(index) {
      this.$confirm("确定要开启吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.item =JSON.parse(JSON.stringify(this.tableData[index]));
        this.item.status  = 1
        this.updateData();
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消开启",
            });
          });
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },

    /**
     * 图片上传成功回调
     */
    handleAvatarSuccess(data) {
      this.item.imageUrl = data.file_url;
      this.item.URL = data.url;
    },
    /**
     * 图片上传成功回调
     */
    handleAvatarSuccess1(data) {
      this.item.imageUrl1 = data.file_url;
      this.item.URL1 = data.url;
    }

  },
};
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
