<template>
  <div class="container">
    <div>BetMace</div>
    <div>Betmacae.com</div>
    <div class="context">
      <p>账号密码登录</p>
      <div class="account">
        <input type="text" v-model="loginForm.username" placeholder="账户"/>
      </div>
      <div class="password">
        <input type="password" v-model="loginForm.password" placeholder="密码"/>
      </div>
<!--      <div class="options">-->
<!--        <div>-->
<!--          <input type="checkbox"/>-->
<!--          <label>自动登录</label>-->
<!--        </div>-->
<!--        <button>忘记密码</button>-->
<!--      </div>-->
    </div>
    <button class="login" @click="handleLogin">登 录</button>
  </div>
</template>

<script>
import {useToast} from "vue-toast-notification";
import loginHttp from "../utils/loginHttp"
import "vue-toast-notification/dist/theme-sugar.css";
import {setToken} from "@/utils/token";
import {removeGoogleType} from "@/utils/google";

const $toast = useToast();
export default {
  data() {
    const loginForm = {username: '', password: ''}
    return {
      loginForm,
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      otherQuery: {}
    }
  },
  mounted() {

  },
  methods: {
    async handleLogin() {
      if (!this.loginForm.username) {
        $toast.warning("请填写用户名", {duration: 3000});
        return
      }
      if (this.loginForm.password.length < 6) {
        $toast.warning("密码长度不得小于6位", {duration: 3000});
        return
      }
      this.loading = true
      loginHttp.post('/core/login', this.loginForm)
          .then((response) => {
            if (response != null) {
              setToken(response.sessionid)
              removeGoogleType();
              window.location.href = this.getRootPath_dc()
            }
          })
    },
    getRootPath_dc() {
      var pathName = window.location.pathname.substring(1);
      var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
      if (webName == "") {
        return window.location.protocol + '//' + window.location.host;
      } else {
        return window.location.protocol + '//' + window.location.host + '/' + webName;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  display: flex;
  flex-direction: column;

  .context {
    margin: auto;

    p {
      text-align: left;
    }

    .account {
      margin: 40px 0;
      width: 368px;
      height: 40px;
      line-height: 40px;

      input {
        width: 100%;
        height: 100%;
      }
    }

    .password {
      margin: 40px 0;
      width: 368px;
      height: 40px;
      line-height: 40px;

      input {
        width: 100%;
        height: 100%;
      }
    }

    .options {
      display: flex;
      justify-content: space-between;
    }
  }

  .login {
    margin: 20px auto;
    width: 368px;
    height: 40px;
    background: #1890ff;
    border: 0;
    border-radius: 2px;
    color: white;
    font-size: 16px;
  }
}
</style>
