<template>
      <div class="d2">
    <el-button class="btn1" type="primary"   @click="addItemHandle">新增</el-button>
        </div>

  <el-row style="background-color: white; text-align: left; padding: 10px">
    <el-form :inline="true">
      <el-form-item label="用户名">
        <el-input
            clearable
            placeholder="请输入"
            v-model="queryParams.name"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()">
          <el-icon>
            <Search/>
          </el-icon>
          <span>搜索</span>
        </el-button>
        <el-button @click="resetHandle">
          <el-icon>
            <Refresh/>
          </el-icon>
          <span>重置</span>
        </el-button>
      </el-form-item>
    </el-form>
  </el-row>
  <br/>
  <el-table
      :data="tableData"
      :header-cell-style="{ 'text-align': 'center' }"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
  >
    <el-table-column fixed prop="id" label="id" align="center"></el-table-column>
    <el-table-column fixed prop="name" label="用户名" align="center"></el-table-column>
    <el-table-column fixed prop="username" label="登录名" align="center"></el-table-column>
    <el-table-column fixed prop="phone" label="手机号" align="center"></el-table-column>
    <el-table-column fixed prop="email" label="邮箱" align="center"></el-table-column>
    <el-table-column fixed label="是否可见统计数据" align="center">
      <template #default="scope">
        <span v-if="scope.row.showViewData===0">否</span>
        <span v-else-if="scope.row.showViewData===1">是</span>
        <span v-else>--</span>
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="操作" width="170" align="center" class-name="table_lastTd">
      <template #default="scope">
        <el-button  type="primary" size="small" @click="itemEditHandle(scope.$index)">编辑</el-button>
        <el-button type="primary" size="small" @click="deleteHandle(scope.$index)">删除</el-button>
        <el-button  type="primary" size="small" @click="updatepassword(scope.$index)">重置密码</el-button>
        <el-button  type="primary" size="small" @click="deleteGoodle(scope.$index)">清除谷歌验证器</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      :page-size="20"
      layout="total,prev, pager, next, jumper"
      :total="total"
      @current-change="pageChangeHandle"
  />

  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @open="queryList"
  >

    <el-form ref="rowForm" :model="item" :rules="rules"  label-width="120px">

      <el-form-item label="用户名" prop="name" required>
        <el-input v-model="item.name" placeholder="请输入"></el-input>
      </el-form-item>

    <el-form-item label="登录名" prop="username" required>
        <el-input v-model="item.username" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="密码"  v-if="!isEdit" prop="newPassword" required>
        <el-input v-model="item.newPassword" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="手机号" prop="phone">
        <el-input v-model="item.phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="item.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="查看统计数据" prop="showViewData" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.showViewData">
          <el-option
              v-for="(item, index) in showViewData"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用" prop="disabled" required>
        <el-select placeholder="请选择" style="width: 100%" v-model="item.disabled">
          <el-option
              v-for="(item, index) in keeps"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="roleList">
        <el-select
            v-model="roleList"
            value-key="id"
            placeholder="请选择角色"
            filterable
            multiple
            clearable
            collapse-tags
            style="display:block; width: 100%;"
        >
          <el-option
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item"
              :multiple="true"
          />
        </el-select>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="dialogOkHandle">发布</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
      v-model="password"
      :title="dialogTitle"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      @open="queryList"
  >
    <el-form ref="rowForm" :model="item" :rules="rules"  label-width="120px">

      <el-form-item label="密码"  prop="newPassword" required>
        <el-input v-model="item.newPassword" placeholder="请输入"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogCancelHandles">取消</el-button>
        <el-button type="primary" @click="cipher">保存</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  data() {
    return {
      keeps: [
        {label:'是',value:0},
        {label:'否',value:1},
      ],
      showViewData: [
        {label:'否',value:0},
        {label:'是',value:1},
      ],
      roleList: [],
      roles: [],
      tableData: [],
      total: 0,
      pages: 0,
      page: 0,
      item: {},
      isEdit: undefined,
      selectedRow: {},
      queryParams: {},
      dialogVisible: false,
      password: false,
      dialogTitle: "",
      cmd: "pub_platform_user",
      data: {cmd: this.cmd, action: "query_page", data: {paramVO: {}},},
      rules: {
        name: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入登录名', trigger: 'blur'},
        ],
        newPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        //phone: [
        //  {required: true, message: '请输入手机号', trigger: 'blur'},
        //],
        disabled: [
          {required: true, message: '请选择', trigger: 'blur'},
        ],
        showViewData: [
          {required: true, message: '请选择', trigger: 'blur'},
        ]
      }
    }
  },
  created() {
    this.queryData()
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    async queryData(index = 0) {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      const data = {
        cmd: this.cmd,
        action: "query_page",
        data: {
          paramVO: params,
          page: index,
        }
      }
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data.list);
            this.tableData = response.data.list;
            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    dialogCancelHandle() {
      this.$refs.rowForm.resetFields()
      this.roles = []
      this.roleList = []
      this.dialogVisible = false;
    },
    dialogCancelHandles() {
      this.$refs.rowForm.resetFields()
      this.password = false;
    },
    dialogOkHandle() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          this.item.rolesList =  this.roleList
          if (this.isEdit) {
            this.updateData();
          } else {
            this.addData();
          }
          this.roles = []
          this.roleList = []
          this.dialogVisible = false;
        }
      })
    },

    cipher() {
      this.$refs.rowForm.validate((valid) => {
        if (!valid) {
          this.$message({
            type: "info",
            message: "请填写数据",
          })
        }else {
          this.item.rolesList =  this.roleList
          this.updateData();
          this.roles = []
          this.roleList = []
          this.password = false;
        }
      })
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    itemEditHandle(index) {
      this.isEdit = true;
      this.dialogTitle = "编辑";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.dialogVisible = true;
    },
    updatepassword(index) {
      this.dialogTitle = "重置密码";
      this.item = JSON.parse(JSON.stringify(this.tableData[index]));
      this.password = true;
    },
    deleteGoodle(index) {
      let info = this.tableData[index];
      this.data.data.paramVO = {
        id:info.id
      }
      this.data.action = "delete_goodle"
      this.$http
          .post(this.cmd, this.data)
          .then(() => {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    updateData() {
      this.data.action = "update"
      this.data.data.paramVO = this.item
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    addData() {
      this.item.node = 0
      const data = {
        cmd: this.cmd,
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
          .post(this.cmd, data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    deleteHandle(index) {
      this.$confirm("确定要删除么?删除后无法回复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let item = this.tableData[index];
        this.deleteData(item.id);
      })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    deleteData(id) {
      this.data.action = 'delete'
      this.data.data.paramVO.id = id
      this.$http
          .post(this.cmd, this.data)
          .then((response) => {
            console.log("response.data", response.data);
            this.$message({
              type: "info",
              message: "删除成功!",
            });
            this.queryData(this.page);
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

    async queryList() {
      const data = {
        cmd: 'pub_role',
        action: "list",
        data: {
          paramVO: {}
        }
      }
      this.$http
          .post('pub_role', data)
          .then((response) => {
            console.log("response.data", response.data.list);
            // 集合
            this.roles = response.data.list;
            if (this.item.roles) { // 编辑时填充角色列表
              this.roleList = JSON.parse(this.item.roles).map(id => this.roles.find(row => row.id === id))
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
  }
}
</script>
<style>
.d2 {
  min-width: 50%;
  height: 45px;
  position: relative;

  justify-content:flex-end;

}
.btn1 {
  position: relative;
  right: 10px;
  top: 10px;
}
</style>
