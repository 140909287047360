<template>
  <div class="big-page">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5"
              class="page-title"
      >
        平台数据
      </el-col>
      <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15"
              class="page-top-text p-right"
      >
        <span class="top-total">在线人数：{{ userData.sum }} &nbsp; |</span>
        <div class="top-total-box">
          <span class="top-total-single">IOS：{{ userData.ios }}</span>
          <span class="top-total-single">Android: {{ userData.android }}</span>
        </div>
        <div class="top-total-box">
          <span class="top-total-single">web在线：{{ userData.web }}</span>
          <span class="top-total-single">其它: {{ userData.other }}</span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4"
              class="page-top-text p-right"
      >
        统计时间：{{ statisticalTime }}
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="9" :lg="5" :xl="5"
              class="search-item"
      >
        <el-date-picker
            clearable
            v-model="queryParams.time"
            value-format="YYYY-MM-DD"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
        ></el-date-picker>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button :type="active===0?'warning':'primary'" @click="changeTime(0)">
          <span>今日</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button :type="active===1?'warning':'primary'" @click="changeTime(1)">
          <span>昨日</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button :type="active===2?'warning':'primary'" @click="changeTime(2)">
          <span>上周</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button :type="active===3?'warning':'primary'" @click="changeTime(3)">
          <span>上月</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button :type="active===4?'warning':'primary'" @click="changeTime(4)">
          <span>截至昨日</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button type="primary" @click="loadData">
          <span>查询</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button type="primary" @click="resetting">
          <span>重置</span>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button type="primary">
          <router-link :to="'/analysis'">
            <span>时段统计</span>
          </router-link>
        </el-button>
      </el-col>
      <el-col :xs="24" :sm="24" :md="6" :lg="2" :xl="2"
              class="search-btn"
      >
        <el-button type="primary" @click="refreshDay" v-loading="refreshDayLoad">
          <span>刷新今日数据</span>
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>
            <el-tooltip content="入账存款-入账提款" placement="left">
              <div class="card-header">
                <span>总盈利</span>

              </div>
            </el-tooltip>
          </template>


          <div class="content-title more-big green" v-if="allInfo.total_profit>=0">
            {{ allInfo.total_profit }}
          </div>
          <div class="content-title more-big red" v-if="allInfo.total_profit<0">
            {{ allInfo.total_profit }}
          </div>




        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>

            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="充值到账总额(含人工补单）-通道手续费(0.5%)" placement="left">
                  <div style="float: left"> 入账存款</div>
                </el-tooltip>
                <div style="float: right">
                  <a href=".#/deposit-list" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>

          </template>
          <div class="content-box">
            <div class="content-title needSetColor">{{ checkValue(allInfo.total_deposit) }}</div>
            <div class="content-body">

              <div class="text item">
                <el-tooltip
                    content="单日查询与截至昨日，此为去重后的人数；按时间段汇总时，人数数据可能重复计算。注意：仪表盘内所有人数相关数据如果按时间段汇总，皆存在此情况"
                    placement="left"> 存款人数：</el-tooltip>{{ checkValue(allInfo.coins_pay_in_person_dis_count) }}


              </div>

              <div class="text item">存款笔数：{{ checkValue(allInfo.coins_pay_in_person_sum_count) }}</div>


              <div class="text item">
                <el-tooltip content="存款成功人数/发起存款人数" placement="left">用户成功率：</el-tooltip>{{(checkValue(allInfo.coins_pay_in_success_rate) * 100).toFixed(2) }}%
</div>


              <div class="text item">
                <el-tooltip content="入账存款总额/存款成功人数" placement="left">
                  ARPPU：</el-tooltip> <span class="needSetColor">{{ (checkValue(allInfo.arppu)).toFixed(2) }}</span>


              </div>

            </div>
          </div>
        </el-card>
      </el-col>


      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip
                    content="用户成功提款-平台扣除手续费+佣金成功提款-佣金提款手续费+佣金人工成功出款-人工出款手续费"
                    placement="left">
                  <div style="float: left"> 入账提款</div>
                </el-tooltip>
                <div style="float: right;color: #999999;font-size: small">
                  <a href=".#/draw-money-list" target="_self" style="color: #999999;font-size: small">现金</a>  | <a href=".#/agency-payment" target="_self" style="color: #999999;font-size: small">佣金</a>

                </div>
              </div>
            </div>


          </template>
          <div class="content-box">
            <div class="content-title needSetColorLoss">{{ checkValue(allInfo.total_withdrawa) }}</div>
            <div class="content-body">

                <div class="text item ">   <el-tooltip
                    content="用户主动发起且已到账的现金提款"
                    placement="left">现金：</el-tooltip><span
                    class="needSetColorLoss">{{ checkValue(allInfo.order_coins_pay_out_success_amount) }}</span>
                  <span style="font-size: small;color: #999999"> |
                {{
                      checkValue(allInfo.order_coins_pay_out_success_person_dis_count)
                    }}
                  人，{{ checkValue(allInfo.order_coins_pay_out_success_person_sum_count) }} 笔，手续费 <span
                        class="needSetColor">{{
                        checkValue(allInfo.order_coins_pay_out_success_commission_amount)
                      }}</span></span>

                </div>



                <div class="text item "><el-tooltip
                    content="代理主动发起且已到账的佣金提款"
                    placement="left">佣金：</el-tooltip><span
                    class="needSetColorLoss">{{
                    checkValue(allInfo.order_commission_pay_out_success_amount)
                  }}</span>
                  <span style="font-size: small;color: #999999"> |
                {{
                      checkValue(allInfo.order_commission_pay_out_success_person_dis_count)
                    }}
                  人，{{ checkValue(allInfo.order_commission_pay_out_success_person_sum_count) }} 笔，手续费 <span
                        class="needSetColor">{{
                        checkValue(allInfo.order_commission_pay_out_success_commission_amount)
                      }}</span></span>

                </div>



                <div class="text item">
                  <el-tooltip content="此处入账人工出款特指佣金部分，走代付，已到账" placement="left">人工：</el-tooltip>
                  <span
                      class="needSetColorLoss">{{
                      checkValue(allInfo.order_commission_operate_pay_out_success_amount)
                    }}</span>
                  <span style="font-size: small;color: #999999"> |
                {{
                      checkValue(allInfo.order_commission_operate_pay_out_success_person_dis_count)
                    }}
                  人，{{ checkValue(allInfo.order_commission_operate_pay_out_success_person_sum_count) }} 笔，手续费 <span
                        class="needSetColor">{{
                        checkValue(allInfo.order_commission_operate_pay_out_success_commission_amount)
                      }}</span></span>

                </div>



            </div>
          </div>
        </el-card>
      </el-col>




      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


              <div class="card-header">
                <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                  <el-tooltip content="用户发起现金存款订单汇总，含失败等订单" placement="left">
                    <div style="float: left"> 发起存款</div>
                  </el-tooltip>
                  <div style="float: right">
                    <a href=".#/deposit-list" target="_self" style="color: #999999;font-size: small">更多</a>
                  </div>
                </div>
              </div>

          </template>
          <div class="content-box">
            <div class="content-title"><span
                class="needSetColor">{{ checkValue(allInfo.order_coins_pay_in_amount) }}</span></div>
            <div class="content-body">
              <div class="text item">存款人数：{{ checkValue(allInfo.order_coins_pay_in_person_dis_count) }}</div>
              <div class="text item">存款笔数：{{ checkValue(allInfo.order_coins_pay_in_person_sum_count) }}</div>
              <el-tooltip content="存款订单成功笔数/存款订单总笔数" placement="left">
                <div class="text item">
                  总成功率：{{ (checkValue(allInfo.order_coins_pay_in_success_rate) * 100).toFixed(2) }}%
                </div>
              </el-tooltip>

            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>

            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="用户或代理发起提款款订单汇总，含已发起、待处理、已到账、失败等订单" placement="left">
                  <div style="float: left"> 发起提款</div>
                </el-tooltip>
                <div style="float: right">
                  <a href=".#/draw-money-list" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>

          </template>
          <div class="content-box">
            <div class="content-title"><span class="needSetColorLoss">{{
                checkValue(allInfo.order_coins_pay_out_amount) + checkValue(allInfo.order_commission_pay_out_amount)
              }}</span></div>



            <div class="content-body">  现金：<span
                class="needSetColorLoss">{{ checkValue(allInfo.order_coins_pay_out_amount) }}</span>
              <span style="font-size: small;color: #999999"> |
                {{
                  checkValue(allInfo.order_coins_pay_out_person_dis_count)
                }}
                  人，{{ checkValue(allInfo.order_coins_pay_out_person_sum_count) }} 笔</span>

            </div>

            <div class="content-body">  佣金：<span
                class="needSetColorLoss">{{ checkValue(allInfo.order_commission_pay_out_amount) }}</span>
              <span style="font-size: small;color: #999999"> |
                {{
                  checkValue(allInfo.order_commission_pay_out_person_dis_count)
                }}
                  人，{{ checkValue(allInfo.order_commission_pay_out_person_sum_count) }} 笔</span>

            </div>


          </div>
        </el-card>
      </el-col>


      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="当日有过下注、充值（成功）、提款（成功）的用户数" placement="left">
                  <div style="float: left"> 活跃用户（人数）</div>
                </el-tooltip>
                <div style="float: right;color: #999999;font-size: small">
                  <a href=".#/user-login-list" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>

          </template>
          <div class="content-box">
            <div class="content-title">{{ checkValue(allInfo.user_active_sum) }}</div>
            <div class="content-body">
              <div class="text item">
              <el-tooltip content="当日登录或访问过平台的用户数" placement="left">
             登录用户：</el-tooltip>{{checkValue(allInfo.user_login_sum) }}</div>
              <div class="text item"> <el-tooltip content="活跃用户/登录用户" placement="left">活跃占比：</el-tooltip>{{(checkValue(allInfo.active_user_proportion)* 100).toFixed(2) }}%</div>

              <div class="text item"> <el-tooltip content="充值成功用户/活跃用户" placement="left">总付费率：</el-tooltip>{{(checkValue(allInfo.user_payment_rate)* 100).toFixed(2) }}%</div>



            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="时段内新增注册用户中的活跃用户（下注、付款成功、提款成功）" placement="left">
                  <div style="float: left"> 新增活跃用户（人数）</div>
                </el-tooltip>

                <div style="float: right">
                  <a href=".#/test" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <div class="content-title">{{ checkValue(allInfo.new_user_register_active_sum) }}</div>
            <div class="content-body">

              <div class="text item"> <el-tooltip content="时段内新增注册用户" placement="left">新增用户：</el-tooltip>{{checkValue(allInfo.user_new_register_sum)}}</div>


                <div class="text item">  <el-tooltip content="时段内新增注册用户的付费情况(含人工入款,未扣除手续费)" placement="left">新增付费：</el-tooltip>{{checkValue(allInfo.new_user_pay_in_person_sum)}} 人 | <span
                    class="needSetColor"> {{ checkValue(allInfo.new_user_pay_in_amount_sum) }}</span></div>


                <div class="text item">   <el-tooltip content="新增付费总额/新增注册用户数" placement="left">人均付费：</el-tooltip><span
                    class="needSetColor">{{ checkValue(allInfo.paid_conversion_rate) }}</span></div>

              <!--              <div class="text item">新增存款：<span style="color: forestgreen">R$ {{ checkValue(allInfo.today_new_pay_amount) }}</span></div>-->
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>



            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="只计算注单的现金部分汇总 输赢差=下注额-结算额" placement="left">
                  <div style="float: left">现金输赢差</div>
                </el-tooltip>

                <div style="float: right">
                  <a href=".#/user-game-list" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>

          </template>
          <div class="content-box">


            <div class="content-title  green" v-if="allInfo.order_coins_bet_win_lose_diff>=0">
              {{ allInfo.order_coins_bet_win_lose_diff }}
            </div>
            <div class="content-title  red" v-if="allInfo.order_coins_bet_win_lose_diff<0">
              {{ allInfo.order_coins_bet_win_lose_diff }}
            </div>




            <div class="content-body">
              <div class="text item"> 下注：<span  class="needSetColor">
               {{ checkValue(allInfo.order_coins_bet_amount) }}</span>
              </div>
              <div class="text item">结算：<span  class="needSetColorLoss">{{
                  checkValue(allInfo.order_coins_win_amount)
                }}</span></div>

                <div class="text item"> <el-tooltip content="注单有任意奖金，则以奖金注单计算人数与回合" placement="left">人数：</el-tooltip>{{ checkValue(allInfo.order_coins_bet_user_count) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  回合：{{ checkValue(allInfo.order_coins_bet_round_times) }}
                </div>


                <div class="text item">  <el-tooltip content="slots游戏：打码量=下注额，其他游戏：打码量=|下注额-结算额|" placement="left">打码：</el-tooltip>{{ checkValue(allInfo.order_coins_bet_code_amount) }}</div>
              <div class="text item"><el-tooltip content="有效下注=min(下注额,打码量)" placement="left">有效下注：</el-tooltip><span
                  class="needSetColor">{{ checkValue(allInfo.order_effective_coins_bet_amount) }}</span>
              </div>



              <!--              <div class="text item">抽&nbsp;&nbsp;水：{{ checkValue(allInfo.pump_coins_amount) }}</div>-->
              <!--              <div class="text item">打码量：{{ checkValue(allInfo.order_coins_bet_code_amount) }}</div>-->
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>
            <el-tooltip content=" " placement="left">

              <div class="card-header">
                <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                  <el-tooltip content="只计算注单的奖金部分汇总 输赢差=下注额-结算额" placement="left">
                    <div style="float: left">奖金输赢差</div>
                  </el-tooltip>

                  <div style="float: right">
                    <a href=".#/user-game-list" target="_self" style="color: #999999;font-size: small">更多</a>
                  </div>
                </div>
              </div>

            </el-tooltip>
          </template>
          <div class="content-box">

            <div class="content-title  green" v-if="allInfo.order_give_bet_win_lose_diff>=0">
              {{ allInfo.order_give_bet_win_lose_diff }}
            </div>
            <div class="content-title  red" v-if="allInfo.order_give_bet_win_lose_diff<0">
              {{ allInfo.order_give_bet_win_lose_diff }}
            </div>
            <div class="content-body">
              <div class="text item">下注：<span class="needSetColor">
                {{ checkValue(allInfo.order_give_bet_amount) }}</span>
              </div>
              <div class="text item">结算：<span  class="needSetColorLoss">{{
                  checkValue(allInfo.order_give_win_amount)
                }}</span></div>
              <el-tooltip content="注单有任意奖金，则以奖金注单计算人数与回合" placement="left">
                <div class="text item">人数：{{ checkValue(allInfo.order_give_bet_user_count) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  回合：{{ checkValue(allInfo.order_give_bet_round_times) }}
                </div>
              </el-tooltip>
              <el-tooltip content="奖金打码量以 标准打码公式/30 来计" placement="left">
                <div class="text item">打码：{{ checkValue(allInfo.order_give_bet_code_amount) }}</div>
              </el-tooltip>

              <!--              <div class="text item">抽&nbsp;&nbsp;水：{{ checkValue(allInfo.pump_bonus_amount) }}</div>-->
              <!--              <div class="text item">打码量：{{ checkValue(allInfo.order_give_bet_code_amount) }}</div>-->
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="现金+奖金非充值送出总额" placement="left">
                  <div style="float: left">活动成本</div>
                </el-tooltip>

                <div style="float: right">
                  <a href=".#/gift-money-log" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <div class="content-title needSetColorLoss">
              {{ (checkValue(allInfo.give_coins_real_amount)+checkValue(allInfo.give_coins_give_amount)).toFixed(2) }}


            </div>
            <div class="content-body">





              <div class="text item">
                <el-tooltip content="类目：注册赠送、充值赠送、活动赠送、任务赠送、输返、赢返、下注返、存钱罐、新手任务、每日签到、赠金券、白银返利、黄金俱乐部、今日挑战" placement="left">奖金：</el-tooltip>
                <span
                    class="needSetColorLoss">{{
                    checkValue(allInfo.give_coins_give_amount)
                  }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.give_coins_give_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.give_coins_give_person_sum_count) }} 笔</span>

              </div>


              <div class="text item">
                <el-tooltip content="类目：注册赠送、赏金转换任务、充值赠送、活动赠送、新手任务、每日签到、白银返利" placement="left">现金：</el-tooltip>
                <span
                    class="needSetColorLoss">{{
                    checkValue(allInfo.give_coins_real_amount)
                  }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.give_coins_real_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.give_coins_real_person_sum_count) }} 笔</span>

              </div>



            </div>
          </div>
        </el-card>
      </el-col>


      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="代理推荐人头奖+代理直推达标奖励+代理合作费解冻+人工入-入工扣" placement="left">
                  <div style="float: left">代理费用</div>
                </el-tooltip>

                <div style="float: right">
                  <a href=".#/agency-pubUserWalletChangeLog" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <div class="content-title"
                 v-if="allInfo.total_agent_amount===null ||  allInfo.total_agent_amount==='' || allInfo.total_agent_amount=== undefined">
              0
            </div>
            <div class="content-title red" v-if="allInfo.total_agent_amount>=0">
              {{ allInfo.total_agent_amount }}
            </div>
            <div class="content-title greed" v-if="allInfo.total_agent_amount<0">
              {{ allInfo.total_agent_amount }}
            </div>
            <div class="content-body">
              <div class="text item ">人头奖励：{{ checkValue(allInfo.agent_invite_bonus_amount) }}</div>
              <div class="text item ">直推达标：{{ checkValue(allInfo.agent_invite_level_bonus_amount) }}</div>



              <div class="text item">合作解冻：<span
                  class="needSetColorLoss">{{
                  checkValue(allInfo.freeze_coins_to_commission_amount)
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.freeze_coins_to_commission_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.freeze_coins_to_commission_person_sum_count) }} 笔</span>

              </div>

              <div class="text item">人工入款：<span
                  class="needSetColorLoss">{{
                  checkValue(allInfo.commission_operate_in_amount)
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.commission_operate_in_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.commission_operate_in_person_sum_count) }} 笔</span>

              </div>
              <div class="text item">人工扣款：<span
                  class="needSetColor">{{
                  checkValue(allInfo.commission_operate_out_amount)*-1
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.commission_operate_out_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.commission_operate_out_person_sum_count) }} 笔</span>

              </div>





            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>

            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="代理主动发起提现到账金额+人工代发起提现到账金额-各自手续费金额" placement="left">
                  <div style="float: left">代理提现</div>
                </el-tooltip>

                <div style="float: right">
                  <a href=".#/agency-pubagentinfo" target="_self" style="color: #999999;font-size: small">更多</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <div class="content-title red">{{ checkValue(allInfo.total_agent_commission_amount) }}</div>
            <div class="content-body">
              <div class="text item">  <el-tooltip content="有一级直推用户注册即算代理，当前代理总数" placement="left">总代理数：{{ checkValue(dayNewInfo.total_agent_count) }} </el-tooltip></div>
<!--              <div class="text item">代理发起：   {{-->
<!--                  checkValue(allInfo.order_commission_pay_out_success_amount)-->
<!--                }}-->


<!--                {{-->
<!--                  checkValue(allInfo.order_commission_pay_out_success_person_dis_count)-->
<!--                }}-->
<!--              </div>-->
              <div class="text item"> <el-tooltip content="成功提现人数/发起提现人数" placement="left">成功占比：</el-tooltip>{{ (checkValue(allInfo.commission_pay_out_proportion)* 100).toFixed(2)  }}%</div>
              <div class="text item"> <el-tooltip content="佣金转到现金的总额" placement="left">佣转现金：</el-tooltip><span
                  class="needSetColorLoss">{{
                  checkValue(allInfo.agent_commission_to_coins_amount)*-1
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.agent_commission_to_coins_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.agent_commission_to_coins_person_sum_count) }} 笔</span>

              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="此处特指人工在后台处理站内资金（无真实资金变动，不走代收代付）" placement="left">
                  <div style="float: left"> 站内资金变动-用户钱包</div>
                </el-tooltip>
                <div style="float: right">
                  <a href=".#/manual-disbursement" target="_blank" style="color: #999999;font-size: small">入款</a>
                  | <a href=".#/manual-manual-payment" target="_blank" style="color: #999999;font-size: small">出款</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <!--            <div class="content-title qingqing">{{ checkValue(allInfo.coins_operate_in_amount) }}</div>-->
            <div class="content-body">
              <!--              <div class="text item">入款人工补单：X 笔 X人 X金额</div>-->
              <!--              <hr style="border-color: darkgrey;border-style: dotted;">-->
              <!--              <div class="text item">现金入：<span-->
              <!--                  style="color: red">R$ {{ checkValue(allInfo.oper_coins_real_in) }}</span></div>-->
              <!--              <div class="text item">现金出：<span-->
              <!--                  style="color: forestgreen">R$ {{ checkValue(allInfo.oper_coins_real_out) }}</span></div>-->
              <!--              <div class="text item">奖金入：<span-->
              <!--                  style="color: red">R$ {{ checkValue(allInfo.oper_coins_give_in) }}</span></div>-->
              <!--              <div class="text item">奖金出：<span-->
              <!--                  style="color: forestgreen">R$ {{ checkValue(allInfo.oper_coins_give_out) }}</span></div>-->



              <div class="text item">现金入款：<span
                  class="needSetColorLoss">{{
                  checkValue(allInfo.coins_operate_in_amount)
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.coins_operate_in_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.coins_operate_in_person_sum_count) }} 笔</span>

              </div>

              <div class="text item">现金扣除：<span
                  class="needSetColor">{{
                  checkValue(allInfo.coins_operate_out_amount)*-1
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.coins_operate_out_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.coins_operate_out_person_sum_count) }} 笔</span>

              </div>

              <div class="text item">奖金入款：<span
                  s class="needSetColorLoss">{{
                  checkValue(allInfo.coins_give_operate_in_amount)
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.coins_give_operate_in_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.coins_give_operate_in_person_sum_count) }} 笔</span>

              </div>

              <div class="text item">奖金扣除：<span
                  class="needSetColor">{{
                  checkValue(allInfo.coins_give_operate_out_amount)*-1
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.coins_give_operate_out_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.coins_give_operate_out_person_sum_count) }} 笔</span>

              </div>




            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>


            <div class="card-header">
              <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
                <el-tooltip content="此处特指人工在后台处理站内资金（无真实资金变动，不走代收代付）" placement="left">
                  <div style="float: left"> 站内资金变动-代理钱包</div>
                </el-tooltip>
                <div style="float: right">
                  <a href=".#/manual-disbursement" target="_blank" style="color: #999999;font-size: small">入款</a>
                  | <a href=".#/manual-manual-payment" target="_blank" style="color: #999999;font-size: small">出款</a>
                </div>
              </div>
            </div>
          </template>
          <div class="content-box">
            <!--            <div class="content-title qingqing">{{ checkValue(allInfo.coins_operate_in_amount) }}</div>-->
            <div class="content-body">



              <div class="text item">合作费入：<span
                  class="needSetColorLoss">{{
                  checkValue(allInfo.freeze_coins_operate_in_amount)
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.freeze_coins_operate_in_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.freeze_coins_operate_in_person_sum_count) }} 笔</span>

              </div>


              <div class="text item">合作费扣：<span
                  class="needSetColor">{{
                  checkValue(allInfo.freeze_coins_operate_out_amount)*-1
                }}</span>
                <span style="font-size: small;color: #999999"> |
                {{
                    checkValue(allInfo.freeze_coins_operate_out_person_dis_count)
                  }}
                  人，{{ checkValue(allInfo.freeze_coins_operate_out_person_sum_count) }} 笔</span>

              </div>



            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6"
      >
        <el-card class="box-card">
          <template #header>



            <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis">
              <el-tooltip content="针对当前七日内登录用户的活跃资金情况分析" placement="left">
                <div style="float: left"> 平台存点快照-七日登录</div>
              </el-tooltip>
              <div style="float: right">
                <a href=".#/manual-disbursement" target="_blank" style="color: #999999;font-size: small">更多</a>

              </div>
            </div>

          </template>
          <div class="content-box">
            <div class="content-title green">{{
                sumFour(dayNewInfo.total_active_coins_over,
                    dayNewInfo.total_active_coins_give_over,
                    dayNewInfo.total_inactive_coins_over,
                    dayNewInfo.total_inactive_coins_give_over)
              }}
            </div>
            <div class="content-body">
              <div class="text item">活跃现金：{{ checkValue(dayNewInfo.total_active_coins_over) }}</div>
              <div class="text item">活跃奖金：{{ checkValue(dayNewInfo.total_active_coins_give_over) }}</div>
              <div class="text item">不活跃现金：{{ checkValue(dayNewInfo.total_inactive_coins_over) }}</div>
              <div class="text item">不活跃奖金：{{ checkValue(dayNewInfo.total_inactive_coins_give_over) }}</div>
            </div>
          </div>
        </el-card>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import {getUserShowViewData} from "@/utils/user";

export default {
  data() {
    return {
      queryParams: {
        start_time: "",
        end_time: "",
        time: "",
        log_type: 'a',
      },
      active: 0,
      allInfo: {
        agent_invite_bonus_amount: 0,
        agent_invite_level_bonus_amount: 0,
        freeze_coins_to_commission_amount: 0,
        commission_operate_in_amount: 0,
      },
      dayNewInfo: {
        total_active_coins_over: 0,
        total_inactive_coins_over: 0,
        total_active_coins_give_over: 0,
        total_inactive_coins_give_over: 0,
        total_agent_count: 0,
      },
      //agentCount: 0, //
      userData: {
        android: 0,
        ios: 0,
        other: 0,
        web: 0,
        sum: 0,
      },
      userActiveData: {
        activeUser: 0,
        loginUser: 0,
        totalPay: 0,
      },
      userRegisterData: {
        registerUser: 0
      },
      refreshDayLoad: false
    };
  },
  computed: {
    statisticalTime() {
      if (this.active === 4) {
        return "全部";
      } else {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        if (params.time) {
          return params['time'][0] + '至' + params['time'][1];
        } else {
          const yesterday = new Date(); // 创建当前时间对象
          return yesterday.toISOString().slice(0, 10).replace(/-/g, '-');
        }
      }
    },
    agencyFees() {
      return this.sumFour(this.allInfo.agent_invite_bonus_amount, this.allInfo.agent_invite_level_bonus_amount, this.allInfo.freeze_coins_to_commission_amount, this.allInfo.commission_operate_in_amount)
    },
    totalProfit() {
      //入账存款
      let coins_pay_in_amount = 0;
      if (this.allInfo.coins_pay_in_amount != null) {
        coins_pay_in_amount = Math.abs(this.allInfo.coins_pay_in_amount);
      }
      //入账存款手续费
      let coins_pay_in_commission_amount = 0;
      if (this.allInfo.coins_pay_in_commission_amount != null) {
        coins_pay_in_commission_amount = Math.abs(this.allInfo.coins_pay_in_commission_amount);
      }
      //入账提款
      let order_coins_pay_out_success_amount = 0;
      if (this.allInfo.order_coins_pay_out_success_amount != null) {
        order_coins_pay_out_success_amount = Math.abs(this.allInfo.order_coins_pay_out_success_amount);
      }
      //入账提款手续费
      let order_coins_pay_out_success_commission_amount = 0;
      if (this.allInfo.order_coins_pay_out_success_commission_amount != null) {
        order_coins_pay_out_success_commission_amount = Math.abs(this.allInfo.order_coins_pay_out_success_commission_amount);
      }
      //代理提现
      let order_commission_pay_out_success_amount = 0;
      if (this.allInfo.order_commission_pay_out_success_amount != null) {
        order_commission_pay_out_success_amount = Math.abs(this.allInfo.order_commission_pay_out_success_amount);
      }
      //代理提现手续费
      let order_commission_pay_out_success_commission_amount = 0;
      if (this.allInfo.order_commission_pay_out_success_commission_amount != null) {
        order_commission_pay_out_success_commission_amount = Math.abs(this.allInfo.order_commission_pay_out_success_commission_amount);
      }
      let netIncome = (coins_pay_in_amount - coins_pay_in_commission_amount) -
          ((order_coins_pay_out_success_amount - order_coins_pay_out_success_commission_amount) + (order_commission_pay_out_success_amount - order_commission_pay_out_success_commission_amount));
      return netIncome.toFixed(4);
    },
  },
  mounted() {
    this.initShowViewData();
    function setColor() {
      var node=document.getElementsByClassName("needSetColor");

      for(var i=0;i<node.length;i++){
        if(node[i].innerHTML>=0){
        node[i].style.color="green";
      }else
        {
          node[i].style.color="#db2c0d"}
      }
       node=document.getElementsByClassName("needSetColorLoss");

      for(var j=0;j<node.length;j++){
        if(node[j].innerHTML>=0){
          node[j].style.color="#db2c0d";
        }else
        {
          node[j].style.color="green"}
      }
    }

    setColor();
  },
  methods: {
    resetting() {
      this.queryParams.start_time = "";
      this.queryParams.end_time = "";
      this.queryParams.time = "";
      this.queryParams.log_type = "a"
      this.changeTime(0);
    },
    changeTime(type) {
      this.active = type;
      if (type == 0) {//今日
        const yesterday = new Date(); // 创建当前时间对象
        const dateStr = this.updateTime(yesterday)
        // const formattedYesterday = day.toISOString().slice(0, 10).replace(/-/g, '-'); // 格式化日期并去除中间的"T"字符
        let formattedYesterday = dateStr.replace(/\//g, '-');
        this.queryParams.time = [formattedYesterday, formattedYesterday];
      } else if (type == 1) {//昨日
        const yesterday = new Date(); // 创建当前时间对象
        yesterday.setDate(yesterday.getDate() - 1); // 将日期设置为昨天
        const dateStr = this.updateTime(yesterday)
        const formattedYesterday = dateStr.replace(/\//g, '-');
        this.queryParams.time = [formattedYesterday, formattedYesterday];
      } else if (type == 2) {//上周
        //this.queryParams.time = this.getLastWeekDates();
        this.queryParams.time = this.chose_date('lastweek');
      } else if (type == 3) {//上月
        //this.queryParams.time = this.getLastMonthDates();
        this.queryParams.time = this.chose_date('lastmonth');
      } else if (type == 4) {//全部
        this.queryParams.time = null;
      }
      this.loadData();
    },
    updateTime(currentDate) {
      let timeZone = "America/Sao_Paulo";
      let dateFormat = new Intl.DateTimeFormat([], {
        timeZone: timeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false
      });
      return dateFormat.format(currentDate);
    },
    sysAnalysis() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      console.log(params.time);

      if (params.time) {
        params.start_time = params['time'][0];
        params.end_time = params['time'][1];
        params.log_type = "d";
      } else {
        params.log_type = "a";
      }
      params.log_rb = 0;
      if (this.active === 4) {
        params.log_rb = 1;
      }
      params.active = this.active;
      const data = {
        cmd: "sys_analysis",
        action: "query_all_analysis",
        data: {
          paramVO: {...params},
        },
      };
      this.$http
          .post("sys_analysis", data)
          .then((response) => {
            this.allInfo = response.data.allInfo;
            this.dayNewInfo = response.data.dayNewInfo;
            //this.agentCount = response.data.agentCount;
            this.userData = response.data.userData;
            this.userActiveData = response.data.userActiveData;
            this.userRegisterData = response.data.userRegisterData;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    refreshDay() {
      if (this.refreshDayLoad) {
        return;
      }
      const data = {
        cmd: "sys_analysis",
        action: "refresh_day_task",
        data: {
          paramVO: {},
        },
      };
      this.refreshDayLoad = true;
      this.$http
          .post("sys_analysis", data)
          .then((response) => {
            this.refreshDayLoad = false;
            this.$message({
              type: "info",
              message: response.errmsg,
            });
          })
          .catch((error) => {
            this.refreshDayLoad = false;
            console.log("error", error);
            this.$message({
              type: "info",
              message: error,
            });
          });
    },
    zhanbi(sum1, sum2) {
      if (sum1 == null || sum2 == null) {
        return "--%";
      }
      if (sum2 == 0) {
        return "0%";
      }
      return ((sum1 / sum2) * 100).toFixed(2) + "%";
    },
    bili(sum1, sum2) {
      if (sum1 == null) {
        sum1 = 0;
      }
      if (sum2 == null) {
        sum2 = 0;
      }
      if (sum2 == 0) {
        return 0;
      }
      return (sum1 / sum2).toFixed(2);
    },
    sumTwo(sum1, sum2) {
      if (sum1 == null) {
        sum1 = 0;
      }
      if (sum2 == null) {
        sum2 = 0;
      }
      return (sum1 + sum2).toFixed(2);
    },
    sumFour(sum1, sum2, sum3, sum4) {
      if (sum1 == null) {
        sum1 = 0;
      }
      if (sum2 == null) {
        sum2 = 0;
      }
      if (sum3 == null) {
        sum3 = 0;
      }
      if (sum4 == null) {
        sum4 = 0;
      }
      return (sum1 + sum2 + sum3 + sum4).toFixed(2);
    },
    loadData() {
      this.sysAnalysis();
    },
    chose_date(type) {
      let nowData = new Date();
      let end = new Date(new Date().toDateString())
      let start = new Date(new Date().toDateString())
      if (type == 'today') { //今天
        end = new Date()
        start.setTime(start.getTime())
      } else if (type == 'yesterday') { //昨天
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
      } else if (type == 'week') { //本周（本周一到当天）
        start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1))
      } else if (type == 'lastweek') { //上周
        const yesterday = new Date(); // 创建当前时间对象
        const dateStr = this.updateTime(yesterday);
        const currentDate = new Date(dateStr);
        var currentDayOfWeek = currentDate.getDay();
        var diff = currentDayOfWeek + 6;
        // 获取上周一的日期对象
        start = new Date(currentDate.getTime() - (diff * 24 * 60 * 60 * 1000));
        // 获取上周日的日期对象
        end = new Date(start.getTime() + (6 * 24 * 60 * 60 * 1000));
        //start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 2))
        //end.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay()))
        //start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      } else if (type == 'month') { //本月
        start = new Date(nowData.getFullYear(), nowData.getMonth(), 1);
        end = new Date(nowData.getFullYear(), nowData.getMonth() + 1, 0);
      } else if (type == 'lastmonth') { //上月
        start = new Date(nowData.getFullYear(), nowData.getMonth() - 1, 1);
        end = new Date(nowData.getFullYear(), nowData.getMonth(), 0);
      }
      return this.search_time([start, end]) //转出时间戳
    },
    search_time(time) {
      let time_arr = []
      time.map(item => {
        let month = '' + (item.getMonth() + 1)
        if (month.length < 2) month = '0' + month;
        let day = '' + item.getDate()
        if (day.length < 2) day = '0' + day;
        let hours = '' + item.getHours()
        if (hours.length < 2) hours = '0' + hours;
        let minutes = '' + item.getMinutes()
        if (minutes.length < 2) minutes = '0' + minutes;
        let seconds = '' + item.getSeconds()
        if (seconds.length < 2) seconds = '0' + seconds;
        var newTime = item.getFullYear() + '-' + month + '-' + day
        time_arr.push(newTime)
      })
      console.log(time_arr)
      return time_arr;
    },
    checkValue(value) {
      if (value === null || value === '' || value === undefined) {
        return 0;
      }
      return value;
    },
    async initShowViewData() {
      if (!await getUserShowViewData()) {
        this.$router.push("/user-list");
      } else {
        this.changeTime(0);
      }
    },
  }
}


</script>

<style lang="scss" scoped>
.big-page {
  background: white;
  padding: 20px;
}

.search-item, .search-btn {
  text-align: left;
}

/deep/ .search-item .el-date-editor {
  width: 100% !important;
  box-sizing: border-box !important;
}

/deep/ .search-btn .el-button {
  width: 100% !important;
}

.el-row {
  padding-bottom: 15px;
}

.el-col {
  padding-bottom: 10px;
}

.el-card {
  padding: 0 10px;
  height: 218px;
}

/deep/ .el-card__header {
  text-align: left;
}

/deep/ .el-card__body {
  text-align: left;
}

/deep/ .el-card {
  height: 240px;
}

.content-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
  word-wrap: break-word;
}

.content-title.more-big {
  font-size: 22px;
}

.qingqing {
  color: #85b90b;
}
.green {
  color: green;
}

.red {
  color: #db2c0d;
}

.content-body .text.item {
  word-wrap: break-word;
}

@media (max-width: 767px) {
  .el-card {
    height: auto;
  }
}

.page-title {
  text-align: left;
  font-weight: bold;
}

.page-top-text {
  white-space: nowrap;
  font-size: 14px;
}

.page-top-text.p-right {
  text-align: right;
}

.top-total-single {
  padding: 0px 10px;
}

.top-total-box {
  display: inline-block;
}

@media (max-width: 991px) {
  .top-total-single {
    width: 50%;
    display: inline-block;
    padding: 0px;
    text-align: left;
  }
  .top-total {
    width: 100%;
    display: block;
    text-align: left;
  }
  .page-top-text {
    text-align: left !important;
  }
  .top-total-box {
    display: block !important;
  }
}
</style>
<style lang="scss">
@media (max-width: 767px) {
  .el-aside {
    display: none !important;
  }
}
</style>
