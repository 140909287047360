function removeEmptyProperties(obj) {
  // 将对象转换为 [key, value] 数组
  const entries = Object.entries(obj);
  // 过滤掉属性值为空字符串的属性
  const filteredEntries = entries.filter(([, value]) => value !== "" && JSON.stringify(value) !== '{}');
  // 将过滤后的数组转换回对象
  const filteredObj = Object.fromEntries(filteredEntries);
  return filteredObj;
}

function dateFormat(dateData,type=1) {
  if(!dateData || dateData==null){
    return '';
  }
  const date = new Date(dateData);
  const y = date.getFullYear();
  const mold = date.getMonth() + 1;
  const m = mold < 10 ? "0" + mold : mold;
  const dold = date.getDate();
  const d = dold < 10 ? "0" + dold : dold;
  let time = y + "-" + m + "-" + d;

  if(type==1){
    const h = date.getHours();
    const mmold = date.getMinutes();
    const s = date.getSeconds();
    const hh = h < 10 ? "0" + h : h;
    const mm = mmold < 10 ? "0" + mmold : mmold;
    const ss = s < 10 ? "0" + s : s;

    time = time + " " + hh + ":" + mm + ":" + ss;
  }
  if(type==3){
    time = time + " " + 23 + ":" + 59 + ":" + 59;
  }

  return time;
}

function getRiskLevelExp(value){
  //风险等级：无风险（0）、低风险（1-5）、中高风险（6-9）、高风险（10-14）、极高风险（14+）
  if(value==0){
    return '无风险';
  }else if(value>=1 && value<=5){
    return '低风险';
  }else if(value>=6 && value<=9){
    return '中高风险';
  }else if(value>=10 && value<=14){
    return '高风险';
  }else if(value>14){
    return '极高风险';
  }else{
    return '';
  }
}

function getVipLevelExp(value){
  let vip_level_arr = [
    {label:'Unranked',value:0},
    {label:'Bronze',value:1},
    {label:'Silver',value:2},
    {label:'Gold',value:3},
    {label:'Platinum',value:4},
    {label:'Diamond',value:5},
    {label:'Crown',value:6},
  ];
  for(let i of vip_level_arr){
    if(i.value==value){
      return i.label;
    }
  }
  return value;
}
// 0 是千分数  1 百分数
function formatRebate(a,b,type,retain,percent) {
    if (a === '' || b === '' ||a === 0|| b ===0 || a === null || b === null) {
        if (type===0){
            return '0‰';
        }else {
            return '0%';
        }
    }
    // 计算返点比例
    const ratio = a / b;
    const formattedRatio = (ratio * percent) .toFixed(retain);
    // 返回格式化后的结果
    if (type===0){
        return `${formattedRatio}‰`
    }else {
        return `${formattedRatio}%`
    }
}

const cdn = "https://cdn.betmace.com";

export default {
  removeEmptyProperties,
  dateFormat,
  getRiskLevelExp,
  getVipLevelExp,
  formatRebate,
  cdn
};
