<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="" style="width: 239px;">
          <el-input clearable  placeholder="请输入平台订单号、用户ID" v-model="queryParams.where_value"></el-input>
        </el-form-item>
<!--        <el-form-item label="用户：">-->
<!--          <el-input placeholder="用户ID"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="代付通道：">-->
<!--          <el-select placeholder="请选择支付通道">-->
<!--            <el-option label="label" value="value"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="用户类型：">
          <el-select clearable  placeholder="请选择用户类型" v-model="queryParams.agentType">
            <el-option
                v-for="(item, index) in agentTypeArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select placeholder="请选择订单状态" v-model="queryParams.status">
            <el-option
                clearable
                v-for="(item, index) in statusArr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单金额：">
          <el-input clearable  placeholder="最小金额" v-model="queryParams.min_amount"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input  clearable placeholder="最大金额" v-model="queryParams.max_amount"></el-input>
        </el-form-item>
        <el-form-item label="订单时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
<!--        <el-form-item label="订单金额：">-->
<!--          <el-input placeholder="最小金额"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="">-->
<!--          <el-input placeholder="最大金额"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="PIX：">-->
<!--          <el-input placeholder="PIX"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="操作人：">-->
<!--          <el-input placeholder="操作人代码"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="订单时间：">-->
<!--          <el-date-picker-->
<!--            class="select1"-->
<!--            type="daterange"-->
<!--            value-format="yyyy/MM/dd"-->
<!--            range-separator="To"-->
<!--            start-placeholder="开始日期"-->
<!--            end-placeholder="结束日期"-->
<!--          >-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->

        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon><Search /></el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
          <el-button type="primary" @click="editConfig">
            <span>提款解锁配置</span>
          </el-button>
          <el-button @click="exportData">导出数据</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-row style="background-color: white; padding: 10px" v-if="showViewData">
      <el-space size="10">
        <el-row>
          <el-col span="">待处理款：</el-col>
          <el-col span="">{{pendingPayment}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">发起提款：</el-col>
          <el-col span="">{{initiateWithdrawal}}</el-col>
        </el-row>
        <el-row>
          <el-col span="">入账提款：</el-col>
          <el-col span="">{{accountsWithdrawals}}</el-col>
        </el-row>
        <el-button type="" @click="doRefresh">刷新</el-button>
      </el-space>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column prop="create_time_str" label="发起时间"></el-table-column>
      <el-table-column prop="merchantorderid" label="平台订单号"></el-table-column>
      <el-table-column prop="disbursement_amount" label="订单金额"></el-table-column>
      <el-table-column prop="create_user" label="发起用户"></el-table-column>
      <el-table-column prop="userid" label="用户ID"></el-table-column>
      <el-table-column prop="recharge"  label="总存款"></el-table-column>
      <el-table-column prop="withdrawal"  label="总提款"></el-table-column>
      <el-table-column prop="total_win_loss"  label="总输赢"></el-table-column>
      <el-table-column prop="risk_level_exp" label="风险等级">
        <template v-slot="{row}">
          <span @click="showUserRisk(row.userid)" v-if="row.risk_level>=6" style="color:red;text-decoration:underline;cursor: pointer;">{{ row.risk_level_exp }}</span>
          <span @click="showUserRisk(row.userid)" v-else style="text-decoration:underline;cursor: pointer;">{{ row.risk_level_exp }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="statusStr" label="订单状态">
        <template #default="scope">
          <el-button type="primary" size="small" @click="handleWait(scope.$index)" v-if="scope.row.status==1">{{scope.row.statusStr}}</el-button>
          <el-button type="warning" size="small" @click="handleFail(scope.$index)" v-else-if="scope.row.status==2">{{scope.row.statusStr}}</el-button>
          <el-button type="primary" size="small" @click="unlock(scope.$index)"  v-else-if="scope.row.status==3">{{scope.row.statusStr}}</el-button>
          <el-button type="info" size="small" @click="handleBreakFrozen(scope.$index)" v-else-if="scope.row.status==8">{{scope.row.statusStr}}</el-button>
          <el-button type="success" size="small" v-else-if="scope.row.status==5">{{scope.row.statusStr}}</el-button>
          <el-button type="primary" size="small" v-else>{{scope.row.statusStr}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="完成时间"></el-table-column>
      <el-table-column prop="order_id" label="代付订单号"></el-table-column>
      <el-table-column prop="traceid" label="代付流水号"></el-table-column>
      <el-table-column prop="result_msg" label="返回信息"></el-table-column>
      <el-table-column prop="update_user" label="操作人"></el-table-column>
      <el-table-column prop="payment_channel_code" label="代付通道"></el-table-column>
      <el-table-column prop="" label="操作">
        <template #default="scope">
          <el-button type="primary" size="small" @click="goDetail(scope.$index)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>

  <el-dialog
      v-model="dialogVisible"
      title="提款解锁配置"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-table :data="configDataView" v-if="dialogVisible">
      <el-table-column prop="type_desc" label="金流类型（真金）"></el-table-column>
      <el-table-column prop="unlock_multiple" label="解锁倍数要求">
        <template #default="scope">
          <el-input-number v-model="scope.row.unlock_multiple" placeholder="请输入"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="withdrawable_processing" label="可提现余额处理">
        <template #default="scope">
          <el-select placeholder="请选择" v-model="scope.row.withdrawable_processing">
            <el-option
                v-for="(item, index) in withdrawable_processing_arr"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="orderDialogVisible"
      title="客服处理"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
      :show-close="false"
  >
    <el-form :model="orderForm">

      <el-form-item label="" :label-width="auto">
        <el-text>你好，{{userInfo.name}}，当前订单已被自动锁定（{{lockLeaveTime}}后自动解锁）（可操作客服：{{userInfo.name}}）</el-text>
      </el-form-item>

      <el-form-item label="" :label-width="auto" class="aaa">
        <el-text>订单金额：{{orderItem.disbursement_amount}}</el-text>
        <el-text>用户风险等级：{{orderItem.risk_level_exp}}</el-text>
        <el-text>用户VIP级别：{{orderItem.vip_level_exp}}</el-text>
      </el-form-item>

      <el-form-item label="请选择管理操作：" v-if="orderItem.status==1">
        <el-select placeholder="请选择" v-model="orderForm.status" style="width:80%;">
          <el-option
              v-for="(item, index) in waitArr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="请选择管理操作：" v-if="orderItem.status==2">
        <el-select placeholder="请选择" v-model="orderForm.status" style="width:80%;">
          <el-option
              v-for="(item, index) in failArr"
              :key="index"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="请填写备注信息：">
        <el-input type="textarea" v-model="orderForm.remark" placeholder="请输入巴普语言" style="width:80%;"></el-input>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="orderDialogCancelHandle">取消</el-button>
        <el-button type="primary" @click="orderDialogOkHandle">确定并提交</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
      v-model="showRisk"
      title="用户风险系数"
      style="text-align: left"
      :close-on-click-modal="false"
      align-center
  >
    <el-row style="padding-bottom: 20px">
      <el-col :span="4">用户id</el-col>
      <el-col :span="4">{{userid_s}}</el-col>
    </el-row>
    <el-row>
      <el-col :span="12">风险类别</el-col>
      <el-col :span="6">风险系数</el-col>
      <el-col :span="6">风险判定</el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">提存比：{{ user_risk.withdrawals_recharge_rate }}</el-col>
      <el-col :span="6">{{ user_risk.withdrawals_recharge_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.withdrawals_recharge_coefficient==0">无风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>0 && user_risk.withdrawals_recharge_coefficient<=5">低风险</span>
        <span v-else-if="user_risk.withdrawals_recharge_coefficient>5 && user_risk.withdrawals_recharge_coefficient<=9">中高风险</span>
        <span
            v-else-if="user_risk.withdrawals_recharge_coefficient>9 && user_risk.withdrawals_recharge_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">关联账号数：{{ user_risk.ip_user_count }}</el-col>
      <el-col :span="6">{{ user_risk.ip_user_count_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.ip_user_count_coefficient==0">无风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>0 && user_risk.ip_user_count_coefficient<=5">低风险</span>
        <span
            v-else-if="user_risk.ip_user_count_coefficient>5 && user_risk.ip_user_count_coefficient<=9">中高风险</span>
        <span v-else-if="user_risk.ip_user_count_coefficient>9 && user_risk.ip_user_count_coefficient<=14">高风险</span>
        <span v-else>极高风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:20倍(*{{ user_risk.win_rate_20 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_20_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_20_coefficient==0">无风险</span>
        <span v-else>低风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:50倍(*{{ user_risk.win_rate_50 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_50_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_50_coefficient==0">无风险</span>
        <span v-else>中风险</span>
      </el-col>
    </el-row>
    <el-row class="riskDiv">
      <el-col :span="12">高倍中奖:100倍(*{{ user_risk.win_rate_100 }})</el-col>
      <el-col :span="6">{{ user_risk.win_rate_100_coefficient }}</el-col>
      <el-col :span="6">
        <span v-if="user_risk.win_rate_100_coefficient==0">无风险</span>
        <span v-else>高风险</span>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showRisk=false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {getUserShowViewData} from "@/utils/user";
import * as XLSX from "xlsx";

export default {
  data() {
    const defaultItem = {

    };
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      tableData: [],
      item: { ...defaultItem },
      queryParams: { ...defaultItem },
      total: 0,
      pages: 0,
      page: 0,
      pendingPayment: 0,
      initiateWithdrawal: 0,
      accountsWithdrawals: 0,
      statusArr: [
        {label:'待处理',value:1},
        {label:'代付失败',value:2},
        {label:'锁定中',value:3},
        {label:'代付中',value:4},
        {label:'已完成',value:5},
        {label:'已拒绝',value:6},
        {label:'已退单',value:7},
        {label:'已冻结',value:8},
        {label:'已关闭',value:9},
        {label:'拒绝并挂起',value:10},
        {label:'退单并挂起',value:11},
        {label:'待审核',value:12},
      ],
      configData: [],
      configDataView: [],
      dialogVisible: false,
      withdrawable_processing_arr: [
        {label:'是',value:1},
        {label:'否',value:0},
      ],
      orderDialogVisible: false,
      orderItem: {},
      agentTypeArr: [
        {label:'普通用户',value:0},
        {label:'全民代理',value:1},
        {label:'签约代理',value:2},
      ],
      waitArr: [
        {label:'通过',value:4},
        {label:'拒绝',value:6},
        {label:'冻结订单',value:8},
        {label:'订单转完成',value:5},
        {label:'拒绝并挂起',value:10},
      ],
      failArr: [
        {label:'退单',value:7},
        {label:'订单转完成',value:5},
        {label:'退单并挂起',value:11},
      ],
      orderForm: {
        status: '',
        remark: ''
      },
      timeLimit: 60*10*1000,
      lockLeaveTime: '',
      userInfo: {},
      showViewData: false,
      showRisk: false,
      user_risk: null,
      userid_s: null,
    };
  },
  methods: {
    showUserRisk(userid) {
      this.userid_s =userid;
      const data = {
        cmd: "user",
        action: "user_risk_coefficient",
        data: {
          paramVO: {},
          userid: userid
        },
      };
      this.$http
          .post("user", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.showRisk = true;
            this.user_risk = response.data.user_risk;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      this.queryData();
    },
    doRefresh() {
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      //
      let data = {
        cmd: "withdrawal",
        action: "query_withdrawal_list",
        data: {
          paramVO: params,
          page: index,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.tableData = response.data.deposit_list;
            this.tableData.forEach((item) => {
              //status:1-待处理2-代付失败3-锁定中4-代付中5-已完成6-已拒绝7-已退单8-已冻结9-已关闭
              for(let i of this.statusArr){
                if(i.value==item.status){
                  item.statusStr = i.label;
                  break;
                }
              }
              item.create_time_str = this.$tools.dateFormat(item.create_time)
              item.risk_level_exp = this.$tools.getRiskLevelExp(item.risk_level)
              item.vip_level_exp = this.$tools.getVipLevelExp(item.vip_level)
            });

            this.page = response.data.page;
            this.pages = response.data.pages;
            this.total = response.data.total;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    goDetail(index) {
      let row = this.tableData[index];
      this.$router.push(`/draw-money-detail/${row.id}`)
    },
    getConfig() {
      let data = {
        cmd: "withdrawal",
        action: "withdrawal_config",
        data: {
          paramVO: {},
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.configData = response.data.withdrawal_config;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    editConfig() {
      this.configDataView = JSON.parse(JSON.stringify(this.configData));
      this.dialogVisible = true;
    },
    dialogOkHandle() {
      // console.log('this.configDataView',this.configDataView)
      this.setConfig();
    },
    setConfig() {
      const data = {
        cmd: "withdrawal",
        action: "update_withdrawal_config",
        data: {
          paramVO: this.configDataView,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.dialogVisible = false;
            this.getConfig();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    lockOrder(callback) {
      const data = {
        cmd: "withdrawal",
        action: "lock_order",
        data: {
          paramVO: {},
          id: this.orderItem.id,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.initTimeCount();
            callback();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    unlockOrder(callback) {
      const data = {
        cmd: "withdrawal",
        action: "unlock_order",
        data: {
          paramVO: {},
          id: this.orderItem.id,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            if (callback){
              callback();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    orderDialogCancelHandle() {
      this.unlockOrder(null);
      this.orderDialogVisible = false;
    },
    orderDialogOkHandle() {
      if(this.orderItem.status==1){
        this.waitDeal();
      }else if(this.orderItem.status==2){
        // this.failDeal();
        this.waitDeal();
      }
    },
    waitDeal() {
      const data = {
        cmd: "withdrawal",
        action: "pending_order",
        data: {
          paramVO: {},
          id: this.orderItem.id,
          status: this.orderForm.status,
          remark: this.orderForm.remark,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.orderDialogVisible = false;
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    failDeal() {
      const data = {
        cmd: "withdrawal",
        action: "payment_failed_order",
        data: {
          paramVO: {},
          id: this.orderItem.id,
          status: this.orderForm.status,
          remark: this.orderForm.remark,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.orderDialogVisible = false;
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleWait(index) {
      Object.keys(this.orderForm).forEach(
          (key) => (this.orderForm[key] = "")
      );
      let self = this;
      this.orderItem = JSON.parse(JSON.stringify(this.tableData[index]));
      this.lockOrder(function(){
        self.orderDialogVisible = true;
      });
    },
    handleFail(index) {
      Object.keys(this.orderForm).forEach(
          (key) => (this.orderForm[key] = "")
      );
      let self = this;
      this.orderItem = JSON.parse(JSON.stringify(this.tableData[index]));
      this.lockOrder(function(){
        self.orderDialogVisible = true;
      });
    },
    unlock(index) {
      Object.keys(this.orderForm).forEach(
          (key) => (this.orderForm[key] = "")
      );
      this.orderItem = JSON.parse(JSON.stringify(this.tableData[index]));
      let self = this;
      this.unlockOrder(function(){
        self.$message({
          type: "info",
          message: "解锁成功",
        });
        self.queryData();
      });
    },
    initTimeCount() {
      this.timeLimit = 60*10*1000;
      this.countTime();
    },
    countTime() {
      var self = this;
      if(self.timeLimit>0){
        this.lockLeaveTime = this.getLeaveTime(this.timeLimit);
        this.timeLimit = this.timeLimit - 1000;
        setTimeout(()=>{
          self.countTime();
        },1000)
      }else{
        this.orderDialogVisible = false;
      }
    },
    getLeaveTime(time){
      var subtime = time / 1000;    //计算时间差,并将毫秒转化为秒
      var days = parseInt(subtime / 86400);  //天  24*60*60*1000
      var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
      var mins = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
      var secs = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
      if(days>0){
        return days + "天" + hours + "小时" + mins + "分钟";
      }else if(hours>0){
        return hours + "小时" + mins + "分钟";
      }else{
        return mins + "分" + secs + "秒 ";
      }
    },
    handleBreakFrozen(index) {
      this.$confirm("确定要解除冻结吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.breakFrozenData(this.tableData[index].id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
    },
    breakFrozenData(id) {
      const data = {
        cmd: "withdrawal",
        action: "unfreeze_order",
        data: {
          paramVO: {},
          id: id,
        },
      };
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.queryData();
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    getUserInfo(){
      const data = {
        cmd: "pub_platform_user",
        action: "user_info",
        data: {},
      };
      this.$http
          .post("pub_platform_user", data)
          .then((response) => {
            this.userInfo = response.data.userInfo
          })
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      //时间
      if(params.time){
        params.start_time = this.$tools.dateFormat(params['time'][0],2);
        params.end_time = this.$tools.dateFormat(params['time'][1],2);
      }
      const data = {
        cmd: "withdrawal",
        action: "query_statistics",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("withdrawal", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.pendingPayment = response.data.pendingPayment;
            this.initiateWithdrawal = response.data.initiateWithdrawal;
            this.accountsWithdrawals = response.data.accountsWithdrawals;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    async initShowViewData() {
      this.showViewData = await getUserShowViewData();
    },
    async exportData() {
      let flag = true;
      let index = 0;
      let pageSize = 1000;
      let listData = [];
      while (flag) {
        const params = this.$tools.removeEmptyProperties(this.queryParams);
        //时间
        if(params.time){
          params.start_time = this.$tools.dateFormat(params['time'][0],2);
          params.end_time = this.$tools.dateFormat(params['time'][1],2);
        }
        //
        let data = {
          cmd: "withdrawal",
          action: "query_withdrawal_list",
          data: {
            paramVO: params,
            page: index,
            pageSize: pageSize,
          },
        };
        await this.$http
            .post("withdrawal", data)
            .then((response) => {
              if (response.data.deposit_list.length !== 0) {
                listData = listData.concat(response.data.deposit_list);
                index++;
                listData.forEach((item) => {
                  //status:1-待处理2-代付失败3-锁定中4-代付中5-已完成6-已拒绝7-已退单8-已冻结9-已关闭
                  for(let i of this.statusArr){
                    if(i.value==item.status){
                      item.statusStr = i.label;
                      break;
                    }
                  }
                  item.create_time_str = this.$tools.dateFormat(item.create_time)
                  item.risk_level_exp = this.$tools.getRiskLevelExp(item.risk_level)
                  item.vip_level_exp = this.$tools.getVipLevelExp(item.vip_level)
                });

              } else {
                flag = false;
              }
            })
            .catch((error) => {
              flag = false;
              console.log("error", error);
            });
      }
      const data = [
        ['发起时间', '平台订单号', '订单金额', '发起用户', '用户ID', '总存款', '总提款', '总输赢', '风险等级','订单状态', '完成时间', '代付订单号', '代付流水号', '返回信息', '操作人', '代付通道'],
      ];
      listData.forEach(item=>{
        data.push([item.create_time_str, item.merchantorderid, item.disbursement_amount, item.create_user, item.userid, item.recharge, item.withdrawal, item.total_win_loss, item.risk_level_exp, item.statusStr, item.update_time, item.order_id, item.traceid, item.result_msg, item.update_user, item.payment_channel_code]);
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, '提款记录');
      XLSX.writeFile(wb, '提款记录.xlsx');
    },
  },
  mounted() {
    this.queryData();
    this.getConfig();
    this.getUserInfo();
    this.initShowViewData();
  },
};
</script>

<style lang="scss" scoped>
/deep/ .aaa{
  width: 80%;
}
/deep/ .aaa .el-form-item__content{
  justify-content: space-between !important;
}
.riskDiv {
  margin-top: 10px;
  //border: #0d1717 1px dashed;
}
</style>
<style>
@media screen and (max-width: 600px) {
  .el-dialog {
    width: 90% !important;
  }
}
@media screen and (min-width: 600px) {
  .el-dialog {
    width: 35% !important;
  }
}
</style>
