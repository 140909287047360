<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="游戏ID">
          <el-input clearable  placeholder="请输入游戏ID"></el-input>
        </el-form-item>
        <el-form-item label="游戏名称">
          <el-input clearable  placeholder="请输入游戏名称"></el-input>
        </el-form-item>
        <el-form-item label="游戏供应商">
          <el-select  clearable placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏状态">
          <el-select clearable  placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏主类型">
          <el-select clearable  placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他类型">
          <el-select clearable  placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="游戏试玩">
          <el-select clearable  placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary">
            <el-icon><Search /></el-icon>
            <span>搜索</span>
          </el-button>
          <el-button>
            <el-icon><Refresh /></el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <div style="background-color: white">
      <el-row style="text-align: left; padding: 10px">
        <el-button type="primary" @click="addItemHandle">
          <el-icon><Plus /></el-icon>
          <span>新增游戏</span>
        </el-button>
      </el-row>

      <el-table :data="tableData">
        <el-table-column prop="game_id" label="游戏ID"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column prop="game_name_en" label="游戏名称"></el-table-column>
        <el-table-column
          prop="game_category_name"
          label="游戏类型"
        ></el-table-column>
        <el-table-column
          prop="game_provider_name"
          label="游戏供应商"
        ></el-table-column>
        <el-table-column prop="accessCode" label="对接代码"></el-table-column>
        <el-table-column prop="gameCover" label="游戏封面"></el-table-column>
        <el-table-column prop="hotInit" label="初始热度"></el-table-column>
        <el-table-column prop="hotInit" label="动态热度"></el-table-column>
        <el-table-column prop="hotTotal" label="总热度"></el-table-column>
        <el-table-column prop="demoDisabled" label="试玩模式"></el-table-column>
        <el-table-column prop="isNew" label="是否新游"></el-table-column>
        <el-table-column prop="isHot" label="热门游戏"></el-table-column>
        <el-table-column prop="isRecommend" label="游戏推荐"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="itemEditHandle(scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="itemDeleteHandle(scope.$index)"
              >下架</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <el-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    style="text-align: left"
    :close-on-click-modal="false"
    align-center
  >
    <el-form :model="item">
      <el-form-item label="*游戏名称：" :label-width="auto">
        <el-input v-model="item.gameNameEn" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="*游戏标识：">
        <el-input v-model="item.gameTag" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="*供应商：">
        <el-select v-model="item.gameProviderName" placeholder="请选择">
          <el-option label="供应商1" value="1"></el-option>
          <el-option label="供应商2" value="2"></el-option>
          <el-option label="供应商3" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="*游戏封面：">
        <el-upload
          style="
            border: 1px dashed red;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 178px;
            height: 178px;
          "
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <el-icon v-else style="width: 178px; height: 178px"><Plus /></el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="对接代码：">
        <el-input v-model="item.accessCode" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="初始热度：">
        <el-row style="width: 100%">
          <el-col :span="16"
            ><el-input v-model="item.hotInit" placeholder="请输入"></el-input
          ></el-col>
          <el-col :span="8"><div>“1” 热度相当于用户100下注额</div> </el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="游戏类型：">
        <el-select v-model="item.gameTag" placeholder="请选择">
          <el-option label="类型1" value="1"></el-option>
          <el-option label="类型2" value="2"></el-option>
          <el-option label="类型3" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="试玩模式：">
        <el-radio-group v-model="item.demoDisabled">
          <el-radio label="0">开启</el-radio>
          <el-radio label="1">关闭</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="前端展示：">
        <el-radio-group v-model="item.frontShow">
          <el-radio :label="0">展示</el-radio>
          <el-radio :label="1">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="dialogOkHandle">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    const defaultItem = {
      accessCode: "游戏代码",
      createBy: "admin00001",
      createTime: null,
      demoDisabled: 1,
      frontShow: 1,
      gameCategoryId: 20001,
      gameCategoryName: "game_category_20001",
      gameCover: "游戏封面",
      gameId: 10001,
      gameNameEn: "game_name_10001",
      gameProviderId: 30001,
      gameProviderName: "game_provider_30001",
      gameTag: "游戏标签",
      hotDynamic: 80,
      hotInit: 100,
      hotTotal: 3000,
      id: 3,
      isHot: 1,
      isNew: 1,
      isRecommend: 1,
      status: 1,
      updateBy: null,
      updateTime: null,
    };
    return {
      tableData: [],
      item: defaultItem,
      dialogVisible: false,
      dialogTitle: "",
      isEdit: false,
      total: 0,
      pages: 0,
      page: 1,
    };
  },
  methods: {
    itemEditHandle(index) {
      this.dialogTitle = "编辑游戏";
      this.isEdit = true;
      this.item = this.tableData[index];
      this.dialogVisible = true;
    },
    itemDeleteHandle(index) {
      this.$confirm("确定要下架游戏吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteData(this.tableData[index].id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消下架",
          });
        });
    },
    addItemHandle() {
      this.isEdit = false;
      this.dialogTitle = "新增游戏";
      Object.keys(this.item).forEach((key) => (this.item[key] = ""));
      this.dialogVisible = true;
    },
    dialogOkHandle() {
      if (this.isEdit) {
        this.updateData();
      } else {
        this.addData();
      }
      this.dialogVisible = false;
    },
    addData() {
      const data = {
        action: "add",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
        .post("game_shelve_unshelve", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    updateData() {
      const data = {
        action: "update",
        data: {
          paramVO: this.item,
        },
      };
      this.$http
        .post("game_shelve_unshelve", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    queryData(index = 1) {
      const data = {
        action: "query",
        data: {
          paramVO: {
            status: "1",
          },
          page: index,
        },
      };
      this.$http
        .post("game_shelve_unshelve", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.tableData = response.data.gameProviderVOList;
          this.page = response.data.page;
          this.pages = response.data.pages;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteData(id) {
      const data = {
        action: "delete",
        data: {
          paramVO: {
            id,
          },
        },
      };
      this.$http
        .post("game_shelve_unshelve", data)
        .then((response) => {
          console.log("response.data", response.data);
          this.queryData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>

<style lang="scss" scoped></style>
