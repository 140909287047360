<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="对象类型">
          <el-select  clearable placeholder="请选择">
            <el-option label="level 1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作人">
          <el-input clearable  placeholder="请输入操作代号"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            clearable
            class="select1"
            type="daterange"
            value-format="yyyy/MM/dd"
            range-separator="To"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">
            <el-icon><Search /></el-icon>
            <span>搜索</span>
          </el-button>
          <el-button type="success">
            <span>新增邮件</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <br />

    <el-table :data="tableData">
      <el-table-column label="邮件发送时间"></el-table-column>
      <el-table-column label="发送对象"></el-table-column>
      <el-table-column label="发送对象详情"></el-table-column>
      <el-table-column label="邮件主题"></el-table-column>
      <el-table-column label="邮件内容"></el-table-column>
      <el-table-column label="操作人"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
